import React, { useEffect, useRef, useState } from 'react'
import { toasts } from '../../../../../services/toast.service';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../../../common/components/OpenCardComponent';
import Page from '../../../../../layout/Page/Page';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../../../services/AlertService';
import Button from '../../../../bootstrap/Button';
import Card, { CardHeader, CardActions, CardBody } from '../../../../bootstrap/Card';
import Collapse from '../../../../bootstrap/Collapse';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import Select from '../../../../bootstrap/forms/Select';
import { getAllStudent, getProfileForStudent, getStudentDetailsBySemesterId } from '../../../../../services/student.service';
import { getGraduationType, getSectionDetails } from '../../../../../services/common.service';
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from '../../../../../services/master.service';
import { getCollegeDetails } from '../../../../../services/college.service';
import Icon from '../../../../icon/Icon';
import SearchableSelect from '../../../../../common/components/SearchableSelect';
import dayjs from 'dayjs';
import CollegeLogo from '../../../../../assets/logos/logo.jpg';

const AttendanceCertificate = () => {
    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
        getAllStudentDetails();
        getCollege();
    }, [])

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)

    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)
    const componentRef = useRef(null);
    const [departmentData, setDepartmentData] = useState([])
    const [courseData, setCourseData] = useState([])
    const [graduationTypeData, setGraduationTypeData] = useState([])
    const [batchMasterData, setBatchMasterData] = useState([])
    const [semesterDetailsData, setSemesterDetailsData] = useState([])
    const [sectionDetailsData, setSectionDetailsData] = useState([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [collegeDetailsData, setCollegeDetailsData] = useState<any>([]);
    const [allStudentData, setAllStudentData] = useState([])
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataSuccess, setDataSuccess] = useState(false)
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const [isLoader, setIsLoader] = useState(false)

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectStudent = (e: any) => {
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    function viewParticularStudent() {
        setDataSuccess(false)
        setIsLoader(true);
        getStudentProfile(studentDetailsId?.value);
    }

    function viewStudentClassWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        getStudentDetailsBySemester(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    function getStudentProfile(studentDetailsId: any) {
        setIsLoader(true);
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setDataSuccess(true)
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
                setDataSuccess(false)
            }
        );
    }

    function getStudentDetailsBySemester(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        setIsLoader(true);
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setDataSuccess(true)
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setIsLoader(false);
                    setDataSuccess(false)
                    setIsOpenListCard(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                    setIsOpenListCard(true);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
                setDataSuccess(false)
            }
        );
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSectionDetailsData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setAllStudentData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        );
    }

    function getCollege() {
        getCollegeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.collegeDetails;
                    if (data != undefined) {
                        setCollegeDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function selectStudentCard() {
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setGraduationTypeId('')
        setStudentDetailsId('')
    }

    return (
        <PageWrapper title='Student List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardActions>
                                <Button
                                    icon='PersonSearch'
                                    color='info'
                                    isLight={isStudentOrClassWise ? false : true}
                                    onClick={selectStudentCard}>
                                    Student
                                </Button>

                                <Button
                                    icon='PeopleAlt'
                                    color='info'
                                    isLight={isStudentOrClassWise ? true : false}
                                    onClick={selectStudentCard}>
                                    Class Wise
                                </Button>
                            </CardActions>
                        </CardHeader>
                        {isStudentOrClassWise ?
                            <CardBody>
                                <div className='row'>
                                    <div className="col-4 mt-3">
                                        <FormGroup id='studentDetailsId' label='Student' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                list={allStudentData.map((data: any) => (
                                                    { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-3 mt-4">
                                        <Button icon='ArrowDownward' color='primary' onClick={viewParticularStudent} isDisable={studentDetailsId?.value == undefined ? true : false}>View</Button>
                                    </div>
                                </div>
                            </CardBody> :
                            <CardBody>
                                <div className='row'>
                                    <div className='col-3'>
                                        <FormGroup id='departmentId' label='Department' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                list={departmentData.map((data: any) => (
                                                    { value: data.departmentId, label: data.departmentName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    {departmentId ? <div className='col-3'>
                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {graduationTypeId ? <div className='col-3'>
                                        <FormGroup id='courseId' label='Course' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                list={courseData.map((data: any) => (
                                                    { value: data.courseId, label: data.courseName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {courseId ? <div className='col-3'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {batchMasterId ? <div className='col-3 mt-3'>
                                        <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                list={semesterDetailsData.map((data: any) => (
                                                    { value: data.semesterDetailsId, label: data.semesterNumber }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {semesterDetailsId ? <div className='col-3 mt-3'>
                                        <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                list={sectionDetailsData.map((data: any) => (
                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {sectionDetailsId ? <div className="col-3 mt-4">
                                        <Button icon='ArrowDownward' color='primary' onClick={viewStudentClassWiseList}>View</Button>
                                    </div> : null}
                                </div>
                            </CardBody>}
                    </Card>
                </Collapse>
                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                {dataSuccess ? <>
                    <div className='d-flex col-7 justify-content-end' style={{ marginLeft: "420px" }}>
                        <Button icon="Print" isLight color="primary" onClick={handlePrint}>Print</Button>
                    </div>
                    <CardBody ref={componentRef} >
                        {studentDetailsData.map((studentData: any) => (
                            <>
                                <table cellPadding={10} cellSpacing={10} width='100%' style={{ fontFamily: "Times New Roman" }} key={studentData.studentDetailsId} className='border border-dark'>
                                    <tbody style={{ borderBottom: 'solid 1px black' }}>
                                        {collegeDetailsData.map((college: any) => (
                                            <tr key={college.collegeDetailsId}>
                                                <td align="center" width="20%">
                                                    <img
                                                        height='100px'
                                                        width='100px'
                                                        src={CollegeLogo}
                                                    />
                                                </td>
                                                <th className='text-center' align="center" style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)', width: "60%"
                                                }}>
                                                    <h1 className="text-uppercase">
                                                        <b style={{ fontSize: '90%' }}> {college.collegeName}</b>
                                                    </h1>

                                                    <p className="fs-6">({college.description})<br />{college.address}<br />
                                                        <Icon icon="Email" /> {college.website} <Icon icon="Phone" /> {college.phone} <Icon icon="PhoneAndroid" /> {college.cellNo}</p>
                                                </th>
                                                <td width="20%"></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '20%' }}></th>
                                            <th style={{ width: '60%' }} className="text-center" >
                                                <h2 className="mt-5 text-dark" style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)', }}>
                                                    <u>ATTENDANCE CERTIFICATE</u>
                                                </h2>
                                            </th>
                                            <th style={{ width: '20%' }}>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td width='100%' colSpan={4} className="fs-4"
                                                style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)', }}>
                                                <br />
                                                <label style={{ marginLeft: "30px" }}>This is to certify that Selvi / Selvan{' '}</label>
                                                <label style={{ width: "350px", borderBottom: "solid rgb(61, 57, 57) 1px" }} className="fw-bold text-center">
                                                    <b contentEditable="true" suppressContentEditableWarning={true} >
                                                        {studentData.studentName}
                                                    </b>
                                                </label>
                                                <label style={{ marginLeft: "30px" }}>has studied{' '}</label>
                                                <br />
                                                <br />
                                                <br />
                                                <label style={{ width: "300px", borderBottom: "solid rgb(61, 57, 57) 1px", marginLeft: "30px" }} className="fw-bold text-center">
                                                    <b contentEditable="true" suppressContentEditableWarning={true} >
                                                        {studentData.courseName}
                                                    </b>
                                                </label>
                                                <label style={{ marginLeft: "30px" }}>in this college during the academic year</label>
                                                <label style={{ width: "200px", borderBottom: "solid rgb(61, 57, 57) 1px" }} className="fw-bold text-center">
                                                    <b contentEditable="true" suppressContentEditableWarning={true} >
                                                        {studentData.academicPeriod}
                                                    </b>
                                                </label>
                                                <br />
                                                <br />
                                                <br />
                                                <label style={{ marginLeft: "30px" }}>and her / his attendance was{' '}</label>
                                                <label style={{ width: "350px", borderBottom: "solid rgb(61, 57, 57) 1px" }} className="fw-bold text-center">
                                                    <b contentEditable="true" suppressContentEditableWarning={true} >
                                                    </b>
                                                </label>
                                                <br />
                                                <br />
                                                <br />
                                                {collegeDetailsData.map((i: any) => (
                                                    <tr>
                                                        <th style={{ width: '30%' }}>
                                                            <label>Place : {i.district}</label><br />
                                                            <label className='mt-3'>Date :{dayjs(new Date()).format('DD/MM/YYYY')}
                                                            </label>
                                                        </th>
                                                        <th style={{ width: '35%' }} className="text-center">
                                                        </th>
                                                        <th style={{ width: '30%' }}>
                                                            <label style={{ marginLeft: "100px" }}>PRINCIPAL</label>
                                                            <label className='mt-3'>
                                                               {i.collegeName}
                                                            </label>
                                                        </th>
                                                    </tr>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <br />
                                <br />
                            </>
                        ))}
                    </CardBody>
                </> : null}
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    );
}

export default AttendanceCertificate