import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile, printPage } from "../../../services/ExportService";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { useReactToPrint } from "react-to-print";
import Icon from "../../icon/Icon";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getXIIInstitutionReport, getXIIInstitutionReportBySchoolId } from "../../../services/report.service";
import AuthContext from "../../../contexts/authContext";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";

function SchoolWiseReport() {

  useEffect(() => {
    getSchoolAreaWiseDepartment()
  }, [])

  const { userAccountId, userTypeId } = useContext(AuthContext);
  const { themeStatus, darkModeStatus } = useDarkMode();
  const [schoolWiseReportData, setSchoolWiseReportData] = useState([]);
  const [studentDetailsData, setStudentDetailsData] = useState([])
  const [columnVisibilityData, setColumnVisibilityData] = useState([]);
  const [allColumnsData, setAllColumnsData] = useState([]);
  const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
  const [columnDataById, setColumnDataById] = useState([])
  const componentRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false)
  const [schoolName, setSchoolName] = useState('')
  const [dataStatus, setDataStatus] = useState(false)
  // ForModal
  const [schoolWiseReportModal, setSchoolWiseReportModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { items, requestSort, getClassNamesFor } = useSortableData(schoolWiseReportData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);

  const [noDataMsg, setNoDataMsg] = useState('')

  const columnVisibilityForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },

    //validateOnChange: false,
    onSubmit: () => { },
  });

  // Filter
  const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

  function onActivate(i: any) {
    if (i != undefined) {
      let selectedStudent = i
      setSchoolWiseReportModal(true);
      setSchoolName(selectedStudent.schoolName)
      getXIIInstitutionReportBySchoolIdList(selectedStudent.XIISchoolNameId)
    }
  }

  function getSchoolAreaWiseDepartment() {
    setDataStatus(false)
    getXIIInstitutionReport(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.xIIInstitutionReport;
          if (data != undefined) {
            setDataStatus(true)
            setSchoolWiseReportData(data);
            getColumnsForTable('getXIIInstitutionReport', 'get');
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            setDataStatus(true)
            toasts("Undefined Data", "Error")   
          }
        }
        else if (response.data.success === false) {
          setDataStatus(true)
          //toasts(response.data.message, "Error")
          getColumnsForTable('getXIIInstitutionReport', 'get');
            setSchoolWiseReportData([]);
            setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true)
          toasts(errorDescription, "Error")
        }
      }, error => {
        setDataStatus(true)
        toasts(error, "Error")
      }
    )
  }

  function getXIIInstitutionReportBySchoolIdList(XIISchoolNameId: any) {
    getXIIInstitutionReportBySchoolId(XIISchoolNameId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.xIIInstitutionReportBySchoolId[0].studentDetails;
          if (data != undefined) {
            setStudentDetailsData(data);
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getColumnsForTable(apiName: any, type: any) {
    getColumnsForDataTable(userTypeId, apiName,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.columnsForDataTable;
          if (data != undefined) {
            let allColumnsData = data;
            setAllColumnsData(allColumnsData)
            let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
            setColumnVisibilityData(columnVisibilityData)

            if (type == 'get') {
              const allData = allColumnsData.map((item: any) => item.isDisplay);
              const allColumns = allData.filter((item: any) => item == false);
              setIsChecked(allColumns == '' ? true : false);
            }
            else if (type == 'post') {
              const all = [];
              let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

              for (let i = 0; i < nonDefaultValue.length; i++) {

                const obj = {
                  columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                  isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                }
                all.push(obj);
              }
              updateColumnsSubmit('', '', all)
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )

  }

  function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

    if (columnVisibilityForm.isValid) {

      setColumnDataById(isDisplay)
      const columnVisibilityPostData = {
        licenseKey: getLicenseKey,
        userAccountId: userAccountId,
        columns: type ? type : [
          {
            columnVisibilityId: columnVisibilityId,
            isDisplay: isDisplay ? 0 : 1
          }
        ]
      }

      updateColumnsForDataTable(columnVisibilityPostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            getColumnsForTable('getXIIInstitutionReport', 'get');
          }
          else if (data.success == false) {
            // toasts(data.success, "Error")
          }
          else {
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            toasts(errorDescription, "Error")
          }
        }
        , (error) => {
          toasts(error, "Error")
        }
      )
    } else if (columnVisibilityForm.isValid == false) {
      toasts("Please fill all the details!", "Error")
    }
  }
  // Export Configuration
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <PageWrapper title="School Wise Report">
        <Page container='fluid'>
          <Card stretch data-tour='list' ref={componentRef} id='pdf'>
            <CardHeader borderSize={1}>
              <CardLabel icon='List' iconColor='info' className="col-lg-6">
                <CardTitle tag='div' className='h5'>XII Institution Report</CardTitle>
              </CardLabel>&nbsp;&nbsp;

              <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
              </ButtonGroup>

              <CardActions className="d-print-none">
                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                  <DropdownToggle>
                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                  </DropdownToggle>
                  <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                    <DropdownItem>
                      <div className='container py-2'>
                        <form className='row g-3'>
                          <div className='col-12'>
                            <FormGroup>
                              <h6>Select All</h6>
                              <Checks
                                id='available'
                                type='switch'
                                label='Select All Columns'
                                onChange={() => getColumnsForTable('getXIIInstitutionReport', 'post')}
                                checked={isChecked}
                                ariaLabel='Available status'
                              />
                            </FormGroup>
                          </div>
                          <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                            <FormGroup>
                              <h6>Columns</h6>
                              <ChecksGroup>
                                {allColumnsData.map((i: any) => (
                                  <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                ))}
                              </ChecksGroup>
                            </FormGroup>
                          </div>
                        </form>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown isButtonGroup>
                  <DropdownToggle>
                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                  </DropdownToggle>
                  <DropdownMenu isAlignmentEnd>
                    <DropdownItem>
                      <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("School Wise Report", schoolWiseReportData, columnVisibilityData)}> Excel </Button>
                    </DropdownItem>
                    <DropdownItem>
                      <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(schoolWiseReportData, columnVisibilityData, "School Wise Report")}> PDF
                      </Button>
                    </DropdownItem>
                    <DropdownItem>
                      <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("School Wise Report", schoolWiseReportData, columnVisibilityData)}> CSV </Button>
                    </DropdownItem>
                    <DropdownItem>
                      <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

              </CardActions>
            </CardHeader>

            <CardBody className='table-responsive' isScrollable>
              {!dataStatus ?
                <TableLoader /> :
                <table className='table table-modern table-hover text-nowrap'>
                  <thead>
                    <tr>
                      <th scope='col' onClick={() => requestSort('sno')}
                        className='cursor-pointer text-decoration-underline'>S.No{' '}
                        <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                      </th>
                      {columnVisibilityData.map((column: any) => (
                        <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                          className='cursor-pointer text-decoration-underline'>
                          {column.columnName}
                          <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                        </th>
                      ))}
                      <th scope='col' className='d-print-none'>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData != "" ?
                      <>
                        {filteredData.map((rowData: any) => (
                          <tr key={rowData.XIISchoolNameId}>
                            <td>{rowData.sno}</td>
                            {columnVisibilityData.map((column: any) => (
                              (column.isDisplay && rowData[column.keyName]) ?
                                <td key={`${rowData.XIISchoolNameId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                            ))}
                            <td className="d-print-none" >
                              <Button color='dark' isLight icon='More' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                          </tr>
                        ))
                        } </>
                      :
                      <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                  </tbody>
                </table>
              }
            </CardBody>
            <PaginationButtons
              className='d-print-none'
              data={items}
              label='items'
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Card>
        </Page>

        <Modal
          titleId='schoolWiseReportModal'
          isOpen={schoolWiseReportModal}
          setIsOpen={setSchoolWiseReportModal}
          isStaticBackdrop={true}
          isCentered
          isScrollable
          size='lg' >
          <ModalHeader setIsOpen={setSchoolWiseReportModal} >
            <ModalTitle id="schoolWiseReportModal">Student List</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="row g-4">
              <div className='col-6 '>
                <div className='d-flex align-items-center'>
                  <div className='flex-shrink-0'>
                    <Icon icon='ListAlt' size='3x' color='info' />
                  </div>
                  <div className='flex-grow-1 ms-3'>
                    <div className='fw-bold fs-5 mb-0'>
                      {schoolName}
                    </div>
                    <div className='text-muted'>
                      School Name
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <table className='table table-modern table-hover'>
                  <thead className='table-info'>
                    <tr>
                      <th>S.NO</th>
                      <th>STUDENT NAME</th>
                      <th>ADMISSION NO</th>
                      <th>COURSE NAME</th>
                      <th>SEMESTER NAME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentDetailsData.map((i: any) => (
                      <tr key={i.studentDetailsId}>
                        <td className="align-self-center">{i.sno}</td>
                        <td className="align-self-center">{i.studentName} </td>
                        <td className="align-self-center">{i.admissionNo} </td>
                        <td className="align-self-center">{i.courseName} </td>
                        <td className="align-self-center">{i.semesterNumber} </td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>

          </ModalBody>
        </Modal>
      </PageWrapper>
    </>
  );
}
export default SchoolWiseReport;