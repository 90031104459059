import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { toasts } from "../../../../services/toast.service";
import { getStudentSchoolName, getStudentSchoolSubject } from "../../../../services/administration.service";
import Button from "../../../bootstrap/Button";
import Card, { CardBody } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import Select from "../../../bootstrap/forms/Select";

interface IStudentProps {
    handleNext1(...args: unknown[]): unknown;
    handlePrevious(...args: unknown[]): unknown;
    setSelectedValue(...args: unknown[]): unknown;
    selectedValue?: any;
}

const MarkInfo: FC<IStudentProps> = ({ handleNext1, handlePrevious, setSelectedValue, selectedValue }) => {

    useEffect(() => {
        getStudentSchoolNameList();
        getStudentSchoolSubjectList();
        addSetValues();
    }, [])

    const [studentSchoolNameData, setStudentSchoolNameData] = useState([])

    //XI subject
    const [studentSchoolSubjectData, setStudentSchoolSubjectData] = useState([])
    const [totalPercentageForXI, setTotalPercentageForXI] = useState('')
    const [totalPercentageForXII, setTotalPercentageForXII] = useState('')
    const [ugDegree, setUgDegree] = useState(false)
    const [pgDegree, setPgDegree] = useState(false)

    const addStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----General Info----////
            graduationTypeId: '',
            admissionNo: '',
            tempNo: 0,
            firstName: '',
            lastName: '',
            surName: '',
            studentNameTamil: '',
            surNameTamil: '',
            dateOfBirth: '',
            genderId: '',
            bloodGroupId: '',
            motherTongue: '',
            nationality: '',
            otherNation: '',
            religionId: '',
            casteUniqueId: '',
            casteDetailsId: '',
            subcaste: '',
            category: '',
            aadharCard: '',
            physicallyChallenged: '',
            physicallyChallengedType: '',
            physicallyChallengedPercentage: '',
            martialStatus: '',
            email: '',
            emisNo: '',
            ///----Address INFO----////
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            permAddr_FlatNo: '',
            permAddr_Street: '',
            permAddr_Area: '',
            permAddr_StateId: '',
            permAddr_CityId: '',
            permAddr_Pincode: '',

            bankName: '',
            bankAccountNo: '',
            branch: '',
            ifscCode: '',
            micrCode: '',
            // ///----Class INFO----////
            departmentId: '',
            courseId: '',
            batchMasterId: '',
            semesterDetailsId: '',
            sectionDetailsId: '',
            shiftId: '',
            studentRollNo: '',
            admissionDateStamp: '',
            sourceName: '',
            transferCourseId: '',
            referralTypeId: '',
            referredId: '',
            referredName: '',
            interimCollegeTransfer: '',
            collegeName: '',
            concessionTypeId: '',
            concessionRemarks: '',
            transportAreaMasterId: '',
            modeOfWays: '',
            isCollegeTransport: '',
            ///----Family INFO----////
            fatherName: '',
            father_AdharNumber: '',
            fatherOccupation: '',
            natureofJoborBusiness: '',
            fatherContactNumber: '',
            fatherEmailId: '',
            motherName: '',
            mother_AdharNumber: '',
            yearlyIncome: '',
            positionHeld: '',
            motherContactNumber: '',
            smsToBeSentNumber: '',
            guardian_Name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_AdharNumber: '',
            isGuardian: 0,
            // // ///----MARK INFO----////
            registerNo_X: '',
            institutionNameAndAddress_X: '',
            boardName_X: '',
            mediumOfInstitution_X: '',
            monthAndYearOfPassing_X: '',

            registerNo_XI: '',
            institutionNameAndAddress_XI: '',
            boardName_XI: '',
            mediumOfInstitution_XI: '',
            monthAndYearOfPassing_XI: '',

            registerNo_XII: '',
            XIISchoolNameId: '',
            boardName_XII: '',
            mediumOfInstitution_XII: '',
            monthAndYearOfPassing_XII: '',

            XI_S1_studentSchoolSubject: '',
            XI_S1_markSecured: '',
            XI_S1_maximumMarks: '',
            XI_S1_monthAndYearOfPassing: '',
            XI_S1_registerNo: '',
            XI_S1_noOfAttempts: '',
            XI_S2_studentSchoolSubject: '',
            XI_S2_markSecured: '',
            XI_S2_maximumMarks: '',
            XI_S2_monthAndYearOfPassing: '',
            XI_S2_registerNo: '',
            XI_S2_noOfAttempts: '',
            XI_S3_studentSchoolSubject: '',
            XI_S3_markSecured: '',
            XI_S3_maximumMarks: '',
            XI_S3_monthAndYearOfPassing: '',
            XI_S3_registerNo: '',
            XI_S3_noOfAttempts: '',
            XI_S4_studentSchoolSubject: '',
            XI_S4_markSecured: '',
            XI_S4_maximumMarks: '',
            XI_S4_monthAndYearOfPassing: '',
            XI_S4_registerNo: '',
            XI_S4_noOfAttempts: '',
            XI_S5_studentSchoolSubject: '',
            XI_S5_markSecured: '',
            XI_S5_maximumMarks: '',
            XI_S5_monthAndYearOfPassing: '',
            XI_S5_registerNo: '',
            XI_S5_noOfAttempts: '',
            XI_S6_studentSchoolSubject: '',
            XI_S6_markSecured: '',
            XI_S6_maximumMarks: '',
            XI_S6_monthAndYearOfPassing: '',
            XI_S6_registerNo: '',
            XI_S6_noOfAttempts: '',

            XII_S1_studentSchoolSubject: '',
            XII_S1_markSecured: '',
            XII_S1_maximumMarks: '',
            XII_S1_monthAndYearOfPassing: '',
            XII_S1_registerNo: '',
            XII_S1_noOfAttempts: '',
            XII_S2_studentSchoolSubject: '',
            XII_S2_markSecured: '',
            XII_S2_maximumMarks: '',
            XII_S2_monthAndYearOfPassing: '',
            XII_S2_registerNo: '',
            XII_S2_noOfAttempts: '',
            XII_S3_studentSchoolSubject: '',
            XII_S3_markSecured: '',
            XII_S3_maximumMarks: '',
            XII_S3_monthAndYearOfPassing: '',
            XII_S3_registerNo: '',
            XII_S3_noOfAttempts: '',
            XII_S4_studentSchoolSubject: '',
            XII_S4_markSecured: '',
            XII_S4_maximumMarks: '',
            XII_S4_monthAndYearOfPassing: '',
            XII_S4_registerNo: '',
            XII_S4_noOfAttempts: '',
            XII_S5_studentSchoolSubject: '',
            XII_S5_markSecured: '',
            XII_S5_maximumMarks: '',
            XII_S5_monthAndYearOfPassing: '',
            XII_S5_registerNo: '',
            XII_S5_noOfAttempts: '',
            XII_S6_studentSchoolSubject: '',
            XII_S6_markSecured: '',
            XII_S6_maximumMarks: '',
            XII_S6_monthAndYearOfPassing: '',
            XII_S6_registerNo: '',
            XII_S6_noOfAttempts: '',

            XI_OverAll_markSecured: '',
            XI_OverAll_maximumMarks: '',
            XII_OverAll_markSecured: '',
            XII_OverAll_maximumMarks: '',
            registerNo_UG: '',
            institutionNameAndAddress_UG: '',
            universityName_UG: '',
            mediumOfInstitution_UG: '',
            monthAndYearOfPassing_UG: '',

            registerNo_PG: '',
            institutionNameAndAddress_PG: '',
            universityName_PG: '',
            mediumOfInstitution_PG: '',
            monthAndYearOfPassing_PG: '',
            registerNo_MPhil: '',
            institutionNameAndAddress_MPhil: '',
            universityName_MPhil: '',
            mediumOfInstitution_MPhil: '',
            monthAndYearOfPassing_MPhil: '',
            UG_courseName: '',
            UG_Part1_SubjectName: '',
            UG_Part1_MarksObtained: '',
            UG_Part1_MaximumMarks: '',
            UG_Part1_Percentage: '',
            UG_Part1_ClassObtained: '',
            UG_Part1_NoOfAttempts: '',
            UG_Part1_RegisterNo: '',
            UG_Part1_Passing: '',
            UG_Part2_SubjectName: '',
            UG_Part2_MarksObtained: '',
            UG_Part2_MaximumMarks: '',
            UG_Part2_Percentage: '',
            UG_Part2_ClassObtained: '',
            UG_Part2_NoOfAttempts: '',
            UG_Part2_RegisterNo: '',
            UG_Part2_Passing: '',
            UG_Part3_SubjectName: '',
            UG_Part3_MarksObtained: '',
            UG_Part3_MaximumMarks: '',
            UG_Part3_Percentage: '',
            UG_Part3_ClassObtained: '',
            UG_Part3_NoOfAttempts: '',
            UG_Part3_RegisterNo: '',
            UG_Part3_Passing: '',
            UG_Allied1_SubjectName: '',
            UG_Allied1_MarksObtained: '',
            UG_Allied1_MaximumMarks: '',
            UG_Allied1_Percentage: '',
            UG_Allied1_ClassObtained: '',
            UG_Allied1_NoOfAttempts: '',
            UG_Allied1_RegisterNo: '',
            UG_Allied1_Passing: '',
            UG_Allied2_SubjectName: '',
            UG_Allied2_MarksObtained: '',
            UG_Allied2_MaximumMarks: '',
            UG_Allied2_Percentage: '',
            UG_Allied2_ClassObtained: '',
            UG_Allied2_NoOfAttempts: '',
            UG_Allied2_RegisterNo: '',
            UG_Allied2_Passing: '',
            UG_Overall_Main_Core: '',
            UG_Overall_Ancy_Allied: '',
            UG_Overall_Part3: '',

            PG_courseName: '',
            PG_Part1_SubjectName: '',
            PG_Part1_MarksObtained: '',
            PG_Part1_MaximumMarks: '',
            PG_Part1_Percentage: '',
            PG_Part1_ClassObtained: '',
            PG_Part1_NoOfAttempts: '',
            PG_Part1_RegisterNo: '',
            PG_Part1_Passing: '',
            PG_Part2_SubjectName: '',
            PG_Part2_MarksObtained: '',
            PG_Part2_MaximumMarks: '',
            PG_Part2_Percentage: '',
            PG_Part2_ClassObtained: '',
            PG_Part2_NoOfAttempts: '',
            PG_Part2_RegisterNo: '',
            PG_Part2_Passing: '',
            PG_Part3_SubjectName: '',
            PG_Part3_MarksObtained: '',
            PG_Part3_MaximumMarks: '',
            PG_Part3_Percentage: '',
            PG_Part3_ClassObtained: '',
            PG_Part3_NoOfAttempts: '',
            PG_Part3_RegisterNo: '',
            PG_Part3_Passing: '',
            PG_Allied1_SubjectName: '',
            PG_Allied1_MarksObtained: '',
            PG_Allied1_MaximumMarks: '',
            PG_Allied1_Percentage: '',
            PG_Allied1_ClassObtained: '',
            PG_Allied1_NoOfAttempts: '',
            PG_Allied1_RegisterNo: '',
            PG_Allied1_Passing: '',
            PG_Allied2_SubjectName: '',
            PG_Allied2_MarksObtained: '',
            PG_Allied2_MaximumMarks: '',
            PG_Allied2_Percentage: '',
            PG_Allied2_ClassObtained: '',
            PG_Allied2_NoOfAttempts: '',
            PG_Allied2_RegisterNo: '',
            PG_Allied2_Passing: '',
            PG_Overall_Main_Core: '',
            PG_Overall_Ancy_Allied: '',
            PG_Overall_Part3: '',

            MPhil_courseName: '',
            MPhil_Part1_SubjectName: '',
            MPhil_Part1_MarksObtained: '',
            MPhil_Part1_MaximumMarks: '',
            MPhil_Part1_Percentage: '',
            MPhil_Part1_ClassObtained: '',
            MPhil_Part1_NoOfAttempts: '',
            MPhil_Part1_RegisterNo: '',
            MPhil_Part1_Passing: '',
            MPhil_Part2_SubjectName: '',
            MPhil_Part2_MarksObtained: '',
            MPhil_Part2_MaximumMarks: '',
            MPhil_Part2_Percentage: '',
            MPhil_Part2_ClassObtained: '',
            MPhil_Part2_NoOfAttempts: '',
            MPhil_Part2_RegisterNo: '',
            MPhil_Part2_Passing: '',
            MPhil_Part3_SubjectName: '',
            MPhil_Part3_MarksObtained: '',
            MPhil_Part3_MaximumMarks: '',
            MPhil_Part3_Percentage: '',
            MPhil_Part3_ClassObtained: '',
            MPhil_Part3_NoOfAttempts: '',
            MPhil_Part3_RegisterNo: '',
            MPhil_Part3_Passing: '',
            MPhil_Allied1_SubjectName: '',
            MPhil_Allied1_MarksObtained: '',
            MPhil_Allied1_MaximumMarks: '',
            MPhil_Allied1_Percentage: '',
            MPhil_Allied1_ClassObtained: '',
            MPhil_Allied1_NoOfAttempts: '',
            MPhil_Allied1_RegisterNo: '',
            MPhil_Allied1_Passing: '',
            MPhil_Allied2_SubjectName: '',
            MPhil_Allied2_MarksObtained: '',
            MPhil_Allied2_MaximumMarks: '',
            MPhil_Allied2_Percentage: '',
            MPhil_Allied2_ClassObtained: '',
            MPhil_Allied2_NoOfAttempts: '',
            MPhil_Allied2_RegisterNo: '',
            MPhil_Allied2_Passing: '',
            MPhil_Overall_Main_Core: '',
            MPhil_Overall_Ancy_Allied: '',
            MPhil_Overall_Part3: '',

            ///----Admission----////
            quotaTypeId: '',
            discription: '',
            isCitizenOfIndia: '',
            isClaimingNriQuotaSeat: '',
            isMedicalCertificateEnclosed: '',
            extraCurricularActivities: '',

            submittedDate: '',
            status: '',

        },
        // validate: (values: any) => {
        //     const errors: {
        //         motherContactNumber?: string;
        //         father_AdharNumber?: string;
        //         fatherContactNumber?: string;
        //         fatherEmailId?: string;
        //         mother_AdharNumber?: string;
        //         guardian_email?: string;
        //         guardian_phone?: string;
        //         guardian_AdharNumber?: string;
        //     } = {};
        //     if (values.father_AdharNumber && !/^\d{12}$/.test(values.father_AdharNumber)) {
        //         errors.father_AdharNumber = 'Must be 12 Numbers';
        //     }
        //     if (values.fatherContactNumber && !/^\d{10}$/.test(values.fatherContactNumber)) {
        //         errors.fatherContactNumber = 'Must be 10 Numbers';
        //     }
        //     if (values.fatherEmailId && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.fatherEmailId)) {
        //         errors.fatherEmailId = 'Invalid email address';
        //     }
        //     if (values.mother_AdharNumber && !/^\d{12}$/.test(values.mother_AdharNumber)) {
        //         errors.mother_AdharNumber = 'Must be 12 Numbers';
        //     }
        //     if (values.motherContactNumber && !/^\d{10}$/.test(values.motherContactNumber)) {
        //         errors.motherContactNumber = 'Must be 10 Numbers';
        //     }
        //     if (values.guardian_AdharNumber && !/^\d{12}$/.test(values.guardian_AdharNumber)) {
        //         errors.guardian_AdharNumber = 'Must be 12 Numbers';
        //     }
        //     if (values.guardian_phone && !/^\d{10}$/.test(values.guardian_phone)) {
        //         errors.guardian_phone = 'Must be 10 Numbers';
        //     }
        //     if (values.guardian_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.guardian_email)) {
        //         errors.guardian_email = 'Invalid email address';
        //     }

        //     return errors;
        // },
        validateOnChange: true,
        onSubmit: () => { addStudentSubmit() },
    })

    function getStudentSchoolNameList() {
        getStudentSchoolName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolName;
                    if (data != undefined) {
                        setStudentSchoolNameData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        );
    }

    function getStudentSchoolSubjectList() {
        getStudentSchoolSubject(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolSubject;
                    if (data != undefined) {
                        setStudentSchoolSubjectData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function handleNext() {
        handleNext1()
    }

    function handlePrevious1() {
        let studentPostData = setAddStudentDetails()
        setSelectedValue(studentPostData)
        handlePrevious()
    }

    function addStudentSubmit() {
        let studentPostData = setAddStudentDetails();
        setSelectedValue(studentPostData);
        handleNext();
    }

    function setAddStudentDetails() {
        return ({
            ///----General Info----////
            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo ? selectedValue.admissionNo : '',
            tempNo: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : '',
            surName: selectedValue.surName ? selectedValue.surName : '',
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : '',
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : '',
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : '',
            genderId: selectedValue.genderId ? selectedValue.genderId : '',
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : '',
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : '',
            nationality: selectedValue.nationality ? selectedValue.nationality : '',
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : '',
            religionId: selectedValue.religionId ? selectedValue.religionId : '',
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : '',
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : '',
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : '',
            category: selectedValue.category ? selectedValue.category : '',
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : '',
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : '',
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : '',
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : '',
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : '',
            email: selectedValue.email ? selectedValue.email : '',
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : '',
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : '',

            ///----Address INFO----////
            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            ///----Class INFO----////
            departmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: addStudentForm.values.registerNo_X,
            institutionNameAndAddress_X: addStudentForm.values.institutionNameAndAddress_X,
            boardName_X: addStudentForm.values.boardName_X,
            mediumOfInstitution_X: addStudentForm.values.mediumOfInstitution_X,
            monthAndYearOfPassing_X: addStudentForm.values.monthAndYearOfPassing_X,

            registerNo_XI: addStudentForm.values.registerNo_XI,
            institutionNameAndAddress_XI: addStudentForm.values.institutionNameAndAddress_XI,
            boardName_XI: addStudentForm.values.boardName_XI,
            mediumOfInstitution_XI: addStudentForm.values.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: addStudentForm.values.monthAndYearOfPassing_XI,

            registerNo_XII: addStudentForm.values.registerNo_XII,
            XIISchoolNameId: addStudentForm.values.XIISchoolNameId,
            boardName_XII: addStudentForm.values.boardName_XII,
            mediumOfInstitution_XII: addStudentForm.values.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: addStudentForm.values.monthAndYearOfPassing_XII,

            registerNo_UG: addStudentForm.values.registerNo_UG,
            institutionNameAndAddress_UG: addStudentForm.values.institutionNameAndAddress_UG,
            universityName_UG: addStudentForm.values.universityName_UG,
            mediumOfInstitution_UG: addStudentForm.values.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: addStudentForm.values.monthAndYearOfPassing_UG,

            registerNo_PG: addStudentForm.values.registerNo_PG,
            institutionNameAndAddress_PG: addStudentForm.values.institutionNameAndAddress_PG,
            universityName_PG: addStudentForm.values.universityName_PG,
            mediumOfInstitution_PG: addStudentForm.values.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: addStudentForm.values.monthAndYearOfPassing_PG,

            registerNo_MPhil: addStudentForm.values.registerNo_MPhil,
            institutionNameAndAddress_MPhil: addStudentForm.values.institutionNameAndAddress_MPhil,
            universityName_MPhil: addStudentForm.values.universityName_MPhil,
            mediumOfInstitution_MPhil: addStudentForm.values.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: addStudentForm.values.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: addStudentForm.values.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: addStudentForm.values.XI_S1_markSecured,
            XI_S1_maximumMarks: addStudentForm.values.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: addStudentForm.values.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: addStudentForm.values.XI_S1_registerNo,
            XI_S1_noOfAttempts: addStudentForm.values.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: addStudentForm.values.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: addStudentForm.values.XI_S2_markSecured,
            XI_S2_maximumMarks: addStudentForm.values.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: addStudentForm.values.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: addStudentForm.values.XI_S2_registerNo,
            XI_S2_noOfAttempts: addStudentForm.values.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: addStudentForm.values.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: addStudentForm.values.XI_S3_markSecured,
            XI_S3_maximumMarks: addStudentForm.values.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: addStudentForm.values.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: addStudentForm.values.XI_S3_registerNo,
            XI_S3_noOfAttempts: addStudentForm.values.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: addStudentForm.values.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: addStudentForm.values.XI_S4_markSecured,
            XI_S4_maximumMarks: addStudentForm.values.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: addStudentForm.values.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: addStudentForm.values.XI_S4_registerNo,
            XI_S4_noOfAttempts: addStudentForm.values.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: addStudentForm.values.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: addStudentForm.values.XI_S5_markSecured,
            XI_S5_maximumMarks: addStudentForm.values.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: addStudentForm.values.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: addStudentForm.values.XI_S5_registerNo,
            XI_S5_noOfAttempts: addStudentForm.values.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: addStudentForm.values.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: addStudentForm.values.XI_S6_markSecured,
            XI_S6_maximumMarks: addStudentForm.values.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: addStudentForm.values.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: addStudentForm.values.XI_S6_registerNo,
            XI_S6_noOfAttempts: addStudentForm.values.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: addStudentForm.values.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: addStudentForm.values.XII_S1_markSecured,
            XII_S1_maximumMarks: addStudentForm.values.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: addStudentForm.values.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: addStudentForm.values.XII_S1_registerNo,
            XII_S1_noOfAttempts: addStudentForm.values.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: addStudentForm.values.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: addStudentForm.values.XII_S2_markSecured,
            XII_S2_maximumMarks: addStudentForm.values.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: addStudentForm.values.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: addStudentForm.values.XII_S2_registerNo,
            XII_S2_noOfAttempts: addStudentForm.values.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: addStudentForm.values.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: addStudentForm.values.XII_S3_markSecured,
            XII_S3_maximumMarks: addStudentForm.values.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: addStudentForm.values.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: addStudentForm.values.XII_S3_registerNo,
            XII_S3_noOfAttempts: addStudentForm.values.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: addStudentForm.values.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: addStudentForm.values.XII_S4_markSecured,
            XII_S4_maximumMarks: addStudentForm.values.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: addStudentForm.values.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: addStudentForm.values.XII_S4_registerNo,
            XII_S4_noOfAttempts: addStudentForm.values.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: addStudentForm.values.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: addStudentForm.values.XII_S5_markSecured,
            XII_S5_maximumMarks: addStudentForm.values.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: addStudentForm.values.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: addStudentForm.values.XII_S5_registerNo,
            XII_S5_noOfAttempts: addStudentForm.values.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: addStudentForm.values.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: addStudentForm.values.XII_S6_markSecured,
            XII_S6_maximumMarks: addStudentForm.values.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: addStudentForm.values.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: addStudentForm.values.XII_S6_registerNo,
            XII_S6_noOfAttempts: addStudentForm.values.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: addStudentForm.values.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: addStudentForm.values.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: addStudentForm.values.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: addStudentForm.values.XII_OverAll_maximumMarks,

            UG_courseName: addStudentForm.values.UG_courseName,
            UG_Part1_SubjectName: addStudentForm.values.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: addStudentForm.values.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: addStudentForm.values.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: addStudentForm.values.UG_Part1_Percentage,
            UG_Part1_ClassObtained: addStudentForm.values.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: addStudentForm.values.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: addStudentForm.values.UG_Part1_RegisterNo,
            UG_Part1_Passing: addStudentForm.values.UG_Part1_Passing,

            UG_Part2_SubjectName: addStudentForm.values.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: addStudentForm.values.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: addStudentForm.values.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: addStudentForm.values.UG_Part2_Percentage,
            UG_Part2_ClassObtained: addStudentForm.values.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: addStudentForm.values.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: addStudentForm.values.UG_Part2_RegisterNo,
            UG_Part2_Passing: addStudentForm.values.UG_Part2_Passing,

            UG_Part3_SubjectName: addStudentForm.values.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: addStudentForm.values.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: addStudentForm.values.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: addStudentForm.values.UG_Part3_Percentage,
            UG_Part3_ClassObtained: addStudentForm.values.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: addStudentForm.values.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: addStudentForm.values.UG_Part3_RegisterNo,
            UG_Part3_Passing: addStudentForm.values.UG_Part3_Passing,

            UG_Allied1_SubjectName: addStudentForm.values.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: addStudentForm.values.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: addStudentForm.values.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: addStudentForm.values.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: addStudentForm.values.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: addStudentForm.values.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: addStudentForm.values.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: addStudentForm.values.UG_Allied1_Passing,

            UG_Allied2_SubjectName: addStudentForm.values.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: addStudentForm.values.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: addStudentForm.values.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: addStudentForm.values.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: addStudentForm.values.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: addStudentForm.values.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: addStudentForm.values.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: addStudentForm.values.UG_Allied2_Passing,

            UG_Overall_Main_Core: addStudentForm.values.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: addStudentForm.values.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: addStudentForm.values.UG_Overall_Part3,

            PG_courseName: addStudentForm.values.PG_courseName,
            PG_Part1_SubjectName: addStudentForm.values.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: addStudentForm.values.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: addStudentForm.values.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: addStudentForm.values.PG_Part1_Percentage,
            PG_Part1_ClassObtained: addStudentForm.values.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: addStudentForm.values.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: addStudentForm.values.PG_Part1_RegisterNo,
            PG_Part1_Passing: addStudentForm.values.PG_Part1_Passing,
            PG_Part2_SubjectName: addStudentForm.values.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: addStudentForm.values.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: addStudentForm.values.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: addStudentForm.values.PG_Part2_Percentage,
            PG_Part2_ClassObtained: addStudentForm.values.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: addStudentForm.values.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: addStudentForm.values.PG_Part2_RegisterNo,
            PG_Part2_Passing: addStudentForm.values.PG_Part2_Passing,
            PG_Part3_SubjectName: addStudentForm.values.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: addStudentForm.values.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: addStudentForm.values.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: addStudentForm.values.PG_Part3_Percentage,
            PG_Part3_ClassObtained: addStudentForm.values.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: addStudentForm.values.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: addStudentForm.values.PG_Part3_RegisterNo,
            PG_Part3_Passing: addStudentForm.values.PG_Part3_Passing,
            PG_Allied1_SubjectName: addStudentForm.values.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: addStudentForm.values.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: addStudentForm.values.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: addStudentForm.values.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: addStudentForm.values.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: addStudentForm.values.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: addStudentForm.values.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: addStudentForm.values.PG_Allied1_Passing,
            PG_Allied2_SubjectName: addStudentForm.values.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: addStudentForm.values.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: addStudentForm.values.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: addStudentForm.values.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: addStudentForm.values.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: addStudentForm.values.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: addStudentForm.values.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: addStudentForm.values.PG_Allied2_Passing,
            PG_Overall_Main_Core: addStudentForm.values.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: addStudentForm.values.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: addStudentForm.values.PG_Overall_Part3,

            MPhil_courseName: addStudentForm.values.MPhil_courseName,
            MPhil_Part1_SubjectName: addStudentForm.values.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: addStudentForm.values.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: addStudentForm.values.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: addStudentForm.values.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: addStudentForm.values.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: addStudentForm.values.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: addStudentForm.values.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: addStudentForm.values.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: addStudentForm.values.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: addStudentForm.values.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: addStudentForm.values.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: addStudentForm.values.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: addStudentForm.values.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: addStudentForm.values.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: addStudentForm.values.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: addStudentForm.values.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: addStudentForm.values.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: addStudentForm.values.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: addStudentForm.values.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: addStudentForm.values.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: addStudentForm.values.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: addStudentForm.values.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: addStudentForm.values.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: addStudentForm.values.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: addStudentForm.values.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: addStudentForm.values.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: addStudentForm.values.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: addStudentForm.values.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: addStudentForm.values.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: addStudentForm.values.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: addStudentForm.values.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: addStudentForm.values.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: addStudentForm.values.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: addStudentForm.values.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: addStudentForm.values.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: addStudentForm.values.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: addStudentForm.values.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: addStudentForm.values.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: addStudentForm.values.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: addStudentForm.values.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: addStudentForm.values.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: addStudentForm.values.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: addStudentForm.values.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
            certificateTypeId: [],
            submittedDate: "",
            status: "",
            scholarshipTypeId: [],
            assignedBy: "",
            licenseKey: "",
            createdBy: "",
            userAccountId: ""
        })
    }

    function addSetValues() {
        console.log(selectedValue);

        if (selectedValue.graduationTypeId == '2') {
            setUgDegree(true)
        }
        else if (selectedValue.graduationTypeId == '3') {
            setUgDegree(true)
            setPgDegree(true)
        }
        else {
            setUgDegree(false)
            setPgDegree(false)
        }
        
        addStudentForm.setValues({

            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo,
            tempNo: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : "",
            surName: selectedValue.surName ? selectedValue.surName : "",
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : "",
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : "",
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : "",
            genderId: selectedValue.genderId ? selectedValue.genderId : "",
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : "",
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : "",
            nationality: selectedValue.nationality ? selectedValue.nationality : "",
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : "",
            religionId: selectedValue.religionId ? selectedValue.religionId : "",
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : "",
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : "",
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : "",
            category: selectedValue.category ? selectedValue.category : "",
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : "",
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : "",
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : "",
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : "",
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : "",
            email: selectedValue.email ? selectedValue.email : "",
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : "",
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : "",


            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            departmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
            // certificateTypeId: [],
            submittedDate: "",
            status: "",
            // scholarshipTypeId: [],
            // assignedBy: "",
            // licenseKey: "",
            // createdBy: "",
            // userAccountId: ""

        })
    }


    return (
        <PageWrapper>
            <Page>
                <Card shadow='none'>
                    <CardBody >
                        <form noValidate onSubmit={addStudentForm.handleSubmit}>
                            <div className="d-grid gap-4">
                                <div className="row  align-self-center">
                                    <FormGroup className="align-self-center">
                                        <span className="text-info h5" >
                                            <Icon icon="Home" color="info" size={'2x'} style={{ marginRight: '4px' }} />
                                            STUDENT MARK INFO
                                        </span>
                                    </FormGroup>
                                </div>
                                <div className="row justify-content-center">
                                    <table className='table table-bordered '>
                                        <thead >
                                            <tr>
                                                <th className="text-center bg-primary text-white">S.NO</th>
                                                <th className="text-center bg-primary text-white">CLASS</th>
                                                <th className="text-center bg-primary text-white">REGISTER<br /> NO</th>
                                                <th className="text-center bg-primary text-white">NAME & ADDRESS OF <br />THE INSTITUTION</th>
                                                <th className="text-center bg-primary text-white">NAME OF <br />BOARD</th>
                                                <th className="text-center bg-primary text-white">MEDIUM OF <br />INSTRUCTION</th>
                                                <th className="text-center bg-primary text-white">MONTH & YEAR <br />OF PASSING</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>10 STD</td>
                                                <td><Input id='registerNo_X' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.registerNo_X} /></td>
                                                <td><Input id='institutionNameAndAddress_X' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.institutionNameAndAddress_X} /></td>
                                                <td><Input id='boardName_X' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.boardName_X} /></td>
                                                <td><Input id='mediumOfInstitution_X' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.mediumOfInstitution_X} /></td>
                                                <td><Input id='monthAndYearOfPassing_X' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.monthAndYearOfPassing_X} /></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>11 STD</td>
                                                <td><Input id='registerNo_XI' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.registerNo_XI} /></td>
                                                <td><Input id='institutionNameAndAddress_XI' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.institutionNameAndAddress_XI} /></td>
                                                <td><Input id='boardName_XI' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.boardName_XI} /></td>
                                                <td><Input id='mediumOfInstitution_XI' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.mediumOfInstitution_XI} /></td>
                                                <td><Input id='monthAndYearOfPassing_XI' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.monthAndYearOfPassing_XI} /></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>12 STD</td>
                                                <td><Input id='registerNo_XII' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.registerNo_XII} /></td>
                                                <td><Select id='XIISchoolNameId' ariaLabel="Select School" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XIISchoolNameId}
                                                    list={studentSchoolNameData.map((data: any) => (
                                                        { value: data.studentSchoolNameId, text: data.schoolName }
                                                    ))} /></td>
                                                <td><Input id='boardName_XII' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.boardName_XII} /></td>
                                                <td><Input id='mediumOfInstitution_XII' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.mediumOfInstitution_XII} /></td>
                                                <td><Input id='monthAndYearOfPassing_XII' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.monthAndYearOfPassing_XII} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row  align-self-center">
                                    <FormGroup className="align-self-center">
                                        <span className="text-info h5" >
                                            Details of Qualifying Examination
                                        </span>
                                    </FormGroup>
                                </div>
                                <div className="row  align-self-center">
                                    <FormGroup className="align-self-center">
                                        <span className="text-info h5" >
                                            (1). Marks In XI th Standard
                                        </span>
                                    </FormGroup>
                                </div>
                                <div className="row justify-content-center">
                                    <table className='table table-bordered text-nowrap'>
                                        <thead >
                                            <tr>
                                                <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MAXIMUM  MARKS</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MONTH & YEAR  OF PASSING</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Select id='XI_S1_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S1_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XI_S1_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S1_markSecured} /></td>
                                                <td><Input id='XI_S1_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S1_maximumMarks} /></td>
                                                <td><Input id='XI_S1_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S1_monthAndYearOfPassing} /></td>
                                                <td><Input id='XI_S1_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S1_registerNo} /></td>
                                                <td><Input id='XI_S1_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S1_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XI_S2_studentSchoolSubject' ariaLabel="Subject" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S2_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XI_S2_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S2_markSecured} /></td>
                                                <td><Input id='XI_S2_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S2_maximumMarks} /></td>
                                                <td><Input id='XI_S2_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S2_monthAndYearOfPassing} /></td>
                                                <td><Input id='XI_S2_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S2_registerNo} /></td>
                                                <td><Input id='XI_S2_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S2_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td>Part-III Subjects</td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XI_S3_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XI_S3_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_markSecured} /></td>
                                                <td><Input id='XI_S3_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_maximumMarks} /></td>
                                                <td><Input id='XI_S3_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_monthAndYearOfPassing} /></td>
                                                <td><Input id='XI_S3_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_registerNo} /></td>
                                                <td><Input id='XI_S3_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XI_S4_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S4_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XI_S4_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S4_markSecured} /></td>
                                                <td><Input id='XI_S4_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S4_maximumMarks} /></td>
                                                <td><Input id='XI_S4_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S4_monthAndYearOfPassing} /></td>
                                                <td><Input id='XI_S4_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S4_registerNo} /></td>
                                                <td><Input id='XI_S4_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S4_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XI_S5_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S5_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XI_S5_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S5_markSecured} /></td>
                                                <td><Input id='XI_S5_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S5_maximumMarks} /></td>
                                                <td><Input id='XI_S5_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S5_monthAndYearOfPassing} /></td>
                                                <td><Input id='XI_S5_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S5_registerNo} /></td>
                                                <td><Input id='XI_S5_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S5_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XI_S6_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S6_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XI_S6_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S6_markSecured} /></td>
                                                <td><Input id='XI_S6_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S6_maximumMarks} /></td>
                                                <td><Input id='XI_S6_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S6_monthAndYearOfPassing} /></td>
                                                <td><Input id='XI_S6_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S6_registerNo} /></td>
                                                <td><Input id='XI_S6_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S6_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td>TOTAL</td>
                                                <td><Input id='XI_OverAll_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    onInput={(e: any) => setTotalPercentageForXI(e.target.value)}
                                                    value={addStudentForm.values.XI_OverAll_markSecured} /></td>
                                                <td><Input id='XI_OverAll_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_OverAll_maximumMarks} /></td>
                                                <td>TOTAL PERCENTAGE</td>
                                                <td><Input id='' type="text" style={{ width: '80' }} disabled
                                                    value={(Number(totalPercentageForXI) / Number(600) * 100).toFixed(2)} /></td>
                                                <td><Input id='' type="text" style={{ width: '80' }} disabled /></td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row  align-self-center">
                                    <FormGroup className="align-self-center">
                                        <span className="text-info h5" >
                                            (2).Marks In XII th Standard
                                        </span>
                                    </FormGroup>
                                </div>
                                <div className="row justify-content-center">
                                    <table className='table table-bordered text-nowrap'>
                                        <thead >
                                            <tr>
                                                <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MAXIMUM MARKS</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MONTH & YEAR OF PASSING</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Select id='XII_S1_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S1_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XII_S1_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S1_markSecured} /></td>
                                                <td><Input id='XII_S1_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S1_maximumMarks} /></td>
                                                <td><Input id='XII_S1_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S1_monthAndYearOfPassing} /></td>
                                                <td><Input id='XII_S1_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S1_registerNo} /></td>
                                                <td><Input id='XII_S1_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S1_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XII_S2_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S2_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XII_S2_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S2_markSecured} /></td>
                                                <td><Input id='XII_S2_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S2_maximumMarks} /></td>
                                                <td><Input id='XII_S2_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S2_monthAndYearOfPassing} /></td>
                                                <td><Input id='XII_S2_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S2_registerNo} /></td>
                                                <td><Input id='XII_S2_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S2_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td>Part-III Subjects</td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XII_S3_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S3_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XII_S3_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S3_markSecured} /></td>
                                                <td><Input id='XII_S3_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S3_maximumMarks} /></td>
                                                <td><Input id='XII_S3_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S3_monthAndYearOfPassing} /></td>
                                                <td><Input id='XII_S3_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XI_S3_registerNo} /></td>
                                                <td><Input id='XII_S3_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S3_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XII_S4_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S4_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XII_S4_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S4_markSecured} /></td>
                                                <td><Input id='XII_S4_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S4_maximumMarks} /></td>
                                                <td><Input id='XII_S4_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S4_monthAndYearOfPassing} /></td>
                                                <td><Input id='XII_S4_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S4_registerNo} /></td>
                                                <td><Input id='XII_S4_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S4_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XII_S5_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S5_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XII_S5_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S5_markSecured} /></td>
                                                <td><Input id='XII_S5_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S5_maximumMarks} /></td>
                                                <td><Input id='XII_S5_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S5_monthAndYearOfPassing} /></td>
                                                <td><Input id='XII_S5_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S5_registerNo} /></td>
                                                <td><Input id='XII_S5_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S5_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td><Select id='XII_S6_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S6_studentSchoolSubject}
                                                    list={studentSchoolSubjectData.map((data: any) => (
                                                        { value: data.studentSchoolSubjectId, text: data.subjectName }
                                                    ))} /></td>
                                                <td><Input id='XII_S6_markSecured' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S6_markSecured} /></td>
                                                <td><Input id='XII_S6_maximumMarks' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S6_maximumMarks} /></td>
                                                <td><Input id='XII_S6_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S6_monthAndYearOfPassing} /></td>
                                                <td><Input id='XII_S6_registerNo' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S6_registerNo} /></td>
                                                <td><Input id='XII_S6_noOfAttempts' type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_S6_noOfAttempts} /></td>
                                            </tr>
                                            <tr>
                                                <td>TOTAL</td>
                                                <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    onInput={(e: any) => setTotalPercentageForXII(e.target.value)}
                                                    value={addStudentForm.values.XII_OverAll_markSecured} /></td>
                                                <td><Input id="XII_OverAll_maximumMarks" type="text" style={{ width: '80' }}
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.XII_OverAll_maximumMarks} /></td>
                                                <td>TOTAL PERCENTAGE</td>
                                                <td><Input type="text" style={{ width: '80' }} disabled
                                                    value={(Number(totalPercentageForXII) / Number(600) * 100).toFixed(2)} /></td>
                                                <td><Input type="text" style={{ width: '80' }} disabled /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {selectedValue === '2' ? <>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <FormGroup >
                                                <span className="text-info h5" >
                                                    (3). Name of the UG Course (if any) :
                                                </span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text" placeholder="Enter UG Course Name"
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.UG_courseName} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <table className="table text-nowrap text-lg-nowrap table-bordered">
                                            <thead className="col-lg-12 text-center bg-primary p-2 fs-6">
                                                <tr>
                                                    <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MAXIMUM MARKS</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>PERCENTAGE OF MARKS %</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>CLASS OBTAINED</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MONTH & YEAR OF PASSING</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td ><Input id="UG_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_SubjectName} /></td>

                                                    <td><Input id="UG_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_MarksObtained} /></td>

                                                    <td><Input id="UG_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_MaximumMarks} /></td>

                                                    <td><Input id="UG_Part1_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_Percentage} /></td>

                                                    <td><Input id="UG_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_ClassObtained} /></td>

                                                    <td><Input id="UG_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_RegisterNo} /></td>

                                                    <td><Input id="UG_Part1_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_SubjectName} /></td>

                                                    <td><Input id="UG_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_MarksObtained} /></td>

                                                    <td><Input id="UG_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_MaximumMarks} /></td>

                                                    <td><Input id="UG_Part2_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_Percentage} /></td>

                                                    <td><Input id="UG_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_ClassObtained} /></td>

                                                    <td><Input id="UG_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_RegisterNo} /></td>

                                                    <td><Input id="UG_Part2_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_SubjectName} /></td>

                                                    <td><Input id="UG_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_MarksObtained} /></td>

                                                    <td><Input id="UG_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_MaximumMarks} /></td>

                                                    <td><Input id="UG_Part3_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_Percentage} /></td>

                                                    <td><Input id="UG_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_ClassObtained} /></td>

                                                    <td><Input id="UG_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_RegisterNo} /></td>

                                                    <td><Input id="UG_Part3_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_SubjectName} /></td>

                                                    <td><Input id="UG_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_MarksObtained} /></td>

                                                    <td><Input id="UG_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_MaximumMarks} /></td>

                                                    <td><Input id="UG_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_Percentage} /></td>

                                                    <td><Input id="UG_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_ClassObtained} /></td>

                                                    <td><Input id="UG_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_RegisterNo} /></td>

                                                    <td><Input id="UG_Allied1_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_SubjectName} /></td>

                                                    <td><Input id="UG_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_MarksObtained} /></td>

                                                    <td><Input id="UG_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_MaximumMarks} /></td>

                                                    <td><Input id="UG_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_Percentage} /></td>

                                                    <td><Input id="UG_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_ClassObtained} /></td>

                                                    <td><Input id="UG_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_RegisterNo} /></td>

                                                    <td><Input id="UG_Allied2_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Percentage</td>
                                                    <td>Main/Core</td>
                                                    <td><Input id="UG_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Overall_Main_Core} /></td>
                                                    <td>Ancy./Allied</td>
                                                    <td><Input id="UG_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Overall_Ancy_Allied} /></td>
                                                    <td>Part - III</td>
                                                    <td><Input id="UG_Overall_Part3" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Overall_Part3} /></td>
                                                    <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.XII_OverAll_markSecured} disabled /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                                {selectedValue === '3' ? <>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <FormGroup >
                                                <span className="text-info h5" >
                                                    (3). Name of the UG Course (if any) :
                                                </span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text" placeholder="Enter UG Course Name"
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.UG_courseName} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <table className="table text-nowrap text-lg-nowrap table-bordered">
                                            <thead className="col-lg-12 text-center bg-primary p-2 fs-6">
                                                <tr>
                                                    <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MAXIMUM MARKS</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>PERCENTAGE OF MARKS %</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>CLASS OBTAINED</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MONTH & YEAR OF PASSING</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td ><Input id="UG_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_SubjectName} /></td>

                                                    <td><Input id="UG_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_MarksObtained} /></td>

                                                    <td><Input id="UG_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_MaximumMarks} /></td>

                                                    <td><Input id="UG_Part1_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_Percentage} /></td>

                                                    <td><Input id="UG_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_ClassObtained} /></td>

                                                    <td><Input id="UG_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_RegisterNo} /></td>

                                                    <td><Input id="UG_Part1_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part1_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_SubjectName} /></td>

                                                    <td><Input id="UG_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_MarksObtained} /></td>

                                                    <td><Input id="UG_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_MaximumMarks} /></td>

                                                    <td><Input id="UG_Part2_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_Percentage} /></td>

                                                    <td><Input id="UG_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_ClassObtained} /></td>

                                                    <td><Input id="UG_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_RegisterNo} /></td>

                                                    <td><Input id="UG_Part2_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part2_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_SubjectName} /></td>

                                                    <td><Input id="UG_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_MarksObtained} /></td>

                                                    <td><Input id="UG_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_MaximumMarks} /></td>

                                                    <td><Input id="UG_Part3_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_Percentage} /></td>

                                                    <td><Input id="UG_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_ClassObtained} /></td>

                                                    <td><Input id="UG_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_RegisterNo} /></td>

                                                    <td><Input id="UG_Part3_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Part3_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_SubjectName} /></td>

                                                    <td><Input id="UG_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_MarksObtained} /></td>

                                                    <td><Input id="UG_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_MaximumMarks} /></td>

                                                    <td><Input id="UG_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_Percentage} /></td>

                                                    <td><Input id="UG_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_ClassObtained} /></td>

                                                    <td><Input id="UG_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_RegisterNo} /></td>

                                                    <td><Input id="UG_Allied1_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied1_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="UG_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_SubjectName} /></td>

                                                    <td><Input id="UG_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_MarksObtained} /></td>

                                                    <td><Input id="UG_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_MaximumMarks} /></td>

                                                    <td><Input id="UG_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_Percentage} /></td>

                                                    <td><Input id="UG_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_ClassObtained} /></td>

                                                    <td><Input id="UG_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_NoOfAttempts} /></td>

                                                    <td><Input id="UG_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_RegisterNo} /></td>

                                                    <td><Input id="UG_Allied2_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Allied2_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Percentage</td>
                                                    <td>Main/Core</td>
                                                    <td><Input id="UG_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Overall_Main_Core} /></td>
                                                    <td>Ancy./Allied</td>
                                                    <td><Input id="UG_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Overall_Ancy_Allied} /></td>
                                                    <td>Part - III</td>
                                                    <td><Input id="UG_Overall_Part3" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.UG_Overall_Part3} /></td>
                                                    <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.XII_OverAll_markSecured} disabled /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <FormGroup >
                                                <span className="text-info h5" >
                                                    (4). Name of the PG Course (if any) :
                                                </span>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-6">
                                            <Input type="text" placeholder="Enter PG Course Name"
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.PG_courseName} />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <table className="table table-bordered text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MAXIMUM MARKS</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>PERCENTAGE OF MARKS %</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>CLASS OBTAINED</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                    <th className="text-center bg-primary text-white"><strong>MONTH & YEAR OF PASSING</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td ><Input id="PG_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_SubjectName} /></td>

                                                    <td><Input id="PG_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_MarksObtained} /></td>

                                                    <td><Input id="PG_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_MaximumMarks} /></td>

                                                    <td><Input id="PG_Part1_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_Percentage} /></td>

                                                    <td><Input id="PG_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_ClassObtained} /></td>

                                                    <td><Input id="PG_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_NoOfAttempts} /></td>

                                                    <td><Input id="PG_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_RegisterNo} /></td>

                                                    <td><Input id="PG_Part1_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part1_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="PG_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_SubjectName} /></td>

                                                    <td><Input id="PG_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_MarksObtained} /></td>

                                                    <td><Input id="PG_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_MaximumMarks} /></td>

                                                    <td><Input id="PG_Part2_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_Percentage} /></td>

                                                    <td><Input id="PG_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_ClassObtained} /></td>

                                                    <td><Input id="PG_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_NoOfAttempts} /></td>

                                                    <td><Input id="PG_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_RegisterNo} /></td>

                                                    <td><Input id="PG_Part2_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part2_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="PG_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_SubjectName} /></td>

                                                    <td><Input id="PG_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_MarksObtained} /></td>

                                                    <td><Input id="PG_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_MaximumMarks} /></td>

                                                    <td><Input id="PG_Part3_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_Percentage} /></td>

                                                    <td><Input id="PG_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_ClassObtained} /></td>

                                                    <td><Input id="PG_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_NoOfAttempts} /></td>

                                                    <td><Input id="PG_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_RegisterNo} /></td>

                                                    <td><Input id="PG_Part3_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Part3_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="PG_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_SubjectName} /></td>

                                                    <td><Input id="PG_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_MarksObtained} /></td>

                                                    <td><Input id="PG_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_MaximumMarks} /></td>

                                                    <td><Input id="PG_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_Percentage} /></td>

                                                    <td><Input id="PG_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_ClassObtained} /></td>

                                                    <td><Input id="PG_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_NoOfAttempts} /></td>

                                                    <td><Input id="PG_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_RegisterNo} /></td>

                                                    <td><Input id="PG_Allied1_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied1_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td ><Input id="PG_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_SubjectName} /></td>

                                                    <td><Input id="PG_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_MarksObtained} /></td>

                                                    <td><Input id="PG_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_MaximumMarks} /></td>

                                                    <td><Input id="PG_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_Percentage} /></td>

                                                    <td><Input id="PG_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_ClassObtained} /></td>

                                                    <td><Input id="PG_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_NoOfAttempts} /></td>

                                                    <td><Input id="PG_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_RegisterNo} /></td>

                                                    <td><Input id="PG_Allied2_Passing" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Allied2_Passing} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Percentage</td>
                                                    <td>Main/Core</td>
                                                    <td><Input id="PG_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Overall_Main_Core} /></td>
                                                    <td>Ancy./Allied</td>
                                                    <td><Input id="PG_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Overall_Ancy_Allied} /></td>
                                                    <td>Part - III</td>
                                                    <td><Input id="PG_Overall_Part3" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.PG_Overall_Part3} /></td>
                                                    <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                        onChange={addStudentForm.handleChange}
                                                        value={addStudentForm.values.XII_OverAll_markSecured} disabled /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                                {/* <div className="row">
                                    <div className="col-lg-4">
                                        <FormGroup >
                                            <span className="text-info h5" >
                                                (5). Name of the Mphil Course (if any) :
                                            </span>
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-6">
                                        <Input id="MPhil_courseName" type="text" placeholder="Enter MPhil Course Name" 
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.MPhil_courseName}/>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <table className="table table-bordered text-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MAXIMUM MARKS</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>PERCENTAGE OF MARKS %</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>CLASS OBTAINED</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                <th className="text-center bg-primary text-white"><strong>MONTH & YEAR OF PASSING</strong></th>
                                            </tr>
                                        </thead>
                                    
                                    <tbody>
                                    <tr>
                                            <td ><Input id="MPhil_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_SubjectName} /></td>

                                            <td><Input id="MPhil_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_MarksObtained} /></td>

                                            <td><Input id="MPhil_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_MaximumMarks} /></td>

                                            <td><Input id="MPhil_Part1_Percentage" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_Percentage} /></td>

                                            <td><Input id="MPhil_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_ClassObtained} /></td>

                                            <td><Input id="MPhil_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_NoOfAttempts} /></td>

                                            <td><Input id="MPhil_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_RegisterNo} /></td>

                                            <td><Input id="MPhil_Part1_Passing" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part1_Passing} /></td>
                                        </tr>
                                        <tr>
                                            <td ><Input id="MPhil_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_SubjectName} /></td>

                                            <td><Input id="MPhil_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_MarksObtained} /></td>

                                            <td><Input id="MPhil_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_MaximumMarks} /></td>

                                            <td><Input id="MPhil_Part2_Percentage" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_Percentage} /></td>

                                            <td><Input id="MPhil_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_ClassObtained} /></td>

                                            <td><Input id="MPhil_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_NoOfAttempts} /></td>

                                            <td><Input id="MPhil_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_RegisterNo} /></td>

                                            <td><Input id="MPhil_Part2_Passing" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part2_Passing} /></td>
                                        </tr>
                                        <tr>
                                            <td ><Input id="MPhil_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_SubjectName} /></td>

                                            <td><Input id="MPhil_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_MarksObtained} /></td>

                                            <td><Input id="MPhil_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_MaximumMarks} /></td>

                                            <td><Input id="MPhil_Part3_Percentage" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_Percentage} /></td>

                                            <td><Input id="MPhil_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_ClassObtained} /></td>

                                            <td><Input id="MPhil_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_NoOfAttempts} /></td>

                                            <td><Input id="MPhil_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_RegisterNo} /></td>

                                            <td><Input id="MPhil_Part3_Passing" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Part3_Passing} /></td>
                                        </tr>
                                        <tr>
                                            <td ><Input id="MPhil_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_SubjectName} /></td>

                                            <td><Input id="MPhil_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_MarksObtained} /></td>

                                            <td><Input id="MPhil_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_MaximumMarks} /></td>

                                            <td><Input id="MPhil_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_Percentage} /></td>

                                            <td><Input id="MPhil_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_ClassObtained} /></td>

                                            <td><Input id="MPhil_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_NoOfAttempts} /></td>

                                            <td><Input id="MPhil_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_RegisterNo} /></td>

                                            <td><Input id="MPhil_Allied1_Passing" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied1_Passing} /></td>
                                        </tr>
                                        <tr>
                                            <td ><Input id="MPhil_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_SubjectName} /></td>

                                            <td><Input id="MPhil_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_MarksObtained} /></td>

                                            <td><Input id="MPhil_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_MaximumMarks} /></td>

                                            <td><Input id="MPhil_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_Percentage} /></td>

                                            <td><Input id="MPhil_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_ClassObtained} /></td>

                                            <td><Input id="MPhil_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_NoOfAttempts} /></td>

                                            <td><Input id="MPhil_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_RegisterNo} /></td>

                                            <td><Input id="MPhil_Allied2_Passing" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Allied2_Passing} /></td>
                                        </tr>
                                        <tr>
                                            <td>Percentage</td>
                                            <td>Main/Core</td>
                                            <td><Input id="MPhil_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Overall_Main_Core} /></td>
                                            <td>Ancy./Allied</td>
                                            <td><Input id="MPhil_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Overall_Ancy_Allied} /></td>
                                            <td>Part - III</td>
                                            <td><Input id="MPhil_Overall_Part3" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.MPhil_Overall_Part3} /></td>
                                            <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                onChange={addStudentForm.handleChange}
                                                value={addStudentForm.values.XII_OverAll_markSecured} disabled/></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div> */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                <Button color="info" onClick={handlePrevious1} type="submit">previous</Button>
                                <Button color="info" type="submit">Next</Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>
        </PageWrapper>

    )
}
export default MarkInfo;