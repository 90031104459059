import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { useReactToPrint } from "react-to-print";
import Icon from "../../icon/Icon";
import Select from "../../bootstrap/forms/Select";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DeleteComponents from "../../../common/components/DeleteComponents";
import AlertService from "../../../services/AlertService";
import AuthContext from "../../../contexts/authContext";
import { convertDateRangeToEpoch, getColumnsForDataTable, getGraduationType, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import { addBatchMasterDetails, addSemesterDetails, deleteBatchMaster, getBatchMasterDetails, getCourseType, getSemester, updateBatchMaster, updateSemester } from "../../../services/master.service";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import dayjs from "dayjs";
import SearchableSelect from "../../../common/components/SearchableSelect";

function BatchMaster() {

    useEffect(() => {
        getCourseTypeList();
        getGraduationList();
        getBatchMaster();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [isBatch, setIsBatch] = useState(true)
    const [isSemester, setIsSemester] = useState(false)
    const [addBatchMasterModal, setAddBatchMasterModal] = useState(false)
    const [editBatchMasterModal, setEditBatchMasterModal] = useState(false)

    const [courseTypeData, setCourseTypeData] = useState<any>([]);
    const [graduationTypeData, setGraduationTypeData] = useState<any>([]);
    const [batchMasterData, setBatchMasterData] = useState([]);
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([]);

    const [semesterData, setSemesterData] = useState<any>([])
    const [semester, setSemester] = useState('')
    const [batchMasterId, setBatchMasterId] = useState('')
    const [semesterDetails, setSemesterDetails] = useState<any>([])
    const [selectedSemester, setSelectedSemester] = useState('')

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const componentRef = useRef(null);

    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(batchMasterData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [updateSemesterDetails, setUpdateSemesterDetails] = useState<any>([])
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')
    const [noDataMsgForSemester, setNoDataMsgSemester] = useState('')

    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [courseTypeId, setCourseTypeId] = useState<any>('')
    const [semesterCount, setSemesterCount] = useState<any>(0)

    const batchMasterForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseTypeId: "",
            graduationTypeId: '',
            fromYear: '',
            toYear: '',
        },
        validate: (values) => {

            const errors: {
                courseTypeId?: string;
                graduationTypeId?: string;
                fromYear?: string;
                toYear?: string;
            } = {};
            if (!courseTypeId) {
                errors.courseTypeId = 'Required';
            }
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.fromYear) {
                errors.fromYear = 'Required';
            }
            if (!values.toYear) {
                errors.toYear = 'Required';
            }
            else if (values.toYear) {
                if (courseTypeId?.value == '1') {
                    let ToYearInMilliseconds = new Date(values.toYear).getTime();
                    let resultDate = dayjs(values.fromYear).add(3, 'month')
                    let threeMonthLaterDate = dayjs(resultDate).format('YYYY-MM-DD')
                    const threeMonthsInMilliseconds = new Date(threeMonthLaterDate).getTime() - (1000 * 60 * 60 * 24); // assuming 30 days in a month
                    if (ToYearInMilliseconds < threeMonthsInMilliseconds) {
                        errors.toYear = 'The difference must be three months';

                    }
                }
                if (courseTypeId?.value == '2') {
                    let ToYearInMilliseconds = new Date(values.toYear).getTime();
                    let resultDate = dayjs(values.fromYear).add(3, 'year')
                    let threeMonthLaterDate = dayjs(resultDate).format('YYYY-MM-DD')
                    const threeMonthsInMilliseconds = new Date(threeMonthLaterDate).getTime() - (1000 * 60 * 60 * 24); // assuming 30 days in a month
                    if (ToYearInMilliseconds < threeMonthsInMilliseconds) {
                        errors.toYear = 'The difference must be three Years';

                    }
                }
                if (courseTypeId?.value == '3') {
                    let ToYearInMilliseconds = new Date(values.toYear).getTime();
                    let resultDate = dayjs(values.fromYear).add(2, 'year')
                    let threeMonthLaterDate = dayjs(resultDate).format('YYYY-MM-DD')
                    const threeMonthsInMilliseconds = new Date(threeMonthLaterDate).getTime() - (1000 * 60 * 60 * 24); // assuming 30 days in a month
                    if (ToYearInMilliseconds < threeMonthsInMilliseconds) {
                        errors.toYear = 'The difference must be two year';

                    }
                }
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { batchMasterSubmit() },
    });

    const batchAndSemesterForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseTypeId: "",
            fromYear: '',
            toYear: '',
            batchMasterId: '',
        },
        //validateOnChange: false,
        onSubmit: () => { semesterSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedBatchMaster = i
            setEditBatchMasterModal(true)
            batchAndSemesterForm.setValues({
                courseTypeId: selectedBatchMaster.courseTypeId,
                fromYear: selectedBatchMaster.fromYear,
                toYear: selectedBatchMaster.toYear,
                batchMasterId: selectedBatchMaster.batchMasterId,
            });
            getSemesterByBatchMaster(selectedBatchMaster.batchMasterId)
        }
    }

    function setBatchMasterDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            courseTypeId: courseTypeId?.value,
            graduationTypeId: graduationTypeId?.value,
            fromYear: batchMasterForm.values.fromYear,
            toYear: batchMasterForm.values.toYear,
        })
    }

    function setSemesterFormData() {
        return {
            batchMasterId: batchMasterId,
            semesterDetails: semesterDetails,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function setUpdateSemesterFormData() {
        return {
            batchMasterId: batchAndSemesterForm.values.batchMasterId,
            updateSemesterDetails: updateSemesterDetails,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function setBatchAndSemesterEditDetails() {
        return {
            batchMasterId: batchAndSemesterForm.values.batchMasterId,
            courseTypeId: batchAndSemesterForm.values.courseTypeId,
            fromYear: batchAndSemesterForm.values.fromYear,
            toYear: batchAndSemesterForm.values.toYear,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function batchMasterSubmit() {
        showLoader(true);
        if (batchMasterForm.isValid) {
            let batchMasterPostData = setBatchMasterDetails()
            addBatchMasterDetails(batchMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsBatch(false)
                        setIsSemester(true)
                        setSemesterData(data.data[0].semesterNumber);                       
                        setSemesterCount(data.data[0].semesterNumber.length)
                        setBatchMasterId(data.data[0].batchMasterId);

                        let lastElement = data.data[0].semesterNumber[data.data[0].semesterNumber.length - 1];
                        let semester = data.data[0].semesterNumber[data.data[0].semesterNumber.length - 1].totalList;
                        setSemester(semester)

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (batchMasterForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateFromDateValue(event: any, cell: string) {

        const newData: any = [];

        semesterData.forEach((item: any, index: any) => {
            if (item['totalList'] == cell) {
                item['fromDate'] = event.target.value
            }
            newData.push(item)
        });
        setSemesterData(newData)

        var fromDateArray = event.target.value

        if (semesterDetails.length == 0) {
            var obj
            obj = {
                semesterNumber: cell,
                fromDate: fromDateArray,
                toDate: ""
            };
            semesterDetails.push(obj);
        } else {
            let selectedItem: any
            semesterDetails.forEach((item: any, index: string) => {
                if (item['semesterNumber'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['fromDate'] = fromDateArray
            } else {
                var obj
                obj = {
                    semesterNumber: cell,
                    fromDate: fromDateArray,
                    toDate: ""
                };
                semesterDetails.push(obj);
            }
        }
    }

    function updateToDateValue(event: any, cell: string) {

        const newData: any = [];

        semesterData.forEach((item: any, index: any) => {
            if (item['totalList'] == cell) {
                item['toDate'] = event.target.value
            }
            newData.push(item)
        });
        setSemesterData(newData)

        var toDateArray = event.target.value

        if (semesterDetails.length == 0) {
            var obj
            obj = {
                semesterNumber: cell,
                fromDate: "",
                toDate: toDateArray,
            };
            semesterDetails.push(obj);
        } else {
            let selectedItem: any
            semesterDetails.forEach((item: any, index: string) => {
                if (item['semesterNumber'] == cell) {
                    selectedItem = item

                    let selectedSemester = selectedItem.semesterNumber;
                    setSelectedSemester(selectedSemester)
                }
            });
            if (selectedItem != undefined) {
                selectedItem['toDate'] = toDateArray
            } else {
                var obj
                obj = {
                    semesterNumber: cell,
                    fromDate: "",
                    toDate: toDateArray,
                };
                semesterDetails.push(obj);
            }
        }
    }

    const semesterSubmit = () => {
        showLoader(true);
        if (semesterData.length == semesterDetails.length) {
            let semesterPostData = setSemesterFormData()
            addSemesterDetails(semesterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal();
                        getBatchMaster()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function editFromDateValue(event: any, cell: number, dataDate: any) {

        var fromDateArray = event.target.value

        const newData: any = [];

        semesterDetailsData.forEach((item: any, index: any) => {
            if (item['semesterDetailsId'] == cell) {
                item['fromDate'] = fromDateArray
            }
            newData.push(item)
        });
        setSemesterDetailsData(newData)

        if (updateSemesterDetails.length == 0) {
            var obj
            obj = {
                semesterDetailsId: cell,
                fromDate: fromDateArray,
                toDate: dataDate
            };
            updateSemesterDetails.push(obj);
        } else {
            let selectedItem: any
            updateSemesterDetails.forEach((item: any, index: string) => {
                if (item['semesterDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['fromDate'] = fromDateArray
            } else {
                var obj
                obj = {
                    semesterDetailsId: cell,
                    fromDate: fromDateArray,
                    toDate: dataDate
                };
                updateSemesterDetails.push(obj)
            }

        }
    }

    function editToDateValue(event: any, cell: any, dataDate: any) {

        var toDateArray = event.target.value

        const newData: any = [];

        semesterDetailsData.forEach((item: any, index: any) => {
            if (item['semesterDetailsId'] == cell) {
                item['toDate'] = toDateArray
            }
            newData.push(item)
        });
        setSemesterDetailsData(newData)

        if (updateSemesterDetails.length == 0) {
            var obj
            obj = {
                semesterDetailsId: cell,
                fromDate: dataDate,
                toDate: toDateArray
            };
            updateSemesterDetails.push(obj);
        } else {
            let selectedItem: any
            updateSemesterDetails.forEach((item: any, index: string) => {
                if (item['semesterDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['toDate'] = toDateArray
            } else {
                var obj
                obj = {
                    semesterDetailsId: cell,
                    fromDate: dataDate,
                    toDate: toDateArray
                };
                updateSemesterDetails.push(obj);
            }
        }
    }

    const updateSemesterSubmit = () => {
        showLoader(true);
        if (updateSemesterDetails.length > 0) {
            let updateSemesterPostData = setUpdateSemesterFormData()
            updateSemester(updateSemesterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsBatch(true)
                        setIsSemester(false)
                        closeModal()
                        getBatchMaster()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateSemesterDetails.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const updateBatchMasterSubmit = () => {
        showLoader(true);
        if (batchAndSemesterForm.isValid) {
            let batchAndSemesterPostData = setBatchAndSemesterEditDetails()
            updateBatchMaster(batchAndSemesterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getBatchMaster()
                        closeModal();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (batchAndSemesterForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            closeModal();
        }
    }

    const deleteBatchMasterSubmit = () => {
        showLoader(true);
        if (batchAndSemesterForm.isValid) {
            let batchAndSemesterPostData = setBatchAndSemesterEditDetails()
            deleteBatchMaster(batchAndSemesterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsDeleteOpen(false);
                        closeModal()
                        getBatchMaster()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (batchAndSemesterForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getCourseTypeList() {
        getCourseType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseType;
                    if (data != undefined) {
                        setCourseTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCourseTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchMaster() {
        setDataStatus(false)
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        getColumnsForTable('getBatchMasterDetails', 'get');
                        setBatchMasterData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataStatus(true)
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")                  
                    getColumnsForTable('getBatchMasterDetails', 'get');
                    setBatchMasterData([]);
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)

                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getSemesterByBatchMaster(batchMasterId: any) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                    setNoDataMsgSemester(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getBatchMasterDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeModal() {
        setEditBatchMasterModal(false)
        setAddBatchMasterModal(false)
        setIsDeleteOpen(false)
        batchMasterForm.resetForm()
        batchAndSemesterForm.resetForm()
        setUpdateSemesterDetails([])
        setSemesterData([]);
        setSemesterCount(0)
        setSemesterDetails([]);
        setBatchMasterId('');
        setIsBatch(true)
        setIsSemester(false)
        setCourseTypeId('')
        setGraduationTypeId('')
        setSemesterDetailsData([]);
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
    }

    const selectCourseType = (e: any) => {
        let courseTypeId = e
        setCourseTypeId(courseTypeId)
    }

    return (
        <>
            <PageWrapper title={`Batch Creation`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Batch List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getBatchMasterDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Batch Master List", batchMasterData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(batchMasterData, columnVisibilityData, "Batch Master List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Batch Master List", batchMasterData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddBatchMasterModal(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>


                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.batchMasterId}>
                                                        <td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.batchMasterId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}
                                            </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>

                </Page>

                <Modal
                    setIsOpen={setAddBatchMasterModal}
                    isOpen={addBatchMasterModal}
                    titleId='addBatchMaster'
                    isStaticBackdrop
                    size='lg'>
                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setAddBatchMasterModal(false) }} onClick={closeModal}>
                        <ModalTitle id='addBatchMaster'>Batch Creation</ModalTitle>
                    </ModalHeader>
                    <form noValidate onSubmit={batchMasterForm.handleSubmit}>
                        <ModalBody>
                            {isBatch ?
                                <div className="row g-4">
                                    <div className="col-md-3">
                                        <FormGroup id="courseTypeId" label="Type Of Course" isFloating>
                                            <SearchableSelect isFloating ariaLabel='Graduation Type' placeholder="Select Graduation"
                                                onChange={(e: any) => selectCourseType(e)}
                                                onBlur={batchMasterForm.handleBlur}
                                                value={courseTypeId}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.courseTypeId}
                                                invalidFeedback={batchMasterForm.errors.courseTypeId}
                                                list={courseTypeData.map((data: any) => (
                                                    { value: data.courseTypeId, label: data.courseTypeName }
                                                ))} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-3">
                                        <FormGroup id="graduationTypeId" label="Graduation Type" isFloating>
                                            <SearchableSelect isFloating ariaLabel='Graduation Type' placeholder="Select Graduation"
                                                onChange={(e: any) => selectGraduationType(e)}
                                                value={graduationTypeId}
                                                onBlur={batchMasterForm.handleBlur}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.graduationTypeId}
                                                invalidFeedback={batchMasterForm.errors.graduationTypeId}
                                                list={graduationTypeData.map((data: any) => (
                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                ))} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-3">
                                        <FormGroup id="fromYear" label="From Year" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='From Year'
                                                onChange={batchMasterForm.handleChange}
                                                onBlur={batchMasterForm.handleBlur}
                                                value={batchMasterForm.values.fromYear}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.fromYear}
                                                invalidFeedback={batchMasterForm.errors.fromYear}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-3">
                                        <FormGroup id="toYear" label="To Year" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='To Year'
                                                onChange={batchMasterForm.handleChange}
                                                onBlur={batchMasterForm.handleBlur}
                                                value={batchMasterForm.values.toYear}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.toYear}
                                                invalidFeedback={batchMasterForm.errors.toYear}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                : null}

                            {isSemester ? <>
                                <div className="row">
                                    <div className="col-md-1 ms-4"></div>
                                    <div className="col-md-3 ms-2">
                                        <FormGroup id="courseTypeId" label="Type Of Course" isFloating>
                                            <SearchableSelect isFloating ariaLabel='Graduation Type' placeholder="Select Graduation"
                                                onChange={(e: any) => selectCourseType(e)}
                                                onBlur={batchMasterForm.handleBlur}
                                                value={courseTypeId}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.courseTypeId}
                                                invalidFeedback={batchMasterForm.errors.courseTypeId}
                                                list={courseTypeData.map((data: any) => (
                                                    { value: data.courseTypeId, label: data.courseTypeName }
                                                ))} disabled />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-3">
                                        <FormGroup id="fromYear" label="From Year" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='From Year'
                                                onChange={batchMasterForm.handleChange}
                                                onBlur={batchMasterForm.handleBlur}
                                                value={batchMasterForm.values.fromYear}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.fromYear}
                                                invalidFeedback={batchMasterForm.errors.fromYear}
                                                disabled
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-md-3">
                                        <FormGroup id="toYear" label="To Year" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='To Year'
                                                onChange={batchMasterForm.handleChange}
                                                onBlur={batchMasterForm.handleBlur}
                                                value={batchMasterForm.values.toYear}
                                                isValid={batchMasterForm.isValid}
                                                isTouched={batchMasterForm.touched.toYear}
                                                invalidFeedback={batchMasterForm.errors.toYear}
                                                disabled
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-10">
                                        <table className="table table-modern table-hover text-center text-nowrap mg-b-0">
                                            <thead className="text-center">
                                                <tr className="table-primary">
                                                    <th>Semester Name</th>
                                                    <th>From Date</th>
                                                    <th>To Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {semesterData.map((data: any) => (
                                                    <tr className="text-center" key={data.totalList}>
                                                        <td>{data.totalList}</td>
                                                        <td>
                                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='FromDate'
                                                                    onChange={(event) => updateFromDateValue(event, data.totalList)}
                                                                    value={data.fromDate}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='ToDate'
                                                                    onChange={(event) => updateToDateValue(event, data.totalList)}
                                                                    value={data.toDate}

                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </> : null}
                        </ModalBody>
                        <ModalFooter>
                            <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                            {isBatch ?
                                <Button icon="Save" color="info" type="submit" isDisable={!batchMasterForm.isValid && !batchMasterForm.submitCount}>
                                    Save
                                </Button> : null}
                            {isSemester ?
                                <Button icon="Save" color="info" isDisable={semesterDetails.length == semesterCount ? false : true} onClick={semesterSubmit}>
                                    Save
                                </Button> : null}
                        </ModalFooter>
                    </form>
                </Modal>

                <Modal
                    setIsOpen={setEditBatchMasterModal}
                    isOpen={editBatchMasterModal}
                    titleId='updateBatchMaster'
                    isCentered
                    isStaticBackdrop
                    size='lg'>

                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setEditBatchMasterModal(false) }} onClick={() => setEditBatchMasterModal(false)}>
                        <ModalTitle id='updateBatchMaster'>Update Batch & Semester</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row g-4">
                            <div className="col-md-3">
                                <FormGroup id="courseTypeId" label="Type Of Course" isFloating>
                                    <Select ariaLabel='Type Of Course' placeholder="Select Type Of Course"
                                        onChange={batchAndSemesterForm.handleChange}
                                        onBlur={batchAndSemesterForm.handleBlur}
                                        value={batchAndSemesterForm.values.courseTypeId}
                                        isValid={batchAndSemesterForm.isValid}
                                        isTouched={batchAndSemesterForm.touched.courseTypeId}
                                        invalidFeedback={batchAndSemesterForm.errors.courseTypeId}
                                        list={courseTypeData.map((data: any) => (
                                            { value: data.courseTypeId, text: data.courseTypeName }
                                        ))} disabled />
                                </FormGroup>
                            </div>
                            <div className="col-md-3">
                                <FormGroup id="fromYear" label="From Year" isFloating>
                                    <Input
                                        type='date'
                                        ariaLabel='From Year'
                                        onChange={batchAndSemesterForm.handleChange}
                                        onBlur={batchAndSemesterForm.handleBlur}
                                        value={batchAndSemesterForm.values.fromYear}
                                        isValid={batchAndSemesterForm.isValid}
                                        isTouched={batchAndSemesterForm.touched.fromYear}
                                        invalidFeedback={batchAndSemesterForm.errors.fromYear}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-md-3">
                                <FormGroup id="toYear" label="To Year" isFloating>
                                    <Input
                                        type='date'
                                        ariaLabel='To Year'
                                        onChange={batchAndSemesterForm.handleChange}
                                        onBlur={batchAndSemesterForm.handleBlur}
                                        value={batchAndSemesterForm.values.toYear}
                                        isValid={batchAndSemesterForm.isValid}
                                        isTouched={batchAndSemesterForm.touched.toYear}
                                        invalidFeedback={batchAndSemesterForm.errors.toYear}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-md-3">
                                <FormGroup>
                                    <Button icon="Save" color="info" type="submit" onClick={updateBatchMasterSubmit}>
                                        Update Batch
                                    </Button>
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                                <table className="table table-modern table-hover text-center text-nowrap mg-b-0">
                                    <thead className="text-center">
                                        <tr className="table-primary">
                                            <th>Semester Name</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {semesterDetailsData != '' ?
                                            <>
                                                {semesterDetailsData.map((data: any) => (
                                                    <tr className="text-center" key={data.semesterNumber}>
                                                        <td><div className="mt-3">{data.semesterNumber}</div></td>
                                                        <td>
                                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='FromDate'
                                                                    onChange={(event) => editFromDateValue(event, data.semesterDetailsId, data.toDate)}
                                                                    value={data.fromDate}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                                <Input
                                                                    type='date'
                                                                    ariaLabel='ToDate'
                                                                    onChange={(event) => editToDateValue(event, data.semesterDetailsId, data.fromDate)}
                                                                    value={data.toDate}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </> : <NoDataMsg columnsCount={3} msg={noDataMsgForSemester} />}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteBatchMasterSubmit} />
                            </div>

                            <ModalFooter>
                                <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                                <Button color='danger' icon='Delete' type="submit" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>Delete All</Button>
                                <Button color='info' icon='Save' type="submit" onClick={updateSemesterSubmit}>Update </Button>
                            </ModalFooter>

                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    );
}
export default BatchMaster;