import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Avatar from '../../../components/Avatar';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import { toasts } from '../../../services/toast.service';
import UserImage2Webp from '../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../assets/img/wanna/wanna1.png';
import { getProfileForStudent } from '../../../services/student.service';

import { getStudentTotalFeeCollection } from '../../../services/fee.service';
import AuthContext from '../../../contexts/authContext';
import { useReactToPrint } from 'react-to-print';
import USERS from '../../../common/data/userDummyData';
import { pictNotLoading, profilePic } from '../../../services/common.service';

const StudentProfile = () => {
    const { darkModeStatus } = useDarkMode();
    const { studentDetailsId } = useParams();
    const { userAccountId, userTypeId } = useContext(AuthContext);


    useEffect(() => {
        getStudentProfile(studentDetailsId);
        getStudentTotalFeeCollectionList(studentDetailsId);
    }, [])

    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([])
    const [isAlumini, setIsAlumini] = useState<number>(0)

    const componentRef = useRef(null);
    const navigate = useNavigate();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        setIsAlumini(data[0].isAluminiStudent)

                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: any) {
        getStudentTotalFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <>
            {studentDetailsData.map((student: any) => (
                <PageWrapper title={`${student.studentName}`} ref={componentRef} key={student.studentDetailsId}>
                    {userTypeId != 10 ?
                        <SubHeader>
                            <SubHeaderLeft>
                                <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                                    Back to List
                                </Button>
                                <SubheaderSeparator />
                            </SubHeaderLeft>
                            <SubHeaderRight>
                                <Button
                                    color='primary'
                                    isLight
                                    icon='Edit'
                                    tag='a'
                                    to={`../academic/updateStudent/${student.studentDetailsId}`}>
                                    Edit
                                </Button>
                            </SubHeaderRight>
                        </SubHeader>
                        : <SubHeader>
                            <SubHeaderLeft>
                                <Button
                                    color='info'
                                    isLink
                                    icon='ArrowBack'
                                    tag='a'
                                    to={isAlumini == 0 ? `../academic/studentFeeDetails` : `../academic/aluminiStudent`}>
                                    Back to List
                                </Button>
                                <SubheaderSeparator />
                            </SubHeaderLeft>
                        </SubHeader>}
                    <Page>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <Card className='shadow-3d-info'>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12 d-flex justify-content-center'>
                                                {student.profilePath != null ?
                                                    <img style={{ borderRadius: '50%' }} src={student.profilePath}
                                                        width="120" height="120" onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                    : <img src={profilePic(student.genderId)}
                                                        width="120" height="120" />}
                                            </div>
                                            <div className='col-12'>
                                                <div className='row g-2'>
                                                    <span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${student.studentName}`}</span>
                                                    <div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
                                                        {student.admissionNo}
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='HowToReg' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0 '>
                                                                    {student.uniRegNo ? student.uniRegNo : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                </div>
                                                                <div className='text-muted'>
                                                                    UniReg No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon={student.genderId == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.gender ? student.gender : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Gender
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='PhoneIphone' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.smsToBeSentNumber ? student.smsToBeSentNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Mobile
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='DateRange' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.dateOfBirth ? student.dateOfBirth : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Date of Birth
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Mail' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold  mb-0'>
                                                                    {student.email ? student.email : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Email Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                {isAlumini == 0 ?
                                    <Card>
                                        <CardHeader>
                                            <CardLabel icon='ShowChart' iconColor='secondary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Fee Status
                                                </CardTitle>
                                            </CardLabel>
                                            <CardActions>
                                                <strong>Till Now</strong>.
                                            </CardActions>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='col-12'>
                                                {studentTotalFeeCollectionData.map((studentTotalFee: any) => (
                                                    <div className='row g-4 align-items-center' key={studentTotalFee.studentDetailsId}>
                                                        <div className='col-xl-6'>
                                                            <div
                                                                className={classNames(
                                                                    'd-flex align-items-center rounded-2 p-3',
                                                                    {
                                                                        'bg-l10-info': !darkModeStatus,
                                                                        'bg-lo25-info': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='DoneAll' size='3x' color='info' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-4 text-info mb-0'>
                                                                        {studentTotalFee.semFees_grossPayable +
                                                                            studentTotalFee.busFees_grossPayable + studentTotalFee.prevSemFees_netPayable + studentTotalFee.prevBusFees_netPayable + studentTotalFee.extraFees_grossPayable + studentTotalFee.prevExtraFees_netPayable}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <b> TOTAL</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <div
                                                                className={classNames(
                                                                    'd-flex align-items-center rounded-2 p-3',
                                                                    {
                                                                        'bg-l10-success': !darkModeStatus,
                                                                        'bg-lo25-success': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Savings' size='3x' color='success' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-4 text-success mb-0'>
                                                                        {studentTotalFee.semFees_amountPaid +
                                                                            studentTotalFee.semFees_concession + studentTotalFee.busFees_amountPaid + studentTotalFee.busFees_concession + studentTotalFee.extraFees_amountPaid + studentTotalFee.extraFees_concession}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <b>PAID</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <div
                                                                className={classNames(
                                                                    'd-flex align-items-center rounded-2 p-3',
                                                                    {
                                                                        'bg-l10-danger': !darkModeStatus,
                                                                        'bg-lo25-danger': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='Celebration'
                                                                        size='3x'
                                                                        color='danger'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-4 text-danger mb-0'>
                                                                        {studentTotalFee.semFees_netPayable +
                                                                            studentTotalFee.prevSemFees_netPayable + studentTotalFee.busFees_netPayable +
                                                                            studentTotalFee.prevBusFees_netPayable + studentTotalFee.extraFees_netPayable}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <b>BALANCE</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6'>
                                                            <div
                                                                className={classNames(
                                                                    'd-flex align-items-center rounded-2 p-3',
                                                                    {
                                                                        'bg-l10-secondary': !darkModeStatus,
                                                                        'bg-lo25-secondary': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Timer' size='3x' color='secondary' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-4 text-secondary mb-0'>
                                                                        {studentTotalFee.totalFineAmount}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <b>FINE</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </CardBody>
                                    </Card>
                                    : null}
                            </div>

                            <div className='col-lg-8'>
                                <Card className='shadow-3d-primary'>
                                    <CardHeader>
                                        <CardLabel icon='Summarize' iconColor='success'>
                                            <CardTitle tag='div' className='h5'>
                                                Other Info
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>
                                            <div className='col-md-6'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-primary bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-primary-hover transition-base rounded-2 mb-4`}
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Address
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className='d-flex align-items-center pb-3'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon
                                                                    icon='House'
                                                                    size='4x'
                                                                    color='primary'
                                                                />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0'>
                                                                    {student.address ? student.address : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-danger-hover transition-base rounded-2 mb-0`}
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Other Details
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className='col-12'>
                                                            <div className='row g-2'>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='Flag' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold mb-0 '>
                                                                                {student.nationality ? student.nationality : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                </div>
                                                                            <div className='text-muted'>
                                                                                Nationality
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='Article' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold mb-0'>
                                                                                {student.religion ? student.religion : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                            </div>
                                                                            <div className='text-muted'>
                                                                                Religion
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='BookmarkAdded' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold mb-0'>
                                                                                {student.casteName ? student.casteName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                                         </div>
                                                                            <div className='text-muted'>
                                                                                Caste Name
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='Cached' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold  mb-0'>
                                                                                {student.category ? student.category : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                                         </div>
                                                                            <div className='text-muted'>
                                                                                Category
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='FilterList' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold  mb-0'>
                                                                                {student.martialStatus ? student.martialStatus : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                                         </div>
                                                                            <div className='text-muted'>
                                                                                Martial status
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className='col-md-6'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-success bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-success-hover transition-base rounded-2 mb-0`}
                                                    stretch
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Class Info
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody className='pt-0'>
                                                        <div className='row g-2 d-flex align-items-center'>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='HomeWork' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold mb-0'>
                                                                            {student.departmentName ? student.departmentName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                                </div>
                                                                        <div className='text-muted'>
                                                                            Department
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='school' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold mb-0'>
                                                                            {student.graduationType ? student.graduationType : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Graduation
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='Source' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.courseName ? student.courseName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Course
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='CalendarToday' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.batchName ? student.batchName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Batch
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='Checklist' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold mb-0'>
                                                                            {student.semesterNumber ? student.semesterNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Semester
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='Subject' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.sectionName ? student.sectionName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Section
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='AccessTime' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.shiftName ? student.shiftName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            shift
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='EmojiPeople' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.referredBy ? student.referredBy : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Referred By
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='DirectionsBus' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.areaName ? student.areaName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Transport Area Name
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                {isAlumini == 0 ?
                                    <Card>
                                        <CardHeader>
                                            <CardLabel icon='Task' iconColor='primary'>
                                                <CardTitle tag='div' className='h5'>
                                                    Fee Details
                                                </CardTitle>
                                            </CardLabel>
                                            <CardActions>
                                                {userTypeId != 10 ?
                                                    <Button color='storybook' isLight icon='ArrowForward' onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId}`)}>
                                                        Fee Details
                                                    </Button>
                                                    : <></>}
                                            </CardActions>
                                        </CardHeader>
                                        <CardBody>
                                            {studentTotalFeeCollectionData ?
                                                <div className="table-responsive">
                                                    <table className="table table-modern table-hover" >
                                                        <thead className="text-center text-uppercase align-middle">
                                                            <tr>
                                                                <th className='table-info'></th>
                                                                <th className='table-info'>Sem <br />Fees</th>
                                                                <th className='table-info'>Prev - Sem <br />Fees</th>
                                                                <th className='table-info'>Fine <br />Fees</th>
                                                                <th className='table-info'>Bus <br />Fees</th>
                                                                <th className='table-info'>Prev - Sem Bus <br />Fees</th>
                                                                <th className='table-info'>Sem - Fine <br />Fees</th>
                                                            </tr>
                                                        </thead>

                                                        {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                            <tbody className="text-center align-middle" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                                <tr>
                                                                    <th>GROSS <br />PAYABLE</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_grossPayable}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_grossPayable}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>AMOUNT PAID</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.totalFineAmount}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_amountPaid}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_amountPaid}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>CONCESSION</th>
                                                                    <td><strong>{studentTotalFeeCollectionData.semFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevSemFees_concession}</strong></td>
                                                                    <td><strong>0</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.busFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.prevBusFees_concession}</strong></td>
                                                                    <td><strong>{studentTotalFeeCollectionData.extraFees_concession}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <th>NET PAYABLE</th>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.semFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.semFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.semFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.prevSemFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.prevSemFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.prevSemFees_netPayable}</strong>
                                                                        }
                                                                    </td>

                                                                    <td><strong>0</strong></td>

                                                                    <td>
                                                                        {studentTotalFeeCollectionData.busFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.busFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.prevBusFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.prevBusFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.prevBusFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {studentTotalFeeCollectionData.extraFees_netPayable === 0 ?
                                                                            <strong className="text-success">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                            :
                                                                            <strong className="text-danger">{studentTotalFeeCollectionData.extraFees_netPayable}</strong>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                    </table>
                                                    <br />

                                                    {studentTotalFeeCollectionData.map((studentTotalFeeCollectionData: any) => (
                                                        <div className="col-12 d-flex justify-content-end" key={studentTotalFeeCollectionData.studentDetailsId}>
                                                            <p className='fw-bold fs-5 mb-0'> Balance : </p>
                                                            <strong className={studentTotalFeeCollectionData.semFees_netPayable + studentTotalFeeCollectionData.prevSemFees_netPayable +
                                                                studentTotalFeeCollectionData.busFees_netPayable +
                                                                studentTotalFeeCollectionData.prevBusFees_netPayable +
                                                                studentTotalFeeCollectionData.extraFees_netPayable == 0 ? "text-success" : "text-danger"}   >
                                                                <p className='fw-bold fs-5 mb-0 ms-2'>
                                                                    {studentTotalFeeCollectionData.semFees_netPayable + studentTotalFeeCollectionData.prevSemFees_netPayable +
                                                                        studentTotalFeeCollectionData.busFees_netPayable +
                                                                        studentTotalFeeCollectionData.prevBusFees_netPayable +
                                                                        studentTotalFeeCollectionData.extraFees_netPayable}
                                                                </p>
                                                            </strong>
                                                        </div>
                                                    ))}

                                                </div>
                                                :

                                                <div className='text-danger col-12 d-flex justify-content-center mt-5'>
                                                    <p className='fw-bold fs-5 mb-0 mt-5'>Fees Not Collected Yet . </p>
                                                </div>
                                            }

                                        </CardBody>
                                    </Card>
                                    : <></>}
                            </div>
                        </div>
                    </Page>
                </PageWrapper >))
            }
        </>
    );
};

export default StudentProfile;
