import { useContext, useEffect, useRef, useState } from "react"
import Page from "../../../layout/Page/Page"
import Collapse from "../../bootstrap/Collapse"
import { toasts } from "../../../services/toast.service"
import Icon from "../../icon/Icon"
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card"
import FormGroup from "../../bootstrap/forms/FormGroup"
import Input from "../../bootstrap/forms/Input"
import Button, { ButtonGroup } from "../../bootstrap/Button"
import Select from "../../bootstrap/forms/Select"
import { useFormik } from "formik"
import PageWrapper from "../../../layout/PageWrapper/PageWrapper"
import OpenCardComponent from "../../../common/components/OpenCardComponent"
import { useReactToPrint } from "react-to-print"
import PaginationButtons, { dataPagination } from "../../PaginationButtons"
import useSortableData from "../../../hooks/useSortableData"
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown"
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks"
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService"
import { convertDateRangeToEpoch, getColumnsForDataTable, getCurrentDateAndTime, updateColumnsForDataTable, updateFilter } from "../../../services/common.service"
import { getDepartmentList, getMonthBySemesterTypeId, getSemesterType, } from "../../../services/master.service"
import { getAttendanceReport } from "../../../services/report.service"
import { getLicenseKey } from "../../../services/application.settings"
import AuthContext from "../../../contexts/authContext"
import NoDataMsg from "../../../common/components/NoDataMsg"
import SearchableSelect from "../../../common/components/SearchableSelect"

const AttendanceReport = () => {

    useEffect(() => {
        getDepartment();
        getSemesterTypeId();
    }, [])

    const [departmentData, setDepartmentData] = useState<any>([])
    const [semesterTypeData, setSemesterTypeData] = useState<any>([])
    const [monthData, setMonthData] = useState<any>([])
    const [lateAttendanceData, setLateAttendanceData] = useState<any>([])
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [attendanceReportWiseData, setAttendanceReportWiseData] = useState<any>([])
    const [semesterTypeId, setSemesterTypeId] = useState<any>('')
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const [isChecked, setIsChecked] = useState(false)
    const componentRef = useRef(null);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [cardTitle, setCardTitle] = useState('')
    const [exportFileName, setExportFileName] = useState('')
    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1000);
    const { items, requestSort, getClassNamesFor } = useSortableData(attendanceReportWiseData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isLoader, setIsLoader] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')

    const [reportTypeId, setReportTypeId] = useState<any>('')
    const [reportTypeIdValue, setReportTypeIdValue] = useState<any>('')

    const [monthId, setMonthId] = useState<any>('')
    const [departmentId, setDepartmentId] = useState<any>('')
    const [trHeading, setTrHeading] = useState<any>('')

    const selectReportType = (e: any) => {
        resetTable()
        setDataSuccess(false)
        setIsLoader(false)
        let reportTypeId = e
        setReportTypeId(reportTypeId?.value)
        setReportTypeIdValue(reportTypeId)
        setIsOpenListCard(true)
    }

    const absenteesReportForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentId: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
            semesterTypeId: '',
            monthId: '',
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function viewDepartWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = absenteesReportForm.values.fromDate
        let toDate = absenteesReportForm.values.toDate
        //let departmentId = absenteesReportForm.values.departmentId
        let datesArray = convertDateRangeToEpoch(fromDate, toDate);

        getDepartmentWiseAttendanceReport(datesArray[0], datesArray[1], departmentId?.value);

        setCardTitle('Department Wise Attendance Report')
        setExportFileName('DepartmentWiseAttendanceReport')
        setTrHeading(`${departmentId?.label} DEPARTMENT ABSENTEES LIST FROM ${absenteesReportForm.values.fromDate} TO ${absenteesReportForm.values.toDate}`)
    }

    function viewDateWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = absenteesReportForm.values.fromDate
        let datesArray = convertDateRangeToEpoch(fromDate, fromDate);
        getDateWiseAttendanceReport(datesArray[0], datesArray[1],);
        setCardTitle('Date Wise Attendance Report')
        setExportFileName('DateWiseAttendanceReport')
        setTrHeading(`STUDENT ABSENT LIST ${absenteesReportForm.values.fromDate}`)
    }

    function viewMonthWiseAttendance() {
        setDataSuccess(false)
        setIsLoader(true);
        getMonthWiseAttendanceReport(monthId?.value, departmentId?.value)
        setCardTitle('Month Wise Attendance Report')
        setExportFileName('MonthWiseAttendanceReport')
        setTrHeading(`STUDENT ABSENT LIST - ({${monthId?.label}} {${departmentId?.label}})`)
    }

    function viewYearWiseAttendanceReport() {
        setDataSuccess(false)
        setIsLoader(true);
        getYearWiseAttendanceReport();
        setCardTitle('Year Wise Attendance Report')
        setExportFileName('YearWiseAttendanceReport')
    }

    const selectDepartment = (e: any) => {
        setSemesterTypeId('')
        setMonthId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectSemesterType = (e: any) => {
        setMonthId('')
        let semesterTypeId = e
        setSemesterTypeId(semesterTypeId)
        if (semesterTypeId?.value != undefined) {
            getMonthBySemesterType(semesterTypeId?.value)
        }
    }

    const selectMonth = (e: any) => {
        let monthId = e
        setMonthId(monthId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        const departmentData = [{ departmentId: 0, departmentName: 'All' }];
                        data.forEach((obj: { departmentId: number; departmentName: string }) => {
                            let department = departmentData.push(obj);
                        });
                        setDepartmentData(departmentData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterTypeId() {
        getSemesterType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semesterType;
                    if (data != undefined) {
                        setSemesterTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMonthBySemesterType(semesterTypeId: any) {
        getMonthBySemesterTypeId(semesterTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.monthBySemesterTypeId;
                    if (data != undefined) {
                        setMonthData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMonthData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartmentWiseAttendanceReport(fromDate: any, toDate: any, departmentId: any) {
        setIsLoader(true);
        getAttendanceReport(fromDate, toDate, departmentId, 0, 'department',
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.attendanceReport;
                    if (data != undefined) {
                        setDataSuccess(true)
                        setIsLoader(false);
                        setAttendanceReportWiseData(data);
                        setIsOpenListCard(false)
                        getColumnsForTable('getAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success == false) {
                    setIsLoader(false);
                    setDataSuccess(true)
                    setIsOpenListCard(false)
                    getColumnsForTable('getAttendanceReport', 'get');
                    setAttendanceReportWiseData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getDateWiseAttendanceReport(fromDate: any, toDate: any) {
        setIsLoader(true);
        getAttendanceReport(fromDate, toDate, 0, 0, 'dateWise',
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.attendanceReport;
                    if (data != undefined) {
                        setDataSuccess(true)
                        setIsLoader(false);
                        setAttendanceReportWiseData(data);
                        setIsOpenListCard(false)
                        getColumnsForTable('getAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success == false) {
                    
                    // toasts(response.data.message, "Error")
                    getColumnsForTable('getAttendanceReport', 'get');
                    setAttendanceReportWiseData([]);
                    setNoDataMsg(response.data.message)
                    setIsLoader(false);
                    setDataSuccess(true)
                    setIsOpenListCard(false)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getMonthWiseAttendanceReport(monthId: any, departmentId: any) {
        setIsLoader(true);
        getAttendanceReport(0, 0, departmentId, monthId, 'month',
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.attendanceReport;
                    if (data != undefined) {
                        setDataSuccess(true)
                        setIsLoader(false);
                        setAttendanceReportWiseData(data);
                        setIsOpenListCard(false)
                        getColumnsForTable('getAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")

                    }
                }
                else if (response.data.success == false) {
                    setIsLoader(false);
                    setDataSuccess(true)
                    // toasts(response.data.message, "Error")
                    getColumnsForTable('getAttendanceReport', 'get');
                    setAttendanceReportWiseData([]);
                    setIsOpenListCard(false)
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getYearWiseAttendanceReport() {
        setIsLoader(true)
        getAttendanceReport(0, 0, 0, 0, 'year',
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.attendanceReport;
                    let periodName = response.data.data.attendanceReport[0].periodName;
                    if (data != undefined) {
                        setIsLoader(false)
                        setIsOpenListCard(false)
                        setDataSuccess(true)
                        setAttendanceReportWiseData(data);
                        //setPeriodName(periodName)
                        setTrHeading(`STUDENT ABSENT LIST( ${periodName} )`)
                        getColumnsForTable('getAttendanceReport', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                    } else {
                        setIsLoader(false)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success == false) {
                    setIsLoader(false)
                    setDataSuccess(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getAttendanceReport', 'get');
                    setIsOpenListCard(false)
                    setAttendanceReportWiseData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false)
                    setDataSuccess(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false)
                setDataSuccess(true)
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }
            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getAttendanceReport', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function resetTable() {
        setDataSuccess(false)
        absenteesReportForm.resetForm()
        setSemesterTypeId('')
        setDepartmentId('')
        setMonthId('')
        setIsOpenListCard(true)
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const reportTypeData = [{ reportTypeId: 1, reportType: 'Department Wise' }, { reportTypeId: 2, reportType: 'Daily' }, { reportTypeId: 3, reportType: 'Monthly' }, { reportTypeId: 4, reportType: 'Yearly' }]

    return (
        <>
            <PageWrapper title={`Attendance Report`}>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='list' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Attendance Report
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <FormGroup id="reportTypeId" label="Select Report Type" isFloating>
                                            <SearchableSelect placeholder="Select Report Type" isFloating
                                                ariaLabel='Report Type'
                                                onChange={selectReportType}
                                                value={reportTypeIdValue}
                                                list={reportTypeData.map((data: any) => (
                                                    { value: data.reportTypeId, label: data.reportType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    {reportTypeId == '1' ?
                                        <>
                                            <div className="col-lg-3">
                                                <FormGroup id='departmentId' label='Department' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                        list={departmentData.map((data: any) => (
                                                            { value: data.departmentId, label: data.departmentName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2">
                                                <FormGroup id='fromDate' label='Date' isFloating>
                                                    <Input onChange={absenteesReportForm.handleChange} value={absenteesReportForm.values.fromDate}
                                                        onBlur={absenteesReportForm.handleBlur}
                                                        isValid={absenteesReportForm.isValid}
                                                        isTouched={absenteesReportForm.touched.fromDate}
                                                        invalidFeedback={absenteesReportForm.errors.fromDate}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2">
                                                <FormGroup id='toDate' label='Date' isFloating>
                                                    <Input onChange={absenteesReportForm.handleChange} value={absenteesReportForm.values.toDate}
                                                        onBlur={absenteesReportForm.handleBlur}
                                                        isValid={absenteesReportForm.isValid}
                                                        isTouched={absenteesReportForm.touched.toDate}
                                                        invalidFeedback={absenteesReportForm.errors.toDate}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2 align-self-center">
                                                <Button icon="ArrowDownward" color="primary" isDisable={departmentId?.value == undefined} onClick={viewDepartWiseList}>View</Button>
                                            </div>
                                        </> : null}
                                    {reportTypeId == '2' ?
                                        <>
                                            <div className="col-lg-3">
                                                <FormGroup id='fromDate' label='Date' isFloating>
                                                    <Input onChange={absenteesReportForm.handleChange} value={absenteesReportForm.values.fromDate}
                                                        onBlur={absenteesReportForm.handleBlur}
                                                        isValid={absenteesReportForm.isValid}
                                                        isTouched={absenteesReportForm.touched.fromDate}
                                                        invalidFeedback={absenteesReportForm.errors.fromDate}
                                                        type='date' />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 align-self-center">
                                                <FormGroup >
                                                    <Button icon="ArrowDownward" color="primary" onClick={viewDateWiseList}>View</Button>
                                                </FormGroup>
                                            </div>
                                        </>
                                        : null}
                                    {reportTypeId == '3' ?
                                        < >
                                            <div className='col-3'>
                                                <FormGroup id='departmentId' label='Department' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                        list={departmentData.map((data: any) => (
                                                            { value: data.departmentId, label: data.departmentName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-2'>
                                                <FormGroup id='semesterTypeId' label='semester Type' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='semester Type'
                                                        onChange={selectSemesterType}
                                                        value={semesterTypeId}
                                                        list={semesterTypeData.map((data: any) => (
                                                            { value: data.semesterTypeId, label: data.semesterType }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-2'>
                                                <FormGroup id='monthId' label='Month' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Month'
                                                        onChange={selectMonth}
                                                        value={monthId}
                                                        list={monthData.map((data: any) => (
                                                            { value: data.monthId, label: data.month }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-2 align-self-center">
                                                <FormGroup>
                                                    <Button icon="South" color="primary" isDisable={departmentId?.value && semesterTypeId?.value && monthId?.value == undefined ? true : false} onClick={viewMonthWiseAttendance}
                                                    >View</Button>
                                                </FormGroup>
                                            </div>
                                        </>
                                        : null}
                                    {reportTypeId == '4' ?
                                        <>
                                            <div className="col-lg-3 align-self-center">
                                                <FormGroup>
                                                    <Button icon="South" color="primary" onClick={viewYearWiseAttendanceReport}
                                                    >View</Button>
                                                </FormGroup>
                                            </div>
                                        </>
                                        : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>{cardTitle}</CardTitle>
                                </CardLabel>

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getAttendanceReport', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel(exportFileName, attendanceReportWiseData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(attendanceReportWiseData, columnVisibilityData, exportFileName)}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile(exportFileName, attendanceReportWiseData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive'>
                                <>
                                    <table className='table table-modern table-hover text-center  text-nowrap'>
                                        <thead>
                                            <tr className='table-primary' style={{ textAlign: 'center' }}>
                                                <td colSpan={13} style={{
                                                    fontFamily: 'Bookman Old Style',
                                                    color: 'rgb(61, 57, 57)'
                                                }}>
                                                    <h5>{trHeading}</h5>
                                                </td>
                                            </tr>
                                            <tr style={{ height: '20px' }}></tr>
                                            <tr className='table-primary'>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline text-center '>S.No{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th >
                                                {columnVisibilityData.map((column: any) => (
                                                    <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                        className='cursor-pointer text-decoration-underline text-center'>
                                                        {column.columnName}
                                                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData != "" ?
                                                <>
                                                    {filteredData.map((attendanceData: any, index: any) => (
                                                        <tr key={attendanceData.index}>
                                                            <td>{attendanceData.sno}</td>
                                                            {columnVisibilityData.map((column: any) => (
                                                                (column.isDisplay && attendanceData[column.keyName]) ?
                                                                    <td key={`${attendanceData.studentDetailsId}-${column.keyName}`}>{attendanceData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                            ))}
                                                        </tr>
                                                    ))
                                                    } </>
                                                :
                                                <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                        </tbody>
                                    </table><h6 className="mg-lg-t-10">Date : {getCurrentDateAndTime('dateAndTime')}</h6>
                                </>
                            </CardBody>
                            {/* <PaginationButtons
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        /> */}
                        </Card> : null}
                </Page>
            </PageWrapper>
        </>
    )
}
export default AttendanceReport;

