import { Children, useContext, useEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getLicenseKey } from "../../../services/application.settings";
import { useFormik } from "formik";
import { getBlood, getCategory, getCity, getConcession, getCurrentDateAndTime, getGenderDetails, getGraduationType, getMotherTongue, getQuotaType, getQuotaTypeDetails, getReligion, getSectionDetails, getStates, onlyAllowNumber, profilePic, profilePicUpload } from "../../../services/common.service";
import { toasts } from "../../../services/toast.service";
import { addStudentDetails, checkCourseCapacity, getTempStudentList, getTempStudentListByGraduationTypeId } from "../../../services/student.service";
import { getBatchByCourseId, getBatchByGraduationTypeId, getCasteDetails, getCourse, getCourseCapacityByBatchCourse, getDepartmentList, getReferralType, getSemester, getUserListByUserTypeId, updateCourseCapacity } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import Card, { CardActions, CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Select from "../../bootstrap/forms/Select";
import Input from "../../bootstrap/forms/Input";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Icon from "../../icon/Icon";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { getShift } from "../../../services/timeTable.service";
import { getTransportAreaMaster } from "../../../services/transport.service";
import { getStudentSchoolName, getStudentSchoolSubject } from "../../../services/administration.service";
import { addCertificateType, getCertificateType } from "../../../services/certificate.service";
import { getScholarshipType } from "../../../services/scholarship.service";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import useDarkMode from "../../../hooks/useDarkMode";
import Avatar from "../../Avatar";
import USERS from "../../../common/data/userDummyData";
import Textarea from "../../bootstrap/forms/Textarea";
import { showLoader } from "../../../services/loader.services";
import AlertService from "../../../services/AlertService";
import classNames from "classnames";
import Popovers from "../../bootstrap/Popovers";
import SearchableSelect from "../../../common/components/SearchableSelect";

function AddStudentWizard() {
    const [step, setStep] = useState<number>(0);
    const totalSteps: number = 7;

    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleStepClick = (newStep: any) => {
        setStep(newStep);
    };

    useEffect(() => {
        getGraduationList();
        getGender();
        getBloodList();
        getMotherTongueList();
        getCategoryDetails();
        getReligionDetails();
        getCaste();
        getStatesList();
        getPermAddr_State();
        selectState({ value: 31, label: 'Tamil Nadu' });
        selectPermAddr_State({ value: 31, label: 'Tamil Nadu' });
        getDepartment();
        getSectionList();
        getReferral();
        getConcessionList();
        getTransportAreaMasterDetails();
        getShiftList();
        getStudentSchoolNameList();
        getStudentSchoolSubjectList();
        getCertificateTypeList(10, 1);
        getScholarshipTypeList();
        getQuota();
        getCityList(31);
        getPermAddr_StateAndCity(31);
        getQuotaList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [tempStudentDetailsId, setTempStudentDetailsId] = useState<any>('')
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [tempStudentData, setTempStudentData] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [bloodData, setBloodData] = useState<any>([])
    const [motherTongueData, setMotherTongueData] = useState<any>([])
    const [categoryData, setCategoryData] = useState<any>([])
    const [religionData, setReligionData] = useState<any>([])
    const [casteDetailsData, setCasteDetailsData] = useState<any>([])
    const [nationality, setNationality] = useState('Indian')
    const [isOtherNation, setIsOtherNation] = useState('isOthers')
    const [physicallyChallenged, setPhysicallyChallenged] = useState('')
    const [isPhysicallyChallengedDetails, setIsPhysicallyChallengedDetails] = useState('isPhysicallyChallengedDetails')
    const [selectedFile, setSelectedFile] = useState<any>()
    const [otherNation, setOtherNation] = useState('')
    const [physicallyChallengedTypeValue, setPhysicallyChallengedTypeValue] = useState('')
    const [physicallyChallengedPercentageValue, setPhysicallyChallengedPercentageValue] = useState('')

    const [commAddr_StateId, setCommAddr_StateId] = useState<any>('')
    const [commAddr_CityId, setCommAddr_CityId] = useState<any>('')

    const [permAddr_StateId, setPermAddr_StateId] = useState<any>('')
    const [permAddr_CityId, setPermAddr_CityId] = useState<any>('')
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [permAddr_StateData, setPermAddr_StateData] = useState<any>([])
    const [permAddr_CityData, setPermAddr_CityData] = useState<any>([])
    const [permAddr_FlatNo, setPermAddr_FlatNo] = useState<any>('')
    const [permAddr_Street, setPermAddr_Street] = useState<any>('')
    const [permAddr_Area, setPermAddr_Area] = useState<any>('')
    const [permAddr_Pincode, setPermAddr_Pincode] = useState<any>('')

    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [transferCourseId, setTransferCourseId] = useState<any>('')
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>({ value: 1, label: 'A' })
    const [shiftId, setShiftId] = useState<any>({ value: 1, label: 'Morning Shift' })

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [shiftData, setShiftData] = useState<any>([]);
    const [interestedCourseData, setInterestedCourseData] = useState<any>([])
    const [referralTypeId, setReferralTypeId] = useState<any>('')
    const [referralTypeData, setReferralTypeData] = useState<any>([])
    const [userListByUserTypeData, setUserListByUserTypeData] = useState<any>([])
    const [concessionData, setConcessionData] = useState<any>([])
    const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([])
    const [isCollegeTransport, setIsCollegeTransport] = useState('')

    const [isUserDetails, setIsUserDetails] = useState('isUserDetails')
    const [interimCollegeTransfer, setIsInterimCollegeTransfer] = useState('No')

    const [referredId, setReferredId] = useState<any>('')
    const [referredName, setReferredName] = useState('')

    const [isGuardian, setIsGuardian] = useState(false);

    const [studentSchoolNameData, setStudentSchoolNameData] = useState<any>([])
    const [studentSchoolSubjectData, setStudentSchoolSubjectData] = useState<any>([])
    const [totalPercentageForXI, setTotalPercentageForXI] = useState('')
    const [totalPercentageForXII, setTotalPercentageForXII] = useState('')

    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState([])
    const [certificateTypeData, setCertificateTypeData] = useState([])
    const [checkArray, setCheckArray] = useState<any>([])
    const [scholarshipTypeData, setAddStudentScholarshipData] = useState([])
    const [scholarshipTypeIdArray, setAddStudentScholarshipIdArray] = useState<any>([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const [quotaDetailsData, setQuotaDetailsData] = useState([])
    const [quotaTypeId, setQuotaTypeId] = useState<any>('')
    const [isQuotaTypeDetails, setIsQuotaTypeDetails] = useState('')
    const [quotaData, setQuotaData] = useState([])
    const [courseCapacityCanvas, setCourseCapacityCanvas] = useState(false)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [isChecked, setIsChecked] = useState(false)
    const [transportAreaMasterId, setTransportAreaMasterId] = useState<any>('')
    const [modeOfWays, setModeOfWays] = useState('')
    const [collegeName, setCollegeName] = useState('')
    const [genderId, setGenderId] = useState<any>('')
    const [bloodGroupId, setBloodGroupId] = useState<any>('')
    const [religionId, setReligionId] = useState<any>('')
    const [motherTongue, setMotherTongue] = useState<any>({ value: 1, label: "Tamil" })
    const [category, setCategory] = useState<any>('')
    const [casteDetailsId, setCasteDetailsId] = useState<any>('')
    const [concessionTypeId, setConcessionTypeId] = useState<any>('')
    const [concessionRemarks, setConcessionRemarks] = useState<any>('')
    const [XIISchoolNameId, setXIISchoolNameId] = useState<any>('')
    const [XI_S1_studentSchoolSubject, setXI_S1_studentSchoolSubject] = useState<any>('')
    const [XI_S2_studentSchoolSubject, setXI_S2_studentSchoolSubject] = useState<any>('')
    const [XI_S3_studentSchoolSubject, setXI_S3_studentSchoolSubject] = useState<any>('')
    const [XI_S4_studentSchoolSubject, setXI_S4_studentSchoolSubject] = useState<any>('')
    const [XI_S5_studentSchoolSubject, setXI_S5_studentSchoolSubject] = useState<any>('')
    const [XI_S6_studentSchoolSubject, setXI_S6_studentSchoolSubject] = useState<any>('')
    const [XII_S1_studentSchoolSubject, setXII_S1_studentSchoolSubject] = useState<any>('')
    const [XII_S2_studentSchoolSubject, setXII_S2_studentSchoolSubject] = useState<any>('')
    const [XII_S3_studentSchoolSubject, setXII_S3_studentSchoolSubject] = useState<any>('')
    const [XII_S4_studentSchoolSubject, setXII_S4_studentSchoolSubject] = useState<any>('')
    const [XII_S5_studentSchoolSubject, setXII_S5_studentSchoolSubject] = useState<any>('')
    const [XII_S6_studentSchoolSubject, setXII_S6_studentSchoolSubject] = useState<any>('')
    const [discription, setDiscription] = useState<any>('')
    const [quotaTypeDetails, setQuotaTypeDetails] = useState<any>('')

    const [isOriginal, setIsOriginal] = useState(false)
    const [originalCertificate, setOriginalCertificate] = useState(false)
    const [courseCapacityData, setCourseCapacityData] = useState<any>([])

    const addStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----General Info----////
            graduationTypeId: '',
            admissionNo: '',
            tempNo: 0,
            firstName: '',
            lastName: '',
            surName: '',
            studentNameTamil: '',
            surNameTamil: '',
            dateOfBirth: '',
            genderId: '',
            bloodGroupId: '',
            motherTongue: 'Tamil',
            nationality: '',
            otherNation: '',
            religionId: '',
            casteUniqueId: '',
            casteDetailsId: '',
            subcaste: '',
            category: '',
            aadharCard: '',
            physicallyChallenged: '',
            physicallyChallengedType: '',
            physicallyChallengedPercentage: '',
            martialStatus: '',
            email: '',
            emisNo: '',
            smsToBeSentNumber: '',
            transportAreaMasterId: '',
            modeOfWays: '',
            isCollegeTransport: '',
        },
        validate: (values: any) => {
            const errors: {
                graduationTypeId?: string;
                admissionNo?: string;
                firstName?: string;
                aadharCard?: string;
                email?: string;
                smsToBeSentNumber?: string;
            } = {};

            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.firstName) {
                errors.firstName = 'Required'
            }
            else if (values.firstName.length < 3) {
                errors.firstName = 'Must be 3 characters or more';
            } else if (values.firstName.length > 20) {
                errors.firstName = 'Must be 20 characters or less';
            }
            if (!values.aadharCard) {
                errors.aadharCard = 'Required'
            }
            else if (!/^\d{12}$/.test(values.aadharCard)) {
                errors.aadharCard = 'Must be 12 Numbers';
            }
            // if (values.email) {
            //     errors.email = 'Required'
            // }
            // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            //     errors.email = 'Invalid email address';
            // }
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.smsToBeSentNumber) {
                errors.smsToBeSentNumber = 'Required';
            } else if (values.smsToBeSentNumber.length < 10 || values.smsToBeSentNumber.length > 10) {
                errors.smsToBeSentNumber = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.smsToBeSentNumber)) {
                errors.smsToBeSentNumber = 'Please enter valid mobile number'
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { handleNext() },
    })

    const addressForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            permAddr_FlatNo: '',
            permAddr_Street: '',
            permAddr_Area: '',
            permAddr_StateId: '',
            permAddr_CityId: '',
            permAddr_Pincode: '',

            bankName: '',
            bankAccountNo: '',
            branch: '',
            ifscCode: '',
            micrCode: '',
        },
        validate: (values: any) => {
            const errors: {
                commAddr_Pincode?: string;
                permAddr_Pincode?: string;
            } = {};
            if (values.commAddr_Pincode && !/^\d{6}$/.test(values.commAddr_Pincode)) {
                errors.commAddr_Pincode = 'Must be 6 Numbers';
            }
            if (permAddr_Pincode && !/^\d{6}$/.test(permAddr_Pincode)) {
                errors.permAddr_Pincode = 'Must be 6 Numbers';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { handleNext() },
    })

    const classInfoForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            // ///----Class INFO----////
            departmentId: '',
            courseId: '',
            batchMasterId: '',
            semesterDetailsId: '',
            sectionDetailsId: '1',
            shiftId: '1',
            studentRollNo: '',
            admissionDateStamp: getCurrentDateAndTime('date'),
            sourceName: '',
            transferCourseId: '',
            referralTypeId: '',
            referredId: '',
            referredName: '',
            interimCollegeTransfer: '',
            collegeName: '',
            concessionTypeId: '',
            concessionRemarks: '',
        },
        validate: (values: any) => {
            const errors: {
                batchMasterId?: string;
                semesterDetailsId?: string;
            } = {};
            if (!batchMasterId) {
                errors.batchMasterId = 'Required';
            }
            if (!semesterDetailsId) {
                errors.semesterDetailsId = 'Required';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { handleNext() },
    })

    const courseCapacityEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseCapacityId: 0,
            govQuota: '',
            managementQuota: '',
            totalAllocated: '',
        },
        validate: (values: any) => {
        },
        validateOnChange: false,
        onSubmit: (values) => { updateCourseCapacitySubmit() },
    });

    const familyForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fatherName: '',
            father_AdharNumber: '',
            fatherOccupation: '',
            natureofJoborBusiness: '',
            fatherContactNumber: '',
            fatherEmailId: '',
            motherName: '',
            mother_AdharNumber: '',
            yearlyIncome: '',
            positionHeld: '',
            motherContactNumber: '',
            smsToBeSentNumber: '',
            guardian_Name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_AdharNumber: '',
            isGuardian: '',
        },
        validate: (values: any) => {
            const errors: {
                motherContactNumber?: string;
                father_AdharNumber?: string;
                fatherContactNumber?: string;
                fatherEmailId?: string;
                mother_AdharNumber?: string;
                guardian_email?: string;
                guardian_phone?: string;
                guardian_AdharNumber?: string;
            } = {};
            if (values.father_AdharNumber && !/^\d{12}$/.test(values.father_AdharNumber)) {
                errors.father_AdharNumber = 'Must be 12 Numbers';
            }
            if (values.fatherContactNumber && values.fatherContactNumber.length < 10 || values.fatherContactNumber.length > 10) {
                errors.fatherContactNumber = 'Must be 10 Numbers'
            } else if (values.fatherContactNumber && !/^[6-9]\d{9}$/i.test(values.fatherContactNumber)) {
                errors.fatherContactNumber = 'Please enter valid mobile number'
            }
            if (values.fatherEmailId && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.fatherEmailId)) {
                errors.fatherEmailId = 'Invalid email address';
            }
            if (values.mother_AdharNumber && !/^\d{12}$/.test(values.mother_AdharNumber)) {
                errors.mother_AdharNumber = 'Must be 12 Numbers';
            }
            if (values.motherContactNumber && values.motherContactNumber.length < 10 || values.motherContactNumber.length > 10) {
                errors.motherContactNumber = 'Must be 10 Numbers'
            } else if (values.motherContactNumber && !/^[6-9]\d{9}$/i.test(values.motherContactNumber)) {
                errors.motherContactNumber = 'Please enter valid mobile number'
            }
            if (values.guardian_AdharNumber && !/^\d{12}$/.test(values.guardian_AdharNumber)) {
                errors.guardian_AdharNumber = 'Must be 12 Numbers';
            }
            if (values.guardian_phone && values.guardian_phone.length < 10 || values.guardian_phone.length > 10) {
                errors.guardian_phone = 'Must be 10 Numbers'
            } else if (values.guardian_phone && !/^[6-9]\d{9}$/i.test(values.guardian_phone)) {
                errors.guardian_phone = 'Please enter valid mobile number'
            }
            if (values.guardian_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.guardian_email)) {
                errors.guardian_email = 'Invalid email address';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { handleNext() },
    })

    const markInfoForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            // // ///----MARK INFO----////
            registerNo_X: '',
            institutionNameAndAddress_X: '',
            boardName_X: '',
            mediumOfInstitution_X: '',
            monthAndYearOfPassing_X: '',

            registerNo_XI: '',
            institutionNameAndAddress_XI: '',
            boardName_XI: '',
            mediumOfInstitution_XI: '',
            monthAndYearOfPassing_XI: '',

            registerNo_XII: '',
            XIISchoolNameId: '',
            boardName_XII: '',
            mediumOfInstitution_XII: '',
            monthAndYearOfPassing_XII: '',

            XI_S1_studentSchoolSubject: '',
            XI_S1_markSecured: '',
            XI_S1_maximumMarks: '',
            XI_S1_monthAndYearOfPassing: '',
            XI_S1_registerNo: '',
            XI_S1_noOfAttempts: '',
            XI_S2_studentSchoolSubject: '',
            XI_S2_markSecured: '',
            XI_S2_maximumMarks: '',
            XI_S2_monthAndYearOfPassing: '',
            XI_S2_registerNo: '',
            XI_S2_noOfAttempts: '',
            XI_S3_studentSchoolSubject: '',
            XI_S3_markSecured: '',
            XI_S3_maximumMarks: '',
            XI_S3_monthAndYearOfPassing: '',
            XI_S3_registerNo: '',
            XI_S3_noOfAttempts: '',
            XI_S4_studentSchoolSubject: '',
            XI_S4_markSecured: '',
            XI_S4_maximumMarks: '',
            XI_S4_monthAndYearOfPassing: '',
            XI_S4_registerNo: '',
            XI_S4_noOfAttempts: '',
            XI_S5_studentSchoolSubject: '',
            XI_S5_markSecured: '',
            XI_S5_maximumMarks: '',
            XI_S5_monthAndYearOfPassing: '',
            XI_S5_registerNo: '',
            XI_S5_noOfAttempts: '',
            XI_S6_studentSchoolSubject: '',
            XI_S6_markSecured: '',
            XI_S6_maximumMarks: '',
            XI_S6_monthAndYearOfPassing: '',
            XI_S6_registerNo: '',
            XI_S6_noOfAttempts: '',

            XII_S1_studentSchoolSubject: '',
            XII_S1_markSecured: '',
            XII_S1_maximumMarks: '',
            XII_S1_monthAndYearOfPassing: '',
            XII_S1_registerNo: '',
            XII_S1_noOfAttempts: '',
            XII_S2_studentSchoolSubject: '',
            XII_S2_markSecured: '',
            XII_S2_maximumMarks: '',
            XII_S2_monthAndYearOfPassing: '',
            XII_S2_registerNo: '',
            XII_S2_noOfAttempts: '',
            XII_S3_studentSchoolSubject: '',
            XII_S3_markSecured: '',
            XII_S3_maximumMarks: '',
            XII_S3_monthAndYearOfPassing: '',
            XII_S3_registerNo: '',
            XII_S3_noOfAttempts: '',
            XII_S4_studentSchoolSubject: '',
            XII_S4_markSecured: '',
            XII_S4_maximumMarks: '',
            XII_S4_monthAndYearOfPassing: '',
            XII_S4_registerNo: '',
            XII_S4_noOfAttempts: '',
            XII_S5_studentSchoolSubject: '',
            XII_S5_markSecured: '',
            XII_S5_maximumMarks: '',
            XII_S5_monthAndYearOfPassing: '',
            XII_S5_registerNo: '',
            XII_S5_noOfAttempts: '',
            XII_S6_studentSchoolSubject: '',
            XII_S6_markSecured: '',
            XII_S6_maximumMarks: '',
            XII_S6_monthAndYearOfPassing: '',
            XII_S6_registerNo: '',
            XII_S6_noOfAttempts: '',

            XI_OverAll_markSecured: '',
            XI_OverAll_maximumMarks: 600,
            XII_OverAll_markSecured: '',
            XII_OverAll_maximumMarks: 600,
            registerNo_UG: '',
            institutionNameAndAddress_UG: '',
            universityName_UG: '',
            mediumOfInstitution_UG: '',
            monthAndYearOfPassing_UG: '',

            registerNo_PG: '',
            institutionNameAndAddress_PG: '',
            universityName_PG: '',
            mediumOfInstitution_PG: '',
            monthAndYearOfPassing_PG: '',
            registerNo_MPhil: '',
            institutionNameAndAddress_MPhil: '',
            universityName_MPhil: '',
            mediumOfInstitution_MPhil: '',
            monthAndYearOfPassing_MPhil: '',
            UG_courseName: '',
            UG_Part1_SubjectName: '',
            UG_Part1_MarksObtained: '',
            UG_Part1_MaximumMarks: '',
            UG_Part1_Percentage: '',
            UG_Part1_ClassObtained: '',
            UG_Part1_NoOfAttempts: '',
            UG_Part1_RegisterNo: '',
            UG_Part1_Passing: '',
            UG_Part2_SubjectName: '',
            UG_Part2_MarksObtained: '',
            UG_Part2_MaximumMarks: '',
            UG_Part2_Percentage: '',
            UG_Part2_ClassObtained: '',
            UG_Part2_NoOfAttempts: '',
            UG_Part2_RegisterNo: '',
            UG_Part2_Passing: '',
            UG_Part3_SubjectName: '',
            UG_Part3_MarksObtained: '',
            UG_Part3_MaximumMarks: '',
            UG_Part3_Percentage: '',
            UG_Part3_ClassObtained: '',
            UG_Part3_NoOfAttempts: '',
            UG_Part3_RegisterNo: '',
            UG_Part3_Passing: '',
            UG_Allied1_SubjectName: '',
            UG_Allied1_MarksObtained: '',
            UG_Allied1_MaximumMarks: '',
            UG_Allied1_Percentage: '',
            UG_Allied1_ClassObtained: '',
            UG_Allied1_NoOfAttempts: '',
            UG_Allied1_RegisterNo: '',
            UG_Allied1_Passing: '',
            UG_Allied2_SubjectName: '',
            UG_Allied2_MarksObtained: '',
            UG_Allied2_MaximumMarks: '',
            UG_Allied2_Percentage: '',
            UG_Allied2_ClassObtained: '',
            UG_Allied2_NoOfAttempts: '',
            UG_Allied2_RegisterNo: '',
            UG_Allied2_Passing: '',
            UG_Overall_Main_Core: '',
            UG_Overall_Ancy_Allied: '',
            UG_Overall_Part3: '',

            PG_courseName: '',
            PG_Part1_SubjectName: '',
            PG_Part1_MarksObtained: '',
            PG_Part1_MaximumMarks: '',
            PG_Part1_Percentage: '',
            PG_Part1_ClassObtained: '',
            PG_Part1_NoOfAttempts: '',
            PG_Part1_RegisterNo: '',
            PG_Part1_Passing: '',
            PG_Part2_SubjectName: '',
            PG_Part2_MarksObtained: '',
            PG_Part2_MaximumMarks: '',
            PG_Part2_Percentage: '',
            PG_Part2_ClassObtained: '',
            PG_Part2_NoOfAttempts: '',
            PG_Part2_RegisterNo: '',
            PG_Part2_Passing: '',
            PG_Part3_SubjectName: '',
            PG_Part3_MarksObtained: '',
            PG_Part3_MaximumMarks: '',
            PG_Part3_Percentage: '',
            PG_Part3_ClassObtained: '',
            PG_Part3_NoOfAttempts: '',
            PG_Part3_RegisterNo: '',
            PG_Part3_Passing: '',
            PG_Allied1_SubjectName: '',
            PG_Allied1_MarksObtained: '',
            PG_Allied1_MaximumMarks: '',
            PG_Allied1_Percentage: '',
            PG_Allied1_ClassObtained: '',
            PG_Allied1_NoOfAttempts: '',
            PG_Allied1_RegisterNo: '',
            PG_Allied1_Passing: '',
            PG_Allied2_SubjectName: '',
            PG_Allied2_MarksObtained: '',
            PG_Allied2_MaximumMarks: '',
            PG_Allied2_Percentage: '',
            PG_Allied2_ClassObtained: '',
            PG_Allied2_NoOfAttempts: '',
            PG_Allied2_RegisterNo: '',
            PG_Allied2_Passing: '',
            PG_Overall_Main_Core: '',
            PG_Overall_Ancy_Allied: '',
            PG_Overall_Part3: '',

            MPhil_courseName: '',
            MPhil_Part1_SubjectName: '',
            MPhil_Part1_MarksObtained: '',
            MPhil_Part1_MaximumMarks: '',
            MPhil_Part1_Percentage: '',
            MPhil_Part1_ClassObtained: '',
            MPhil_Part1_NoOfAttempts: '',
            MPhil_Part1_RegisterNo: '',
            MPhil_Part1_Passing: '',
            MPhil_Part2_SubjectName: '',
            MPhil_Part2_MarksObtained: '',
            MPhil_Part2_MaximumMarks: '',
            MPhil_Part2_Percentage: '',
            MPhil_Part2_ClassObtained: '',
            MPhil_Part2_NoOfAttempts: '',
            MPhil_Part2_RegisterNo: '',
            MPhil_Part2_Passing: '',
            MPhil_Part3_SubjectName: '',
            MPhil_Part3_MarksObtained: '',
            MPhil_Part3_MaximumMarks: '',
            MPhil_Part3_Percentage: '',
            MPhil_Part3_ClassObtained: '',
            MPhil_Part3_NoOfAttempts: '',
            MPhil_Part3_RegisterNo: '',
            MPhil_Part3_Passing: '',
            MPhil_Allied1_SubjectName: '',
            MPhil_Allied1_MarksObtained: '',
            MPhil_Allied1_MaximumMarks: '',
            MPhil_Allied1_Percentage: '',
            MPhil_Allied1_ClassObtained: '',
            MPhil_Allied1_NoOfAttempts: '',
            MPhil_Allied1_RegisterNo: '',
            MPhil_Allied1_Passing: '',
            MPhil_Allied2_SubjectName: '',
            MPhil_Allied2_MarksObtained: '',
            MPhil_Allied2_MaximumMarks: '',
            MPhil_Allied2_Percentage: '',
            MPhil_Allied2_ClassObtained: '',
            MPhil_Allied2_NoOfAttempts: '',
            MPhil_Allied2_RegisterNo: '',
            MPhil_Allied2_Passing: '',
            MPhil_Overall_Main_Core: '',
            MPhil_Overall_Ancy_Allied: '',
            MPhil_Overall_Part3: '',
        },
        validate: (values: any) => { },
        validateOnChange: true,
        onSubmit: () => { handleNext() },
    })

    const certificateInfoForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            submittedDate: getCurrentDateAndTime('date'),
            status: '',
        },
        validate: (values: any) => {
        },
        validateOnChange: true,
        onSubmit: (values) => { handleNext() },
    })

    const admissionInfoForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            ///----Admission----////
            quotaTypeId: '',
            discription: '',
            isCitizenOfIndia: '',
            isClaimingNriQuotaSeat: '',
            isMedicalCertificateEnclosed: '',
            extraCurricularActivities: '',
        },
        validate: (values: any) => {
        },
        validateOnChange: true,
        onSubmit: (values) => { },
    })

    const originalCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            certificateName: '',
            userTypeIdForCertificate: '',
            isOriginal: 0,
        },
        validate: (values: any) => {
            const errors: {
                certificateName?: string;
                userTypeIdForCertificate?: string;
            } = {};

            if (!values.certificateName) {
                errors.certificateName = 'Required';
            }
            if (!userTypeIdForCertificate) {
                errors.userTypeIdForCertificate = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => { addCertificateSubmit() },
    });

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId);
        if (graduationTypeId?.value != undefined) {
            getTempStudentListByGraduationType(graduationTypeId?.value)
            getInterestedCourse(0, graduationTypeId?.value)
        }
    }

    const selectTempStudent = (e: any) => {
        let tempStudentDetailsId = e
        setTempStudentDetailsId(tempStudentDetailsId)
        if (tempStudentDetailsId?.value != undefined) {
            getTempStudent(tempStudentDetailsId?.value)
        }
    }

    const selectQuotaTypeDetails = (e: any) => {
        let quotaTypeDetails = e
        setQuotaTypeDetails(quotaTypeDetails)
        setDiscription(quotaTypeDetails)
    }

    const selectNationality = (e: any) => {
        let nationality = e.target.value
        setNationality(nationality)
        if (nationality == 'Others') {
            setIsOtherNation('Other')
        }
        else {
            setIsOtherNation('India')
            setOtherNation('')
        }
    }

    const selectPhysicallyChallenged = (e: any) => {
        let physicallyChallenged = e.target.value
        setPhysicallyChallenged(physicallyChallenged)
        if (physicallyChallenged == 'Yes') {
            setIsPhysicallyChallengedDetails('Yes')
        }
        else {
            setIsPhysicallyChallengedDetails('No')
            setPhysicallyChallengedPercentageValue('')
            setPhysicallyChallengedTypeValue('')
        }
    }

    function onUpload(studentOrStaffDetailsId: any) {
        const filedata = new FormData();
        filedata.append('profilePic', selectedFile, selectedFile.name)
        filedata.append('licenseKey', getLicenseKey.toString())
        filedata.append('userAccountId', userAccountId)
        filedata.append('studentOrStaffDetailsId', studentOrStaffDetailsId)
        filedata.append('userTypeId', "10")

        profilePicUpload(filedata,
            (response) => {
                console.log('Profile pic upload');
            }
            , (error) => {
                console.log('Profile pic not upload');

            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudentListByGraduationType(graduationTypeId: any) {
        getTempStudentListByGraduationTypeId(graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentListByGraduationTypeId;
                    if (data != undefined) {

                        setTempStudentData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTempStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudent(tempStudentDetailsId: number) {
        getTempStudentList(tempStudentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentList[0];
                    if (data != undefined) {
                        // setGraduationTypeId(data.graduationTypeId);
                        setDepartmentId({ value: data.departmentId, label: data.departmentName })
                        setCourseId({ value: data.courseId, label: data.courseName })
                        setCommAddr_StateId({ value: data.commAddr_StateId, label: data.stateName })
                        setCommAddr_CityId({ value: data.commAddr_CityId, label: data.cityName })
                        setTotalPercentageForXII((Number(data.scoredMark) / Number(600) * 100).toFixed(2))

                        getCourseList(data.departmentId, graduationTypeId?.value)
                        getCityList(data.commAddr_StateId)
                        getBatchList(data.courseId, graduationTypeId?.value)
                        getCityList(data.commAddr_StateId)

                        setGenderId({ value: data.genderId, label: data.gender })
                        setXIISchoolNameId({ value: data.schoolNameId, label: data.schoolName })

                        addStudentForm.setValues({
                            graduationTypeId: graduationTypeId?.value,
                            admissionNo: addStudentForm.values.admissionNo,
                            tempNo: tempStudentDetailsId,
                            firstName: data['firstname'],
                            lastName: data['lastname'],
                            surName: addStudentForm.values.surName,
                            studentNameTamil: addStudentForm.values.studentNameTamil,
                            surNameTamil: addStudentForm.values.surNameTamil,
                            dateOfBirth: addStudentForm.values.dateOfBirth,
                            genderId: data['genderId'],
                            bloodGroupId: addStudentForm.values.bloodGroupId,
                            motherTongue: addStudentForm.values.motherTongue,
                            nationality: nationality,
                            otherNation: otherNation,
                            religionId: addStudentForm.values.religionId,
                            casteUniqueId: addStudentForm.values.casteUniqueId,
                            casteDetailsId: addStudentForm.values.casteDetailsId,
                            subcaste: addStudentForm.values.subcaste,
                            category: addStudentForm.values.category,
                            aadharCard: addStudentForm.values.aadharCard,
                            physicallyChallenged: physicallyChallenged,
                            physicallyChallengedType: physicallyChallengedTypeValue,
                            physicallyChallengedPercentage: physicallyChallengedPercentageValue,
                            martialStatus: addStudentForm.values.martialStatus,
                            email: addStudentForm.values.email,
                            emisNo: addStudentForm.values.emisNo,
                            smsToBeSentNumber: data['mobile'],
                            transportAreaMasterId: addStudentForm.values.transportAreaMasterId,
                            modeOfWays: addStudentForm.values.modeOfWays,
                            isCollegeTransport: addStudentForm.values.isCollegeTransport,
                        })

                        addressForm.setValues({
                            ///----Address INFO----////
                            commAddr_FlatNo: data['commAddr_FlatNo'],
                            commAddr_Street: data['commAddr_Street'],
                            commAddr_Area: data['commAddr_Area'],
                            commAddr_StateId: data['commAddr_StateId'],
                            commAddr_CityId: data['commAddr_CityId'],
                            commAddr_Pincode: data['commAddr_Pincode'],

                            permAddr_FlatNo: '',
                            permAddr_Street: '',
                            permAddr_Area: '',
                            permAddr_StateId: '',
                            permAddr_CityId: '',
                            permAddr_Pincode: '',

                            bankName: '',
                            bankAccountNo: '',
                            branch: '',
                            ifscCode: '',
                            micrCode: '',
                        })

                        markInfoForm.setValues({
                            registerNo_X: '',
                            institutionNameAndAddress_X: '',
                            boardName_X: '',
                            mediumOfInstitution_X: '',
                            monthAndYearOfPassing_X: '',

                            registerNo_XI: '',
                            institutionNameAndAddress_XI: '',
                            boardName_XI: '',
                            mediumOfInstitution_XI: '',
                            monthAndYearOfPassing_XI: '',

                            registerNo_XII: data['regNumber'],
                            XIISchoolNameId: data['schoolNameId'],
                            boardName_XII: '',
                            mediumOfInstitution_XII: '',
                            monthAndYearOfPassing_XII: '',

                            XI_S1_studentSchoolSubject: '',
                            XI_S1_markSecured: '',
                            XI_S1_maximumMarks: '',
                            XI_S1_monthAndYearOfPassing: '',
                            XI_S1_registerNo: '',
                            XI_S1_noOfAttempts: '',
                            XI_S2_studentSchoolSubject: '',
                            XI_S2_markSecured: '',
                            XI_S2_maximumMarks: '',
                            XI_S2_monthAndYearOfPassing: '',
                            XI_S2_registerNo: '',
                            XI_S2_noOfAttempts: '',
                            XI_S3_studentSchoolSubject: '',
                            XI_S3_markSecured: '',
                            XI_S3_maximumMarks: '',
                            XI_S3_monthAndYearOfPassing: '',
                            XI_S3_registerNo: '',
                            XI_S3_noOfAttempts: '',
                            XI_S4_studentSchoolSubject: '',
                            XI_S4_markSecured: '',
                            XI_S4_maximumMarks: '',
                            XI_S4_monthAndYearOfPassing: '',
                            XI_S4_registerNo: '',
                            XI_S4_noOfAttempts: '',
                            XI_S5_studentSchoolSubject: '',
                            XI_S5_markSecured: '',
                            XI_S5_maximumMarks: '',
                            XI_S5_monthAndYearOfPassing: '',
                            XI_S5_registerNo: '',
                            XI_S5_noOfAttempts: '',
                            XI_S6_studentSchoolSubject: '',
                            XI_S6_markSecured: '',
                            XI_S6_maximumMarks: '',
                            XI_S6_monthAndYearOfPassing: '',
                            XI_S6_registerNo: '',
                            XI_S6_noOfAttempts: '',

                            XII_S1_studentSchoolSubject: '',
                            XII_S1_markSecured: '',
                            XII_S1_maximumMarks: '',
                            XII_S1_monthAndYearOfPassing: '',
                            XII_S1_registerNo: '',
                            XII_S1_noOfAttempts: '',
                            XII_S2_studentSchoolSubject: '',
                            XII_S2_markSecured: '',
                            XII_S2_maximumMarks: '',
                            XII_S2_monthAndYearOfPassing: '',
                            XII_S2_registerNo: '',
                            XII_S2_noOfAttempts: '',
                            XII_S3_studentSchoolSubject: '',
                            XII_S3_markSecured: '',
                            XII_S3_maximumMarks: '',
                            XII_S3_monthAndYearOfPassing: '',
                            XII_S3_registerNo: '',
                            XII_S3_noOfAttempts: '',
                            XII_S4_studentSchoolSubject: '',
                            XII_S4_markSecured: '',
                            XII_S4_maximumMarks: '',
                            XII_S4_monthAndYearOfPassing: '',
                            XII_S4_registerNo: '',
                            XII_S4_noOfAttempts: '',
                            XII_S5_studentSchoolSubject: '',
                            XII_S5_markSecured: '',
                            XII_S5_maximumMarks: '',
                            XII_S5_monthAndYearOfPassing: '',
                            XII_S5_registerNo: '',
                            XII_S5_noOfAttempts: '',
                            XII_S6_studentSchoolSubject: '',
                            XII_S6_markSecured: '',
                            XII_S6_maximumMarks: '',
                            XII_S6_monthAndYearOfPassing: '',
                            XII_S6_registerNo: '',
                            XII_S6_noOfAttempts: '',

                            XI_OverAll_markSecured: '',
                            XI_OverAll_maximumMarks: 0,
                            XII_OverAll_markSecured: data['scoredMark'],
                            XII_OverAll_maximumMarks: 0,
                            registerNo_UG: '',
                            institutionNameAndAddress_UG: '',
                            universityName_UG: '',
                            mediumOfInstitution_UG: '',
                            monthAndYearOfPassing_UG: '',

                            registerNo_PG: '',
                            institutionNameAndAddress_PG: '',
                            universityName_PG: '',
                            mediumOfInstitution_PG: '',
                            monthAndYearOfPassing_PG: '',
                            registerNo_MPhil: '',
                            institutionNameAndAddress_MPhil: '',
                            universityName_MPhil: '',
                            mediumOfInstitution_MPhil: '',
                            monthAndYearOfPassing_MPhil: '',
                            UG_courseName: '',
                            UG_Part1_SubjectName: '',
                            UG_Part1_MarksObtained: '',
                            UG_Part1_MaximumMarks: '',
                            UG_Part1_Percentage: '',
                            UG_Part1_ClassObtained: '',
                            UG_Part1_NoOfAttempts: '',
                            UG_Part1_RegisterNo: '',
                            UG_Part1_Passing: '',
                            UG_Part2_SubjectName: '',
                            UG_Part2_MarksObtained: '',
                            UG_Part2_MaximumMarks: '',
                            UG_Part2_Percentage: '',
                            UG_Part2_ClassObtained: '',
                            UG_Part2_NoOfAttempts: '',
                            UG_Part2_RegisterNo: '',
                            UG_Part2_Passing: '',
                            UG_Part3_SubjectName: '',
                            UG_Part3_MarksObtained: '',
                            UG_Part3_MaximumMarks: '',
                            UG_Part3_Percentage: '',
                            UG_Part3_ClassObtained: '',
                            UG_Part3_NoOfAttempts: '',
                            UG_Part3_RegisterNo: '',
                            UG_Part3_Passing: '',
                            UG_Allied1_SubjectName: '',
                            UG_Allied1_MarksObtained: '',
                            UG_Allied1_MaximumMarks: '',
                            UG_Allied1_Percentage: '',
                            UG_Allied1_ClassObtained: '',
                            UG_Allied1_NoOfAttempts: '',
                            UG_Allied1_RegisterNo: '',
                            UG_Allied1_Passing: '',
                            UG_Allied2_SubjectName: '',
                            UG_Allied2_MarksObtained: '',
                            UG_Allied2_MaximumMarks: '',
                            UG_Allied2_Percentage: '',
                            UG_Allied2_ClassObtained: '',
                            UG_Allied2_NoOfAttempts: '',
                            UG_Allied2_RegisterNo: '',
                            UG_Allied2_Passing: '',
                            UG_Overall_Main_Core: '',
                            UG_Overall_Ancy_Allied: '',
                            UG_Overall_Part3: '',

                            PG_courseName: '',
                            PG_Part1_SubjectName: '',
                            PG_Part1_MarksObtained: '',
                            PG_Part1_MaximumMarks: '',
                            PG_Part1_Percentage: '',
                            PG_Part1_ClassObtained: '',
                            PG_Part1_NoOfAttempts: '',
                            PG_Part1_RegisterNo: '',
                            PG_Part1_Passing: '',
                            PG_Part2_SubjectName: '',
                            PG_Part2_MarksObtained: '',
                            PG_Part2_MaximumMarks: '',
                            PG_Part2_Percentage: '',
                            PG_Part2_ClassObtained: '',
                            PG_Part2_NoOfAttempts: '',
                            PG_Part2_RegisterNo: '',
                            PG_Part2_Passing: '',
                            PG_Part3_SubjectName: '',
                            PG_Part3_MarksObtained: '',
                            PG_Part3_MaximumMarks: '',
                            PG_Part3_Percentage: '',
                            PG_Part3_ClassObtained: '',
                            PG_Part3_NoOfAttempts: '',
                            PG_Part3_RegisterNo: '',
                            PG_Part3_Passing: '',
                            PG_Allied1_SubjectName: '',
                            PG_Allied1_MarksObtained: '',
                            PG_Allied1_MaximumMarks: '',
                            PG_Allied1_Percentage: '',
                            PG_Allied1_ClassObtained: '',
                            PG_Allied1_NoOfAttempts: '',
                            PG_Allied1_RegisterNo: '',
                            PG_Allied1_Passing: '',
                            PG_Allied2_SubjectName: '',
                            PG_Allied2_MarksObtained: '',
                            PG_Allied2_MaximumMarks: '',
                            PG_Allied2_Percentage: '',
                            PG_Allied2_ClassObtained: '',
                            PG_Allied2_NoOfAttempts: '',
                            PG_Allied2_RegisterNo: '',
                            PG_Allied2_Passing: '',
                            PG_Overall_Main_Core: '',
                            PG_Overall_Ancy_Allied: '',
                            PG_Overall_Part3: '',

                            MPhil_courseName: '',
                            MPhil_Part1_SubjectName: '',
                            MPhil_Part1_MarksObtained: '',
                            MPhil_Part1_MaximumMarks: '',
                            MPhil_Part1_Percentage: '',
                            MPhil_Part1_ClassObtained: '',
                            MPhil_Part1_NoOfAttempts: '',
                            MPhil_Part1_RegisterNo: '',
                            MPhil_Part1_Passing: '',
                            MPhil_Part2_SubjectName: '',
                            MPhil_Part2_MarksObtained: '',
                            MPhil_Part2_MaximumMarks: '',
                            MPhil_Part2_Percentage: '',
                            MPhil_Part2_ClassObtained: '',
                            MPhil_Part2_NoOfAttempts: '',
                            MPhil_Part2_RegisterNo: '',
                            MPhil_Part2_Passing: '',
                            MPhil_Part3_SubjectName: '',
                            MPhil_Part3_MarksObtained: '',
                            MPhil_Part3_MaximumMarks: '',
                            MPhil_Part3_Percentage: '',
                            MPhil_Part3_ClassObtained: '',
                            MPhil_Part3_NoOfAttempts: '',
                            MPhil_Part3_RegisterNo: '',
                            MPhil_Part3_Passing: '',
                            MPhil_Allied1_SubjectName: '',
                            MPhil_Allied1_MarksObtained: '',
                            MPhil_Allied1_MaximumMarks: '',
                            MPhil_Allied1_Percentage: '',
                            MPhil_Allied1_ClassObtained: '',
                            MPhil_Allied1_NoOfAttempts: '',
                            MPhil_Allied1_RegisterNo: '',
                            MPhil_Allied1_Passing: '',
                            MPhil_Allied2_SubjectName: '',
                            MPhil_Allied2_MarksObtained: '',
                            MPhil_Allied2_MaximumMarks: '',
                            MPhil_Allied2_Percentage: '',
                            MPhil_Allied2_ClassObtained: '',
                            MPhil_Allied2_NoOfAttempts: '',
                            MPhil_Allied2_RegisterNo: '',
                            MPhil_Allied2_Passing: '',
                            MPhil_Overall_Main_Core: '',
                            MPhil_Overall_Ancy_Allied: '',
                            MPhil_Overall_Part3: '',
                        })
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBloodList() {
        getBlood(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.blood;
                    if (data != undefined) {
                        setBloodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBloodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMotherTongueList() {
        getMotherTongue(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.motherTongue;
                    if (data != undefined) {
                        setMotherTongueData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMotherTongueData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getReligionDetails() {
        getReligion(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.religion;
                    if (data != undefined) {
                        setReligionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setReligionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCategoryDetails() {
        getCategory(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.category;
                    if (data != undefined) {
                        setCategoryData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCategoryData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCaste() {
        getCasteDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.casteDetails;
                    if (data != undefined) {
                        setCasteDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCasteDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectState = (e: any) => {
        setCityData([])
        setCommAddr_CityId('')
        let commAddr_StateId = e
        setCommAddr_StateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let commAddr_CityId = e
        setCommAddr_CityId(commAddr_CityId)
    }

    const selectPermAddr_State = (e: any) => {
        setPermAddr_CityId('')
        setPermAddr_CityData([])
        let permAddr_StateId = e
        setPermAddr_StateId(permAddr_StateId)
        if (permAddr_StateId?.value != undefined) {
            getPermAddr_StateAndCity(permAddr_StateId?.value)
        }
    }

    const selectPermAddr_City = (e: any) => {
        let permAddr_CityId = e
        setPermAddr_CityId(permAddr_CityId)
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPermAddr_State() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setPermAddr_StateData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setPermAddr_StateData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPermAddr_StateAndCity(stateId: any) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setPermAddr_CityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setPermAddr_CityData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function address(e: any) {
        setIsChecked(e.target.checked)
        if (e.target.checked == true) {
            setPermAddr_FlatNo(addressForm.values.commAddr_FlatNo)
            setPermAddr_Street(addressForm.values.commAddr_Street)
            setPermAddr_Area(addressForm.values.commAddr_Area)
            setPermAddr_Pincode(addressForm.values.commAddr_Pincode)
            setPermAddr_StateId(commAddr_StateId)
            setPermAddr_CityId(commAddr_CityId)

            if (commAddr_StateId?.value != undefined) {
                getPermAddr_StateAndCity(commAddr_StateId?.value)
            }
        }
        else {
            setPermAddr_FlatNo('')
            setPermAddr_Street('')
            setPermAddr_Area('')
            setPermAddr_Pincode('')
            setPermAddr_StateId('')
            setPermAddr_CityId('')
        }
    }

    const selectReferralType = (e: any) => {
        setReferredId('')
        setReferredName('')
        setIsUserDetails('isUserDetails')
        let referralTypeId = e
        setReferralTypeId(referralTypeId);
        if (referralTypeId?.value != undefined) {
            getUserListByUserType(referralTypeId?.value)
            if (referralTypeId?.value == "11") {
                setIsUserDetails('others')
                setReferredId('')
            }
            else if (referralTypeId?.value !== "11") {
                setIsUserDetails('collegeStaff')
                setReferredName('')
            }
            else {
                setIsUserDetails('isUserDetails')
            }
        }

    }

    const selectDepartment = (e: any) => {
        setCourseId('')
        setCourseData([])
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        let departmentId = e
        setDepartmentId(departmentId)
        if (departmentId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            checkCourseCapacitySubmit(courseId?.value, batchMasterId?.value)
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectConcessionTypeId = (e: any) => {
        setConcessionRemarks('')
        let concessionTypeId = e
        setConcessionTypeId(concessionTypeId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getShiftList() {
        getShift(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.shift;
                    if (data != undefined) {
                        setShiftData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setShiftData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getInterestedCourse(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setInterestedCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setInterestedCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getReferral() {
        getReferralType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.referralType;
                    if (data != undefined) {
                        setReferralTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setReferralTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getUserListByUserType(referralTypeId: any) {
        getUserListByUserTypeId(referralTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userListByUserTypeId;
                    if (data != undefined) {
                        setUserListByUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setUserListByUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getConcessionList() {
        getConcession(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.concession;
                    if (data != undefined) {
                        setConcessionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setConcessionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransportAreaMasterDetails() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTransportAreaMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const selectTransport = (e: any) => {
        let isCollegeTransport = e.target.value
        setIsCollegeTransport(isCollegeTransport)
        if (isCollegeTransport == 'No') {
            setTransportAreaMasterId('')
            setModeOfWays('')
        }
    }

    const selectTransportArea = (e: any) => {
        setModeOfWays('')
        let transportAreaMasterId = e
        setTransportAreaMasterId(transportAreaMasterId)
    }

    const selectInterimCollegeTransfer = (e: any) => {
        setCollegeName('')
        let interimCollegeTransfer = e.target.value
        setIsInterimCollegeTransfer(interimCollegeTransfer)
        if (interimCollegeTransfer == 'No') {
            setCollegeName('')
        }
    }

    const handleCheckboxChange = (e: any) => {
        setIsGuardian(e.target.checked);
    };

    function getStudentSchoolNameList() {
        getStudentSchoolName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolName;
                    if (data != undefined) {
                        setStudentSchoolNameData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentSchoolNameData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        );
    }

    function getStudentSchoolSubjectList() {
        getStudentSchoolSubject(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolSubject;
                    if (data != undefined) {
                        setStudentSchoolSubjectData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentSchoolSubjectData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCertificateTypeList(userTypeId: number, certificateTypeId: number) {
        getCertificateType(userTypeId, certificateTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.certificateType;
                    if (data != undefined) {
                        setCertificateTypeData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function selectOriginalCertificateType(e: any, certificateTypeId: any) {
        const newData: any = []

        certificateTypeData.forEach((item: any, index: any) => {
            if (item['certificateTypeId'] == certificateTypeId) {
                item['isChecked'] = e.target.checked
            }
            newData.push(item)
        });
        setCertificateTypeData(newData)
        if (e.target.checked == true) {
            checkArray.push(certificateTypeId);
        }
        else if (e.target.checked == false) {
            let unCheckArrayId = checkArray.indexOf(Number(certificateTypeId))
            checkArray.splice(unCheckArrayId, 1);
        }
    }

    function selectScholarship(e: any, scholarshipTypeId: any) {
        const newData: any = [];
        scholarshipTypeData.forEach((item: any, index: any) => {
            if (item['scholarshipTypeId'] == scholarshipTypeId) {
                item['isChecked'] = e.target.checked
            }
            newData.push(item)
        });
        setFilteredDataToRefresh(newData)
        if (e.target.checked == true) {
            scholarshipTypeIdArray.push(scholarshipTypeId);
        }
        else if (e.target.checked == false) {
            let unCheckArrayId = scholarshipTypeIdArray.indexOf(Number(scholarshipTypeId))

            scholarshipTypeIdArray.splice(unCheckArrayId, 1);
            const newData: any = [];
            scholarshipTypeData.forEach((item: any, index: any) => {
                if (item['scholarshipTypeId'] == scholarshipTypeId) {
                    item['isChecked'] = e.target.checked
                }
                newData.push(item)
            });
        }
    }

    function getScholarshipTypeList() {
        getScholarshipType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scholarshipType;
                    if (data != undefined) {
                        setAddStudentScholarshipData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i]['isChecked'] = data.includes(data[i]['scholarshipTypeId'])
                        }

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function isOriginalOrDuplicate(e: any) {
        setIsOriginal(e.target.checked)
    }


    const [userTypeIdForCertificate, setUserTypeIdForCertificate] = useState<any>('')
    const [userTypeIdForCertificateValue, setUserTypeIdForCertificateValue] = useState<any>('')

    function selectUserTypeIdForCertificate(e: any) {
        setUserTypeIdForCertificate(e)
        let userType = e.map((item: any) => item.value)
        setUserTypeIdForCertificateValue(userType)

    }

    const userListByCertificateData = [{ userTypeId: 7, userType: 'Teaching Staff' }, { userTypeId: 10, userType: 'Student' }]

    function OriginalCertificate() {
        return {
            certificateName: originalCertificateForm.values.certificateName,
            userTypeId: userTypeIdForCertificateValue,
            isOriginal: isOriginal == true ? 1 : 0,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function addCertificateSubmit() {
        showLoader(true)
        if (originalCertificateForm.isValid) {
            let certificatePostData = OriginalCertificate()
            addCertificateType(certificatePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(null)
                        getCertificateTypeList(10, 1);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (originalCertificateForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function closeAndReset() {
        originalCertificateForm.resetForm()
        setOriginalCertificate(false)
        setUserTypeIdForCertificate('')
        setUserTypeIdForCertificateValue('')
        setIsOriginal(false)
    }

    const selectQuota = (e: any) => {
        setIsQuotaTypeDetails('');
        setDiscription('')
        setQuotaTypeDetails('')
        let quotaTypeId = e
        setQuotaTypeId(quotaTypeId)

        if (quotaTypeId?.value != undefined) {
            if (quotaTypeId?.value == '1') {
                setIsQuotaTypeDetails('isGovernment');
            }
            else if (quotaTypeId?.value == '2') {
                setIsQuotaTypeDetails('isManagement');
            }
        }
    }

    function getQuota() {
        getQuotaType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.quotaType;
                    if (data != undefined) {
                        setQuotaData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setQuotaData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getQuotaList() {
        getQuotaTypeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.quotaTypeDetails;
                    if (data != undefined) {
                        setQuotaDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setQuotaDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setCourseCapacityEditDetails() {
        return ({
            courseCapacityId: courseCapacityEditForm.values.courseCapacityId,
            batchMasterId: batchMasterId?.value,
            courseId: courseId?.value,
            govQuota: courseCapacityEditForm.values.govQuota,
            managementQuota: courseCapacityEditForm.values.managementQuota,
            totalAllocated: Number(courseCapacityEditForm.values.govQuota) + Number(courseCapacityEditForm.values.totalAllocated),
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function checkCourseCapacitySubmit(courseId: any, batchMasterId: any) {

        if (courseId != '' && batchMasterId != "") {

            let courseCapacityStudentPostData = {
                courseId: courseId,
                batchMasterId: batchMasterId,
                licenseKey: getLicenseKey
            }
            checkCourseCapacity(courseCapacityStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        // setAlertStatus({ message: data.message, type: "success" });
                        // setIsOpen(true);
                    }
                    else if (data.success == false) {
                        // setAlertStatus({ message: data.message, type: "error" });
                        // setIsOpen(true);
                        setCourseCapacityCanvas(true)
                        getCourseCapacityCourse(batchMasterId, courseId)

                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        // setAlertStatus({ message: errorDescription, type: "error" });
                        // setIsOpen(true);
                    }
                }
                , (error) => {
                    // setAlertStatus({ message: error, type: "error" });
                    // setIsOpen(true);
                }
            )
        } else if (courseId == '' && batchMasterId == "") {
            // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            // setIsOpen(true);
        }
    }

    function updateCourseCapacitySubmit() {
        showLoader(true)
        if (courseCapacityEditForm.isValid) {
            let courseCapacityEditPostData = setCourseCapacityEditDetails()
            updateCourseCapacity(courseCapacityEditPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        courseCapacityEditForm.resetForm();
                        setCourseCapacityCanvas(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (courseCapacityEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function getCourseCapacityCourse(batchMasterId: any, courseId: any) {
        getCourseCapacityByBatchCourse(batchMasterId, courseId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseCapacityByBatchMasterId[0];
                    if (data != undefined) {
                        setCourseCapacityData(data)
                        courseCapacityEditForm.setValues({
                            courseCapacityId: data.courseCapacityId,
                            govQuota: data.govQuota,
                            managementQuota: data.managementQuota,
                            totalAllocated: data.totalAllocated,
                        });
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }

                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    getBatchByGraduationTypeId(courseId, batchMasterId, graduationTypeId?.value,
                        (response) => {
                            if (response.data.success) {
                                let data = response.data.data.batchByCourseId[0];
                                if (data != undefined) {
                                    setCourseCapacityData(data)
                                    courseCapacityEditForm.setValues({
                                        courseCapacityId: 0,
                                        govQuota: '',
                                        managementQuota: '',
                                        totalAllocated: ''
                                    });
                                }
                            }
                        }, error => {
                            toasts(error, "Error")
                        }
                    )
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setAddStudentDetails() {
        return ({
            ///----General Info----////
            graduationTypeId: graduationTypeId?.value,
            admissionNo: addStudentForm.values.admissionNo,
            tempNo: tempStudentDetailsId?.value != undefined ? tempStudentDetailsId?.value : null,
            firstName: addStudentForm.values.firstName != "" ? addStudentForm.values.firstName : null,
            lastName: addStudentForm.values.lastName != "" ? addStudentForm.values.lastName : null,
            surName: addStudentForm.values.surName != "" ? addStudentForm.values.surName : null,
            studentNameTamil: addStudentForm.values.studentNameTamil != "" ? addStudentForm.values.studentNameTamil : null,
            surNameTamil: addStudentForm.values.surNameTamil != "" ? addStudentForm.values.surNameTamil : null,
            dateOfBirth: addStudentForm.values.dateOfBirth != "" ? addStudentForm.values.dateOfBirth : null,
            genderId: genderId?.value != undefined ? genderId?.value : null,
            bloodGroupId: bloodGroupId?.value != undefined ? bloodGroupId?.value : null,
            motherTongue: motherTongue?.value != undefined ? motherTongue?.value : null,
            nationality: nationality != "" ? nationality : null,
            otherNation: otherNation != "" ? otherNation : null,
            religionId: religionId?.value != undefined ? religionId?.value : null,
            casteUniqueId: addStudentForm.values.casteUniqueId != "" ? addStudentForm.values.casteUniqueId : null,
            casteDetailsId: casteDetailsId?.value != undefined ? casteDetailsId?.value : null,
            subcaste: addStudentForm.values.subcaste != "" ? addStudentForm.values.subcaste : null,
            category: category?.value != undefined ? category?.value : null,
            aadharCard: addStudentForm.values.aadharCard != "" ? addStudentForm.values.aadharCard : null,
            physicallyChallenged: physicallyChallenged != "" ? physicallyChallenged : null,
            physicallyChallengedType: physicallyChallengedTypeValue != "" ? physicallyChallengedTypeValue : null,
            physicallyChallengedPercentage: physicallyChallengedPercentageValue != "" ? physicallyChallengedPercentageValue : null,
            martialStatus: addStudentForm.values.martialStatus != "" ? addStudentForm.values.martialStatus : null,
            email: addStudentForm.values.email != "" ? addStudentForm.values.email : null,
            emisNo: addStudentForm.values.emisNo != "" ? addStudentForm.values.emisNo : null,
            smsToBeSentNumber: addStudentForm.values.smsToBeSentNumber != "" ? addStudentForm.values.smsToBeSentNumber : null,
            transportAreaMasterId: transportAreaMasterId?.value != undefined ? transportAreaMasterId?.value : null,
            modeOfWays: modeOfWays != "" ? modeOfWays : null,
            isCollegeTransport: isCollegeTransport != "" ? isCollegeTransport : null,

            ///----Address INFO----////
            commAddr_FlatNo: addressForm.values.commAddr_FlatNo != "" ? addressForm.values.commAddr_FlatNo : null,
            commAddr_Street: addressForm.values.commAddr_Street != "" ? addressForm.values.commAddr_Street : null,
            commAddr_Area: addressForm.values.commAddr_Area != "" ? addressForm.values.commAddr_Area : null,
            commAddr_StateId: commAddr_StateId?.value != undefined ? commAddr_StateId?.value : null,
            commAddr_CityId: commAddr_CityId?.value != undefined ? commAddr_CityId?.value : null,
            commAddr_Pincode: addressForm.values.commAddr_Pincode != "" ? addressForm.values.commAddr_Pincode : null,

            permAddr_FlatNo: permAddr_FlatNo != "" ? permAddr_FlatNo : null,
            permAddr_Street: permAddr_Street != "" ? permAddr_Street : null,
            permAddr_Area: permAddr_Area != "" ? permAddr_Area : null,
            permAddr_StateId: permAddr_StateId?.value != undefined ? permAddr_StateId?.value : null,
            permAddr_CityId: permAddr_CityId?.value != undefined ? permAddr_CityId?.value : null,
            permAddr_Pincode: permAddr_Pincode != "" ? permAddr_Pincode : null,

            bankName: addressForm.values.bankName != "" ? addressForm.values.bankName : null,
            bankAccountNo: addressForm.values.bankAccountNo != "" ? addressForm.values.bankAccountNo : null,
            branch: addressForm.values.branch != "" ? addressForm.values.branch : null,
            ifscCode: addressForm.values.ifscCode != "" ? addressForm.values.ifscCode : null,
            micrCode: addressForm.values.micrCode != "" ? addressForm.values.micrCode : null,
            // ///----Class INFO----////
            departmentId: departmentId?.value != undefined ? departmentId?.value : null,
            courseId: courseId?.value != undefined ? courseId?.value : null,
            batchMasterId: batchMasterId?.value != undefined ? batchMasterId?.value : null,
            semesterDetailsId: semesterDetailsId?.value != undefined ? semesterDetailsId?.value : null,
            sectionDetailsId: sectionDetailsId?.value != undefined ? sectionDetailsId?.value : null,
            shiftId: shiftId?.value != undefined ? shiftId?.value : null,
            studentRollNo: classInfoForm.values.studentRollNo != "" ? classInfoForm.values.studentRollNo : null,
            admissionDateStamp: classInfoForm.values.admissionDateStamp != "" ? classInfoForm.values.admissionDateStamp : null,
            sourceName: classInfoForm.values.sourceName != "" ? classInfoForm.values.sourceName : null,
            transferCourseId: transferCourseId?.value != undefined ? transferCourseId?.value : null,
            referralTypeId: referralTypeId?.value != undefined ? referralTypeId?.value : null,
            referredId: referredId?.value != undefined ? referredId?.value : null,
            referredName: referredName != "" ? referredName : null,
            interimCollegeTransfer: interimCollegeTransfer != "" ? interimCollegeTransfer : null,
            collegeName: collegeName != "" ? collegeName : null,
            concessionTypeId: concessionTypeId?.value != undefined ? concessionTypeId?.value : null,
            concessionRemarks: concessionRemarks != "" ? concessionRemarks : null,

            ///----Family INFO----////
            fatherName: familyForm.values.fatherName != "" ? familyForm.values.fatherName : null,
            father_AdharNumber: familyForm.values.father_AdharNumber != "" ? familyForm.values.father_AdharNumber : null,
            fatherOccupation: familyForm.values.fatherOccupation != "" ? familyForm.values.fatherOccupation : null,
            natureofJoborBusiness: familyForm.values.natureofJoborBusiness != "" ? familyForm.values.natureofJoborBusiness : null,
            fatherContactNumber: familyForm.values.fatherContactNumber != "" ? familyForm.values.fatherContactNumber : null,
            fatherEmailId: familyForm.values.fatherEmailId != "" ? familyForm.values.fatherEmailId : null,
            motherName: familyForm.values.motherName != "" ? familyForm.values.motherName : null,
            mother_AdharNumber: familyForm.values.mother_AdharNumber != "" ? familyForm.values.mother_AdharNumber : null,
            yearlyIncome: familyForm.values.yearlyIncome != "" ? familyForm.values.yearlyIncome : null,
            positionHeld: familyForm.values.positionHeld != "" ? familyForm.values.positionHeld : null,
            motherContactNumber: familyForm.values.motherContactNumber != "" ? familyForm.values.motherContactNumber : null,
            guardian_Name: familyForm.values.guardian_Name != "" ? familyForm.values.guardian_Name : null,
            guardian_email: familyForm.values.guardian_email != "" ? familyForm.values.guardian_email : null,
            guardian_phone: familyForm.values.guardian_phone != "" ? familyForm.values.guardian_phone : null,
            guardian_AdharNumber: familyForm.values.guardian_AdharNumber != "" ? familyForm.values.guardian_AdharNumber : null,
            isGuardian: isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: markInfoForm.values.registerNo_X,
            institutionNameAndAddress_X: markInfoForm.values.institutionNameAndAddress_X,
            boardName_X: markInfoForm.values.boardName_X,
            mediumOfInstitution_X: markInfoForm.values.mediumOfInstitution_X,
            monthAndYearOfPassing_X: markInfoForm.values.monthAndYearOfPassing_X,

            registerNo_XI: markInfoForm.values.registerNo_XI,
            institutionNameAndAddress_XI: markInfoForm.values.institutionNameAndAddress_XI,
            boardName_XI: markInfoForm.values.boardName_XI,
            mediumOfInstitution_XI: markInfoForm.values.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: markInfoForm.values.monthAndYearOfPassing_XI,

            registerNo_XII: markInfoForm.values.registerNo_XII,
            XIISchoolNameId: XIISchoolNameId?.value != undefined ? XIISchoolNameId?.value : null,
            boardName_XII: markInfoForm.values.boardName_XII,
            mediumOfInstitution_XII: markInfoForm.values.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: markInfoForm.values.monthAndYearOfPassing_XII,

            registerNo_UG: markInfoForm.values.registerNo_UG,
            institutionNameAndAddress_UG: markInfoForm.values.institutionNameAndAddress_UG,
            universityName_UG: markInfoForm.values.universityName_UG,
            mediumOfInstitution_UG: markInfoForm.values.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: markInfoForm.values.monthAndYearOfPassing_UG,

            registerNo_PG: markInfoForm.values.registerNo_PG,
            institutionNameAndAddress_PG: markInfoForm.values.institutionNameAndAddress_PG,
            universityName_PG: markInfoForm.values.universityName_PG,
            mediumOfInstitution_PG: markInfoForm.values.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: markInfoForm.values.monthAndYearOfPassing_PG,

            registerNo_MPhil: markInfoForm.values.registerNo_MPhil,
            institutionNameAndAddress_MPhil: markInfoForm.values.institutionNameAndAddress_MPhil,
            universityName_MPhil: markInfoForm.values.universityName_MPhil,
            mediumOfInstitution_MPhil: markInfoForm.values.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: markInfoForm.values.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: XI_S1_studentSchoolSubject?.value != undefined ? XI_S1_studentSchoolSubject?.value : null,
            XI_S1_markSecured: markInfoForm.values.XI_S1_markSecured,
            XI_S1_maximumMarks: markInfoForm.values.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: markInfoForm.values.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: markInfoForm.values.XI_S1_registerNo,
            XI_S1_noOfAttempts: markInfoForm.values.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: XI_S2_studentSchoolSubject?.value != undefined ? XI_S2_studentSchoolSubject?.value : null,
            XI_S2_markSecured: markInfoForm.values.XI_S2_markSecured,
            XI_S2_maximumMarks: markInfoForm.values.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: markInfoForm.values.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: markInfoForm.values.XI_S2_registerNo,
            XI_S2_noOfAttempts: markInfoForm.values.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: XI_S3_studentSchoolSubject?.value != undefined ? XI_S3_studentSchoolSubject?.value : null,
            XI_S3_markSecured: markInfoForm.values.XI_S3_markSecured,
            XI_S3_maximumMarks: markInfoForm.values.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: markInfoForm.values.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: markInfoForm.values.XI_S3_registerNo,
            XI_S3_noOfAttempts: markInfoForm.values.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: XI_S4_studentSchoolSubject?.value != undefined ? XI_S4_studentSchoolSubject?.value : null,
            XI_S4_markSecured: markInfoForm.values.XI_S4_markSecured,
            XI_S4_maximumMarks: markInfoForm.values.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: markInfoForm.values.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: markInfoForm.values.XI_S4_registerNo,
            XI_S4_noOfAttempts: markInfoForm.values.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: XI_S5_studentSchoolSubject?.value != undefined ? XI_S5_studentSchoolSubject?.value : null,
            XI_S5_markSecured: markInfoForm.values.XI_S5_markSecured,
            XI_S5_maximumMarks: markInfoForm.values.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: markInfoForm.values.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: markInfoForm.values.XI_S5_registerNo,
            XI_S5_noOfAttempts: markInfoForm.values.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: XI_S6_studentSchoolSubject?.value != undefined ? XI_S6_studentSchoolSubject?.value : null,
            XI_S6_markSecured: markInfoForm.values.XI_S6_markSecured,
            XI_S6_maximumMarks: markInfoForm.values.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: markInfoForm.values.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: markInfoForm.values.XI_S6_registerNo,
            XI_S6_noOfAttempts: markInfoForm.values.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: XII_S1_studentSchoolSubject?.value != undefined ? XII_S1_studentSchoolSubject?.value : null,
            XII_S1_markSecured: markInfoForm.values.XII_S1_markSecured,
            XII_S1_maximumMarks: markInfoForm.values.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: markInfoForm.values.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: markInfoForm.values.XII_S1_registerNo,
            XII_S1_noOfAttempts: markInfoForm.values.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: XII_S2_studentSchoolSubject?.value != undefined ? XII_S2_studentSchoolSubject?.value : null,
            XII_S2_markSecured: markInfoForm.values.XII_S2_markSecured,
            XII_S2_maximumMarks: markInfoForm.values.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: markInfoForm.values.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: markInfoForm.values.XII_S2_registerNo,
            XII_S2_noOfAttempts: markInfoForm.values.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: XII_S3_studentSchoolSubject?.value != undefined ? XII_S3_studentSchoolSubject?.value : null,
            XII_S3_markSecured: markInfoForm.values.XII_S3_markSecured,
            XII_S3_maximumMarks: markInfoForm.values.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: markInfoForm.values.XII_S3_monthAndYearOfPassing != '' ? markInfoForm.values.XII_S3_monthAndYearOfPassing : null,
            XII_S3_registerNo: markInfoForm.values.XII_S3_registerNo,
            XII_S3_noOfAttempts: markInfoForm.values.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: XII_S4_studentSchoolSubject?.value != undefined ? XII_S4_studentSchoolSubject?.value : null,
            XII_S4_markSecured: markInfoForm.values.XII_S4_markSecured,
            XII_S4_maximumMarks: markInfoForm.values.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: markInfoForm.values.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: markInfoForm.values.XII_S4_registerNo,
            XII_S4_noOfAttempts: markInfoForm.values.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: XII_S5_studentSchoolSubject?.value != undefined ? XII_S5_studentSchoolSubject?.value : null,
            XII_S5_markSecured: markInfoForm.values.XII_S5_markSecured,
            XII_S5_maximumMarks: markInfoForm.values.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: markInfoForm.values.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: markInfoForm.values.XII_S5_registerNo,
            XII_S5_noOfAttempts: markInfoForm.values.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: XII_S6_studentSchoolSubject?.value != undefined ? XII_S6_studentSchoolSubject?.value : null,
            XII_S6_markSecured: markInfoForm.values.XII_S6_markSecured,
            XII_S6_maximumMarks: markInfoForm.values.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: markInfoForm.values.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: markInfoForm.values.XII_S6_registerNo,
            XII_S6_noOfAttempts: markInfoForm.values.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: markInfoForm.values.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: markInfoForm.values.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: markInfoForm.values.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: markInfoForm.values.XII_OverAll_maximumMarks,

            UG_courseName: markInfoForm.values.UG_courseName,
            UG_Part1_SubjectName: markInfoForm.values.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: markInfoForm.values.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: markInfoForm.values.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: markInfoForm.values.UG_Part1_Percentage,
            UG_Part1_ClassObtained: markInfoForm.values.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: markInfoForm.values.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: markInfoForm.values.UG_Part1_RegisterNo,
            UG_Part1_Passing: markInfoForm.values.UG_Part1_Passing,

            UG_Part2_SubjectName: markInfoForm.values.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: markInfoForm.values.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: markInfoForm.values.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: markInfoForm.values.UG_Part2_Percentage,
            UG_Part2_ClassObtained: markInfoForm.values.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: markInfoForm.values.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: markInfoForm.values.UG_Part2_RegisterNo,
            UG_Part2_Passing: markInfoForm.values.UG_Part2_Passing,

            UG_Part3_SubjectName: markInfoForm.values.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: markInfoForm.values.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: markInfoForm.values.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: markInfoForm.values.UG_Part3_Percentage,
            UG_Part3_ClassObtained: markInfoForm.values.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: markInfoForm.values.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: markInfoForm.values.UG_Part3_RegisterNo,
            UG_Part3_Passing: markInfoForm.values.UG_Part3_Passing,

            UG_Allied1_SubjectName: markInfoForm.values.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: markInfoForm.values.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: markInfoForm.values.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: markInfoForm.values.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: markInfoForm.values.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: markInfoForm.values.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: markInfoForm.values.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: markInfoForm.values.UG_Allied1_Passing,

            UG_Allied2_SubjectName: markInfoForm.values.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: markInfoForm.values.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: markInfoForm.values.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: markInfoForm.values.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: markInfoForm.values.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: markInfoForm.values.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: markInfoForm.values.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: markInfoForm.values.UG_Allied2_Passing,

            UG_Overall_Main_Core: markInfoForm.values.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: markInfoForm.values.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: markInfoForm.values.UG_Overall_Part3,

            PG_courseName: markInfoForm.values.PG_courseName,
            PG_Part1_SubjectName: markInfoForm.values.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: markInfoForm.values.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: markInfoForm.values.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: markInfoForm.values.PG_Part1_Percentage,
            PG_Part1_ClassObtained: markInfoForm.values.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: markInfoForm.values.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: markInfoForm.values.PG_Part1_RegisterNo,
            PG_Part1_Passing: markInfoForm.values.PG_Part1_Passing,
            PG_Part2_SubjectName: markInfoForm.values.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: markInfoForm.values.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: markInfoForm.values.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: markInfoForm.values.PG_Part2_Percentage,
            PG_Part2_ClassObtained: markInfoForm.values.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: markInfoForm.values.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: markInfoForm.values.PG_Part2_RegisterNo,
            PG_Part2_Passing: markInfoForm.values.PG_Part2_Passing,
            PG_Part3_SubjectName: markInfoForm.values.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: markInfoForm.values.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: markInfoForm.values.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: markInfoForm.values.PG_Part3_Percentage,
            PG_Part3_ClassObtained: markInfoForm.values.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: markInfoForm.values.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: markInfoForm.values.PG_Part3_RegisterNo,
            PG_Part3_Passing: markInfoForm.values.PG_Part3_Passing,
            PG_Allied1_SubjectName: markInfoForm.values.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: markInfoForm.values.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: markInfoForm.values.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: markInfoForm.values.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: markInfoForm.values.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: markInfoForm.values.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: markInfoForm.values.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: markInfoForm.values.PG_Allied1_Passing,
            PG_Allied2_SubjectName: markInfoForm.values.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: markInfoForm.values.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: markInfoForm.values.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: markInfoForm.values.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: markInfoForm.values.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: markInfoForm.values.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: markInfoForm.values.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: markInfoForm.values.PG_Allied2_Passing,
            PG_Overall_Main_Core: markInfoForm.values.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: markInfoForm.values.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: markInfoForm.values.PG_Overall_Part3,

            MPhil_courseName: markInfoForm.values.MPhil_courseName,
            MPhil_Part1_SubjectName: markInfoForm.values.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: markInfoForm.values.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: markInfoForm.values.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: markInfoForm.values.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: markInfoForm.values.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: markInfoForm.values.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: markInfoForm.values.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: markInfoForm.values.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: markInfoForm.values.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: markInfoForm.values.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: markInfoForm.values.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: markInfoForm.values.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: markInfoForm.values.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: markInfoForm.values.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: markInfoForm.values.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: markInfoForm.values.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: markInfoForm.values.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: markInfoForm.values.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: markInfoForm.values.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: markInfoForm.values.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: markInfoForm.values.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: markInfoForm.values.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: markInfoForm.values.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: markInfoForm.values.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: markInfoForm.values.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: markInfoForm.values.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: markInfoForm.values.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: markInfoForm.values.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: markInfoForm.values.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: markInfoForm.values.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: markInfoForm.values.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: markInfoForm.values.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: markInfoForm.values.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: markInfoForm.values.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: markInfoForm.values.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: markInfoForm.values.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: markInfoForm.values.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: markInfoForm.values.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: markInfoForm.values.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: markInfoForm.values.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: markInfoForm.values.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: markInfoForm.values.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: markInfoForm.values.MPhil_Overall_Part3,

            ///----Admission----////
            quotaTypeId: quotaTypeId?.value != undefined ? quotaTypeId?.value : null,
            discription: admissionInfoForm.values.discription != "" ? admissionInfoForm.values.discription : null,
            isCitizenOfIndia: admissionInfoForm.values.isCitizenOfIndia != "" ? admissionInfoForm.values.isCitizenOfIndia : null,
            isClaimingNriQuotaSeat: admissionInfoForm.values.isClaimingNriQuotaSeat != "" ? admissionInfoForm.values.isClaimingNriQuotaSeat : null,
            isMedicalCertificateEnclosed: admissionInfoForm.values.isMedicalCertificateEnclosed != "" ? admissionInfoForm.values.isMedicalCertificateEnclosed : null,
            extraCurricularActivities: admissionInfoForm.values.extraCurricularActivities != "" ? admissionInfoForm.values.extraCurricularActivities : null,
            ///----Certificate----////
            certificateTypeId: checkArray,
            submittedDate: certificateInfoForm.values.submittedDate != "" ? certificateInfoForm.values.submittedDate : null,
            status: certificateInfoForm.values.status != "" ? certificateInfoForm.values.status : null,
            ///----Scholarship Details----////
            scholarshipTypeId: scholarshipTypeIdArray,
            assignedBy: userAccountId,
            licenseKey: getLicenseKey,
            createdBy: userAccountId,
            userAccountId: userAccountId
        })
    }

    function addStudentSubmit() {
        showLoader(true)
        if (addStudentForm.isValid) {
            let studentPostData = setAddStudentDetails()
            addStudentDetails(studentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        let studentDetailsId = data.data.studentDetailsId;
                        if (selectedFile != undefined) {
                            onUpload(studentDetailsId);
                        }
                        // resetState();
                        setIsNavigate(`../academic/studentList`)

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (addStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    const titles = [
        'General Info',
        'Address Info',
        'Class Info',
        'Family Info',
        'Mark Info',
        'Certificate Info',
        'Admission Info'
    ];

    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleDeleteAvatar = () => {
        setSelectedFile(null);
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <PageWrapper>
            <Page container='fluid'>
                <Card>
                    <CardHeader>
                        <CardLabel icon="Assignment" color="info">
                            {[...Array(totalSteps)].map((_, index) => (
                                <CardTitle
                                    key={index}
                                    className={index !== step ? 'd-none' : undefined}
                                >{titles[step]}
                                </CardTitle>
                            ))}
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='wizard-progress position-relative'>
                            <div className='progress'>
                                <div
                                    className='progress-bar bg-primary'
                                    role='progressbar'
                                    style={{ width: `${(100 / (totalSteps - 1)) * step}%` }}
                                    aria-valuenow={(100 / (totalSteps - 1)) * step}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    aria-label='progress'
                                />
                            </div>
                            {[...Array(totalSteps)].map((child: any, index: any) => (
                                <Popovers
                                    key={index}
                                    desc={titles[index]}
                                    trigger='hover'>
                                    <button
                                        type='button'
                                        className={classNames(
                                            'wizard-progress-btn',
                                            'position-absolute p-0 top-0',
                                            'translate-middle',
                                            'btn btn-sm',
                                            {
                                                [`btn-primary`]: step >= index,
                                                [`btn-${themeStatus}`]: step < index,
                                            },
                                            'rounded-pill',
                                        )}
                                        style={{
                                            left: `${(100 / (totalSteps - 1)) * index}%`,
                                        }}
                                        onClick={() => { handleStepClick(index) }}>
                                        {index + 1}
                                    </button>
                                </Popovers>
                            ))}
                        </div>

                        {step == 0 && (
                            <Card shadow='none' title="GeneralInfo">
                                <form noValidate onSubmit={addStudentForm.handleSubmit}>

                                    <div className="row g-4">
                                        <div className='col-12'>
                                            <div className='row g-4 align-items-center'>
                                                <div className="col-6 g-4">
                                                    <div className="col-lg-12">
                                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                            <SearchableSelect isFloating
                                                                ariaLabel='Graduation Type'
                                                                onChange={selectGraduationType}
                                                                value={graduationTypeId}
                                                                list={graduationTypeData.map((data: any) => (
                                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                                ))}
                                                                onBlur={addStudentForm.handleBlur}
                                                                isValid={addStudentForm.isValid}
                                                                isTouched={addStudentForm.touched.graduationTypeId}
                                                                invalidFeedback={addStudentForm.errors.graduationTypeId}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-lg-12 mt-3">
                                                        <FormGroup id="tempNo" label="Temp No" isFloating>
                                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Temp No"
                                                                onChange={selectTempStudent}
                                                                value={tempStudentDetailsId}
                                                                list={tempStudentData.map((data: any) => (
                                                                    { value: data.tempStudentDetailsId, label: data.tempNoAndStudentName }
                                                                ))} />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='col-12'>
                                                        <div className='row g-4 align-items-center'>
                                                            <div className='col-lg-3'>
                                                                {selectedFile ?
                                                                    <img style={{ borderRadius: '50%' }}
                                                                        src={URL.createObjectURL(selectedFile)} 
                                                                        height='120' width='120' />
                                                                    :
                                                                    <img style={{ borderRadius: '50%' }}
                                                                        src={profilePic(1)}
                                                                        height='120' width='120'
                                                                    />}
                                                            </div>
                                                            <div className='col-lg mt-3'>
                                                                <div className='row g-4'>
                                                                    <div className='col-10'>
                                                                        <Input
                                                                            id="fileInput"
                                                                            type='file'
                                                                            autoComplete='photo'
                                                                            ariaLabel='Upload image file'
                                                                            onChange={handleFileChange}
                                                                        />
                                                                    </div>
                                                                    <div className='col-12'>
                                                                        <Button
                                                                            color='dark'
                                                                            isLight
                                                                            icon='Delete'
                                                                            onClick={handleDeleteAvatar}>
                                                                            Delete Avatar
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                            <FormGroup id="admissionNo" label="Admission No / Roll Number" isFloating>
                                                <Input type="text" placeholder="Admission No / Roll Number"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.admissionNo}
                                                    onBlur={addStudentForm.handleBlur}
                                                    isValid={addStudentForm.isValid}
                                                    isTouched={addStudentForm.touched.admissionNo}
                                                    invalidFeedback={addStudentForm.errors.admissionNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                            <FormGroup id="firstName" label="First Name" isFloating>
                                                <Input type="text" placeholder="Enter First Name"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.firstName}
                                                    onBlur={addStudentForm.handleBlur}
                                                    isValid={addStudentForm.isValid}
                                                    isTouched={addStudentForm.touched.firstName}
                                                    invalidFeedback={addStudentForm.errors.firstName} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                            <FormGroup id="lastName" label="Last Name" isFloating>
                                                <Input type="text" placeholder="Enter Last Name"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.lastName} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="studentNameTamil" label="Student Name (Tamil)" isFloating>
                                                <Input type="text" placeholder="Enter Student Name (Tamil)"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.studentNameTamil} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15" >
                                            <FormGroup id="genderId" label="Gender" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Gender"
                                                    onChange={(e: any) => setGenderId(e)}
                                                    value={genderId}
                                                    list={genderData.map((data: any) => (
                                                        { value: data.genderId, label: data.gender }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="dateOfBirth" label="Date Of Birth" isFloating>
                                                <Input type="date" placeholder="Date Of Birth"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.dateOfBirth} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="smsToBeSentNumber" label="SMS Sent Number" isFloating>
                                                <Input type="text" placeholder="SMS Sent Number"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.smsToBeSentNumber}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={addStudentForm.handleBlur}
                                                    isValid={addStudentForm.isValid}
                                                    isTouched={addStudentForm.touched.smsToBeSentNumber}
                                                    invalidFeedback={addStudentForm.errors.smsToBeSentNumber} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="email" label="Email" isFloating>
                                                <Input type="text" placeholder="Enter Email"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.email}
                                                    onBlur={addStudentForm.handleBlur}
                                                    isValid={addStudentForm.isValid}
                                                    isTouched={addStudentForm.touched.email}
                                                    invalidFeedback={addStudentForm.errors.email} />
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="emisNo" label="EMIS No" isFloating>
                                                <Input type="text" placeholder="Enter EMIS No"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.emisNo}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="aadharCard" label="Aadhar Number" isFloating>
                                                <Input type="text" placeholder="Enter Aadhar Number"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.aadharCard}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={addStudentForm.handleBlur}
                                                    isValid={addStudentForm.isValid}
                                                    isTouched={addStudentForm.touched.aadharCard}
                                                    invalidFeedback={addStudentForm.errors.aadharCard} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="bloodGroupId" label="Blood Group" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Blood Group"
                                                    onChange={(e: any) => setBloodGroupId(e)}
                                                    value={bloodGroupId}
                                                    list={bloodData.map((data: any) => (
                                                        { value: data.bloodGroupId, label: data.groupName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="martialStatus" label="Marital Status" isFloating>
                                                <Select ariaLabel="" placeholder="Select Marital Status"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.martialStatus}>
                                                    <option value='Married'>Married</option>
                                                    <option value='Un Married'>Un Married</option>
                                                </Select>
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="motherTongue" label="Mother Tongue" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Mother Tongue"
                                                    onChange={(e: any) => setMotherTongue(e)}
                                                    value={motherTongue}
                                                    list={motherTongueData.map((data: any) => (
                                                        { value: data.motherTongue, label: data.motherTongue }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="nationality" label="Nationality" isFloating>
                                                <Select ariaLabel="" placeholder="Select Nationality"
                                                    onChange={selectNationality}
                                                    value={nationality}>
                                                    <option value={'Indian'}>Indian</option>
                                                    <option value={'Others'}>Others</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15 ">
                                            <FormGroup id="otherNation" label="Other Nation" isFloating>
                                                <Input type="text" placeholder="Enter Other Nation"
                                                    onChange={(e: any) => setOtherNation(e.target.value)}
                                                    value={otherNation} disabled={isOtherNation == 'Other' ? false : true} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="religionId" label="Religion" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Religion"
                                                    onChange={(e: any) => setReligionId(e)}
                                                    value={religionId}
                                                    list={religionData.map((data: any) => (
                                                        { value: data.religionId, label: data.religion }
                                                    ))} />
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="category" label="Category" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Category"
                                                    onChange={(e: any) => setCategory(e)}
                                                    value={category}
                                                    list={categoryData.map((data: any) => (
                                                        { value: data.category, label: data.category }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="casteDetailsId" label="Caste" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Caste"
                                                    onChange={(e: any) => setCasteDetailsId(e)}
                                                    value={casteDetailsId}
                                                    list={casteDetailsData.map((data: any) => (
                                                        { value: data.casteDetailsId, label: data.casteName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                            <FormGroup id="subcaste" label="Sub Caste" isFloating>
                                                <Input type="text" placeholder="Select Sub Caste"
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.subcaste} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3 mg-t-20 mg-lg-t-15" >
                                            <FormGroup id="physicallyChallenged" label="Physically Challenged" isFloating>
                                                <Select ariaLabel="" placeholder="Select Physically Challenged"
                                                    onChange={selectPhysicallyChallenged}
                                                    value={physicallyChallenged}>
                                                    <option value={'Yes'}>Yes</option>
                                                    <option value={'No'}>No</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        {isPhysicallyChallengedDetails == 'Yes' ? <>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="physicallyChallengedType" label="physically Challenged Type" isFloating>
                                                    <Input type="text" placeholder="physically Challenged Type"
                                                        onChange={(e: any) => setPhysicallyChallengedTypeValue(e.target.value)}
                                                        value={physicallyChallengedTypeValue} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                                <FormGroup id="physicallyChallengedPercentage" label="physically Challenged Percentage" isFloating>
                                                    <Input type="text" placeholder="physically Challenged Percentage"
                                                        onChange={(e: any) => setPhysicallyChallengedPercentageValue(e.target.value)}
                                                        value={physicallyChallengedPercentageValue}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                </FormGroup>
                                            </div>
                                        </> : null}

                                        <div className="col-lg-3 ">
                                            <FormGroup id="isCollegeTransport" label="Transport" isFloating>
                                                <Select ariaLabel="" placeholder="Select Transport"
                                                    onChange={selectTransport}
                                                    value={isCollegeTransport}>
                                                    <option value='Yes'>Yes</option>
                                                    <option value='No'>No</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        {isCollegeTransport == 'Yes' ?
                                            <>
                                                <div className="col-lg-3 ">
                                                    <FormGroup id="transportAreaMasterId" label="Area of Student" isFloating>
                                                        <SearchableSelect isFloating ariaLabel="" placeholder="Select Area"
                                                            onChange={selectTransportArea}
                                                            value={transportAreaMasterId}
                                                            list={transportAreaMasterData.map((data: any) => (
                                                                { value: data.transportAreaMasterId, label: data.areaName }
                                                            ))} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="modeOfWays" label="Mode Of Ways" isFloating>
                                                        <Select ariaLabel="" placeholder="Select Ways"
                                                            onChange={(e: any) => { setModeOfWays(e.target.value) }}
                                                            value={modeOfWays}>
                                                            <option value='1'>One Way</option>
                                                            <option value='2'>Two Way</option>
                                                        </Select>
                                                    </FormGroup>
                                                </div>
                                            </> : null}

                                    </div>
                                    <div style={{ textAlign: 'right' }} className="mt-4">
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>

                            </Card>
                        )}
                        {step == 1 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={addressForm.handleSubmit}>
                                    <div className="row g-4">
                                        <div className="col-lg-12">
                                            <CardLabel icon="LocationOn" color="info">
                                                <CardTitle className="h5">ADDRESS INFO</CardTitle>
                                            </CardLabel>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="commAddr_FlatNo" label="Flat No Or Name" isFloating>
                                                <Input type="text" placeholder="Flat No or Name"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.commAddr_FlatNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="commAddr_Street" label="Street" isFloating>
                                                <Input type="text" placeholder="Street"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.commAddr_Street} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="commAddr_Area" label="Area/Locality" isFloating>
                                                <Input type="text" placeholder="Area/Locality"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.commAddr_Area} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="commAddr_StateId" label="State" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select State"
                                                    onChange={(e: any) => selectState(e)}
                                                    value={commAddr_StateId}
                                                    list={statesData.map((data: any) => (
                                                        { value: data.stateId, label: data.stateName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="commAddr_CityId" label="City" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select City"
                                                    onChange={selectCity}
                                                    value={commAddr_CityId}
                                                    list={cityData.map((data: any) => (
                                                        { value: data.cityId, label: data.cityName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="commAddr_Pincode" label="Pincode" isFloating>
                                                <Input type="text" placeholder="Enter Pincode"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.commAddr_Pincode}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={addressForm.handleBlur}
                                                    isValid={addressForm.isValid}
                                                    isTouched={addressForm.touched.commAddr_Pincode}
                                                    invalidFeedback={addressForm.errors.commAddr_Pincode} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-12">
                                            <FormGroup id="checkbox">
                                                <Checks
                                                    type='checkbox'
                                                    label='Permanent Address Same As Address For Communication Click Checkbox.'
                                                    onChange={address}
                                                    checked={isChecked}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="permAddr_FlatNo" label="Flat No Or Name" isFloating>
                                                <Input type="text" placeholder="Flat No or Name"
                                                    onInput={(e: any) => setPermAddr_FlatNo(e.target.value)}
                                                    value={permAddr_FlatNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="permAddr_Street" label="Street" isFloating>
                                                <Input type="text" placeholder="Street"
                                                    onInput={(e: any) => setPermAddr_Street(e.target.value)}
                                                    value={permAddr_Street} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="permAddr_Area" label="Area/Locality" isFloating>
                                                <Input type="text" placeholder="Area/Locality"
                                                    onInput={(e: any) => setPermAddr_Area(e.target.value)}
                                                    value={permAddr_Area} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="permAddr_StateId" label="State" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select State"
                                                    onChange={(e: any) => selectPermAddr_State(e)}
                                                    value={permAddr_StateId}
                                                    list={permAddr_StateData.map((data: any) => (
                                                        { value: data.stateId, label: data.stateName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="permAddr_CityId" label="City" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select City"
                                                    onChange={selectPermAddr_City}
                                                    value={permAddr_CityId}
                                                    list={permAddr_CityData.map((data: any) => (
                                                        { value: data.cityId, label: data.cityName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="permAddr_Pincode" label="Pincode" isFloating>
                                                <Input type="text" placeholder="Enter Pincode"
                                                    onInput={(e: any) => setPermAddr_Pincode(e.target.value)}
                                                    onChange={addressForm.handleChange}
                                                    value={permAddr_Pincode}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={addressForm.handleBlur}
                                                    isValid={addressForm.isValid}
                                                    isTouched={addressForm.touched.permAddr_Pincode}
                                                    invalidFeedback={addressForm.errors.permAddr_Pincode} />
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-12  align-self-center">
                                            <CardLabel icon="MonetizationOn" color="info">
                                                <CardTitle className="h5">BANK INFO</CardTitle>
                                            </CardLabel>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="bankName" label="Bank Name" isFloating>
                                                <Input type="text" placeholder="Enter Bank Name"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.bankName} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="branch" label="Branch" isFloating>
                                                <Input type="text" placeholder="Enter Branch"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.branch} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="bankAccountNo" label="Bank Account No" isFloating>
                                                <Input type="text" placeholder="Enter Bank Account No"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.bankAccountNo}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="ifscCode" label="IFSC Code" isFloating>
                                                <Input type="text" placeholder="Enter IFSC Code"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.ifscCode} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup id="micrCode" label="MICR Code" isFloating>
                                                <Input type="text" placeholder="Enter MICR Code"
                                                    onChange={addressForm.handleChange}
                                                    value={addressForm.values.micrCode} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 2 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={classInfoForm.handleSubmit}>
                                    <div className="row g-4">
                                        <div className="col-lg-3">
                                            <FormGroup id="department" label="Department" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Department"
                                                    onChange={selectDepartment}
                                                    value={departmentId}
                                                    list={departmentData.map((data: any) => (
                                                        { value: data.departmentId, label: data.departmentName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="courseId" label="Course" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Course"
                                                    onChange={selectCourse}
                                                    value={courseId}
                                                    list={courseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="batchMasterId" label="Batch" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Batch"
                                                    onChange={selectBatch}
                                                    value={batchMasterId}
                                                    list={batchMasterData.map((data: any) => (
                                                        { value: data.batchMasterId, label: data.batchName }
                                                    ))}
                                                    onBlur={classInfoForm.handleBlur}
                                                    isValid={classInfoForm.isValid}
                                                    isTouched={classInfoForm.touched.batchMasterId}
                                                    invalidFeedback={classInfoForm.errors.batchMasterId} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="semesterDetailsId" label="Semester" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Semester"
                                                    onChange={selectSemester}
                                                    value={semesterDetailsId}
                                                    list={semesterDetailsData.map((data: any) => (
                                                        { value: data.semesterDetailsId, label: data.semesterNumber }
                                                    ))}
                                                    onBlur={classInfoForm.handleBlur}
                                                    isValid={classInfoForm.isValid}
                                                    isTouched={classInfoForm.touched.semesterDetailsId}
                                                    invalidFeedback={classInfoForm.errors.semesterDetailsId} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="sectionDetailsId" label="Section" isFloating>
                                                <SearchableSelect isFloating ariaLabel=""
                                                    onChange={selectSection}
                                                    value={sectionDetailsId}
                                                    list={sectionDetailsData.map((data: any) => (
                                                        { value: data.sectionDetailsId, label: data.sectionName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="shiftId" label="Shift" isFloating>
                                                <SearchableSelect isFloating ariaLabel=""
                                                    onChange={(e: any) => setShiftId(e)}
                                                    value={shiftId}
                                                    list={shiftData.map((data: any) => (
                                                        { value: data.shiftId, label: data.shift }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="admissionDateStamp" label="Admission Date" isFloating>
                                                <Input type="date" placeholder="Enter Admission Date"
                                                    onChange={classInfoForm.handleChange}
                                                    value={classInfoForm.values.admissionDateStamp} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="sourceName" label="Source" isFloating>
                                                <Input type="text" placeholder="Enter Source"
                                                    onChange={classInfoForm.handleChange}
                                                    value={classInfoForm.values.sourceName} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="transferCourseId" label="Interested to Transfer the Course" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Interested to Transfer the Course"
                                                    onChange={(e: any) => setTransferCourseId(e)}
                                                    value={transferCourseId}
                                                    list={interestedCourseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="referralTypeId" label="Reference (if any)" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Reference"
                                                    onChange={selectReferralType}
                                                    value={referralTypeId}
                                                    list={referralTypeData.map((data: any) => (
                                                        { value: data.referralTypeId, label: data.referralType }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        {isUserDetails == 'collegeStaff' ? <div className="col-lg-3">
                                            <FormGroup id="referredId" label="Referred Person" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Referred Person"
                                                    onChange={(e: any) => { setReferredId(e) }}
                                                    value={referredId}
                                                    list={userListByUserTypeData.map((data: any) => (
                                                        { value: data.referredId, label: data.registerNoForStudentAndStaff }
                                                    ))} />
                                            </FormGroup>
                                        </div> : null}
                                        {isUserDetails == 'others' ? <div className="col-lg-3">
                                            <FormGroup id="referredName" label="Referred Person" isFloating>
                                                <Input type="text" placeholder="Enter Referred Person"
                                                    onInput={(e: any) => { setReferredName(e.target.value) }}
                                                    value={referredName} />
                                            </FormGroup>
                                        </div> : null}

                                        <div className="col-lg-3">
                                            <FormGroup id="concessionTypeId" label="Concession Type" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Concession Type"
                                                    onChange={selectConcessionTypeId}
                                                    value={concessionTypeId}
                                                    list={concessionData.map((data: any) => (
                                                        { value: data.concessionTypeId, label: data.concessionType }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="concessionRemarks" label="Concession Remark" isFloating>
                                                <Input type="text" placeholder="Enter Concession Remark"
                                                    onChange={classInfoForm.handleChange}
                                                    onInput={(e: any) => setConcessionRemarks(e.target.value)}
                                                    value={concessionRemarks} disabled={concessionTypeId?.value != undefined ? false : true} />
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-3">
                                            <FormGroup id="interimCollegeTransfer" label="Interim College Transfer ?" isFloating>
                                                <Select ariaLabel="" placeholder="Select Interim College Transfer ?"
                                                    onChange={selectInterimCollegeTransfer}
                                                    value={interimCollegeTransfer}>
                                                    <option value='Yes'>Yes</option>
                                                    <option value='No'>No</option>
                                                </Select>
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-3">
                                            <FormGroup id="collegeName" label="College Name" isFloating>
                                                <Input type="text" placeholder="Enter College Name"
                                                    onChange={(e: any) => setCollegeName(e.target.value)}
                                                    value={collegeName} disabled={interimCollegeTransfer == "No" ? true : false} />
                                            </FormGroup>
                                        </div>



                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 3 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={familyForm.handleSubmit}>

                                    <div className="row g-4">
                                        <div className="col-lg-3">
                                            <FormGroup id="fatherName" label="Father Name" isFloating>
                                                <Input type="text" placeholder="Enter Father Name"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.fatherName} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="father_AdharNumber" label="Father Aadhar Number" isFloating>
                                                <Input type="text" placeholder="Enter Father Aadhar Number"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.father_AdharNumber}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={familyForm.handleBlur}
                                                    isValid={familyForm.isValid}
                                                    isTouched={familyForm.touched.father_AdharNumber}
                                                    invalidFeedback={familyForm.errors.father_AdharNumber} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="fatherOccupation" label="Father Occupation" isFloating>
                                                <Select ariaLabel="" placeholder="Select Father Occupation"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.fatherOccupation}>
                                                    <option>Job</option>
                                                    <option>Business</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="natureofJoborBusiness" label="Nature of Job/Business" isFloating>
                                                <Input type="text" placeholder="Enter Nature of Job/Business"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.natureofJoborBusiness} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="fatherContactNumber" label="Mobile Number" isFloating>
                                                <Input type="text" placeholder="Enter Mobile Number"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.fatherContactNumber}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={familyForm.handleBlur}
                                                    isValid={familyForm.isValid}
                                                    isTouched={familyForm.touched.fatherContactNumber}
                                                    invalidFeedback={familyForm.errors.fatherContactNumber} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="fatherEmailId" label="Father Email Id" isFloating>
                                                <Input type="text" placeholder="Enter Father Email Id"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.fatherEmailId}
                                                    onBlur={familyForm.handleBlur}
                                                    isValid={familyForm.isValid}
                                                    isTouched={familyForm.touched.fatherEmailId}
                                                    invalidFeedback={familyForm.errors.fatherEmailId} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="motherName" label="Mother Name" isFloating>
                                                <Input type="text" placeholder="Enter Mother Name"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.motherName} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="mother_AdharNumber" label="Mother Aadhar Number" isFloating>
                                                <Input type="text" placeholder="Enter Mother Aadhar Number"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.mother_AdharNumber}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={familyForm.handleBlur}
                                                    isValid={familyForm.isValid}
                                                    isTouched={familyForm.touched.mother_AdharNumber}
                                                    invalidFeedback={familyForm.errors.mother_AdharNumber} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="yearlyIncome" label="Yearly Income" isFloating>
                                                <Input type="text" placeholder="Enter Yearly Income"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.yearlyIncome}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="positionHeld" label="Position held" isFloating>
                                                <Input type="text" placeholder="Enter Position held"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.positionHeld} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="motherContactNumber" label="Mother Mobile Number" isFloating>
                                                <Input type="text" placeholder="Enter Mother Mobile Number"
                                                    onChange={familyForm.handleChange}
                                                    value={familyForm.values.motherContactNumber}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    onBlur={familyForm.handleBlur}
                                                    isValid={familyForm.isValid}
                                                    isTouched={familyForm.touched.motherContactNumber}
                                                    invalidFeedback={familyForm.errors.motherContactNumber} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-12">
                                            <FormGroup id="isGuardian">
                                                <Checks
                                                    type='checkbox'
                                                    label='Lives With Guardian Click Checkbox.'
                                                    onChange={handleCheckboxChange}
                                                    checked={isGuardian}
                                                />
                                            </FormGroup>
                                        </div>
                                        {isGuardian && (<>
                                            <div className="col-lg-3">
                                                <FormGroup id="guardian_Name" label="Guardian Name" isFloating>
                                                    <Input type="text" placeholder="Enter Guardian Name"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.guardian_Name} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="guardian_AdharNumber" label="Guardian Aadhar Number" isFloating>
                                                    <Input type="text" placeholder="Enter Guardian Aadhar Number"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.guardian_AdharNumber}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.guardian_AdharNumber}
                                                        invalidFeedback={familyForm.errors.guardian_AdharNumber} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="guardian_phone" label="Guardian Mobile Number" isFloating>
                                                    <Input type="text" placeholder="Enter Guardian Mobile Number"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.guardian_phone}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.guardian_phone}
                                                        invalidFeedback={familyForm.errors.guardian_phone} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormGroup id="guardian_email" label="Guardian Email Id" isFloating>
                                                    <Input type="text" placeholder="Enter Guardian Email Id"
                                                        onChange={familyForm.handleChange}
                                                        value={familyForm.values.guardian_email}
                                                        onBlur={familyForm.handleBlur}
                                                        isValid={familyForm.isValid}
                                                        isTouched={familyForm.touched.guardian_email}
                                                        invalidFeedback={familyForm.errors.guardian_email} />
                                                </FormGroup>
                                            </div></>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 4 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={markInfoForm.handleSubmit}>
                                    <div className="d-grid gap-4">

                                        <div className="col-lg-12">
                                            <CardLabel icon="School" color="info">
                                                <CardTitle className="h5"> STUDENT MARK INFO</CardTitle>
                                            </CardLabel>
                                        </div>
                                        <div className="row justify-content-center">
                                            <table className='table table-modern table-hover text-nowrap '>
                                                <thead >
                                                    <tr className='table-primary'>
                                                        <th >S.NO</th>
                                                        <th >CLASS</th>
                                                        <th >REGISTER<br /> NO</th>
                                                        <th >NAME & ADDRESS OF <br />THE INSTITUTION</th>
                                                        <th >NAME OF <br />BOARD</th>
                                                        <th >MEDIUM OF <br />INSTRUCTION</th>
                                                        <th >MONTH & YEAR <br />OF PASSING</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center fw-bold">1</td>
                                                        <td className="text-center fw-bold">10 STD</td>
                                                        <td><Input id='registerNo_X' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.registerNo_X} /></td>
                                                        <td><Input id='institutionNameAndAddress_X' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.institutionNameAndAddress_X} /></td>
                                                        <td><Input id='boardName_X' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.boardName_X} /></td>
                                                        <td><Input id='mediumOfInstitution_X' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.mediumOfInstitution_X} /></td>
                                                        <td><Input id='monthAndYearOfPassing_X' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.monthAndYearOfPassing_X} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center fw-bold">2</td>
                                                        <td className="text-center fw-bold">11 STD</td>
                                                        <td><Input id='registerNo_XI' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.registerNo_XI} /></td>
                                                        <td><Input id='institutionNameAndAddress_XI' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.institutionNameAndAddress_XI} /></td>
                                                        <td><Input id='boardName_XI' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.boardName_XI} /></td>
                                                        <td><Input id='mediumOfInstitution_XI' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.mediumOfInstitution_XI} /></td>
                                                        <td><Input id='monthAndYearOfPassing_XI' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.monthAndYearOfPassing_XI} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center fw-bold">3</td>
                                                        <td className="text-center fw-bold">12 STD</td>
                                                        <td><Input id='registerNo_XII' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.registerNo_XII} /></td>
                                                        <td>
                                                            <SearchableSelect id='XIISchoolNameId' ariaLabel="Select School" style={{ width: '80' }} placeholder="Select School"
                                                                onChange={(e: any) => setXIISchoolNameId(e)}
                                                                value={XIISchoolNameId}
                                                                list={studentSchoolNameData.map((data: any) => (
                                                                    { value: data.studentSchoolNameId, label: data.schoolName }
                                                                ))} /></td>
                                                        <td><Input id='boardName_XII' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.boardName_XII} /></td>
                                                        <td><Input id='mediumOfInstitution_XII' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.mediumOfInstitution_XII} /></td>
                                                        <td><Input id='monthAndYearOfPassing_XII' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.monthAndYearOfPassing_XII} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row  align-self-center">
                                            <FormGroup className="align-self-center">
                                                <span className="text-info h5" >
                                                    Details of Qualifying Examination
                                                </span>
                                            </FormGroup>
                                        </div>
                                        <div className="row  align-self-center">
                                            <FormGroup className="align-self-center">
                                                <span className="text-info h5" >
                                                    (1). Marks In XI th Standard
                                                </span>
                                            </FormGroup>
                                        </div>
                                        <div className="row justify-content-center">
                                            <table className='table table-modern table-hover text-nowrap'>
                                                <thead>
                                                    <tr className='table-primary'>
                                                        <th >SUBJECT</th>
                                                        <th >MARKS <br />SECURED</th>
                                                        <th >MAXIMUM <br />MARKS</th>
                                                        <th >MONTH & YEAR  <br />OF PASSING</th>
                                                        <th >REGISTER<br /> NO</th>
                                                        <th >NO OF <br />ATTEMPTS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <SearchableSelect id='XI_S1_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                                onChange={(e: any) => setXI_S1_studentSchoolSubject(e)}
                                                                value={XI_S1_studentSchoolSubject}
                                                                list={studentSchoolSubjectData.map((data: any) => (
                                                                    { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                                ))} />
                                                        </td>
                                                        <td>
                                                            <Input id='XI_S1_markSecured' type="text" style={{ width: '80' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XI_S1_markSecured} />
                                                        </td>
                                                        <td>
                                                            <Input id='XI_S1_maximumMarks' type="text" style={{ width: '80' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XI_S1_maximumMarks} />
                                                        </td>
                                                        <td>
                                                            <Input id='XI_S1_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XI_S1_monthAndYearOfPassing} />
                                                        </td>
                                                        <td>
                                                            <Input id='XI_S1_registerNo' type="text" style={{ width: '80' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XI_S1_registerNo} />
                                                        </td>
                                                        <td>
                                                            <Input id='XI_S1_noOfAttempts' type="text" style={{ width: '80' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XI_S1_noOfAttempts} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XI_S2_studentSchoolSubject' ariaLabel="Subject" placeholder="Subject"
                                                            onChange={(e: any) => setXI_S2_studentSchoolSubject(e)}
                                                            value={XI_S2_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XI_S2_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S2_markSecured} /></td>
                                                        <td><Input id='XI_S2_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S2_maximumMarks} /></td>
                                                        <td><Input id='XI_S2_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S2_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XI_S2_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S2_registerNo} /></td>
                                                        <td><Input id='XI_S2_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S2_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center fw-bold">Part-III Subjects</td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XI_S3_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXI_S3_studentSchoolSubject(e)}
                                                            value={XI_S3_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XI_S3_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S3_markSecured} /></td>
                                                        <td><Input id='XI_S3_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S3_maximumMarks} /></td>
                                                        <td><Input id='XI_S3_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S3_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XI_S3_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S3_registerNo} /></td>
                                                        <td><Input id='XI_S3_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S3_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XI_S4_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXI_S4_studentSchoolSubject(e)}
                                                            value={XI_S4_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XI_S4_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S4_markSecured} /></td>
                                                        <td><Input id='XI_S4_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S4_maximumMarks} /></td>
                                                        <td><Input id='XI_S4_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S4_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XI_S4_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S4_registerNo} /></td>
                                                        <td><Input id='XI_S4_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S4_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XI_S5_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXI_S5_studentSchoolSubject(e)}
                                                            value={XI_S5_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XI_S5_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S5_markSecured} /></td>
                                                        <td><Input id='XI_S5_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S5_maximumMarks} /></td>
                                                        <td><Input id='XI_S5_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S5_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XI_S5_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S5_registerNo} /></td>
                                                        <td><Input id='XI_S5_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S5_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XI_S6_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXI_S6_studentSchoolSubject(e)}
                                                            value={XI_S6_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XI_S6_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S6_markSecured} /></td>
                                                        <td><Input id='XI_S6_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S6_maximumMarks} /></td>
                                                        <td><Input id='XI_S6_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S6_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XI_S6_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S6_registerNo} /></td>
                                                        <td><Input id='XI_S6_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_S6_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center fw-bold">TOTAL</td>
                                                        <td><Input id='XI_OverAll_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            onInput={(e: any) => setTotalPercentageForXI(e.target.value)}
                                                            value={markInfoForm.values.XI_OverAll_markSecured} /></td>
                                                        <td><Input id='XI_OverAll_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XI_OverAll_maximumMarks} /></td>
                                                        <td className="text-center fw-bold">TOTAL PERCENTAGE</td>
                                                        <td><Input id='' type="text" style={{ width: '80' }} disabled
                                                            value={(Number(totalPercentageForXI) / Number(600) * 100).toFixed(2)} /></td>
                                                        <td><Input id='' type="text" style={{ width: '80' }} disabled /></td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row  align-self-center">
                                            <FormGroup className="align-self-center">
                                                <span className="text-info h5" >
                                                    (2).Marks In XII th Standard
                                                </span>
                                            </FormGroup>
                                        </div>
                                        <div className="row justify-content-center">
                                            <table className='table table-modern table-hover text-nowrap'>
                                                <thead >
                                                    <tr className='table-primary'>
                                                        <th >SUBJECT</th>
                                                        <th >MARKS <br />SECURED</th>
                                                        <th >MAXIMUM <br />MARKS</th>
                                                        <th >MONTH & YEAR <br />OF PASSING</th>
                                                        <th >REGISTER<br /> NO</th>
                                                        <th >NO OF<br /> ATTEMPTS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><SearchableSelect id='XII_S1_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXII_S1_studentSchoolSubject(e)}
                                                            value={XII_S1_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XII_S1_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S1_markSecured} /></td>
                                                        <td><Input id='XII_S1_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S1_maximumMarks} /></td>
                                                        <td><Input id='XII_S1_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S1_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XII_S1_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S1_registerNo} /></td>
                                                        <td><Input id='XII_S1_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S1_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XII_S2_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXII_S2_studentSchoolSubject(e)}
                                                            value={XII_S2_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XII_S2_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S2_markSecured} /></td>
                                                        <td><Input id='XII_S2_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S2_maximumMarks} /></td>
                                                        <td><Input id='XII_S2_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S2_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XII_S2_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S2_registerNo} /></td>
                                                        <td><Input id='XII_S2_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S2_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center fw-bold">Part-III Subjects</td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XII_S3_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXII_S3_studentSchoolSubject(e)}
                                                            value={XII_S3_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XII_S3_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S3_markSecured} /></td>
                                                        <td><Input id='XII_S3_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S3_maximumMarks} /></td>
                                                        <td><Input id='XII_S3_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S3_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XII_S3_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S3_registerNo} /></td>
                                                        <td><Input id='XII_S3_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S3_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XII_S4_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXII_S4_studentSchoolSubject(e)}
                                                            value={XII_S4_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XII_S4_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S4_markSecured} /></td>
                                                        <td><Input id='XII_S4_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S4_maximumMarks} /></td>
                                                        <td><Input id='XII_S4_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S4_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XII_S4_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S4_registerNo} /></td>
                                                        <td><Input id='XII_S4_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S4_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XII_S5_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXII_S5_studentSchoolSubject(e)}
                                                            value={XII_S5_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XII_S5_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S5_markSecured} /></td>
                                                        <td><Input id='XII_S5_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S5_maximumMarks} /></td>
                                                        <td><Input id='XII_S5_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S5_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XII_S5_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S5_registerNo} /></td>
                                                        <td><Input id='XII_S5_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S5_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><SearchableSelect id='XII_S6_studentSchoolSubject' ariaLabel="" placeholder="Subject"
                                                            onChange={(e: any) => setXII_S6_studentSchoolSubject(e)}
                                                            value={XII_S6_studentSchoolSubject}
                                                            list={studentSchoolSubjectData.map((data: any) => (
                                                                { value: data.studentSchoolSubjectId, label: data.subjectName }
                                                            ))} /></td>
                                                        <td><Input id='XII_S6_markSecured' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S6_markSecured} /></td>
                                                        <td><Input id='XII_S6_maximumMarks' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S6_maximumMarks} /></td>
                                                        <td><Input id='XII_S6_monthAndYearOfPassing' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S6_monthAndYearOfPassing} /></td>
                                                        <td><Input id='XII_S6_registerNo' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S6_registerNo} /></td>
                                                        <td><Input id='XII_S6_noOfAttempts' type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_S6_noOfAttempts} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center fw-bold">TOTAL</td>
                                                        <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            onInput={(e: any) => setTotalPercentageForXII(e.target.value)}
                                                            value={markInfoForm.values.XII_OverAll_markSecured} /></td>
                                                        <td><Input id="XII_OverAll_maximumMarks" type="text" style={{ width: '80' }}
                                                            onChange={markInfoForm.handleChange}
                                                            value={markInfoForm.values.XII_OverAll_maximumMarks} /></td>
                                                        <td className="text-center fw-bold">TOTAL PERCENTAGE</td>
                                                        <td><Input type="text" style={{ width: '80' }} disabled
                                                            value={(Number(totalPercentageForXII) / Number(600) * 100).toFixed(2)} /></td>
                                                        <td><Input type="text" style={{ width: '80' }} disabled /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {graduationTypeId?.value == 2 || graduationTypeId?.value == 3 ? (<>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <FormGroup >
                                                        <span className="text-info h5" >
                                                            (3). Name of the UG Course (if any) :
                                                        </span>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Input type="text" placeholder="Enter UG Course Name"
                                                        onChange={markInfoForm.handleChange}
                                                        value={markInfoForm.values.UG_courseName} />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <table className="table table-modern table-hover text-center text-nowrap text-lg-nowrap ">
                                                    <thead className="col-lg-12 text-center bg-info p-2 fs-6">
                                                        <tr className='table-primary'>
                                                            <th>SUBJECT</th>
                                                            <th>MARKS <br />SECURED</th>
                                                            <th>MAXIMUM <br />MARKS</th>
                                                            <th>PERCENTAGE <br />OF MARKS %</th>
                                                            <th>CLASS <br />OBTAINED</th>
                                                            <th>NO OF <br />ATTEMPTS</th>
                                                            <th>REGISTER <br />NO</th>
                                                            <th>MONTH & YEAR <br />OF PASSING</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td ><Input id="UG_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_SubjectName} /></td>

                                                            <td><Input id="UG_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_MarksObtained} /></td>

                                                            <td><Input id="UG_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_MaximumMarks} /></td>

                                                            <td><Input id="UG_Part1_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_Percentage} /></td>

                                                            <td><Input id="UG_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_ClassObtained} /></td>

                                                            <td><Input id="UG_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_NoOfAttempts} /></td>

                                                            <td><Input id="UG_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_RegisterNo} /></td>

                                                            <td><Input id="UG_Part1_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part1_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="UG_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_SubjectName} /></td>

                                                            <td><Input id="UG_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_MarksObtained} /></td>

                                                            <td><Input id="UG_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_MaximumMarks} /></td>

                                                            <td><Input id="UG_Part2_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_Percentage} /></td>

                                                            <td><Input id="UG_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_ClassObtained} /></td>

                                                            <td><Input id="UG_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_NoOfAttempts} /></td>

                                                            <td><Input id="UG_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_RegisterNo} /></td>

                                                            <td><Input id="UG_Part2_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part2_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="UG_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_SubjectName} /></td>

                                                            <td><Input id="UG_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_MarksObtained} /></td>

                                                            <td><Input id="UG_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_MaximumMarks} /></td>

                                                            <td><Input id="UG_Part3_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_Percentage} /></td>

                                                            <td><Input id="UG_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_ClassObtained} /></td>

                                                            <td><Input id="UG_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_NoOfAttempts} /></td>

                                                            <td><Input id="UG_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_RegisterNo} /></td>

                                                            <td><Input id="UG_Part3_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Part3_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="UG_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_SubjectName} /></td>

                                                            <td><Input id="UG_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_MarksObtained} /></td>

                                                            <td><Input id="UG_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_MaximumMarks} /></td>

                                                            <td><Input id="UG_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_Percentage} /></td>

                                                            <td><Input id="UG_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_ClassObtained} /></td>

                                                            <td><Input id="UG_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_NoOfAttempts} /></td>

                                                            <td><Input id="UG_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_RegisterNo} /></td>

                                                            <td><Input id="UG_Allied1_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied1_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="UG_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_SubjectName} /></td>

                                                            <td><Input id="UG_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_MarksObtained} /></td>

                                                            <td><Input id="UG_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_MaximumMarks} /></td>

                                                            <td><Input id="UG_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_Percentage} /></td>

                                                            <td><Input id="UG_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_ClassObtained} /></td>

                                                            <td><Input id="UG_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_NoOfAttempts} /></td>

                                                            <td><Input id="UG_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_RegisterNo} /></td>

                                                            <td><Input id="UG_Allied2_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Allied2_Passing} /></td>
                                                        </tr>
                                                        <tr className='table-primary'>
                                                            <td className="text-center fw-bold">Percentage</td>
                                                            <td className="text-center fw-bold">Main/Core</td>
                                                            <td><Input id="UG_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Overall_Main_Core} /></td>
                                                            <td className="text-center fw-bold">Ancy./Allied</td>
                                                            <td><Input id="UG_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Overall_Ancy_Allied} /></td>
                                                            <td className="text-center fw-bold">Part - III</td>
                                                            <td><Input id="UG_Overall_Part3" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.UG_Overall_Part3} /></td>
                                                            <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XII_OverAll_markSecured} disabled /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>) : null}
                                        {graduationTypeId == 3 ? (<>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <FormGroup >
                                                        <span className="text-info h5" >
                                                            (4). Name of the PG Course (if any) :
                                                        </span>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Input type="text" placeholder="Enter PG Course Name"
                                                        onChange={markInfoForm.handleChange}
                                                        value={markInfoForm.values.PG_courseName} />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <table className="table table-modern table-hover text-center text-nowrap">
                                                    <thead>
                                                        <tr className='table-primary'>
                                                            <th>SUBJECT</th>
                                                            <th>MARKS <br />SECURED</th>
                                                            <th>MAXIMUM MARKS</th>
                                                            <th>PERCENTAGE OF<br /> MARKS %</th>
                                                            <th>CLASS <br />OBTAINED</th>
                                                            <th>NO OF <br />ATTEMPTS</th>
                                                            <th>REGISTER<br /> NO</th>
                                                            <th>MONTH & YEAR<br /> OF PASSING</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td ><Input id="PG_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_SubjectName} /></td>

                                                            <td><Input id="PG_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_MarksObtained} /></td>

                                                            <td><Input id="PG_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_MaximumMarks} /></td>

                                                            <td><Input id="PG_Part1_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_Percentage} /></td>

                                                            <td><Input id="PG_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_ClassObtained} /></td>

                                                            <td><Input id="PG_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_NoOfAttempts} /></td>

                                                            <td><Input id="PG_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_RegisterNo} /></td>

                                                            <td><Input id="PG_Part1_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part1_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="PG_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_SubjectName} /></td>

                                                            <td><Input id="PG_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_MarksObtained} /></td>

                                                            <td><Input id="PG_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_MaximumMarks} /></td>

                                                            <td><Input id="PG_Part2_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_Percentage} /></td>

                                                            <td><Input id="PG_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_ClassObtained} /></td>

                                                            <td><Input id="PG_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_NoOfAttempts} /></td>

                                                            <td><Input id="PG_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_RegisterNo} /></td>

                                                            <td><Input id="PG_Part2_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part2_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="PG_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_SubjectName} /></td>

                                                            <td><Input id="PG_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_MarksObtained} /></td>

                                                            <td><Input id="PG_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_MaximumMarks} /></td>

                                                            <td><Input id="PG_Part3_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_Percentage} /></td>

                                                            <td><Input id="PG_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_ClassObtained} /></td>

                                                            <td><Input id="PG_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_NoOfAttempts} /></td>

                                                            <td><Input id="PG_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_RegisterNo} /></td>

                                                            <td><Input id="PG_Part3_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Part3_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="PG_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_SubjectName} /></td>

                                                            <td><Input id="PG_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_MarksObtained} /></td>

                                                            <td><Input id="PG_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_MaximumMarks} /></td>

                                                            <td><Input id="PG_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_Percentage} /></td>

                                                            <td><Input id="PG_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_ClassObtained} /></td>

                                                            <td><Input id="PG_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_NoOfAttempts} /></td>

                                                            <td><Input id="PG_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_RegisterNo} /></td>

                                                            <td><Input id="PG_Allied1_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied1_Passing} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td ><Input id="PG_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_SubjectName} /></td>

                                                            <td><Input id="PG_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_MarksObtained} /></td>

                                                            <td><Input id="PG_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_MaximumMarks} /></td>

                                                            <td><Input id="PG_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_Percentage} /></td>

                                                            <td><Input id="PG_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_ClassObtained} /></td>

                                                            <td><Input id="PG_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_NoOfAttempts} /></td>

                                                            <td><Input id="PG_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_RegisterNo} /></td>

                                                            <td><Input id="PG_Allied2_Passing" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Allied2_Passing} /></td>
                                                        </tr>
                                                        <tr className='table-primary'>
                                                            <td className="text-center fw-bold">Percentage</td>
                                                            <td className="text-center fw-bold">Main/Core</td>
                                                            <td><Input id="PG_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Overall_Main_Core} /></td>
                                                            <td className="text-center fw-bold">Ancy./Allied</td>
                                                            <td><Input id="PG_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Overall_Ancy_Allied} /></td>
                                                            <td className="text-center fw-bold">Part - III</td>
                                                            <td><Input id="PG_Overall_Part3" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.PG_Overall_Part3} /></td>
                                                            <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                                onChange={markInfoForm.handleChange}
                                                                value={markInfoForm.values.XII_OverAll_markSecured} disabled /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>) : null}
                                        {/* <div className="row">
                                           <div className="col-lg-4">
                                               <FormGroup >
                                                   <span className="text-info h5" >
                                                       (5). Name of the MPhil Course (if any) :
                                                   </span>
                                               </FormGroup>
                                           </div>
                                           <div className="col-lg-6">
                                               <Input id="MPhil_courseName" type="text" placeholder="Enter MPhil Course Name" 
                                                   onChange={markInfoForm.handleChange}
                                                   value={markInfoForm.values.MPhil_courseName}/>
                                           </div>
                                       </div>
                                       <div className="row justify-content-center">
                                           <table className="table table-bordered text-nowrap">
                                               <thead>
                                                   <tr>
                                                       <th className="text-center bg-primary text-white"><strong>SUBJECT</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>MARKS SECURED</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>MAXIMUM MARKS</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>PERCENTAGE OF MARKS %</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>CLASS OBTAINED</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>NO OF ATTEMPTS</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>REGISTER NO</strong></th>
                                                       <th className="text-center bg-primary text-white"><strong>MONTH & YEAR OF PASSING</strong></th>
                                                   </tr>
                                               </thead>
                                           
                                           <tbody>
                                           <tr>
                                                   <td ><Input id="MPhil_Part1_SubjectName" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_SubjectName} /></td>
       
                                                   <td><Input id="MPhil_Part1_MarksObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_MarksObtained} /></td>
       
                                                   <td><Input id="MPhil_Part1_MaximumMarks" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_MaximumMarks} /></td>
       
                                                   <td><Input id="MPhil_Part1_Percentage" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_Percentage} /></td>
       
                                                   <td><Input id="MPhil_Part1_ClassObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_ClassObtained} /></td>
       
                                                   <td><Input id="MPhil_Part1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_NoOfAttempts} /></td>
       
                                                   <td><Input id="MPhil_Part1_RegisterNo" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_RegisterNo} /></td>
       
                                                   <td><Input id="MPhil_Part1_Passing" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part1_Passing} /></td>
                                               </tr>
                                               <tr>
                                                   <td ><Input id="MPhil_Part2_SubjectName" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_SubjectName} /></td>
       
                                                   <td><Input id="MPhil_Part2_MarksObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_MarksObtained} /></td>
       
                                                   <td><Input id="MPhil_Part2_MaximumMarks" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_MaximumMarks} /></td>
       
                                                   <td><Input id="MPhil_Part2_Percentage" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_Percentage} /></td>
       
                                                   <td><Input id="MPhil_Part2_ClassObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_ClassObtained} /></td>
       
                                                   <td><Input id="MPhil_Part2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_NoOfAttempts} /></td>
       
                                                   <td><Input id="MPhil_Part2_RegisterNo" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_RegisterNo} /></td>
       
                                                   <td><Input id="MPhil_Part2_Passing" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part2_Passing} /></td>
                                               </tr>
                                               <tr>
                                                   <td ><Input id="MPhil_Part3_SubjectName" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_SubjectName} /></td>
       
                                                   <td><Input id="MPhil_Part3_MarksObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_MarksObtained} /></td>
       
                                                   <td><Input id="MPhil_Part3_MaximumMarks" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_MaximumMarks} /></td>
       
                                                   <td><Input id="MPhil_Part3_Percentage" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_Percentage} /></td>
       
                                                   <td><Input id="MPhil_Part3_ClassObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_ClassObtained} /></td>
       
                                                   <td><Input id="MPhil_Part3_NoOfAttempts" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_NoOfAttempts} /></td>
       
                                                   <td><Input id="MPhil_Part3_RegisterNo" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_RegisterNo} /></td>
       
                                                   <td><Input id="MPhil_Part3_Passing" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Part3_Passing} /></td>
                                               </tr>
                                               <tr>
                                                   <td ><Input id="MPhil_Allied1_SubjectName" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_SubjectName} /></td>
       
                                                   <td><Input id="MPhil_Allied1_MarksObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_MarksObtained} /></td>
       
                                                   <td><Input id="MPhil_Allied1_MaximumMarks" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_MaximumMarks} /></td>
       
                                                   <td><Input id="MPhil_Allied1_Percentage" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_Percentage} /></td>
       
                                                   <td><Input id="MPhil_Allied1_ClassObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_ClassObtained} /></td>
       
                                                   <td><Input id="MPhil_Allied1_NoOfAttempts" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_NoOfAttempts} /></td>
       
                                                   <td><Input id="MPhil_Allied1_RegisterNo" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_RegisterNo} /></td>
       
                                                   <td><Input id="MPhil_Allied1_Passing" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied1_Passing} /></td>
                                               </tr>
                                               <tr>
                                                   <td ><Input id="MPhil_Allied2_SubjectName" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_SubjectName} /></td>
       
                                                   <td><Input id="MPhil_Allied2_MarksObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_MarksObtained} /></td>
       
                                                   <td><Input id="MPhil_Allied2_MaximumMarks" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_MaximumMarks} /></td>
       
                                                   <td><Input id="MPhil_Allied2_Percentage" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_Percentage} /></td>
       
                                                   <td><Input id="MPhil_Allied2_ClassObtained" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_ClassObtained} /></td>
       
                                                   <td><Input id="MPhil_Allied2_NoOfAttempts" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_NoOfAttempts} /></td>
       
                                                   <td><Input id="MPhil_Allied2_RegisterNo" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_RegisterNo} /></td>
       
                                                   <td><Input id="MPhil_Allied2_Passing" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Allied2_Passing} /></td>
                                               </tr>
                                               <tr>
                                                   <td>Percentage</td>
                                                   <td>Main/Core</td>
                                                   <td><Input id="MPhil_Overall_Main_Core" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Overall_Main_Core} /></td>
                                                   <td>Ancy./Allied</td>
                                                   <td><Input id="MPhil_Overall_Ancy_Allied" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Overall_Ancy_Allied} /></td>
                                                   <td>Part - III</td>
                                                   <td><Input id="MPhil_Overall_Part3" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.MPhil_Overall_Part3} /></td>
                                                   <td><Input id="XII_OverAll_markSecured" type="text" style={{ width: '10' }}
                                                       onChange={markInfoForm.handleChange}
                                                       value={markInfoForm.values.XII_OverAll_markSecured} disabled/></td>
                                               </tr>
                                           </tbody>
                                           </table>
                                       </div> */}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 5 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={certificateInfoForm.handleSubmit}>
                                    <CardBody>
                                        <div className="d-grid gap-4">
                                            <div className="row  align-self-center">
                                                <div className="col-lg-4">
                                                    <CardLabel icon="FileCopy" color="info">
                                                        <CardTitle className="h5">Original Certificate</CardTitle>
                                                    </CardLabel>
                                                </div>
                                                <div className="col-lg-3">
                                                    <CardActions>
                                                        <Button size='sm' color='primary' icon='Add' isLight onClick={() => { setOriginalCertificate(true) }} >
                                                            Add New
                                                        </Button>
                                                    </CardActions>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-lg-8">
                                                    <div className="row mb-3 g-4">
                                                        <ChecksGroup className="row fs-5 " id="certificateTypeId">
                                                            {certificateTypeData.map((i: any) => (
                                                                <div className='col-6' key={i.certificateTypeId}>
                                                                    <Checks className="fs-5 mt-2" label={i.certificateName} checked={i.isChecked} onChange={(e) => selectOriginalCertificateType(e, i.certificateTypeId)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </ChecksGroup>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <FormGroup id="submittedDate" label="Date" isFloating>
                                                                <Input type="date"
                                                                    onChange={certificateInfoForm.handleChange}
                                                                    value={certificateInfoForm.values.submittedDate} />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <FormGroup id="status" label="Status" isFloating>
                                                                <Select ariaLabel="" placeholder="Select Status"
                                                                    onChange={certificateInfoForm.handleChange}
                                                                    value={certificateInfoForm.values.status}
                                                                > <option value='IN'>IN</option>
                                                                    <option value='DUE'>DUE</option>
                                                                </Select>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row  align-self-center">
                                                <div className="col-lg-4">
                                                    <CardLabel icon="Equalizer" color="info">
                                                        <CardTitle className="h5">Scholar Ship</CardTitle>
                                                    </CardLabel>

                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <div className="row mb-3 g-4">
                                                        <ChecksGroup className="row fs-5 " id="scholarshipTypeId">
                                                            {scholarshipTypeData.map((i: any) => (
                                                                <div className='col-6' key={i.scholarshipTypeId}>
                                                                    <Checks className="fs-5 mt-2" key={i.scholarshipTypeId} label={i.scholarshipType}
                                                                        checked={i.isChecked} onChange={(e) => selectScholarship(e, i.scholarshipTypeId)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </ChecksGroup>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            <Button color="primary" type="submit" isLight>Next</Button>
                                        </div>
                                    </CardBody>
                                </form>
                            </Card>
                        )}
                        {step == 6 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={admissionInfoForm.handleSubmit}>
                                    <CardBody>

                                        <div className="d-grid gap-5">
                                            <div className="row g-4">
                                                <div className="col-lg-3">
                                                    <FormGroup id="quotaTypeId" label="Quota Type" isFloating>
                                                        <SearchableSelect isFloating placeholder="Select Quota Type"
                                                            ariaLabel='Quota Type'
                                                            onChange={selectQuota}
                                                            value={quotaTypeId}
                                                            list={quotaData.map((data: any) => (
                                                                { value: data.quotaTypeId, label: data.quotaType }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                {isQuotaTypeDetails == 'isGovernment' ?
                                                    <div className="col-lg-3">
                                                        <FormGroup id="discription" label="Quota Type Details" isFloating>
                                                            <SearchableSelect isFloating placeholder="Select Quota Type"
                                                                ariaLabel='Quota Type'
                                                                onChange={selectQuotaTypeDetails}
                                                                value={quotaTypeDetails}
                                                                list={quotaDetailsData.map((data: any) => (
                                                                    { value: data.discription, label: data.discription }
                                                                ))}
                                                            />
                                                        </FormGroup>
                                                    </div> : null}
                                                {isQuotaTypeDetails == 'isManagement' ?
                                                    <div className="col-lg-6">
                                                        <FormGroup id="discription" label="discription" isFloating>
                                                            <Input type="text" placeholder="Enter discription"
                                                                onChange={admissionInfoForm.handleChange}
                                                                value={discription}
                                                                onInput={(e: any) => setDiscription(e)} />
                                                        </FormGroup>

                                                    </div> : null}

                                            </div>
                                            <div className="row g-4">
                                                <div className="col-lg-4">Are you a citizen of India ?</div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="isCitizenOfIndia">
                                                        <Checks
                                                            type='radio'
                                                            label='Yes'
                                                            value={'YES'}
                                                            onChange={admissionInfoForm.handleChange}
                                                            checked={admissionInfoForm.values.isCitizenOfIndia}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="isCitizenOfIndia">
                                                        <Checks
                                                            type='radio'
                                                            label='No'
                                                            value={'NO'}
                                                            onChange={admissionInfoForm.handleChange}
                                                            checked={admissionInfoForm.values.isCitizenOfIndia}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-lg-4">Are you claiming NRI quota Seat ?</div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="isClaimingNriQuotaSeat">
                                                        <Checks
                                                            type='radio'
                                                            label='Yes'
                                                            value={'YES'}
                                                            onChange={admissionInfoForm.handleChange}
                                                            checked={admissionInfoForm.values.isClaimingNriQuotaSeat}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="isClaimingNriQuotaSeat">
                                                        <Checks
                                                            type='radio'
                                                            label='No'
                                                            value={'NO'}
                                                            onChange={admissionInfoForm.handleChange}
                                                            checked={admissionInfoForm.values.isClaimingNriQuotaSeat}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-lg-4">Is Medical Certificate enclosed ?</div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="isMedicalCertificateEnclosed">
                                                        <Checks
                                                            type='radio'
                                                            label='Yes'
                                                            value={'YES'}
                                                            onChange={admissionInfoForm.handleChange}
                                                            checked={admissionInfoForm.values.isMedicalCertificateEnclosed}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-3">
                                                    <FormGroup id="isMedicalCertificateEnclosed">
                                                        <Checks
                                                            type='radio'
                                                            label='No'
                                                            value={'NO'}
                                                            onChange={admissionInfoForm.handleChange}
                                                            checked={admissionInfoForm.values.isMedicalCertificateEnclosed}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <FormGroup id="extraCurricularActivities" label="Any extra curricular activities" isFloating>
                                                    <Textarea placeholder="Any extra curricular activities"
                                                        onChange={admissionInfoForm.handleChange}
                                                        value={admissionInfoForm.values.extraCurricularActivities} />
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                            <Button color="info" type="submit" onClick={addStudentSubmit} >Submit</Button>
                                        </div>
                                    </CardBody>
                                </form>
                            </Card>
                        )}
                    </CardBody>
                </Card>
            </Page>

            <OffCanvas setOpen={setCourseCapacityCanvas} isOpen={courseCapacityCanvas} titleId='courseCapacityCanvas' placement='end' isBackdrop tag="form" noValidate onSubmit={courseCapacityEditForm.handleSubmit} isNotClose>
                <OffCanvasHeader>
                    <OffCanvasTitle id='courseCapacityCanvas'>Update Course Capacity</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">

                        <div className='col-12'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-info bg-l${darkModeStatus ? 'o50' : '10'
                                }-info-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0 ms-2'>
                                            <Icon icon='Assignment' size='4x' color='info' />
                                        </div>
                                        <div className="row">
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseCapacityData.batchName}
                                                </div>
                                                <div className='text-muted'>
                                                    Batch
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseCapacityData.courseName}
                                                </div>
                                                <div className='text-muted'>
                                                    Course
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='col-12'>
                            <FormGroup id='govQuota' label='Gov Quota'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={courseCapacityEditForm.values.govQuota} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.govQuota} invalidFeedback={courseCapacityEditForm.errors.govQuota} type='text' placeholder="Gov Quota" required
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='managementQuota' label='Management Quota'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={courseCapacityEditForm.values.managementQuota} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.managementQuota} invalidFeedback={courseCapacityEditForm.errors.managementQuota} type='text' placeholder="Management Quota" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='totalAllocated' label='Total Allocated'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={Number(courseCapacityEditForm.values.govQuota) + Number(courseCapacityEditForm.values.managementQuota)} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.totalAllocated} invalidFeedback={courseCapacityEditForm.errors.totalAllocated} type='text' placeholder="Total Allocated" required onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!courseCapacityEditForm.isValid && !!courseCapacityEditForm.submitCount}> Update</Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas setOpen={setOriginalCertificate} isOpen={originalCertificate} titleId='originalCertificate' isBodyScroll placement='end' tag="form" noValidate onSubmit={originalCertificateForm.handleSubmit}>
                <OffCanvasHeader setOpen={setOriginalCertificate} onClick={closeAndReset}>
                    <OffCanvasTitle id='originalCertificate'>ADD ORIGINAL CERTIFICATE</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className="col-12">
                            <FormGroup id="certificateName" label="Certificate Name" >
                                <Input placeholder="Enter Certificate Name"
                                    onChange={originalCertificateForm.handleChange}
                                    value={originalCertificateForm.values.certificateName} isValid={originalCertificateForm.isValid} onBlur={originalCertificateForm.handleBlur} isTouched={originalCertificateForm.touched.certificateName} invalidFeedback={originalCertificateForm.errors.certificateName} type='text' required />
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <FormGroup id="userTypeId" label="Select User Type" >
                                <SearchableSelect placeholder="Select User Type"
                                    ariaLabel='User Type' multiple
                                    onChange={(e: any) => selectUserTypeIdForCertificate(e)}
                                    value={userTypeIdForCertificate}
                                    list={userListByCertificateData.map((data: any) => (
                                        { value: data.userTypeId, label: data.userType }
                                    ))}
                                    isValid={originalCertificateForm.isValid} onBlur={originalCertificateForm.handleBlur} isTouched={originalCertificateForm.touched.userTypeIdForCertificate} invalidFeedback={originalCertificateForm.errors.userTypeIdForCertificate}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-12">
                            <ButtonGroup>
                                <Checks onChange={isOriginalOrDuplicate} checked={isOriginal} />
                                <h6 className="mt-1"> isOriginal</h6>
                            </ButtonGroup>

                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!originalCertificateForm.isValid && !!originalCertificateForm.submitCount}> Save</Button>
                    </div>
                </div>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
        </PageWrapper>
    )
}
export default AddStudentWizard;