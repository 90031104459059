import React, { useContext, useEffect, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';
import Search from '../../../components/Search';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	const { darkModeStatus } = useDarkMode();
	const { userTypeId, userAccountId, userData } = useContext(AuthContext);

	return (

		<Header>
			{/* <HeaderLeft>
				<Button color='primary' isLink></Button>
				<Icon icon='ArrowForwardIos' className='mb-0 text-dark fw-bold' />
				<Button color='primary' isLink>
					B.SC Chemistry
				</Button>
				<Icon icon='ArrowForwardIos' className='mb-0 text-dark fw-bold' />
				<Button color='primary' isLink>
					2023-2023
				</Button>
				<Icon icon='ArrowForwardIos' className='mb-0 text-dark fw-bold' />
				<Button color='primary' isLink>
					Semester 2
				</Button>
				<Icon icon='ArrowForwardIos' className='mb-0 text-dark fw-bold' />
				<Button color='primary' isLink>
					Section A
				</Button>
			</HeaderLeft> */}
			<HeaderLeft>
				{userTypeId != 10 ? <Search /> :
					<div className='d-flex align-items-center'>
						<div className='row g-4'>
							<div className='col-md-auto'>
								<div
									className={classNames('fs-3', 'fw-bold', {
										'text-dark': !darkModeStatus,
									})}>
									Hi, {userData.studentName}!
								</div>
							</div>
						</div>
					</div>
				}

			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
