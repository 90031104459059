import React, { useContext, useEffect, useRef, useState } from 'react'
import useDarkMode from '../../../hooks/useDarkMode'
import useSortableData from '../../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../../PaginationButtons'
import { useFormik } from 'formik'
import Button, { ButtonGroup } from '../../bootstrap/Button'
import { toasts } from '../../../services/toast.service'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import Collapse from '../../bootstrap/Collapse'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Select from '../../bootstrap/forms/Select'
import Icon from '../../icon/Icon'
import Input from '../../bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas'
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown'
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks'
import { useReactToPrint } from 'react-to-print'
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService'
import AlertService from '../../../services/AlertService'
import DeleteComponents from '../../../common/components/DeleteComponents'
import OpenCardComponent from '../../../common/components/OpenCardComponent'
import { addCourseFee, addFeeType, deleteCourseFee, getCourseFees, getFeeType, updateCourseFee, updateFeeType } from '../../../services/fee.service'
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from '../../../services/master.service'
import { getColumnsForDataTable, getGraduationType, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from '../../../services/common.service'
import { getLicenseKey } from '../../../services/application.settings'
import AuthContext from '../../../contexts/authContext'
import Alert, { AlertHeading } from '../../bootstrap/Alert'
import { showLoader } from '../../../services/loader.services'
import NoDataMsg from '../../../common/components/NoDataMsg'
import SearchableSelect from '../../../common/components/SearchableSelect'

const FeeMaster = () => {

    useEffect(() => {
        getDepartment();
        getFeeTypeList();
        getGraduationList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')

    const [courseFeeData, setCourseFeeData] = useState<any>([])
    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [feeTypeDetailsData, setFeeTypeDetailsData] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [feesTypeData, setFeesTypeData] = useState([])

    const [editFeeMasterOffcanvas, setEditFeeMasterOffcanvas] = useState(false);
    const [addFeeMasterOffcanvas, setAddFeeMasterOffcanvas] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(courseFeeData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [feeTypeId, setFeeTypeId] = useState<any>(0)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const feeMasterForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            semesterDetailsId: '',
            feeTypeId: '',
            feeAmount: '',
            dueDate: '',
        },
        validate: (values) => {
            const errors: {
                semesterDetailsId?: string;
                feeTypeId?: string;
                feeAmount?: string;
                dueDate?: string;
            } = {};
            if (!semesterDetailsId) {
                errors.semesterDetailsId = 'Required'
            }
            if (addFeeTypeForm.values.feeType == "") {
                if (!feeTypeId) {
                    errors.feeTypeId = 'Required'
                }
            }
            if (!values.feeAmount) {
                errors.feeAmount = "Required"
            }
            if (!values.dueDate) {
                errors.dueDate = "Required"
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: (values) => { feeMasterSubmit() },
    });

    const feeMasterEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseFeeMasterId: '',
            semesterDetailsId: '',
            feeAmount: '',
            feeTypeId: '',
            dueDate: '',
        },
        validate: (values) => {
            const errors: {
                feeTypeId?: string;
                feeAmount?: string;
                dueDate?: string;
            } = {};
            if (!feeTypeId) {
                errors.feeTypeId = 'Required'
            }
            if (!values.feeAmount) {
                errors.feeAmount = "Required"
            }
            if (!values.dueDate) {
                errors.dueDate = "Required"
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { updateCourseFeeSubmit() },
    });

    const addFeeTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            feeType: '',
        },
        validate: (values) => {
            const errors: {
                feeType?: string;
            } = {};
            if (!values.feeType) {
                errors.feeType = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedCourseFees = i
            setEditFeeMasterOffcanvas(true)
            setFeeTypeId(selectedCourseFees.feeTypeId)
            setSemesterDetailsId(selectedCourseFees.semesterDetailsId)
            feeMasterEditForm.setValues({
                courseFeeMasterId: selectedCourseFees.courseFeeMasterId,
                feeAmount: selectedCourseFees.feeAmount,
                feeTypeId: selectedCourseFees.feeTypeId,
                dueDate: selectedCourseFees.dueDate,
                semesterDetailsId: selectedCourseFees.semesterDetailsId,
            })
            getSemesterList(selectedCourseFees.batchMasterId)
        }
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseData([])
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setBatchMasterData([])
        setSemesterDetailsData([])
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setBatchMasterData([])
        setSemesterDetailsData([])
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    function viewCourseFeesList() {
        setDataSuccess(false)
        setIsLoader(true);
        setCourseFeeData([]);
        setDataStatus(false);
        columnVisibilityForm.resetForm()
        getFeeMasterList(courseId?.value, batchMasterId?.value)
    }

    function getFeeMasterList(courseId: number, batchMasterId: number) {
        getCourseFees(courseId, batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseFees;
                    if (data != undefined) {
                        getColumnsForTable('getCourseFees', 'get');
                        setCourseFeeData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                    getColumnsForTable('getCourseFees', 'get');
                    setCourseFeeData([]);
                    setNoDataMsg(response.data.message)

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(false);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: any) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getCourseFees', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function setFeeMasterDetails() {
        return ({
            courseId: courseId?.value,
            batchMasterId: batchMasterId?.value,
            semesterDetailsId: semesterDetailsId?.value,
            feeAmount: feeMasterForm.values.feeAmount,
            feeTypeId: feeTypeId?.value,
            dueDate: feeMasterForm.values.dueDate,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditFeeMasterDetails() {
        return ({
            courseFeeMasterId: feeMasterEditForm.values.courseFeeMasterId,
            feeAmount: feeMasterEditForm.values.feeAmount,
            feeTypeId: feeMasterEditForm.values.feeTypeId,
            dueDate: feeMasterEditForm.values.dueDate,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            isActive: 1
        })
    }

    function setAddFeesDetails() {
        return ({
            licenseKey: getLicenseKey,
            feeType: addFeeTypeForm.values.feeType
        })
    }

    function feeMasterSubmit() {
        showLoader(true);
        if (addFeeTypeForm.values.feeType == "") {
            if (feeMasterForm.isValid) {
                let feeMasterPostData = setFeeMasterDetails();
                addCourseFee(feeMasterPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            viewCourseFeesList();
                            closeAndReset();

                        }
                        else if (data.success == false) {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false);
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false);
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (feeMasterForm.isValid == false) {
                showLoader(false);
                setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                setIsOpen(true);
            }
        } else {
            if (addFeeTypeForm.isValid) {
                let feeTypePostData = setAddFeesDetails();
                addFeeType(feeTypePostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            //showLoader(false);
                            setFeeTypeId(data.data.feeTypeId)
                            let postData = {
                                courseId: courseId?.value,
                                batchMasterId: batchMasterId?.value,
                                semesterDetailsId: semesterDetailsId?.value,
                                feeAmount: feeMasterForm.values.feeAmount,
                                feeTypeId: data.data.feeTypeId,
                                dueDate: feeMasterForm.values.dueDate,
                                licenseKey: getLicenseKey,
                                userAccountId: userAccountId
                            }
                            feeMasterSubmit1(postData)
                            getFeeTypeList();

                        }
                        else if (data.success == false) {
                            setFeeTypeId(data.data.feeTypeId)
                            let postData = {
                                courseId: courseId?.value,
                                batchMasterId: batchMasterId?.value,
                                semesterDetailsId: semesterDetailsId?.value,
                                feeAmount: feeMasterForm.values.feeAmount,
                                feeTypeId: data.data.feeTypeId,
                                dueDate: feeMasterForm.values.dueDate,
                                licenseKey: getLicenseKey,
                                userAccountId: userAccountId
                            }
                            feeMasterSubmit1(postData)
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false);
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false);
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (addFeeTypeForm.isValid == false) {
                //showLoader(true)
                // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                // setIsOpen(true);
            }

        }
    }

    function feeMasterSubmit1(postData: any) {
        // showLoader(true);
        if (feeMasterForm.isValid) {
            let feeMasterPostData = postData;
            addCourseFee(feeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewCourseFeesList();
                        closeAndReset();

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (feeMasterForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateCourseFeeSubmit() {
        showLoader(true);
        if (feeMasterEditForm.isValid) {
            let editFeeMasterPostData = setEditFeeMasterDetails();
            updateCourseFee(editFeeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewCourseFeesList();
                        closeAndReset();

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (feeMasterEditForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteCourseFeeSubmit() {
        showLoader(true);
        // if (feeMasterEditForm.isValid) {
        let deletePostData = setEditFeeMasterDetails();
        deleteCourseFee(deletePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    viewCourseFeesList();
                    closeAndReset();

                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false);
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (feeMasterEditForm.isValid == false) {
        //     setIsLoader(false);
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getFeeTypeList() {
        getFeeType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.feeType;
                    if (data != undefined) {
                        setFeeTypeDetailsData(data);
                        setFeesTypeData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        setEditFeeMasterOffcanvas(false)
        feeMasterForm.resetForm();
        feeMasterEditForm.resetForm();
        addFeeTypeForm.resetForm();
        setIsDeleteOpen(false);
        setFeeTypeId(0);
        setIsVisible(true);
        setSemesterDetailsId('')
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
        setFeeTypeId(0);
        addFeeTypeForm.resetForm();
    };

    return (
        <PageWrapper title='Fee Master'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Fee Master
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='departmentId' label='Department' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Department'
                                            onChange={selectDepartment}
                                            value={departmentId}
                                            list={departmentData.map((data: any) => (
                                                { value: data.departmentId, label: data.departmentName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {departmentId?.value ? <div className='col-2'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, label: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                    : null}

                                {graduationTypeId?.value ? <div className='col-3'>
                                    <FormGroup id='courseId' label='Course' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Course'
                                            onChange={selectCourse}
                                            value={courseId}
                                            list={courseData.map((data: any) => (
                                                { value: data.courseId, label: data.courseName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {courseId?.value ? <div className='col-2'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Batch'
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, label: data.batchName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {batchMasterId?.value ? <div className="col-2 mt-4 d-flex align-self-center" >
                                    <Button icon='ArrowDownward' color='primary' onClick={viewCourseFeesList}>View</Button>
                                </div> : null}

                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ? <>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Course Fees</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getCourseFees', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId}
                                                                        id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Course Fee List", courseFeeData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(courseFeeData, columnVisibilityData, "Course Fee List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Course Fee List", courseFeeData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddFeeMasterOffcanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.courseFeeMasterId}>
                                                    <td width={20}>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.studentTransferCertificateId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                    <td className="d-print-none" >
                                                        <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                </tr>
                                            ))
                                            } </>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card></>
                    : null
                }

                <OffCanvas
                    setOpen={setAddFeeMasterOffcanvas}
                    isOpen={addFeeMasterOffcanvas}
                    titleId='addFeeMasterOffcanvas'
                    isBodyScroll
                    placement='end' tag='form' noValidate onSubmit={feeMasterForm.handleSubmit} isNotClose>
                    {/* <form noValidate onSubmit={feeMasterForm.handleSubmit}> */}
                    <OffCanvasHeader setOpen={setAddFeeMasterOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='addFeeMasterOffcanvas'>Add Course Fees</OffCanvasTitle>
                    </OffCanvasHeader>

                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='semesterDetailsId' label='Semester'>
                                    <SearchableSelect placeholder='Select Semester' ariaLabel='Semester' onChange={selectSemester}
                                        value={semesterDetailsId}
                                        list={semesterDetailsData.map((data: any) => (
                                            { value: data.semesterDetailsId, label: data.semesterNumber }
                                        ))}
                                        onBlur={feeMasterForm.handleBlur}
                                        isValid={feeMasterForm.isValid}
                                        isTouched={feeMasterForm.touched.semesterDetailsId}
                                        invalidFeedback={feeMasterForm.errors.semesterDetailsId}
                                    />
                                </FormGroup>
                            </div>

                            {isVisible ? <div className='col-10'>
                                <FormGroup id='feeTypeId' label='Fees Type'>
                                    <SearchableSelect
                                        placeholder='Select Fee Type'
                                        ariaLabel='Fees Type'
                                        onChange={(e: any) => { setFeeTypeId(e) }}
                                        value={feeTypeId}
                                        onBlur={feeMasterForm.handleBlur}
                                        isValid={feeMasterForm.isValid}
                                        isTouched={feeMasterForm.touched.feeTypeId}
                                        invalidFeedback={feeMasterForm.errors.feeTypeId}
                                        list={feeTypeDetailsData.map((data: any) => (
                                            { value: data.feeTypeId, label: data.feeType }
                                        ))}

                                    />
                                </FormGroup>
                            </div> : <div className='col-10'>
                                <FormGroup id='feeType' label="Fees Type">
                                    <Input placeholder='Enter Fee Type'
                                        onChange={addFeeTypeForm.handleChange} value={addFeeTypeForm.values.feeType}
                                        isValid={addFeeTypeForm.isValid}
                                        onBlur={addFeeTypeForm.handleBlur}
                                        isTouched={addFeeTypeForm.touched.feeType}
                                        invalidFeedback={addFeeTypeForm.errors.feeType}
                                        type="text"
                                        required
                                    />
                                </FormGroup>
                            </div>}
                            <div className='col-1 align-middle'>
                                <FormGroup id='' label={"      "} >
                                    <Button className='mt-2'
                                        color='primary' icon='Add' isLight
                                        hoverShadow='lg'
                                        onClick={toggleVisibility}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='feeAmount' label='Fee Amount' >
                                    <Input placeholder='Fee Amount'
                                        onChange={feeMasterForm.handleChange}
                                        value={feeMasterForm.values.feeAmount}
                                        onBlur={feeMasterForm.handleBlur}
                                        isValid={feeMasterForm.isValid}
                                        isTouched={feeMasterForm.touched.feeAmount}
                                        invalidFeedback={feeMasterForm.errors.feeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='dueDate' label='Due Date'>
                                    <Input type='date' placeholder='DueDate'
                                        onChange={feeMasterForm.handleChange}
                                        value={feeMasterForm.values.dueDate}
                                        onBlur={feeMasterForm.handleBlur}
                                        isValid={feeMasterForm.isValid}
                                        isTouched={feeMasterForm.touched.dueDate}
                                        invalidFeedback={feeMasterForm.errors.dueDate} />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button
                                color='info'
                                className='w-100' type="submit" isDisable={!feeMasterForm.isValid && !!feeMasterForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                    {/* </form> */}
                </OffCanvas>

                <OffCanvas
                    setOpen={setEditFeeMasterOffcanvas}
                    isOpen={editFeeMasterOffcanvas}
                    titleId='editFeeMasterOffcanvas'
                    isBodyScroll
                    placement='end' tag='form' noValidate onSubmit={feeMasterEditForm.handleSubmit}>
                    {/* <form noValidate onSubmit={feeMasterEditForm.handleSubmit}> */}
                    <OffCanvasHeader setOpen={setEditFeeMasterOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='editFeeMasterOffcanvas'>Update Course Fees</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='semesterDetailsId' label='Semester'>
                                    <Select
                                        placeholder='Select Semester'
                                        ariaLabel='Semester'
                                        onChange={selectSemester}
                                        value={semesterDetailsId}
                                        onBlur={feeMasterEditForm.handleBlur}
                                        isValid={feeMasterEditForm.isValid}
                                        isTouched={feeMasterEditForm.touched.semesterDetailsId}
                                        invalidFeedback={feeMasterEditForm.errors.semesterDetailsId}
                                        list={semesterDetailsData.map((data: any) => (
                                            { value: data.semesterDetailsId, text: data.semesterNumber }
                                        ))} disabled
                                    />

                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='feeTypeId' label='Fees Type'>
                                    <Select
                                        placeholder='Select Fee Type'
                                        ariaLabel='Fees Type'
                                        onChange={feeMasterEditForm.handleChange}
                                        value={feeTypeId}
                                        onBlur={feeMasterEditForm.handleBlur}
                                        isValid={feeMasterEditForm.isValid}
                                        isTouched={feeMasterEditForm.touched.feeTypeId}
                                        invalidFeedback={feeMasterEditForm.errors.feeTypeId}
                                        list={feeTypeDetailsData.map((data: any) => (
                                            { value: data.feeTypeId, text: data.feeType }
                                        ))} disabled
                                    />

                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='feeAmount' label='Fee Amount' >
                                    <Input placeholder='Fee Amount'
                                        onChange={feeMasterEditForm.handleChange}
                                        value={feeMasterEditForm.values.feeAmount}
                                        onBlur={feeMasterEditForm.handleBlur}
                                        isValid={feeMasterEditForm.isValid}
                                        isTouched={feeMasterEditForm.touched.feeAmount}
                                        invalidFeedback={feeMasterEditForm.errors.feeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='dueDate' label='Due Date'>
                                    <Input type='date' placeholder='DueDate'
                                        onChange={feeMasterEditForm.handleChange}
                                        value={feeMasterEditForm.values.dueDate}
                                        onBlur={feeMasterEditForm.handleBlur}
                                        isValid={feeMasterEditForm.isValid}
                                        isTouched={feeMasterEditForm.touched.dueDate}
                                        invalidFeedback={feeMasterEditForm.errors.dueDate} />
                                </FormGroup>
                            </div>
                            <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteCourseFeeSubmit} />
                            </div>
                        </div>
                    </OffCanvasBody>

                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            <div className='col-6 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                            <div className='col-6 p-3'>
                                <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!feeMasterEditForm.isValid && !!feeMasterEditForm.submitCount}> Update</Button>
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                </OffCanvas>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
}

export default FeeMaster