import { useContext, useEffect, useRef, useState } from "react";
import Page from "../../../layout/Page/Page";
import Collapse from "../../bootstrap/Collapse";
import Icon from "../../icon/Icon";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Select from "../../bootstrap/forms/Select";
import AlertService from "../../../services/AlertService";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import classNames from "classnames";
import { getFirstLetter } from "../../../helpers/helpers";
import { useReactToPrint } from "react-to-print";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getGraduationType, getSectionDetails, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import { getPeriodListByShiftId } from "../../../services/timeTable.service";
import { addStudentAttendanceForWeb, checkIsWorkingDay, getStudentAttendanceReport, getStudentAttendanceSummaryReport } from "../../../services/attendance.service";
import { getStudentDetailsBySemesterId } from "../../../services/student.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { showLoader } from "../../../services/loader.services";
import SearchableSelect from "../../../common/components/SearchableSelect";
import NoDataMsg from "../../../common/components/NoDataMsg";

const StudentAttendence = () => {

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
        getPeriod(1);
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [periodDetailsId, setPeriodDetailsId] = useState<any>('')

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [workingDayData, setWorkingDayData] = useState<any>([])
    const [periodData, setPeriodData] = useState<any>([])

    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [studentDetails, setStudentDetails] = useState<any>([])
    const [attendanceConfirmationArray, setAttendanceConfirmationArray] = useState<any>([])

    // For Table
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentAttendanceSummaryData, setStudentAttendanceSummaryData] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    const [columnDataById, setColumnDataById] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isWorkingDay, setIsWorkingDay] = useState(false)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isReportOrAdd, setIsReportOrAdd] = useState('')
    const componentRef = useRef(null);
    const [isLoader, setIsLoader] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const studentAttendanceForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            dateOfAttendance: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                dateOfAttendance?: string;
            } = {};

            if (!values.dateOfAttendance) {
                errors.dateOfAttendance = "Required"
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { selectAttendanceDate() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(studentDetailsData, columnVisibilityData, columnVisibilityForm);

    const filteredData1 = onCurrentPageData.filter(
        (i) =>
            // Name
            i.admissionNo !== null && i.admissionNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.uniRegNo !== null && i.uniRegNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName !== null && i.studentName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.semesterNumber !== null && i.semesterNumber.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.sectionName !== null && i.sectionName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.staffName !== null && i.staffName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.paperName !== null && i.paperName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.unit !== null && i.unit.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.noOfPeriod !== null && i.noOfPeriod.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())
    );

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setPeriodDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setPeriodDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setPeriodDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setPeriodDetailsId('')
        let batchMasterId = e;
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setPeriodDetailsId('')
        let semesterDetailsId = e;
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setPeriodDetailsId('')
        let sectionDetailsId = e;
        setSectionDetailsId(sectionDetailsId)
    }

    const selectPeriod = (e: any) => {
        let periodDetailsId = e;
        setPeriodDetailsId(periodDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false)
        setIsLoader(true);
        setFilteredDataToRefresh([]);
        setStudentDetailsData([]);
        setStudentDetails([])
        setAttendanceConfirmationArray([]);
        getStudentAttendanceList(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    function viewStudentAttendanceReport() {
        setDataSuccess(false)
        setIsLoader(true);
        setFilteredDataToRefresh([]);
        setStudentDetailsData([]);
        setStudentDetails([])
        setAttendanceConfirmationArray([]);
        setStudentAttendanceSummaryData([])
        let dateOfAttendance = studentAttendanceForm.values.dateOfAttendance
        let dateInMilliSec = convertDateToEpoch(dateOfAttendance)

        if (dateInMilliSec != convertDateToEpoch(getCurrentDateAndTime('date'))) {
            setIsLoader(false);
            setAlertStatus({ message: 'Please Select Today Date.', type: "error" })
            setIsOpen(true)
        } else {
            getStudentAttendanceSummaryReportList(dateInMilliSec, courseId?.value, batchMasterId?.value, semesterDetailsId?.value, sectionDetailsId?.value, 1, periodDetailsId?.value)

        }
    }

    function selectAttendanceDate() {
        let dateOfAttendance = studentAttendanceForm.values.dateOfAttendance

        // check is working day
        if (dateOfAttendance != null) {
            let dateInMilliSec = convertDateToEpoch(dateOfAttendance)
            checkIsWorkingDayAttendance(dateInMilliSec);
        }
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPeriod(shiftId: number) {
        getPeriodListByShiftId(shiftId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.period;
                    if (data != undefined) {
                        setPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function checkIsWorkingDayAttendance(dateInMilliSec: number) {
        checkIsWorkingDay(dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.checkIsWorkingDay;
                    if (data != undefined) {
                        setWorkingDayData(data);
                        setIsWorkingDay(true);
                    } else {
                        setAlertStatus({ message: "Undefined Data", type: "error" })
                        setIsOpen(true)
                        setIsWorkingDay(false);
                    }
                }
                else if (response.data.success === false) {
                    setAlertStatus({ message: response.data.message, type: "error" })
                    setIsOpen(true)
                    setIsWorkingDay(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" })
                    setIsOpen(true)
                    setIsWorkingDay(false);
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentAttendanceList(courseId: any, semesterDetailsId: any, sectionDetailsId: any) {
        setIsLoader(true);
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setIsOpenListCard(false);
                        setFilteredDataToRefresh(data)
                        setDataSuccess(true);
                        setIsReportOrAdd("add");
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].isEditable = data[i].isEditable = true;
                            data[i].isSelect = data[i].isSelect = false;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    // toasts(response.data.message, "Error")
                    setIsReportOrAdd("add");
                    setStudentDetailsData([])
                    setDataSuccess(true);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(true);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getStudentAttendanceReportList(dateOfAttendance: number, courseId: any, semesterDetailsId: any, sectionDetailsId: any, periodDetailsId: string) {
        setIsLoader(true);
        getStudentAttendanceReport(dateOfAttendance, courseId, semesterDetailsId, sectionDetailsId, periodDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentAttendanceReportList;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setIsOpenListCard(false);
                        setFilteredDataToRefresh(data)
                        setDataSuccess(true);
                        setIsReportOrAdd("report");
                        getColumnsForTable('getStudentAttendanceReportList', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setIsReportOrAdd("report");
                    getColumnsForTable('getStudentAttendanceReportList', 'get');
                    setStudentDetailsData([])
                    setNoDataMsg(response.data.message)
                    // toasts(response.data.message, "Error")
                    setDataSuccess(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(false);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getStudentAttendanceSummaryReportList(dateOfAttendance: number, courseId: any, batchMasterId: any, semesterDetailsId: any, sectionDetailsId: any, shift: number, periodDetailsId: string) {
        setIsLoader(true);
        getStudentAttendanceSummaryReport(dateOfAttendance, courseId, batchMasterId, semesterDetailsId, sectionDetailsId, shift, periodDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentAttendanceSummaryReport[0];
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentAttendanceSummaryData(data);
                        if (data.totalAbsent == 0) {
                            setAlertStatus({ message: 'All Present!', type: "success" })
                            setIsOpen(true)
                            setDataSuccess(false);
                        } else {
                            getStudentAttendanceReportList(dateOfAttendance, courseId, semesterDetailsId, sectionDetailsId, periodDetailsId)
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    viewStudentList()
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(false);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    const updateStudentDetailsIdValue = (e: any, cell: number, admissionNo: any, uniRegNo: any, studentName: any) => {
        const newData: any = [];
        if (e.target.checked == true) {
            filteredDataToRefresh.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    item['isSelect'] = true
                    item['isEditable'] = false
                }
                newData.push(item)
            });
            setFilteredDataToRefresh(newData)

        } else if (e.target.checked == false) {

            filteredDataToRefresh.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    item['isSelect'] = false
                    item['isEditable'] = true
                    item['remarks'] = ''
                }
                newData.push(item)
            });

            setFilteredDataToRefresh(newData)
        }

        if (attendanceConfirmationArray.length == 0) {
            const obj = {
                studentDetailsId: cell,
                admissionNo: admissionNo,
                uniRegNo: uniRegNo,
                studentName: studentName
            };
            attendanceConfirmationArray.push(obj);
        } else {
            let selectedItem1: any;
            attendanceConfirmationArray.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    selectedItem1 = item
                    selectedItem1['studentDetailsId'] = cell
                }
            });
            if (selectedItem1 != undefined) {
                attendanceConfirmationArray.splice(attendanceConfirmationArray.indexOf(selectedItem1), 1);
                selectedItem1['studentDetailsId'] = cell
            } else {
                var obj
                obj = {
                    studentDetailsId: cell,
                    admissionNo: admissionNo,
                    uniRegNo: uniRegNo,
                    studentName: studentName
                };

                attendanceConfirmationArray.push(obj);
            }
        }

        if (studentDetails.length == 0) {
            const obj = {
                studentDetailsId: cell,
                absentOrPermission: "Absent",
                remarks: ""
            };
            studentDetails.push(obj);
        } else {
            let selectedItem: any;
            studentDetails.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['studentDetailsId'] = cell
                }
            });
            if (selectedItem != undefined) {
                studentDetails.splice(studentDetails.indexOf(selectedItem), 1);
                selectedItem['studentDetailsId'] = cell
            } else {
                var obj
                obj = {
                    studentDetailsId: cell,
                    absentOrPermission: "Absent",
                    remarks: ""
                };

                studentDetails.push(obj);
            }
        }
    }

    const updateRemarksValue = (remarks: any, cell: number) => {
        const newData: any = [];

        filteredDataToRefresh.forEach((item: any, index: any) => {
            if (item['studentDetailsId'] == cell) {
                item['remarks'] = remarks
            }
            newData.push(item)
        });

        setFilteredDataToRefresh(newData)

        if (studentDetails.length == 0) {
            const obj = {
                studentDetailsId: cell,
                absentOrPermission: "",
                remarks: remarks,
            };
            studentDetails.push(obj);
        } else {
            let selectedItem: any;
            studentDetails.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['remarks'] = remarks
                }
            });
            if (selectedItem != undefined) {
                selectedItem['remarks'] = remarks
            } else {
                var obj
                obj = {
                    studentDetailsId: cell,
                    absentOrPermission: "",
                    remarks: remarks,
                };

                studentDetails.push(obj);
            }
        }
    }

    function setStudentAttendance() {
        return ({
            dateOfAttendance: studentAttendanceForm.values.dateOfAttendance,
            periodDetailsId: periodDetailsId?.value,
            courseId: courseId?.value,
            batchMasterId: batchMasterId?.value,
            semesterDetailsId: semesterDetailsId?.value,
            sectionDetailsId: sectionDetailsId?.value,
            shift: 1,
            staffDetailsId: userAccountId,
            studentDetails: studentDetails,
            licenseKey: getLicenseKey,
        })
    }

    function studentAttendanceSubmit() {
        showLoader(true);
        if (studentAttendanceForm.isValid) {
            let studentAttendancePostData = setStudentAttendance();
            addStudentAttendanceForWeb(studentAttendancePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        setStudentDetails([])
                        setDataSuccess(false);
                        setIsWorkingDay(false);
                        setIsOpenListCard(true);
                        resetStates()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentAttendanceForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentAttendanceReportList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setConfirmationOpen(false);
    }

    function resetStates() {
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setGraduationTypeId('')
        setSectionDetailsId('')
        setPeriodDetailsId('')
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title='Attendance'>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list' tag='form' noValidate onSubmit={studentAttendanceForm.handleSubmit}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Student Attendance
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row g-4">
                                    <div className="col-md-2">
                                        <FormGroup id='dateOfAttendance' label='Date' isFloating>
                                            <Input onChange={studentAttendanceForm.handleChange} value={studentAttendanceForm.values.dateOfAttendance}
                                                onBlur={studentAttendanceForm.handleBlur}
                                                isValid={studentAttendanceForm.isValid}
                                                isTouched={studentAttendanceForm.touched.dateOfAttendance}
                                                invalidFeedback={studentAttendanceForm.errors.dateOfAttendance}
                                                type='date' />
                                        </FormGroup>
                                    </div>

                                    {isWorkingDay == false ? <div className="col-md-3">
                                        <Button icon='ArrowForward' color='primary' type="submit" isDisable={!studentAttendanceForm.isValid && !!studentAttendanceForm.submitCount}>Next</Button>
                                    </div> : null}

                                    {isWorkingDay == true ? <> <div className="col-md-3">
                                        <FormGroup id='departmentId' label='Department' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Department'
                                                onChange={selectDepartment}
                                                value={departmentId}
                                                list={departmentData.map((data: any) => (
                                                    { value: data.departmentId, label: data.departmentName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                        {departmentId ? <div className="col-md-2">
                                            <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                    value={graduationTypeId}
                                                    list={graduationTypeData.map((data: any) => (
                                                        { value: data.graduationTypeId, label: data.graduationType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {graduationTypeId ? <div className="col-md-3">
                                            <FormGroup id='courseId' label='Course' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse}
                                                    value={courseId} list={courseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {courseId ? <div className="col-md-2">
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch}
                                                    value={batchMasterId} list={batchMasterData.map((data: any) => (
                                                        { value: data.batchMasterId, label: data.batchName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {batchMasterId ? <div className="col-md-2">
                                            <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester}
                                                    value={semesterDetailsId} list={semesterDetailsData.map((data: any) => ({ value: data.semesterDetailsId, label: data.semesterNumber }))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {semesterDetailsId ? <div className="col-md-3">
                                            <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId} list={sectionDetailsData.map((data: any) => (
                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {sectionDetailsId ? <div className="col-md-2">
                                            <FormGroup id='periodDetailsId' label='Period' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Period' onChange={selectPeriod} value={periodDetailsId}
                                                    list={periodData.map((data: any) => ({ value: data.periodDetailsId, label: data.periodName }))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {periodDetailsId ? <div className="col-md-3">
                                            {/* <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>&nbsp; */}
                                            <Button icon='ArrowDownward' color='primary' onClick={viewStudentAttendanceReport}>View</Button>
                                        </div> : null} </>
                                        : null}

                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ? <> {isReportOrAdd == 'add' ?
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                {/* <div className='d-flex col-5 border border-danger'> */}
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Student Attendance List</CardTitle>
                                </CardLabel>
                                {/* </div> */}

                                <CardActions className="d-print-none">
                                    {/* <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup> */}
                                    <Button icon='Save' color='primary' isDisable={studentDetails.length > 0 ? false : true} onClick={() => { setConfirmationOpen(true) }}>Submit
                                    </Button>
                                    <Button icon='PresentToAll' color='success' isDisable={studentDetails.length > 0 ? true : false} onClick={studentAttendanceSubmit}>All Present
                                    </Button>
                                </CardActions>
                                {/* <div className='d-flex col-7 justify-content-center g-4 border border-danger'>
                                    <div className='col-2 justify-content-center'></div>
                                    <div className='d-flex col-5 justify-content-start' data-tour='search'>
                                        <label className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                                            htmlFor='searchInput'>
                                            <Icon icon='Search' size='2x' color='primary' />
                                        </label>
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput}
                                        />
                                    </div>&nbsp;&nbsp;
                                    <div className='col-2 justify-content-center'>
                                        <Button icon='Save' color='primary' isDisable={studentDetails.length > 0 ? false : true} onClick={() => { setConfirmationOpen(true) }}>Submit
                                        </Button>
                                    </div>
                                    <div className='col-3 justify-content-end'>
                                        <Button icon='PresentToAll' color='success' isDisable={studentDetails.length > 0 ? true : false} onClick={studentAttendanceSubmit}>All Present
                                        </Button>
                                    </div>
                                </div> */}
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <td />
                                            <th>S.No</th>
                                            <th>Adm / Uni Reg No</th>
                                            <th>Student Name</th>
                                            <th>Period</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentDetailsData != '' ? <>
                                            {filteredData1.map((i: any) => (
                                                <tr key={i.studentDetailsId}>

                                                    <td>
                                                        <Checks id={i.studentDetailsId} name='selectedList' value={i.studentDetailsId} checked={i.isSelect} onChange={(e: any) => updateStudentDetailsIdValue(e, i.studentDetailsId, i.admissionNo, i.uniRegNo, i.studentName)} />
                                                    </td>
                                                    <td>{i.sno}</td>
                                                    {i.uniRegNo != null ? <td>{i.admissionNo} / {i.uniRegNo}</td> : <td>{i.admissionNo}</td>}
                                                    <td>{i.studentName}</td>
                                                    {i.isSelect == true ?
                                                        <td scope='col'>
                                                            <Button isLink color='danger' icon='Circle' className='text-nowrap'>
                                                                Absent
                                                            </Button>
                                                        </td> :
                                                        <td scope='col'>
                                                            <Button isLink color='success' icon='Circle' className='text-nowrap'>
                                                                Present
                                                            </Button>
                                                        </td>
                                                    }
                                                    <td scope='col'>
                                                        <div className="col-12">
                                                            <FormGroup id='remarks'>
                                                                <Input className="w-200" placeholder="" value={i.remarks} disabled={i.isEditable} onInput={(e: any) => updateRemarksValue(e.target.value, i.studentDetailsId)} />
                                                            </FormGroup>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}</> : <NoDataMsg columnsCount={6} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage}
                            />

                            <Modal setIsOpen={setConfirmationOpen} isOpen={confirmationOpen} titleId='absentsStudent' isStaticBackdrop size='sm' isScrollable>
                                <ModalHeader setIsOpen={setConfirmationOpen}>
                                    <ModalTitle id='absentsStudent'>Absent Students</ModalTitle>
                                </ModalHeader>
                                <ModalBody>

                                    {attendanceConfirmationArray.map((attendanceConfirmationArray: any) => (

                                        <div className='col d-flex align-items-center'>
                                            <div className='flex-shrink-0 mt-2'>
                                                <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                                                    <div
                                                        className={classNames(
                                                            'rounded-2',
                                                            'd-flex align-items-center justify-content-center',
                                                            {
                                                                'bg-l10-dark': !darkModeStatus,
                                                                'bg-l90-dark': darkModeStatus,
                                                            },
                                                        )}>
                                                        <span className='fw-bold'>{getFirstLetter(attendanceConfirmationArray.studentName)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex-grow-1'>
                                                <div className='fs-6'>{attendanceConfirmationArray.studentName}</div>
                                                <div className='text-muted'>
                                                    {attendanceConfirmationArray.uniRegNo != null ? <small>{attendanceConfirmationArray.admissionNo} / {attendanceConfirmationArray.uniRegNo}</small> : <small>{attendanceConfirmationArray.admissionNo}</small>}
                                                </div>
                                            </div>
                                        </div>))}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='info' isOutline className='border-0' onClick={closeAndReset}>
                                        Close
                                    </Button>
                                    <Button color='info' icon='Save' type="submit" onClick={studentAttendanceSubmit}>
                                        Submit
                                    </Button>
                                </ModalFooter>
                            </Modal>

                        </Card>
                        :
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Student Attendance Report
                                        </CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-6 justify-content-end'>
                                    <div className='d-flex col-12 justify-content-end gap-2'>
                                        <div className='d-flex col-6 d-print-none justify-content-end' data-tour='search'>
                                            <label className='border-0 bg-transparent cursor-pointer mt-2'
                                                htmlFor='searchInput'>
                                                <Icon icon='Search' size='2x' color='primary' />
                                            </label>

                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </div>

                                        <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                            <DropdownToggle>
                                                <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                                <DropdownItem>
                                                    <div className='container py-2'>
                                                        <form className='row g-3'>
                                                            <div className='col-12'>
                                                                <FormGroup>
                                                                    <h6>Select All</h6>
                                                                    <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentAttendanceReportList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                                </FormGroup>
                                                            </div>
                                                            <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                <FormGroup>
                                                                    <h6>Columns</h6>
                                                                    <ChecksGroup>
                                                                        {allColumnsData.map((i: any) => (
                                                                            <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                        ))}
                                                                    </ChecksGroup>
                                                                </FormGroup>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>


                                        <CardActions className='d-print-none'>
                                            <Dropdown isButtonGroup>
                                                <DropdownToggle>
                                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Student Attendance Report", studentDetailsData, columnVisibilityData)}> Excel </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentDetailsData, columnVisibilityData, "Student Attendance Report")}> PDF
                                                        </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Student Attendance Report", studentDetailsData, columnVisibilityData)}> CSV </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </CardActions>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                {studentDetailsData != '' ?
                                    <div className='row g-4 align-items-center'>
                                        <div className='col-xl-1'></div>
                                        <div className='col-xl-3'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-3',
                                                    {
                                                        'bg-l10-primary': !darkModeStatus,
                                                        'bg-lo25-primary': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='PeopleOutline'
                                                        size='3x'
                                                        color='primary'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 mb-0'>{studentAttendanceSummaryData.totalStudents}</div>
                                                    <div className='text-muted mt-n2'>Total Student</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-3',
                                                    {
                                                        'bg-l10-success': !darkModeStatus,
                                                        'bg-lo25-success': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='PeopleOutline'
                                                        size='3x'
                                                        color='success'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 mb-0'>{studentAttendanceSummaryData.totalPresent}</div>
                                                    <div className='text-muted mt-n2'>Total Present</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3'>
                                            <div
                                                className={classNames(
                                                    'd-flex align-items-center rounded-2 p-3',
                                                    {
                                                        'bg-l10-danger': !darkModeStatus,
                                                        'bg-lo25-danger': darkModeStatus,
                                                    },
                                                )}>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='PeopleOutline'
                                                        size='3x'
                                                        color='danger'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 mb-0'>{studentAttendanceSummaryData.totalAbsent}</div>
                                                    <div className='text-muted mt-n2'>Total Absent</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                                <table className='table table-modern table-hover mt-4'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline text-nowrap'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (<>
                                                <th scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline text-nowrap'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            </>))}
                                            <td className="d-print-none" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ? <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.studentDetailsId}>
                                                    <td>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td className="text-nowrap" key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                </tr>
                                            ))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            {/* <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} /> */}
                        </Card>}
                    </> : null}
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default StudentAttendence