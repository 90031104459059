import { useContext, useEffect, useRef, useState } from "react";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { useFormik } from "formik";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import { useReactToPrint } from "react-to-print";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import Select from "../../bootstrap/forms/Select";
import dayjs from "dayjs";
import ReactSelect from "react-select";
import { getColumnsForDataTable, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { getAllStudent, getProfileForStudent } from "../../../services/student.service";
import { addStudentLateAttendance, addStudentLateAttendanceReason, getLateAttendanceReason, getStudentLateAttendanceList, updateLateAttendance } from "../../../services/attendance.service";
import AlertService from "../../../services/AlertService";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";

function LateAttendance() {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getStudentLateAttendance(userAccountId);
    }, [])

    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [lateAttendanceData, setLateAttendanceData] = useState<any>([]);
    const [allStudentData, setAllStudentData] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [selectedOption, setSelectedOption] = useState<any>([]);
    const [dataStatus, setDataStatus] = useState(false)
    const { themeStatus, darkModeStatus } = useDarkMode();
    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(lateAttendanceData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addLateAttendanceOffCanvas, setAddLateAttendanceOffCanvas] = useState(false)
    const [addReason, setAddReason] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [lateAttendanceReasonId, setLateAttendanceReasonId] = useState<any>('')
    const [lateAttendanceReasonData, setLateAttendanceReasonData] = useState<any>([])

    const [studentData, setStudentData] = useState<any>([])

    const addLateAbsenteesForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            dateOfAttendance: dayjs().format('YYYY-MM-DD'),
            studentDetailsId: '',
        },
        validate: (values) => {
            const errors: {
                dateOfAttendance?: string;
                studentDetailsId?: string;
            } = {};
            if (!values.dateOfAttendance) {
                errors.dateOfAttendance = 'Required';
            }
            if (studentDetailsData.length == 0) {
                errors.studentDetailsId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { studentLateAttendanceSubmit() },
    });

    const reasonForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            lateAttendanceReasonId: '',
            studentLateAttendanceId: '',
            reasonName: '',
        },
        validate: (values) => {
            const errors: {
                //studentLateAttendanceId?: string;
                lateAttendanceReasonId?: string;
                reasonName?: string;
            } = {};
            // if (!values.studentLateAttendanceId) {
            //     errors.studentLateAttendanceId = 'Required';
            // }
            if (values.reasonName == "") {
                if (!lateAttendanceReasonId?.value) {
                    errors.lateAttendanceReasonId = 'Required';
                }
            }
            if (!lateAttendanceReasonId) {
                if (!values.reasonName) {
                    errors.reasonName = 'Required';
                }
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { studentReasonSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    function selectedStudent(e: any) {
        let studentDetailsId = e;
        setSelectedOption(studentDetailsId.value)
        const studentArrayId = studentDetailsId.map((e: any) => e.value);
        setStudentDetailsData(studentArrayId)
    }

    function selectedReason(e: any) {
        let lateAttendanceReasonId = e;
        setLateAttendanceReasonId(lateAttendanceReasonId);
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function openAddNew() {
        setAddLateAttendanceOffCanvas(true)
        getStudentDetails();
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedStudent = i
            getLateAttendanceReasonDetails();
            setAddReason(true)
            setLateAttendanceReasonId(i.lateAttendanceReasonId != null ? { value: i.lateAttendanceReasonId, label: i.reasonName } : '')
            getStudentProfile(selectedStudent.studentDetailsId)

            reasonForm.setValues({
                studentLateAttendanceId: selectedStudent.studentLateAttendanceId,
                lateAttendanceReasonId: lateAttendanceReasonId?.value,
                reasonName: '',
            });
        }
    }

    function setReasonDetails() {
        return {
            studentLateAttendanceId: reasonForm.values.studentLateAttendanceId,
            lateAttendanceReasonId: lateAttendanceReasonId?.value,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setLateAttendanceDetails() {
        return {
            dateOfAttendance: addLateAbsenteesForm.values.dateOfAttendance,
            studentDetailsId: studentDetailsData,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setReasonName() {
        return ({
            reasonName: reasonForm.values.reasonName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function studentLateAttendanceSubmit() {
        showLoader(true)
        if (addLateAbsenteesForm.isValid) {
            let attendancePostData = setLateAttendanceDetails()
            addStudentLateAttendance(attendancePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentLateAttendance(userAccountId)
                        closeAndReset();

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addLateAbsenteesForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getStudentLateAttendance(userAccountId: any) {
        setDataStatus(false)
        getStudentLateAttendanceList(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentLateAttendanceList;
                    if (data != undefined) {
                        getColumnsForTable('getStudentLateAttendanceList', 'get');
                        setLateAttendanceData(data);
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    setLateAttendanceData([]);
                    getColumnsForTable('getStudentLateAttendanceList', 'get');
                    // toasts(response.data.message, "Error")
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getLateAttendanceReasonDetails() {
        getLateAttendanceReason(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.lateAttendanceReason;
                    if (data != undefined) {
                        setLateAttendanceReasonData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setLateAttendanceReasonData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function studentReasonSubmit() {
        showLoader(true)
        if (reasonForm.values.reasonName == '') {
            if (reasonForm.isValid) {
                let attendanceReasonPostData = setReasonDetails()
                updateLateAttendance(attendanceReasonPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            if (data.data != null) {
                                setAlertStatus({ message: data.message, type: "warning" });
                                setIsOpen(true);
                            } else {
                                setAlertStatus({ message: data.message, type: "success" });
                                setIsOpen(true);
                            }
                            getStudentLateAttendance(userAccountId)
                            getLateAttendanceReasonDetails()
                            closeAndReset();
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false)
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (reasonForm.isValid == false) {
                showLoader(false)
                setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                setIsOpen(true);
            }
        } else {
            if (reasonForm.isValid) {
                let attendanceReasonPostData = setReasonName()
                addStudentLateAttendanceReason(attendanceReasonPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            setLateAttendanceReasonId(data.data.lateAttendanceReasonId)
                            let postData = {
                                studentLateAttendanceId: reasonForm.values.studentLateAttendanceId,
                                lateAttendanceReasonId: data.data.lateAttendanceReasonId,
                                licenseKey: getLicenseKey,
                                userAccountId: userAccountId
                            }
                            studentReasonSubmit1(postData)
                            //getStudentLateAttendance()
                            getLateAttendanceReasonDetails()
                            closeAndReset();
                        }
                        else if (data.success == false) {
                            setLateAttendanceReasonId(data.data.lateAttendanceReasonId)
                            let postData = {
                                studentLateAttendanceId: reasonForm.values.studentLateAttendanceId,
                                lateAttendanceReasonId: data.data.lateAttendanceReasonId,
                                licenseKey: getLicenseKey,
                                userAccountId: userAccountId
                            }
                            studentReasonSubmit1(postData)
                            // showLoader(false)
                            // setAlertStatus({ message: data.message, type: "error" });
                            // setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false)
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (reasonForm.isValid == false) {
                // showLoader(false)
                // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                // setIsOpen(true);
            }
        }
    }

    function studentReasonSubmit1(postData: any) {
        // showLoader(true)
        if (reasonForm.isValid) {
            let attendanceReasonPostData = postData
            updateLateAttendance(attendanceReasonPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        if (data.data != null) {
                            setAlertStatus({ message: data.message, type: "warning" });
                            setIsOpen(true);
                        } else {
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                        }
                        getStudentLateAttendance(userAccountId)
                        getLateAttendanceReasonDetails()
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (reasonForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentLateAttendanceList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }


    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStudentData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        setAddLateAttendanceOffCanvas(false);
        setAddReason(false);
        addLateAbsenteesForm.resetForm();
        reasonForm.resetForm();
        setIsVisible(true)
        setSelectedOption([])
        setStudentDetailsData([])
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
        setLateAttendanceReasonId('');
    };

    return (
        <>
            <PageWrapper title="Late Attendance">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Student Late Attendance List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentLateAttendanceList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("LateAttendanceList", lateAttendanceData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(lateAttendanceData, columnVisibilityData, "LateAttendanceList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("LateAttendanceList", lateAttendanceData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={openAddNew}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'><thead><tr><th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No{' '} <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>{columnVisibilityData.map((column: any) => (<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)} className='cursor-pointer text-decoration-underline'>{column.columnName}<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' /></th>))}<th className="d-print-none">Action </th></tr></thead><tbody>{lateAttendanceData != '' ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>}
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>

                <OffCanvas
                    setOpen={setAddLateAttendanceOffCanvas}
                    isOpen={addLateAttendanceOffCanvas}
                    titleId='addLateAttendanceOffCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={addLateAbsenteesForm.handleSubmit} isNotClose>
                    <OffCanvasHeader setOpen={setAddLateAttendanceOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addLateAttendanceOffCanvas">Student Late Attendance</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='dateOfAttendance' label='Date' >
                                    <Input type='date' onChange={addLateAbsenteesForm.handleChange} value={addLateAbsenteesForm.values.dateOfAttendance}
                                        isValid={addLateAbsenteesForm.isValid}
                                        onBlur={addLateAbsenteesForm.handleBlur}
                                        isTouched={addLateAbsenteesForm.touched.dateOfAttendance}
                                        invalidFeedback={addLateAbsenteesForm.errors.dateOfAttendance}
                                        placeholder="Date" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='studentDetailsId' label='Student' >
                                    <SearchableSelect ariaLabel='Student' onChange={selectedStudent} value={selectedOption} multiple
                                        list={allStudentData.map((data: any) => (
                                            { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                        ))}
                                        placeholder="Select Student"
                                        isValid={addLateAbsenteesForm.isValid}
                                        onBlur={addLateAbsenteesForm.handleBlur}
                                        isTouched={addLateAbsenteesForm.touched.studentDetailsId}
                                        invalidFeedback={addLateAbsenteesForm.errors.studentDetailsId}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit" isDisable={!addLateAbsenteesForm.isValid && !!addLateAbsenteesForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setAddReason}
                    isOpen={addReason}
                    titleId='addReason'
                    isBodyScroll
                    placement='end'
                    tag='form' noValidate onSubmit={reasonForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddReason} onClick={closeAndReset}>
                        <OffCanvasTitle id="addReason">Update Reason</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            {studentData.map((student: any) => (
                                <div className='col-12' key={student.studentDetailsId}>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                        }-info-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0 ms-2'>
                                                    <img style={{ borderRadius: '50%' }} src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-4 text-dark mb-0'>
                                                        {student.studentName}
                                                    </div>
                                                    <div className='text-muted'>
                                                        ( {student.admissionNo} )
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                            {isVisible ? <div className='col-10 mt-2'>
                                <FormGroup id='lateAttendanceReasonId' label='Select Reason'>
                                    <SearchableSelect
                                        ariaLabel='Select Reason'
                                        placeholder="Select Reason"
                                        onChange={selectedReason}
                                        value={lateAttendanceReasonId}
                                        onBlur={reasonForm.handleBlur}
                                        isValid={reasonForm.isValid}
                                        isTouched={reasonForm.touched.lateAttendanceReasonId}
                                        invalidFeedback={reasonForm.errors.lateAttendanceReasonId}
                                        list={lateAttendanceReasonData.map((data: any) => (
                                            { value: data.lateAttendanceReasonId, label: data.reasonName }
                                        ))}
                                    />
                                </FormGroup>
                            </div> :
                                <div className='col-10 mt-2'>
                                    <FormGroup id='reasonName' label="Enter Reason" >
                                        <Input onChange={reasonForm.handleChange} value={reasonForm.values.reasonName}
                                            onBlur={reasonForm.handleBlur}
                                            isValid={reasonForm.isValid}
                                            isTouched={reasonForm.touched.reasonName}
                                            invalidFeedback={reasonForm.errors.reasonName}
                                            type='text'
                                            placeholder="Enter Reason" />
                                    </FormGroup>
                                </div>}

                            <div className='col-md-1 mt-2'>
                                <FormGroup id='' label={"      "}>
                                    <Button className='mt-2'
                                        color='primary' icon='Add' isLight
                                        hoverShadow='lg'
                                        onClick={toggleVisibility}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit" isDisable={!reasonForm.isValid && !!reasonForm.submitCount}>
                                Update
                            </Button>
                        </div>
                    </div>
                </OffCanvas>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    );
}
export default LateAttendance;