import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import { toasts } from "../../../../services/toast.service";
import { useReactToPrint } from "react-to-print";
import Page from "../../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Icon from "../../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import Input from "../../../bootstrap/forms/Input";
import Select from "../../../bootstrap/forms/Select";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Collapse from "../../../bootstrap/Collapse";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import AlertService from "../../../../services/AlertService";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import AuthContext from "../../../../contexts/authContext";
import { getColumnsForDataTable, getGraduationType, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { getLicenseKey } from "../../../../services/application.settings";
import { addCourseCapacity, getBatchByCourse, getBatchByCourseId, getCourseByBatchMasterId, getCourseCapacityByBatchMasterId, updateCourseCapacity } from "../../../../services/master.service";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";

function CourseCapacity() {

    useEffect(() => {
        getCourseCapacityByBatchMaster();
        getGraduationList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [batchMasterId, setBatchMasterId] = useState<any>('');
    const [batchName, setBatchName] = useState('');
    const [courseName, setCourseName] = useState('');
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchByCourseMasterData, setBatchByCourseMasterData] = useState<any>([])
    const [courseCapacityData, setCourseCapacityData] = useState([]);
    const [courseByBatchMasterData, setCourseByBatchMasterData] = useState([]);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [courseCapacity, setCourseCapacity] = useState<any>([])
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(courseCapacityData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(false)
    const [addCourseCapacityModal, setAddCourseCapacityModal] = useState(false)
    const [editCourseCapacityOffcanvas, setEditCourseCapacityOffcanvas] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const courseCapacityCollapseForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            graduationTypeId:'',
            batchMasterId:'',
        },
        validate: (values) => { 
            const errors: {
                graduationTypeId?: string;
                batchMasterId?: string;
                // totalAllocated?: string;
            } = {};
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!batchMasterId) {
                errors.batchMasterId = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { viewCourseCapacity ()},
    });
    
    const courseCapacityEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseCapacityId: '',
            govQuota: '',
            managementQuota: '',
            totalAllocated: 0,
            courseId: '',
            batchMasterId: '',
        },
        validate: (values: any) => {
            const errors: {
                govQuota?: string;
                managementQuota?: string;
                // totalAllocated?: string;
            } = {};
            if (!values.govQuota) {
                errors.govQuota = 'Required';
            }
            if (!values.managementQuota) {
                errors.managementQuota = 'Required';
            }
            // if (!values.totalAllocated) {
            //     errors.totalAllocated = 'Required';
            // }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { updateCourseCapacitySubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectGraduationType = (e: any) => {
        setBatchMasterId('')
        setBatchByCourseMasterData([]);
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if(graduationTypeId?.value != undefined){
        getBatchByCourseId(graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const viewCourseCapacity = () => {
        setAddCourseCapacityModal(true)
        getCourseByBatchMaster(batchMasterId?.value)
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedCourseCapacity = i
            setEditCourseCapacityOffcanvas(true)

            courseCapacityEditForm.setValues({
                courseCapacityId: selectedCourseCapacity.courseCapacityId,
                govQuota: selectedCourseCapacity.govQuota,
                managementQuota: selectedCourseCapacity.managementQuota,
                totalAllocated: Number(selectedCourseCapacity.govQuota) + Number(selectedCourseCapacity.managementQuota),
                courseId: selectedCourseCapacity.courseId,
                batchMasterId: selectedCourseCapacity.batchMasterId,
            })

            setBatchName(selectedCourseCapacity.batchName)
            setCourseName(selectedCourseCapacity.courseName)
        }
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchByCourseId(graduationTypeId: any) {
        getBatchByCourse(graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchByCourseMasterData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchByCourseMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseByBatchMaster(batchMasterId: any) {
        getCourseByBatchMasterId(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseByBatchMasterId[0].courseCapacityDetails;
                    let batchName = response.data.data.courseByBatchMasterId[0].batchName;
                    if (data != undefined) {
                        setIsLoader(false);
                        setCourseByBatchMasterData(data);
                        setBatchName(batchName);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseCapacityByBatchMaster() {
        setDataStatus(false)
        getCourseCapacityByBatchMasterId(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseCapacityByBatchMasterId;
                    if (data != undefined) {
                        getColumnsForTable('getCourseCapacityByBatchMasterId', 'get');
                        setCourseCapacityData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getCourseCapacityByBatchMasterId', 'get');
                    setCourseCapacityData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function updateGovQuotaValue(event: any, cell: number, managementQuota: number) {

        const newData: any = [];

        courseByBatchMasterData.forEach((item: any, index: any) => {
            if (item['courseId'] == cell) {
                item['govQuota'] = event.target.value
                item['totalAllocated'] = Number(item['govQuota']) + Number(item['managementQuota'])
            }
            newData.push(item)
        });

        setCourseByBatchMasterData(newData)

        if (courseCapacity.length == 0) {
            var obj
            obj = {
                courseId: cell,
                govQuota: event.target.value,
                managementQuota: managementQuota > 0 ? managementQuota : "",
                totalAllocated: event.target.value + managementQuota,
            };
            courseCapacity.push(obj);
        } else {
            let selectedItem: any
            courseCapacity.forEach((item: any, index: number) => {
                if (item['courseId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['govQuota'] = event.target.value
                selectedItem['totalAllocated'] = Number(selectedItem['govQuota']) + Number(selectedItem['managementQuota'])
            } else {
                var obj
                obj = {
                    courseId: cell,
                    govQuota: event.target.value,
                    managementQuota: managementQuota > 0 ? managementQuota : "",
                    totalAllocated: event.target.value + managementQuota,
                };
                courseCapacity.push(obj);
            }
        }
    }

    function updateManagementQuotaValue(event: any, cell: number, govQuota: number) {

        const newData: any = [];

        courseByBatchMasterData.forEach((item: any, index: any) => {
            if (item['courseId'] == cell) {
                item['managementQuota'] = event.target.value
                item['totalAllocated'] = Number(item['govQuota']) + Number(item['managementQuota'])
            }

            newData.push(item)
        });

        setCourseByBatchMasterData(newData)

        if (courseCapacity.length == 0) {
            var obj
            obj = {
                courseId: cell,
                govQuota: govQuota > 0 ? govQuota : "",
                managementQuota: event.target.value,
                totalAllocated: event.target.value + govQuota,

            };
            courseCapacity.push(obj);
        } else {
            let selectedItem: any
            courseCapacity.forEach((item: any, index: number) => {
                if (item['courseId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['managementQuota'] = event.target.value
                selectedItem['totalAllocated'] = Number(selectedItem['govQuota']) + Number(selectedItem['managementQuota'])
            } else {
                var obj
                obj = {
                    courseId: cell,
                    govQuota: govQuota > 0 ? govQuota : "",
                    managementQuota: event.target.value,
                    totalAllocated: event.target.value + govQuota,
                };
                courseCapacity.push(obj);
            }
        }
    }

    function setCourseCapacityDetails() {
        return {
            batchMasterId: batchMasterId?.value,
            courseCapacity: courseCapacity,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function setCourseCapacityEditDetails() {
        return ({
            courseCapacityId: courseCapacityEditForm.values.courseCapacityId,
            batchMasterId: courseCapacityEditForm.values.batchMasterId,
            courseId: courseCapacityEditForm.values.courseId,
            govQuota: courseCapacityEditForm.values.govQuota,
            managementQuota: courseCapacityEditForm.values.managementQuota,
            totalAllocated: Number(courseCapacityEditForm.values.govQuota) + Number(courseCapacityEditForm.values.managementQuota),
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        })
    }

    function addCourseCapacitySubmit() {
        showLoader(true)
        if (courseCapacity.length > 0) {
            let courseCapacityPostData = setCourseCapacityDetails()
            addCourseCapacity(courseCapacityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getCourseCapacityByBatchMaster();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateCourseCapacitySubmit() {
        showLoader(true)
        if (courseCapacityEditForm.isValid) {
            let courseCapacityEditPostData = setCourseCapacityEditDetails()
            updateCourseCapacity(courseCapacityEditPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getCourseCapacityByBatchMaster();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (courseCapacityEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getCourseCapacityByBatchMasterId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddCourseCapacityModal(false)
        setEditCourseCapacityOffcanvas(false)
        courseCapacityEditForm.resetForm();
        setCourseCapacity([])
        setGraduationTypeId('');
        setBatchMasterId('');
    }
    
    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Course Capacity">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Course Capacity Information
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <form  noValidate onSubmit={courseCapacityCollapseForm.handleSubmit}>
                                <div className="row g-4">
                                    <div className='col-md-3'>
                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Graduation Type' placeholder="Select Graduation Type" onChange={selectGraduationType} onBlur={courseCapacityCollapseForm.handleBlur} value={graduationTypeId} isValid={courseCapacityCollapseForm.isValid} isTouched={courseCapacityCollapseForm.touched.graduationTypeId} invalidFeedback={courseCapacityCollapseForm.errors.graduationTypeId} list={graduationTypeData.map((data: any) => ({ value: data.graduationTypeId, label: data.graduationType }))} />
                                        </FormGroup>
                                        
                                    </div>
                                    {graduationTypeId?.value != '' ?
                                        <div className='col-md-3'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch Type' placeholder="Select Batch Type" onChange={(e: any) =>selectBatch(e)} value={batchMasterId} isValid={courseCapacityCollapseForm.isValid} isTouched={courseCapacityCollapseForm.touched.batchMasterId} onBlur={courseCapacityCollapseForm.handleBlur} invalidFeedback={courseCapacityCollapseForm.errors.batchMasterId}  list={batchByCourseMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} />
                                            </FormGroup>
                                        </div>
                                        : null}

                                    {batchMasterId?.value != '' ?
                                        <div className="col-md-3">
                                            <Button  isDisable={!courseCapacityCollapseForm.isValid && !!courseCapacityCollapseForm.submitCount} icon='ArrowDownward' color='primary' type="submit">View</Button>
                                        </div> : null}
                                </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} />

                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Course Capacity Information</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getCourseCapacityByBatchMasterId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("CourseCapacity", courseCapacityData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(courseCapacityData, columnVisibilityData, "CourseCapacity")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("CourseCapacity", courseCapacityData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setIsOpenListCard(!isOpenListCard)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.courseCapacityId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.courseCapacityId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg}/>}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>
            </PageWrapper>

            <Modal setIsOpen={setAddCourseCapacityModal} isOpen={addCourseCapacityModal} titleId='addCourseCapacity' isStaticBackdrop isScrollable size='lg'>
                <ModalHeader className="'modal-header'" setIsOpen={() => { setAddCourseCapacityModal(false) }} onClick={closeAndReset}>
                    <ModalTitle id='addCourseCapacity'>Add Course Capacity</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='p-3 mb-2 bg-primary text-white fw-bold'>Batch : {batchName}</div>
                    <table className='table table-modern  mt-3'>
                        <thead>
                            <tr>
                                <th style={{ width: '40%' }} scope="col">Course Name</th>
                                <th style={{ width: '20%' }} scope="col">Government</th>
                                <th style={{ width: '20%' }} scope="col">Management</th>
                                <th style={{ width: '20%' }} scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody className='justify-content-center'>
                            {courseByBatchMasterData.map((i: any) => (
                                <tr key={i.courseId}>
                                    <td scope='col'>{i.courseName}</td>
                                    <td scope='col'>
                                        <FormGroup id='uniRegNo'>
                                            <Input className="w-60" placeholder="" value={i.govQuota ? i.govQuota : ''} onChange={(event) => updateGovQuotaValue(event, i.courseId, i.managementQuota ? i.managementQuota : 0)} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </td>
                                    <td scope='col'>
                                        <FormGroup id='uniRegNo'>
                                            <Input className="w-60" placeholder="" value={i.managementQuota ? i.managementQuota : ''} onChange={(event) => updateManagementQuotaValue(event, i.courseId, i.govQuota ? i.govQuota : 0)} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </td>
                                    <td scope='col'>
                                        <FormGroup id='uniRegNo'>
                                            <Input className="w-60" placeholder="" value={i.totalAllocated ? i.totalAllocated : ''} disabled onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ModalBody>

                <ModalFooter>
                    <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                    <Button color='info' icon='Save' type="submit" onClick={addCourseCapacitySubmit} isDisable={courseCapacity.length > 0 ? false : true}>Save </Button>
                </ModalFooter>
            </Modal>

            <OffCanvas setOpen={setEditCourseCapacityOffcanvas} isOpen={editCourseCapacityOffcanvas} titleId='editCourseCapacityOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={courseCapacityEditForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditCourseCapacityOffcanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='editCourseCapacityOffcanvas'>Update Course Capacity</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-info bg-l${darkModeStatus ? 'o50' : '10'
                                }-info-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0 ms-2'>
                                            <Icon icon='Assignment' size='4x' color='info' />
                                        </div>
                                        <div className="row">
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {batchName}
                                                </div>
                                                <div className='text-muted'>
                                                    Batch
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {courseName}
                                                </div>
                                                <div className='text-muted'>
                                                    Course
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='govQuota' label='Govt Quota'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={courseCapacityEditForm.values.govQuota} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.govQuota} invalidFeedback={courseCapacityEditForm.errors.govQuota} type='text' placeholder="Gov Quota" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='managementQuota' label='Management Quota'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={courseCapacityEditForm.values.managementQuota} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.managementQuota} invalidFeedback={courseCapacityEditForm.errors.managementQuota} type='text' placeholder="Management Quota" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='totalAllocated' label='Total Allocated'>
                                <Input onChange={courseCapacityEditForm.handleChange} value={Number(courseCapacityEditForm.values.govQuota) + Number(courseCapacityEditForm.values.managementQuota)} isValid={courseCapacityEditForm.isValid} onBlur={courseCapacityEditForm.handleBlur} isTouched={courseCapacityEditForm.touched.totalAllocated} invalidFeedback={courseCapacityEditForm.errors.totalAllocated} type='text' placeholder="Total Allocated" required onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    {/* <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteCourseSubmit} />
                    </div> */}
                    {/* <div className='col-6 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div> */}
                    <div className='col-12 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!courseCapacityEditForm.isValid && !!courseCapacityEditForm.submitCount}> Update</Button>
                    </div>
                </div>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </>
    )
}
export default CourseCapacity;