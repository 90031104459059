import { useEffect, useState } from "react";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { useFormik } from "formik";
import { toasts } from "../../../services/toast.service";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Collapse from "../../bootstrap/Collapse";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Select from "../../bootstrap/forms/Select";
import Button from "../../bootstrap/Button";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import { getGraduationType, getSectionDetails } from "../../../services/common.service";

const AttendencePerforma = () => {

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
    }, [])


    const [departmentId, setDepartmentId] = useState('')
    const [courseId, setCourseId] = useState('')
    const [batchMasterId, setBatchMasterId] = useState('')
    const [semesterDetailsId, setSemesterDetailsId] = useState('')
    const [graduationTypeId, setGraduationTypeId] = useState('')
    const [sectionDetailsId, setSectionDetailsId] = useState('')

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])


    const [filteredData, setFilteredData] = useState<any>([])
    const [updateUniRegNo, setUpdateUniRegNo] = useState<any>([])

    const [studentDetailsData, setStudentDetailsData] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectDepartment = (e: any) => {
        let departmentId = e.target.value
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e.target.value
        setGraduationTypeId(graduationTypeId)
        getCourseList(departmentId, graduationTypeId)
    }

    const selectCourse = (e: any) => {
        let courseId = e.target.value
        setCourseId(courseId)
        getBatchList(courseId, graduationTypeId)
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e.target.value
        setBatchMasterId(batchMasterId)
        getSemesterList(batchMasterId)
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e.target.value
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e.target.value
        setSectionDetailsId(sectionDetailsId)
    }

    function viewStudentList() {
        // getStudentListBySectionId(courseId, semesterDetailsId, sectionDetailsId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([{ departmentId: "", departmentName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([{ graduationTypeId: "", graduationType: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([{ courseId: "", courseName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([{ batchMasterId: "", batchName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: any) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([{ semesterDetailsId: "", semesterNumber: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([{ sectionDetailsId: "", sectionName: response.data.message }]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // function getStudentListBySectionId(courseId: string, semesterDetailsId: string, sectionDetailsId: string) {
    //     console.log("Inside getStudentListBySectionId");
    //     axios.get(apiUrl + `getStudentDetailsBySemesterId/5566/` + courseId + '/' + semesterDetailsId + '/' + sectionDetailsId)
    //         .then((response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.studentDetailsBySemesterId;
    //                 console.log("getStudentListBySectionId: " + data);
    //                 if (data != undefined) {
    //                     setStudentDetailsData(data);
    //                     setIsOpenListCard(false);
    //                     setDataSuccess(true);
    //                     for (let i = 0; i < data.length; i++) {
    //                         data[i].sno = i + 1;
    //                     }
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 toasts(response.data.message, "Error")
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         });
    // }


    return (
        <PageWrapper>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Attendance Proforma
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className='col-3'>
                                    <FormGroup id='departmentId' label='Department' isFloating>
                                        <Select
                                            ariaLabel='Department'
                                            onChange={selectDepartment}
                                            value={departmentId}
                                            list={departmentData.map((data: any) => (
                                                { value: data.departmentId, text: data.departmentName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {departmentId ? <div className='col-3'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <Select
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, text: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {graduationTypeId ? <div className='col-3'>
                                    <FormGroup id='courseId' label='Course' isFloating>
                                        <Select
                                            ariaLabel='Course'
                                            onChange={selectCourse}
                                            value={courseId}
                                            list={courseData.map((data: any) => (
                                                { value: data.courseId, text: data.courseName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {courseId ? <div className='col-3'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <Select
                                            ariaLabel='Batch'
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, text: data.batchName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {batchMasterId ? <div className='col-3 mt-3'>
                                    <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                        <Select
                                            ariaLabel='Semester'
                                            onChange={selectSemester}
                                            value={semesterDetailsId}
                                            list={semesterDetailsData.map((data: any) => (
                                                { value: data.semesterDetailsId, text: data.semesterNumber }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {semesterDetailsId ? <div className='col-3 mt-3'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <Select
                                            ariaLabel='Section'
                                            onChange={selectSection}
                                            value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, text: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}


                                {sectionDetailsId ?
                                    <div className="col-4 mt-4">
                                        <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>Add</Button>&nbsp;
                                        <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>Report</Button>
                                    </div>
                                    : null}


                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                {
                    studentDetailsData ?
                        <div className='row justify-content-center'>
                            < div className='col-5 justify-content-center'></div>
                            <div className='col-6 justify-content-center'>
                                {isOpenListCard ?
                                    <Icon icon='ExpandLess' className='mb-0 text-dark h2' onClick={() => setIsOpenListCard(!isOpenListCard)} /> : <Icon icon='ExpandMore' className='mb-0 text-dark h2' onClick={() => setIsOpenListCard(!isOpenListCard)} />}
                            </div>
                        </div>
                        : null
                }

                {dataSuccess ?
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <div className='d-flex col-5 justify-content-start'>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Student List</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='d-flex col-7 justify-content-end'>
                                <div className='d-flex col-5 justify-content-end' data-tour='search'>
                                    <label className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                                        htmlFor='searchInput'>
                                        <Icon icon='Search' size='2x' color='primary' />
                                    </label>
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable >
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>

                                        <th>Admission Number</th>
                                        <th>Student Name</th>
                                        <th>Course Name</th>
                                        <th>University Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((i: any) => (
                                        <tr key={i.studentDetailsId}>

                                            <td>{i.sno}</td>
                                            <td>{i.admissionNo}</td>
                                            <td>{i.studentName}</td>
                                            <td>{i.courseName}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                    : null}

            </Page>
        </PageWrapper>
    )
}
export default AttendencePerforma