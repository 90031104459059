import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import autoTable from 'jspdf-autotable';

// export const downloadExcel = (fileName: string, data: any, columnVisibilityData: any) => {

//     let downloadData: any = []
//     data.forEach((data: any) => {
//         const obj: any = {}
//         columnVisibilityData.forEach((prop: any) => {
//             obj[prop.columnName] = data[prop.keyName]
//         })
//         downloadData.push(obj)
//     })

//     const worksheet = XLSX.utils.json_to_sheet(downloadData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//     //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
//     //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
//     XLSX.writeFile(workbook, fileName + ".xlsx");
// };

export const downloadExcel = async (fileName: string, data: any, columnVisibilityData: any) => {
    try {
        // Prepare data with the specified columns
        let downloadData: any[] = [];
        data.forEach((rowData: any) => {
            const obj: any = {};
            columnVisibilityData.forEach((prop: any) => {
                obj[prop.columnName] = rowData[prop.keyName];
            });
            downloadData.push(obj);
        });

        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Add headers
        const headerRow = worksheet.addRow(Object.keys(downloadData[0]));

        // Apply style to header row (first row)
        headerRow.eachCell((cell: any) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' } // Yellow color
            };
            cell.font = { bold: true }; // Make text bold
        });

        // Add data
        downloadData.forEach((rowData: any) => {
            worksheet.addRow(Object.values(rowData));
        });

        worksheet.columns.forEach((column: any) => {
            column.eachCell((cell: any) => {
                column.width = Math.max((column.width || 0), cell.text ? (cell.text.length + 2) * 1.1 : 0);
            });
        });

        // Generate buffer
        const buffer = await workbook.xlsx.writeBuffer();

        // Create blob
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        // Create link and trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } catch (error) {
        console.error('Error exporting data to Excel:', error);
    }
};

export const printPage = (divName: string) => {

    var printContents = (document.getElementById(divName) as HTMLInputElement).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
}

export const downloadFile = (fileName: string, data: any, columnVisibilityData: any) => {

    let downloadData: any = []
    data.forEach((data: any) => {
        const obj: any = {}
        columnVisibilityData.forEach((prop: any) => {
            obj[prop.columnName] = data[prop.keyName]
        })
        downloadData.push(obj)
    })

    const worksheet = XLSX.utils.json_to_sheet(downloadData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, fileName + ".csv");
}

// export const convertJsonToPdf = (data: any[], columnVisibilityData: any, title: string) => {

//     let downloadData: any = []
//     data.forEach((data: any) => {
//         const obj: any = {}
//         columnVisibilityData.forEach((prop: any) => {
//             obj[prop.columnName] = data[prop.keyName]
//         })
//         downloadData.push(obj)
//     })

//     const pdf = new jsPDF();

//     pdf.setFontSize(18);
//     pdf.text(title, pdf.internal.pageSize.getWidth() / 2, 15, { align: 'right' });


//     const table = document.createElement('table');
//     const thead = document.createElement('thead');
//     const tbody = document.createElement('tbody');

//     // Extract keys from the first item in the JSON data to create table headers
//     const keys = Object.keys(downloadData[0]);

//     // Create table header row with styling
//     const headerRow = document.createElement('tr');
//     keys.forEach((key) => {
//         const th = document.createElement('th');
//         th.textContent = key;
//         th.style.backgroundColor = '#f2f2f2'; // Example background color
//         th.style.padding = '10px'; // Example padding
//         headerRow.appendChild(th);
//     });
//     thead.appendChild(headerRow);

//     // Create table rows from JSON data with styling
//     downloadData.forEach((item: any, index: any) => {
//         const row = document.createElement('tr');
//         row.style.backgroundColor = index % 2 === 0 ? '#f9f9f9' : 'white'; // Example alternating row background color

//         keys.forEach((key) => {
//             const cell = document.createElement('td');
//             cell.textContent = item[key];
//             cell.style.padding = '10px'; // Example padding
//             row.appendChild(cell);
//         });

//         tbody.appendChild(row);
//     });

//     table.appendChild(thead);
//     table.appendChild(tbody);

//     // Apply overall table style
//     table.style.borderCollapse = 'collapse'; // Example border collapse
//     table.style.width = '100%'; // Example width

//     document.body.appendChild(table);

//     // Use html2canvas to capture the table as an image
//     html2canvas(table).then((canvas) => {
//         const imgData = canvas.toDataURL('image/png');

//         // Add the image to the PDF
//         pdf.addImage(imgData, 'PNG', 10, 25, pdf.internal.pageSize.getWidth() - 20, 0);


//         // Save the PDF
//         pdf.save(title);

//         // Remove the temporary table from the DOM
//         document.body.removeChild(table);
//     });
// };

export const convertJsonToPdf = (data: any[], columnVisibilityData: any, title: string) => {

    let downloadData: any = [];
    data.forEach((data: any, index: number) => {
        const obj: any = { 'Serial No': index + 1 };
        columnVisibilityData.forEach((prop: any) => {
            obj[prop.columnName] = data[prop.keyName];
        });
        downloadData.push(obj);
    });

    const pdf = new jsPDF();

    pdf.setFontSize(18);
    pdf.text(title, pdf.internal.pageSize.getWidth() / 2, 15, { align: 'center' });

    const columns = [
        { header: 'SNo', dataKey: 'Serial No' },
        ...columnVisibilityData.map((prop: any) => ({
            header: prop.keyName,
            dataKey: prop.columnName,
        })),
    ];

    autoTable(pdf, {
        columns: columns,
        body: downloadData,
        startY: 25,
        theme: 'grid',
        headStyles: {
            fillColor: '#f2f2f2', // Background color
            textColor: '#000000', // Text color (black)
        },
        //bodyStyles: { fillColor: ['#f9f9f9'] }, // Alternating row colors
        margin: { top: 10 },
        didDrawPage: (data) => {
            if (data.pageNumber === 1) {
              pdf.setFontSize(18);
              pdf.text(title, pdf.internal.pageSize.getWidth() / 2, 15, { align: 'center' });
            }
          },
    });

    pdf.save(`${title}.pdf`);

};


export const downloadPdfDocument = (rootElementId: any, downloadFileName: any) => {
    // const input = document.getElementById(rootElementId);
    let data = document.getElementById(rootElementId) || rootElementId;
    html2canvas(data).then(canvas => {
        const imgWidth = 127;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a5');
        const position = 10;
        pdf.addImage(contentDataURL, 'PNG', 10, position, imgWidth, imgHeight);
        pdf.save(downloadFileName + '.pdf');
    });

}