import React, { useEffect, useState } from 'react';
import { getTimeTableListByStaffDetailsId } from '../../../../services/dashboard.service';
import { useSSR } from 'react-i18next';
import { toasts } from '../../../../services/toast.service';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import { getPeriodListByShiftId } from '../../../../services/timeTable.service';
import NoDataMsg from '../../../../common/components/NoDataMsg';

const StaffTimeTable = () => {
	useEffect(() => {
		getTimeTableListByStaffDetailstList();
		getPeriodForTimeTable(0);
	}, []);

	const [dataStatus, setDataStatus] = useState(true);
	const [isLoader, setIsLoader] = useState(true);
	const [periodDetailsData, setPeriodDetailsData] = useState([]);
	const [timeTableData, setTimeTableData] = useState<any>([]);

	function getPeriodForTimeTable(shift: number) {
		getPeriodListByShiftId(
			shift,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.period;
					if (data != undefined) {
						setPeriodDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getTimeTableListByStaffDetailstList() {
		setDataStatus(false);
		getTimeTableListByStaffDetailsId(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.timeTableListByStaffDetailsId;
					if (data != undefined) {
						setIsLoader(false);
						setDataStatus(true);
						setTimeTableData(data[0].dayOrder);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].id = data[i].timeTableMasterId.toString();
						}
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}
	return (
		<PageWrapper title='Dashboard'>
			<Page container='fluid'>
				<Card stretch data-tour='list'>
					<CardHeader borderSize={1}>
						<CardLabel icon='List' iconColor='info' className='col-lg-8'>
							<CardTitle tag='div' className='h5'>
								Staff Time Table
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='table-responsive'>
						<table className="table table-modern table-hover">
							<thead className="table-info text-center text-uppercase align-middle">
								<tr>
									<th className="table-info text-uppercase">Day</th>
									{periodDetailsData.map((periodDetails: any) => (
										<th
											key={periodDetails.periodDetailsId}
											className="table-info text-uppercase">
											{periodDetails.periodName}
											<br />
											{periodDetails.startTime}
											<br />
											{periodDetails.endTime}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{timeTableData != '' ?
									<>
										{timeTableData.map((dayOrder: any) => (<tr key={dayOrder.dayId} className="text-center text-wrap align-middle"><td>{dayOrder.day}</td><td>
											{dayOrder.period[0].periodDetailsId === 1 && dayOrder.period[0].paperName != undefined ? dayOrder.period[0].paperName : 'Nil'}</td><td>
												{dayOrder.period[1].periodDetailsId === 2 && dayOrder.period[1]?.paperName != undefined ? dayOrder.period[1].paperName : 'Nil'}</td><td>{dayOrder.period[2].periodDetailsId === 3 && dayOrder.period[2]?.paperName != undefined ? dayOrder.period[2].paperName : 'Nil'}</td><td><b>LUNCH</b></td><td>{dayOrder.period[3].periodDetailsId === 5 && dayOrder.period[3]?.paperName != undefined ? dayOrder.period[3].paperName : 'Nil'}</td><td>{dayOrder.period[4].periodDetailsId === 6 && dayOrder.period[4]?.paperName != undefined ? dayOrder.period[4].paperName
													: 'Nil'}</td></tr>))}</> : <NoDataMsg columnsCount={7} msg={"Time Table Not Created. Please Create."} />}</tbody>
						</table>
					</CardBody>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default StaffTimeTable;
