import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { getBatchByCourseId, getCourse, getDepartmentList, getReferralType, getSemester, getUserListByUserTypeId } from "../../../../services/master.service";
import { toasts } from "../../../../services/toast.service";
import { getConcession, getCurrentDateAndTime, getSectionDetails } from "../../../../services/common.service";
import { getShift } from "../../../../services/timeTable.service";
import { getTransportAreaMaster } from "../../../../services/transport.service";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, { CardBody } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Select from "../../../bootstrap/forms/Select";
import Input from "../../../bootstrap/forms/Input";
import Button from "../../../bootstrap/Button";

interface IStudentProps {
    handleNext1(...args: unknown[]): unknown;
    handlePrevious(...args: unknown[]): unknown;
    setSelectedValue(...args: unknown[]): unknown;
    selectedValue?: any;
}

const ClassInfo: FC<IStudentProps> = ({ handleNext1, handlePrevious, setSelectedValue, selectedValue }) => {

    useEffect(() => {
        getDepartment();
        getSectionList();
        getReferral();
        getConcessionList();
        getTransportAreaMasterDetails();
        getShiftList();
        addSetValues();
    }, [])

    const [departmentId, setDepartmentId] = useState("")
    const [courseId, setCourseId] = useState("")
    const [batchMasterId, setBatchMasterId] = useState("")
    const [semesterDetailsId, setSemesterDetailsId] = useState("")
    const [sectionDetailsId, setSectionDetailsId] = useState("")

    const [departmentData, setDepartmentData] = useState([])
    const [courseData, setCourseData] = useState([])
    const [batchMasterData, setBatchMasterData] = useState([])
    const [semesterDetailsData, setSemesterDetailsData] = useState([])
    const [sectionDetailsData, setSectionDetailsData] = useState([])
    const [shiftData, setShiftData] = useState([]);
    const [interestedCourseData, setInterestedCourseData] = useState([])
    const [referralTypeId, setReferralTypeId] = useState("")
    const [referralTypeData, setReferralTypeData] = useState([])
    const [userListByUserTypeData, setUserListByUserTypeData] = useState([])
    const [concessionData, setConcessionData] = useState([])
    const [transportAreaMasterData, setTransportAreaMasterData] = useState([])
    const [isCollegeTransport, setIsCollegeTransport] = useState("")

    const [isUserDetails, setIsUserDetails] = useState('isUserDetails')
    const [isTransportDetails, setIsTransportDetails] = useState(true)
    const [interimCollegeTransfer, setIsInterimCollegeTransfer] = useState('')

    const [referredId, setReferredId] = useState("")
    const [referredName, setReferredName] = useState("")

    const addStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----General Info----////
            graduationTypeId: '',
            admissionNo: '',
            tempStudentDetailsId: '',
            firstName: '',
            lastName: '',
            surName: '',
            studentNameTamil: '',
            surNameTamil: '',
            dateOfBirth: '',
            genderId: '',
            bloodGroupId: '',
            motherTongue: '',
            nationality: '',
            otherNation: '',
            religionId: '',
            casteUniqueId: '',
            casteDetailsId: '',
            subcaste: '',
            category: '',
            aadharCard: '',
            physicallyChallenged: '',
            physicallyChallengedType: '',
            physicallyChallengedPercentage: '',
            martialStatus: '',
            email: '',
            emisNo: '',
            ///----Address INFO----////
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            permAddr_FlatNo: '',
            permAddr_Street: '',
            permAddr_Area: '',
            permAddr_StateId: '',
            permAddr_CityId: '',
            permAddr_Pincode: '',

            bankName: '',
            bankAccountNo: '',
            branch: '',
            ifscCode: '',
            micrCode: '',
            // ///----Class INFO----////
            departmentId: '',
            courseId: '',
            batchMasterId: '',
            semesterDetailsId: '',
            sectionDetailsId: '',
            shiftId: '',
            studentRollNo: '',
            admissionDateStamp: getCurrentDateAndTime('date'),
            sourceName: '',
            transferCourseId: '',
            referralTypeId: '',
            referredId: '',
            referredName: '',
            interimCollegeTransfer: '',
            collegeName: '',
            concessionTypeId: '',
            concessionRemarks: '',
            transportAreaMasterId: '',
            modeOfWays: '',
            isCollegeTransport: '',
            ///----Family INFO----////
            fatherName: '',
            father_AdharNumber: '',
            fatherOccupation: '',
            natureofJoborBusiness: '',
            fatherContactNumber: '',
            fatherEmailId: '',
            motherName: '',
            mother_AdharNumber: '',
            yearlyIncome: '',
            positionHeld: '',
            motherContactNumber: '',
            smsToBeSentNumber: '',
            guardian_Name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_AdharNumber: '',
            isGuardian: 0,
            // // ///----MARK INFO----////
            registerNo_X: '',
            institutionNameAndAddress_X: '',
            boardName_X: '',
            mediumOfInstitution_X: '',
            monthAndYearOfPassing_X: '',

            registerNo_XI: '',
            institutionNameAndAddress_XI: '',
            boardName_XI: '',
            mediumOfInstitution_XI: '',
            monthAndYearOfPassing_XI: '',

            registerNo_XII: '',
            XIISchoolNameId: '',
            boardName_XII: '',
            mediumOfInstitution_XII: '',
            monthAndYearOfPassing_XII: '',

            XI_S1_studentSchoolSubject: '',
            XI_S1_markSecured: '',
            XI_S1_maximumMarks: '',
            XI_S1_monthAndYearOfPassing: '',
            XI_S1_registerNo: '',
            XI_S1_noOfAttempts: '',
            XI_S2_studentSchoolSubject: '',
            XI_S2_markSecured: '',
            XI_S2_maximumMarks: '',
            XI_S2_monthAndYearOfPassing: '',
            XI_S2_registerNo: '',
            XI_S2_noOfAttempts: '',
            XI_S3_studentSchoolSubject: '',
            XI_S3_markSecured: '',
            XI_S3_maximumMarks: '',
            XI_S3_monthAndYearOfPassing: '',
            XI_S3_registerNo: '',
            XI_S3_noOfAttempts: '',
            XI_S4_studentSchoolSubject: '',
            XI_S4_markSecured: '',
            XI_S4_maximumMarks: '',
            XI_S4_monthAndYearOfPassing: '',
            XI_S4_registerNo: '',
            XI_S4_noOfAttempts: '',
            XI_S5_studentSchoolSubject: '',
            XI_S5_markSecured: '',
            XI_S5_maximumMarks: '',
            XI_S5_monthAndYearOfPassing: '',
            XI_S5_registerNo: '',
            XI_S5_noOfAttempts: '',
            XI_S6_studentSchoolSubject: '',
            XI_S6_markSecured: '',
            XI_S6_maximumMarks: '',
            XI_S6_monthAndYearOfPassing: '',
            XI_S6_registerNo: '',
            XI_S6_noOfAttempts: '',

            XII_S1_studentSchoolSubject: '',
            XII_S1_markSecured: '',
            XII_S1_maximumMarks: '',
            XII_S1_monthAndYearOfPassing: '',
            XII_S1_registerNo: '',
            XII_S1_noOfAttempts: '',
            XII_S2_studentSchoolSubject: '',
            XII_S2_markSecured: '',
            XII_S2_maximumMarks: '',
            XII_S2_monthAndYearOfPassing: '',
            XII_S2_registerNo: '',
            XII_S2_noOfAttempts: '',
            XII_S3_studentSchoolSubject: '',
            XII_S3_markSecured: '',
            XII_S3_maximumMarks: '',
            XII_S3_monthAndYearOfPassing: '',
            XII_S3_registerNo: '',
            XII_S3_noOfAttempts: '',
            XII_S4_studentSchoolSubject: '',
            XII_S4_markSecured: '',
            XII_S4_maximumMarks: '',
            XII_S4_monthAndYearOfPassing: '',
            XII_S4_registerNo: '',
            XII_S4_noOfAttempts: '',
            XII_S5_studentSchoolSubject: '',
            XII_S5_markSecured: '',
            XII_S5_maximumMarks: '',
            XII_S5_monthAndYearOfPassing: '',
            XII_S5_registerNo: '',
            XII_S5_noOfAttempts: '',
            XII_S6_studentSchoolSubject: '',
            XII_S6_markSecured: '',
            XII_S6_maximumMarks: '',
            XII_S6_monthAndYearOfPassing: '',
            XII_S6_registerNo: '',
            XII_S6_noOfAttempts: '',

            XI_OverAll_markSecured: '',
            XI_OverAll_maximumMarks: '',
            XII_OverAll_markSecured: '',
            XII_OverAll_maximumMarks: '',
            registerNo_UG: '',
            institutionNameAndAddress_UG: '',
            universityName_UG: '',
            mediumOfInstitution_UG: '',
            monthAndYearOfPassing_UG: '',

            registerNo_PG: '',
            institutionNameAndAddress_PG: '',
            universityName_PG: '',
            mediumOfInstitution_PG: '',
            monthAndYearOfPassing_PG: '',
            registerNo_MPhil: '',
            institutionNameAndAddress_MPhil: '',
            universityName_MPhil: '',
            mediumOfInstitution_MPhil: '',
            monthAndYearOfPassing_MPhil: '',
            UG_courseName: '',
            UG_Part1_SubjectName: '',
            UG_Part1_MarksObtained: '',
            UG_Part1_MaximumMarks: '',
            UG_Part1_Percentage: '',
            UG_Part1_ClassObtained: '',
            UG_Part1_NoOfAttempts: '',
            UG_Part1_RegisterNo: '',
            UG_Part1_Passing: '',
            UG_Part2_SubjectName: '',
            UG_Part2_MarksObtained: '',
            UG_Part2_MaximumMarks: '',
            UG_Part2_Percentage: '',
            UG_Part2_ClassObtained: '',
            UG_Part2_NoOfAttempts: '',
            UG_Part2_RegisterNo: '',
            UG_Part2_Passing: '',
            UG_Part3_SubjectName: '',
            UG_Part3_MarksObtained: '',
            UG_Part3_MaximumMarks: '',
            UG_Part3_Percentage: '',
            UG_Part3_ClassObtained: '',
            UG_Part3_NoOfAttempts: '',
            UG_Part3_RegisterNo: '',
            UG_Part3_Passing: '',
            UG_Allied1_SubjectName: '',
            UG_Allied1_MarksObtained: '',
            UG_Allied1_MaximumMarks: '',
            UG_Allied1_Percentage: '',
            UG_Allied1_ClassObtained: '',
            UG_Allied1_NoOfAttempts: '',
            UG_Allied1_RegisterNo: '',
            UG_Allied1_Passing: '',
            UG_Allied2_SubjectName: '',
            UG_Allied2_MarksObtained: '',
            UG_Allied2_MaximumMarks: '',
            UG_Allied2_Percentage: '',
            UG_Allied2_ClassObtained: '',
            UG_Allied2_NoOfAttempts: '',
            UG_Allied2_RegisterNo: '',
            UG_Allied2_Passing: '',
            UG_Overall_Main_Core: '',
            UG_Overall_Ancy_Allied: '',
            UG_Overall_Part3: '',

            PG_courseName: '',
            PG_Part1_SubjectName: '',
            PG_Part1_MarksObtained: '',
            PG_Part1_MaximumMarks: '',
            PG_Part1_Percentage: '',
            PG_Part1_ClassObtained: '',
            PG_Part1_NoOfAttempts: '',
            PG_Part1_RegisterNo: '',
            PG_Part1_Passing: '',
            PG_Part2_SubjectName: '',
            PG_Part2_MarksObtained: '',
            PG_Part2_MaximumMarks: '',
            PG_Part2_Percentage: '',
            PG_Part2_ClassObtained: '',
            PG_Part2_NoOfAttempts: '',
            PG_Part2_RegisterNo: '',
            PG_Part2_Passing: '',
            PG_Part3_SubjectName: '',
            PG_Part3_MarksObtained: '',
            PG_Part3_MaximumMarks: '',
            PG_Part3_Percentage: '',
            PG_Part3_ClassObtained: '',
            PG_Part3_NoOfAttempts: '',
            PG_Part3_RegisterNo: '',
            PG_Part3_Passing: '',
            PG_Allied1_SubjectName: '',
            PG_Allied1_MarksObtained: '',
            PG_Allied1_MaximumMarks: '',
            PG_Allied1_Percentage: '',
            PG_Allied1_ClassObtained: '',
            PG_Allied1_NoOfAttempts: '',
            PG_Allied1_RegisterNo: '',
            PG_Allied1_Passing: '',
            PG_Allied2_SubjectName: '',
            PG_Allied2_MarksObtained: '',
            PG_Allied2_MaximumMarks: '',
            PG_Allied2_Percentage: '',
            PG_Allied2_ClassObtained: '',
            PG_Allied2_NoOfAttempts: '',
            PG_Allied2_RegisterNo: '',
            PG_Allied2_Passing: '',
            PG_Overall_Main_Core: '',
            PG_Overall_Ancy_Allied: '',
            PG_Overall_Part3: '',

            MPhil_courseName: '',
            MPhil_Part1_SubjectName: '',
            MPhil_Part1_MarksObtained: '',
            MPhil_Part1_MaximumMarks: '',
            MPhil_Part1_Percentage: '',
            MPhil_Part1_ClassObtained: '',
            MPhil_Part1_NoOfAttempts: '',
            MPhil_Part1_RegisterNo: '',
            MPhil_Part1_Passing: '',
            MPhil_Part2_SubjectName: '',
            MPhil_Part2_MarksObtained: '',
            MPhil_Part2_MaximumMarks: '',
            MPhil_Part2_Percentage: '',
            MPhil_Part2_ClassObtained: '',
            MPhil_Part2_NoOfAttempts: '',
            MPhil_Part2_RegisterNo: '',
            MPhil_Part2_Passing: '',
            MPhil_Part3_SubjectName: '',
            MPhil_Part3_MarksObtained: '',
            MPhil_Part3_MaximumMarks: '',
            MPhil_Part3_Percentage: '',
            MPhil_Part3_ClassObtained: '',
            MPhil_Part3_NoOfAttempts: '',
            MPhil_Part3_RegisterNo: '',
            MPhil_Part3_Passing: '',
            MPhil_Allied1_SubjectName: '',
            MPhil_Allied1_MarksObtained: '',
            MPhil_Allied1_MaximumMarks: '',
            MPhil_Allied1_Percentage: '',
            MPhil_Allied1_ClassObtained: '',
            MPhil_Allied1_NoOfAttempts: '',
            MPhil_Allied1_RegisterNo: '',
            MPhil_Allied1_Passing: '',
            MPhil_Allied2_SubjectName: '',
            MPhil_Allied2_MarksObtained: '',
            MPhil_Allied2_MaximumMarks: '',
            MPhil_Allied2_Percentage: '',
            MPhil_Allied2_ClassObtained: '',
            MPhil_Allied2_NoOfAttempts: '',
            MPhil_Allied2_RegisterNo: '',
            MPhil_Allied2_Passing: '',
            MPhil_Overall_Main_Core: '',
            MPhil_Overall_Ancy_Allied: '',
            MPhil_Overall_Part3: '',

            ///----Admission----////
            quotaTypeId: '',
            discription: '',
            isCitizenOfIndia: '',
            isClaimingNriQuotaSeat: '',
            isMedicalCertificateEnclosed: '',
            extraCurricularActivities: '',

            ///----Certificate----////
            submittedDate: '',
            status: '',
            certificateTypeId: [],
            scholarshipTypeId: [],
            assignedBy: '',

        },
        validate: (values: any) => {
            const errors: {
                batchMasterId?: string;
                semesterDetailsId?: string;
            } = {};
            if (!batchMasterId) {
                errors.batchMasterId = 'Required';
            }
            if (!semesterDetailsId) {
                errors.semesterDetailsId = 'Required';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { addStudentSubmit() },
    })

    const courseCapacityEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseCapacityId: 0,
            govQuota: '',
            managementQuota: '',
            totalAllocated: '',
        },
        validate: (values: any) => {
        },
        validateOnChange: false,
        onSubmit: (values) => { },
    });

    const selectRefferalType = (e: any) => {
        let referralTypeId = e
        setReferralTypeId(referralTypeId);
        getUserListByUserType(referralTypeId)
        if (referralTypeId == "11") {
            setIsUserDetails('others')
            setReferredId('')
        }
        else if (referralTypeId !== "11") {
            setIsUserDetails('collegeStaff')
            setReferredName('')
        }
        else {
            setIsUserDetails('isUserDetails')
        }
    }

    const selectDepartment = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
        getCourseList(departmentId, selectedValue.graduationTypeId)
        getInterestedCourse(departmentId, selectedValue.graduationTypeId)

    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        let courseId = e
        setCourseId(courseId)
        getBatchList(courseId, selectedValue.graduationTypeId)
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        //checkCourseCapacitySubmit(courseId, batchMasterId)
        getSemesterList(batchMasterId)
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e.target.value
        setSectionDetailsId(sectionDetailsId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getShiftList() {
        getShift(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.shift;
                    if (data != undefined) {
                        setShiftData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getInterestedCourse(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setInterestedCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getReferral() {
        getReferralType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.referralType;
                    if (data != undefined) {
                        setReferralTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getUserListByUserType(referralTypeId: any) {
        getUserListByUserTypeId(referralTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userListByUserTypeId;
                    if (data != undefined) {
                        setUserListByUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getConcessionList() {
        getConcession(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.concession;
                    if (data != undefined) {
                        setConcessionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransportAreaMasterDetails() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [transportAreaMasterId, setTransportAreaMasterId] = useState('')
    const [modeOfWays, setModeOfWays] = useState('')

    const selectIsCollegeTransport = (e: any) => {
        let isCollegeTransport = e
        setIsCollegeTransport(isCollegeTransport)
        if (isCollegeTransport == 'Yes') {
            setIsTransportDetails(true)
        }
        else {
            setIsTransportDetails(false)
            setTransportAreaMasterId('')
            setModeOfWays('')
        }
    }

    const [collegeNameValue, setCollegeNameValue] = useState('')

    const selectInterimCollegeTransfer = (e: any) => {
        let interimCollegeTransfer = e
        setIsInterimCollegeTransfer(interimCollegeTransfer)
        if (interimCollegeTransfer == 'No') {
            setCollegeNameValue('')
        }
    }

    function handleNext() {
        handleNext1()
    }

    function handlePrevious1() {
        let studentPostData = setAddStudentDetails()
        setSelectedValue(studentPostData)
        handlePrevious()
    }

    function setAddStudentDetails() {
        return ({
            ///----General Info----////
            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo ? selectedValue.admissionNo : '',
            tempNo: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : '',
            surName: selectedValue.surName ? selectedValue.surName : '',
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : '',
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : '',
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : '',
            genderId: selectedValue.genderId ? selectedValue.genderId : '',
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : '',
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : '',
            nationality: selectedValue.nationality ? selectedValue.nationality : '',
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : '',
            religionId: selectedValue.religionId ? selectedValue.religionId : '',
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : '',
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : '',
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : '',
            category: selectedValue.category ? selectedValue.category : '',
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : '',
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : '',
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : '',
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : '',
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : '',
            email: selectedValue.email ? selectedValue.email : '',
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : '',
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : '',

            ///----Address INFO----////
            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            ///----Class INFO----////
            departmentId: departmentId ? departmentId : "",
            courseId: courseId ? courseId : "",
            batchMasterId: batchMasterId ? batchMasterId : "",
            semesterDetailsId: semesterDetailsId ? semesterDetailsId : "",
            sectionDetailsId: sectionDetailsId ? sectionDetailsId : "",
            shiftId: addStudentForm.values.shiftId ? addStudentForm.values.shiftId : "",
            studentRollNo: addStudentForm.values.studentRollNo ? addStudentForm.values.studentRollNo : "",
            admissionDateStamp: addStudentForm.values.admissionDateStamp ? addStudentForm.values.admissionDateStamp : "",
            sourceName: addStudentForm.values.sourceName ? addStudentForm.values.sourceName : "",
            transferCourseId: addStudentForm.values.transferCourseId ? addStudentForm.values.transferCourseId : "",
            referralTypeId: referralTypeId ? referralTypeId : "",
            referredId: referredId ? referredId : "",
            referredName: referredName ? referredName : "",
            interimCollegeTransfer: interimCollegeTransfer ? interimCollegeTransfer : "",
            collegeName: collegeNameValue ? collegeNameValue : "",
            concessionTypeId: addStudentForm.values.concessionTypeId ? addStudentForm.values.concessionTypeId : "",
            concessionRemarks: addStudentForm.values.concessionRemarks ? addStudentForm.values.concessionRemarks : "",
            transportAreaMasterId: transportAreaMasterId ? transportAreaMasterId : "",
            modeOfWays: modeOfWays ? modeOfWays : "",
            isCollegeTransport: isCollegeTransport ? isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
           
            ///----CertificateType Details----////
            certificateTypeId: selectedValue.checkArray,
            submittedDate: selectedValue.submittedDate,
            status: selectedValue.status,

            ///----Scholarship Details----////
            scholarshipTypeId: selectedValue.scholarshipTypeIdArray,
            assignedBy: selectedValue.userAccountId,
        })
    }

    function addStudentSubmit() {
        let studentPostData = setAddStudentDetails();
        setSelectedValue(studentPostData);
        handleNext();
    }

    function addSetValues() {
        console.log(selectedValue);
        if (selectedValue.departmentId != "") {
            selectDepartment(selectedValue.departmentId)
        }
        if (selectedValue.courseId != "") {
            selectCourse(selectedValue.courseId)
        }
        if (selectedValue.batchMasterId != "") {
            selectBatch(selectedValue.batchMasterId)
        }
        if (selectedValue.semesterDetailsId != "") {
            selectSemester(selectedValue.semesterDetailsId)
        }
        if (selectedValue.interimCollegeTransfer != "") {
            selectInterimCollegeTransfer(selectedValue.interimCollegeTransfer)
        }
        if (selectedValue.referralTypeId != "") {
            selectRefferalType(selectedValue.referralTypeId)
        }

        setReferredId(selectedValue.referredId)
        setReferredName(selectedValue.referredName)
        selectIsCollegeTransport(selectedValue.isCollegeTransport)
        setTransportAreaMasterId(selectedValue.transportAreaMasterId)
        setModeOfWays(selectedValue.modeOfWays)

        addStudentForm.setValues({

            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo,
            tempStudentDetailsId: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : "",
            surName: selectedValue.surName ? selectedValue.surName : "",
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : "",
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : "",
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : "",
            genderId: selectedValue.genderId ? selectedValue.genderId : "",
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : "",
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : "",
            nationality: selectedValue.nationality ? selectedValue.nationality : "",
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : "",
            religionId: selectedValue.religionId ? selectedValue.religionId : "",
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : "",
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : "",
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : "",
            category: selectedValue.category ? selectedValue.category : "",
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : "",
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : "",
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : "",
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : "",
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : "",
            email: selectedValue.email ? selectedValue.email : "",
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : "",
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : "",


            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            departmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
            
            ///----CertificateType Details----////
            certificateTypeId: selectedValue.checkArray,
            submittedDate: selectedValue.submittedDate,
            status: selectedValue.status,

            ///----Scholarship Details----////
            scholarshipTypeId: selectedValue.scholarshipTypeIdArray,
            assignedBy: selectedValue.userAccountId,

        })
    }

    return (
        <PageWrapper>
            <Page>
                <Card shadow='none'>
                    <CardBody>
                        <form noValidate onSubmit={addStudentForm.handleSubmit}>
                            <div className="row g-4">
                                <div className="col-lg-3">
                                    <FormGroup id="department" label="Department" isFloating>
                                        <Select ariaLabel="" placeholder="Select Department"
                                            onChange={(e: any) => selectDepartment(e.target.value)}
                                            value={departmentId}
                                            list={departmentData.map((data: any) => (
                                                { value: data.departmentId, text: data.departmentName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="courseId" label="Course" isFloating>
                                        <Select ariaLabel="" placeholder="Select Course"
                                            onChange={(e: any) => selectCourse(e.target.value)}
                                            value={courseId}
                                            list={courseData.map((data: any) => (
                                                { value: data.courseId, text: data.courseName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="batchMasterId" label="Batch" isFloating>
                                        <Select ariaLabel="" placeholder="Select Batch"
                                            onChange={(e: any) => selectBatch(e.target.value)}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, text: data.batchName }
                                            ))}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.batchMasterId}
                                            invalidFeedback={addStudentForm.errors.batchMasterId} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="semesterDetailsId" label="Semester" isFloating>
                                        <Select ariaLabel="" placeholder="Select Semester"
                                            onChange={(e: any) => selectSemester(e.target.value)}
                                            value={semesterDetailsId}
                                            list={semesterDetailsData.map((data: any) => (
                                                { value: data.semesterDetailsId, text: data.semesterNumber }
                                            ))}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.semesterDetailsId}
                                            invalidFeedback={addStudentForm.errors.semesterDetailsId} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="sectionDetailsId" label="Section" isFloating>
                                        <Select ariaLabel=""
                                            onChange={selectSection}
                                            value={sectionDetailsId || '1'}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, text: data.sectionName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="shiftId" label="Shift" isFloating>
                                        <Select ariaLabel=""
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.shiftId || '1'}
                                            list={shiftData.map((data: any) => (
                                                { value: data.shiftId, text: data.shift }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="admissionDateStamp" label="Admission Date" isFloating>
                                        <Input type="date" placeholder="Enter Admission Date"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.admissionDateStamp} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="sourceName" label="Source" isFloating>
                                        <Input type="text" placeholder="Enter Source"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.sourceName} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="transferCourseId" label="Interested to Transfer the Course" isFloating>
                                        <Select ariaLabel="" placeholder="Select Interested to Transfer the Course"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.transferCourseId}
                                            list={interestedCourseData.map((data: any) => (
                                                { value: data.courseId, text: data.courseName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="referralTypeId" label="Reference (if any)" isFloating>
                                        <Select ariaLabel="" placeholder="Select Reference (if any)"
                                            onChange={(e: any) => selectRefferalType(e.target.value)}
                                            value={referralTypeId}
                                            list={referralTypeData.map((data: any) => (
                                                { value: data.referralTypeId, text: data.referralType }
                                            ))} />
                                    </FormGroup>
                                </div>
                                {isUserDetails == 'collegeStaff' ? <div className="col-lg-3">
                                    <FormGroup id="referredId" label="Referred Person" isFloating>
                                        <Select ariaLabel="" placeholder="Select Referred Person"
                                            onChange={(e: any) => { setReferredId(e.target.value) }}
                                            value={referredId}
                                            list={userListByUserTypeData.map((data: any) => (
                                                { value: data.referredId, text: data.registerNoForStudentAndStaff }
                                            ))} />
                                    </FormGroup>
                                </div> : null}
                                {isUserDetails == 'others' ? <div className="col-lg-3">
                                    <FormGroup id="referredName" label="Referred Person" isFloating>
                                        <Input type="text" placeholder="Enter Others"
                                            onChange={(e: any) => { setReferredName(e.target.value) }}
                                            value={referredName} />
                                    </FormGroup>
                                </div> : null}

                                <div className="col-lg-3">
                                    <FormGroup id="concessionTypeId" label="Concession Type" isFloating>
                                        <Select ariaLabel="" placeholder="Select Concession Type"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.concessionTypeId}
                                            list={concessionData.map((data: any) => (
                                                { value: data.concessionTypeId, text: data.concessionType }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="concessionRemarks" label="Concession Remark" isFloating>
                                        <Input type="text" placeholder="Enter Concession Remark"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.concessionRemarks} disabled={addStudentForm.values.concessionTypeId == "" ? true : false} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3">
                                    <FormGroup id="interimCollegeTransfer" label="Interim College Transfer ?" isFloating>
                                        <Select ariaLabel="" placeholder="Select Interim College Transfer ?"
                                            onChange={(e: any) => selectInterimCollegeTransfer(e.target.value)}
                                            value={interimCollegeTransfer}>
                                            <option value='Yes'>Yes</option>
                                            <option value='No'>No</option>
                                        </Select>
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3">
                                    <FormGroup id="collegeName" label="College Name" isFloating>
                                        <Input type="text" placeholder="Enter College Name"
                                            onChange={(e: any) => setCollegeNameValue(e.target.value)}
                                            value={collegeNameValue} disabled={interimCollegeTransfer != "Yes" ? true : false} />
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3">
                                    <FormGroup id="isCollegeTransport" label="Transport" isFloating>
                                        <Select ariaLabel="" placeholder="Select Transport"
                                            onChange={(e: any) => selectIsCollegeTransport(e.target.value)}
                                            value={isCollegeTransport}>
                                            <option value='Yes'>Yes</option>
                                            <option value='No'>No</option>
                                        </Select>
                                    </FormGroup>
                                </div>

                                {isTransportDetails ?
                                    <>
                                        <div className="col-lg-3 ">
                                            <FormGroup id="transportAreaMasterId" label="Area of Student" isFloating>
                                                <Select ariaLabel="" placeholder="Select Area"
                                                    onChange={(e: any) => { setTransportAreaMasterId(e.target.value) }}
                                                    value={transportAreaMasterId}
                                                    list={transportAreaMasterData.map((data: any) => (
                                                        { value: data.transportAreaMasterId, text: data.areaName }
                                                    ))} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="modeOfWays" label="Mode Of Ways" isFloating>
                                                <Select ariaLabel="" placeholder="Select Ways"
                                                    onChange={(e: any) => { setModeOfWays(e.target.value) }}
                                                    value={modeOfWays}>
                                                    <option value='1'>One Way</option>
                                                    <option value='2'>Two Way</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                    </> : null}

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                <Button color="info" onClick={handlePrevious1} type="submit">previous</Button>
                                <Button color="info" type="submit">Next</Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>
        </PageWrapper>
    )
}
export default ClassInfo;