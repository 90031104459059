import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getSubjectPaperDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSubjectPaperDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSubjectPaperDetails - " + error.message)
            console.log('error caught in service : getSubjectPaperDetails')
        },
    );

    export const addSubjectPaper = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSubjectPaper`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addSubjectPaper')
        }
    );
    
    export const updateSubjectPaperDetails = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateSubjectPaperDetails`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateSubjectPaperDetails')
        }
    );
    export const deleteSubjectPaperDetails = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteSubjectPaperDetails`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteSubjectPaperDetails')
        }
    );

    export const getPaperType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPaperType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getPaperType - " + error.message)
            console.log('error caught in service : getPaperType')
        },
    );

    export const addPaperType = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addPaperType`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addPaperType')
        }
    );
    export const updatePaperType = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updatePaperType`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updatePaperType')
        }
    );
    export const deletePaperType = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deletePaperType`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteSubjectPaperDetails')
        }
    );

    export const getStaffSubjectAllocation = (courseId: string, batchMasterId: string, semesterDetailsId: string, sectionDetailsId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffSubjectAllocation/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffSubjectAllocation - " + error.message)
            console.log('error caught in service : getStaffSubjectAllocation')
        },
    );

    export const addStaffSubjectAllocationDetails = (addAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffSubjectAllocationDetails`, addAllocationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffSubjectAllocationDetails')
        }
    );

    export const updateStaffSubjectAllocationDetails = (updateAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffSubjectAllocationDetails`, updateAllocationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffSubjectAllocationDetails')
        }
    );

    export const deleteStaffSubjectAllocationDetails = (deleteAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffSubjectAllocationDetails`, deleteAllocationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffSubjectAllocationDetails')
        }
    );
    export const getPart = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPart/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getPart - " + error.message)
            console.log('error caught in service : getPart')
        },
    );

    export const getUnit = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUnit/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getUnit - " + error.message)
            console.log('error caught in service : getUnit')
        },
    );