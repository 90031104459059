import { useContext, useEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import Collapse from "../../bootstrap/Collapse";
import Icon from "../../icon/Icon";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Select from "../../bootstrap/forms/Select";
import AlertService from "../../../services/AlertService";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import { getGraduationType, getSectionDetails } from "../../../services/common.service";
import { getStudentDetailsBySemesterId, updateUniversityNumberForStudent } from "../../../services/student.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";

const UniversityNumberEdit = () => {

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])

    const [filteredData1, setFilteredData1] = useState<any>([])
    const [updateUniRegNo, setUpdateUniRegNo] = useState<any>([])
    const [isLoader, setIsLoader] = useState(false)

    // For Table
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const editUniversityNumberForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            uniRegNo: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = items.filter(
        (i) =>
            i.admissionNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())
    );

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if(graduationTypeId?.value != undefined){
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if(courseId?.value != undefined){
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if(batchMasterId?.value != undefined){
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                   // toasts(response.data.message, "Error")
                   setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setIsOpenListCard(false);
                        setFilteredData1(data)
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setStudentDetailsData([]);
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    const updateUniRegNoValue = (cell: number, e: any) => {
        const newData: any = [];

        filteredData1.forEach((item: any, index: any) => {
            if (item['studentDetailsId'] == cell) {
                item['uniRegNo'] = e
            }
            newData.push(item)
        });

        setFilteredData1(newData)

        if (updateUniRegNo.length == 0) {
            const obj = {
                studentDetailsId: cell,
                uniRegNo: e,
            };
            updateUniRegNo.push(obj);
        } else {
            let selectedItem: any;
            updateUniRegNo.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['uniRegNo'] = e
                }
            });
            if (selectedItem != undefined) {
                selectedItem['uniRegNo'] = e
            } else {
                var obj
                obj = {
                    studentDetailsId: cell,
                    uniRegNo: e,
                };

                updateUniRegNo.push(obj);
            }
        }
    }

    function setUniversityNumber() {
        return ({
            studentDetails: updateUniRegNo,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function editUniversityNumberSubmit() {
        showLoader(true);
        if (updateUniRegNo.length > 0) {
            let editUniversityNumberPostData = setUniversityNumber();
            updateUniversityNumberForStudent(editUniversityNumberPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateUniRegNo.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function closeAndReset() {
        setIsOpenListCard(true)
        setDataSuccess(false);
        setUpdateUniRegNo([])
        setDepartmentId('')
        setGraduationTypeId('')
        setCourseId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
    }

    return (
        <>
            <PageWrapper title="University Number">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Edit University Number
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row g-4">
                                    <div className='col-md-3'>
                                        <FormGroup id='departmentId' label='Department' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Department'
                                                onChange={selectDepartment}
                                                value={departmentId}
                                                list={departmentData.map((data: any) => (
                                                    { value: data.departmentId, label: data.departmentName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    {departmentId?.value != undefined ? <div className='col-md-3'>
                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Graduation Type'
                                                onChange={selectGraduationType}
                                                value={graduationTypeId}
                                                list={graduationTypeData.map((data: any) => (
                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {graduationTypeId?.value != undefined ? <div className='col-md-3'>
                                        <FormGroup id='courseId' label='Course' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Course'
                                                onChange={selectCourse}
                                                value={courseId}
                                                list={courseData.map((data: any) => (
                                                    { value: data.courseId, label: data.courseName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {courseId?.value != undefined ? <div className='col-md-3'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Batch'
                                                onChange={selectBatch}
                                                value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {batchMasterId?.value != undefined ? <div className='col-md-3'>
                                        <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Semester'
                                                onChange={selectSemester}
                                                value={semesterDetailsId}
                                                list={semesterDetailsData.map((data: any) => (
                                                    { value: data.semesterDetailsId, label: data.semesterNumber }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {semesterDetailsId?.value != undefined ? <div className='col-md-3'>
                                        <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Section'
                                                onChange={selectSection}
                                                value={sectionDetailsId}
                                                list={sectionDetailsData.map((data: any) => (
                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {sectionDetailsId?.value != undefined ? <div className='col-3 mt-4'>
                                        <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>
                                    </div> : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card stretch data-tour='list'>

                            <CardHeader borderSize={1}>
                                <div className='d-flex col-md-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>Student University Number Edit List</CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-md-7 justify-content-end'>
                                    <div className='d-flex col-8 justify-content-end' data-tour='search'>
                                        <ButtonGroup className="col-lg-8 d-print-none" color='primary'>
                                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </ButtonGroup>
                                    </div>&nbsp;&nbsp;
                                    <div className='col-md-2 justify-content-center'>
                                        <Button color='info' icon="Save" type="submit" isDisable={updateUniRegNo.length == 0} onClick={editUniversityNumberSubmit}>Save</Button>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>

                                            <th scope='col' onClick={() => requestSort('admissionNo')}
                                                className='cursor-pointer text-decoration-underline'>Admission Number{' '}
                                                <Icon size='lg' className={getClassNamesFor('admissionNo')} icon='FilterList' />
                                            </th>

                                            <th scope='col' onClick={() => requestSort('studentName')}
                                                className='cursor-pointer text-decoration-underline'>Student Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                            </th>

                                            <th>Course Name</th>
                                            <th>University Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {studentDetailsData != "" ?
                                            <>
                                        {filteredData.map((i: any) => (
                                            <tr key={i.studentDetailsId}>

                                                <td>{i.sno}</td>
                                                <td>{i.admissionNo}</td>
                                                <td>{i.studentName}</td>
                                                <td>{i.courseName}</td>
                                                <td scope='col'>
                                                    <FormGroup id='uniRegNo'>
                                                        <Input className="w-60" placeholder="" value={i.uniRegNo ? i.uniRegNo : ''} onInput={(e: any) => updateUniRegNoValue(i.studentDetailsId, e.target.value)} />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        ))}
                                        </>
                                            :
                                            <NoDataMsg columnsCount={5} msg={noDataMsg}/>}
                                    </tbody>
                                </table>
                            </CardBody>
                            {/* <PaginationButtons
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            /> */}

                            {/* <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <div className='row m-0'>
                                    <div className='col-9 p-3'></div>
                                    <div className='col-3 p-3'>
                                        <Button color='info' icon="Save" type="submit" className='w-50' onClick={editUniversityNumberSubmit}>Save</Button>
                                    </div>
                                </div>
                            </div> */}

                        </Card>
                        : null}
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default UniversityNumberEdit