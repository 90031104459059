import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { toasts } from "../../../services/toast.service";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import Input from "../../bootstrap/forms/Input";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import { convertDateToWords, getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getDiscontinuedStudentList, getProfileForStudent, rejoinStudentDetails } from "../../../services/student.service";
import { addStudentTransferCertificate, getStudentTransferCertificate, updateStudentTransferCertificate } from "../../../services/certificate.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";

function DiscontinuedStudentList() {


    useEffect(() => {
        getDiscontinuedStudent();
    }, [])

    const navigate = useNavigate();
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [discontinuedStudentData, setDiscontinuedStudentData] = useState([]);
    const [studentTransferCertificateData, setStudentTransferCertificateData] = useState([])
    const [studentProfileData, setStudentProfileData] = useState([])
    const [isStudentTCData, setStudentTCData] = useState(false)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal
    const [addTcDetailsModal, setAddTcDetailsModal] = useState(false);
    const [updateTcDetailsModal, setUpdateTcDetailsModal] = useState(false);

    const [dateOfBirthInWord, setDateOfBirthInWord] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(discontinuedStudentData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const rejoinedStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const studentTransferCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!values.gender) {
                errors.gender = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { addStudentTransferCertificateSubmit() },
    })

    const updateStudentTransferCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
            serialNo: "",
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!values.gender) {
                errors.gender = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { updateStudentTransferCertificateSubmit() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const convertDateOfBirth = (e: any) => {
        let dateOfBirth = e.target.value
        setDateOfBirth(dateOfBirth)
        setDateOfBirthInWord(convertDateToWords(dateOfBirth))
    };

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStudent = i
            rejoinedStudentForm.setValues({
                studentDetailsId: selectedStudent.studentDetailsId,
            });
            setStudentDetailsId(selectedStudent.studentDetailsId)
            getStudentTransferCertificateDetails(selectedStudent.studentDetailsId)
        }
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedStudentTransferCertificate = i
            window.open(`../academic/commonTc/${selectedStudentTransferCertificate.studentDetailsId}`)
        }
    }
    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getDiscontinuedStudent() {
        setDataStatus(false)
        getDiscontinuedStudentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.discontinuedStudentList;
                    if (data != undefined) {
                        getColumnsForTable('getDiscontinuedStudentList', 'get');
                        setDiscontinuedStudentData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getDiscontinuedStudentList', 'get');
                    setDiscontinuedStudentData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setRejoinedStudentDetails() {

        return ({
            studentDetailsId: rejoinedStudentForm.values.studentDetailsId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function rejoinedStudentSubmit() {
        showLoader(true)
        if (rejoinedStudentForm.isValid) {
            let rejoinedStudentFormPostData = setRejoinedStudentDetails()
            rejoinStudentDetails(rejoinedStudentFormPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getDiscontinuedStudent();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (rejoinedStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getStudentTransferCertificateDetails(studentDetailsId: number) {
        getStudentTransferCertificate(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTransferCertificate[0];
                    if (data != undefined) {
                        setDateOfBirth(data.dateOfBirth)
                        setDateOfBirthInWord(convertDateToWords(data.dateOfBirth))
                        setStudentTransferCertificateData(data);
                        setStudentTCData(true)
                        updateStudentTransferCertificateForm.setValues({
                            studentDetailsId: data['studentDetailsId'],
                            serialNo: data['serialNo'] ? data['serialNo'] : '',
                            admissionNo: data['admissionNo'] ? data['admissionNo'] : '',
                            studentName: data['studentName'] ? data['studentName'] : '',
                            dateOfBirth: dateOfBirth ? dateOfBirth : '',
                            dateOfBirthInWord: dateOfBirthInWord ? dateOfBirthInWord : '',
                            religion: data['religion'] ? data['religion'] : '',
                            caste: data['caste'] ? data['caste'] : '',
                            gender: data['gender'] ? data['gender'] : '',
                            nationality: data['nationality'] ? data['nationality'] : '',
                            admissionDate: data.admissionDate,
                            fatherOrMotherName: data['fatherOrMotherName'] ? data['fatherOrMotherName'] : '',
                            community: data['community'] ? data['community'] : '',
                            degreeAndCourse: data['degreeAndCourse'] ? data['degreeAndCourse'] : '',
                            timeOfLeaving: data['timeOfLeaving'] ? data['timeOfLeaving'] : '',
                            leavingDate: data.leavingDate ? data.leavingDate : '',
                            leavingReason: data['leavingReason'] ? data['leavingReason'] : '',
                            qualifiedForHigherStudy: data['qualifiedForHigherStudy'] ? data['qualifiedForHigherStudy'] : '',
                            transferCertificateDate: data.transferCertificateDate ? data.transferCertificateDate : '',
                            conductAndCharacter: data['conductAndCharacter'] ? data['conductAndCharacter'] : '',
                            medium: data['medium'] ? data['medium'] : ''
                        });

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentTCData(false)
                    getStudentProfile(studentDetailsId);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile[0];
                    if (data != undefined) {

                        setDateOfBirth(data.dateOfBirth)
                        setDateOfBirthInWord(convertDateToWords(data.dateOfBirth))
                        setStudentProfileData(data);
                        studentTransferCertificateForm.setValues({
                            studentDetailsId: data['studentDetailsId'],
                            admissionNo: data['admissionNo'] ? data['admissionNo'] : '',
                            studentName: data['studentName'] ? data['studentName'] : '',
                            dateOfBirth: dateOfBirth ? dateOfBirth : '',
                            dateOfBirthInWord: dateOfBirthInWord ? dateOfBirthInWord : '',
                            religion: data['religion'] ? data['religion'] : '',
                            caste: data['casteName'] ? data['casteName'] : '',
                            gender: data['gender'] ? data['gender'] : '',
                            nationality: data['nationality'] ? data['nationality'] : '',
                            admissionDate: data.admissionDateStamp ? data.admissionDateStamp : '',
                            fatherOrMotherName: data['fatherName'] ? data['fatherName'] : data['motherName'],
                            community: data['category'] ? data['category'] : '',
                            degreeAndCourse: data['admittedYear'] != null ?data['admittedYear'] + " " + data['courseName']: data['courseName'],
                            timeOfLeaving: data['currentYear'] + " - " + data['courseName'],
                            leavingDate: data.leavingDate ? data.leavingDate : '',
                            leavingReason: data['leavingReason'] ? data['leavingReason'] : '',
                            qualifiedForHigherStudy: data['qualifiedForHigherStudy'] ? data['qualifiedForHigherStudy'] : '',
                            transferCertificateDate: data.transferCertificateDate ? data.transferCertificateDate : '',
                            conductAndCharacter: data['conductAndCharacter'] ? data['conductAndCharacter'] : '',
                            medium: data['medium'] ? data['medium'] : ''
                        })

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    getStudentProfile(studentDetailsId);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStudentTransferCertificateDetails() {
        return ({
            studentDetailsId: studentTransferCertificateForm.values.studentDetailsId,
            admissionNo: studentTransferCertificateForm.values.admissionNo,
            studentName: studentTransferCertificateForm.values.studentName,
            fatherOrMotherName: studentTransferCertificateForm.values.fatherOrMotherName,
            religion: studentTransferCertificateForm.values.religion,
            nationality: studentTransferCertificateForm.values.nationality,
            caste: studentTransferCertificateForm.values.caste,
            community: studentTransferCertificateForm.values.community,
            gender: studentTransferCertificateForm.values.gender,
            dateOfBirth: dateOfBirth,
            dateOfBirthInWord: dateOfBirthInWord,
            admissionDate: studentTransferCertificateForm.values.admissionDate != '' ? studentTransferCertificateForm.values.admissionDate : null,
            degreeAndCourse: studentTransferCertificateForm.values.degreeAndCourse,
            timeOfLeaving: studentTransferCertificateForm.values.timeOfLeaving,
            leavingDate: studentTransferCertificateForm.values.leavingDate != '' ? studentTransferCertificateForm.values.leavingDate : null,
            leavingReason: studentTransferCertificateForm.values.leavingReason,
            qualifiedForHigherStudy: studentTransferCertificateForm.values.qualifiedForHigherStudy,
            transferCertificateDate: studentTransferCertificateForm.values.transferCertificateDate != '' ? studentTransferCertificateForm.values.transferCertificateDate : null,
            conductAndCharacter: studentTransferCertificateForm.values.conductAndCharacter,
            medium: studentTransferCertificateForm.values.medium,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditStudentTransferCertificateDetails() {
        return ({
            studentDetailsId: updateStudentTransferCertificateForm.values.studentDetailsId,
            serialNo: updateStudentTransferCertificateForm.values.serialNo,
            admissionNo: updateStudentTransferCertificateForm.values.admissionNo,
            studentName: updateStudentTransferCertificateForm.values.studentName,
            fatherOrMotherName: updateStudentTransferCertificateForm.values.fatherOrMotherName,
            religion: updateStudentTransferCertificateForm.values.religion,
            nationality: updateStudentTransferCertificateForm.values.nationality,
            caste: updateStudentTransferCertificateForm.values.caste,
            community: updateStudentTransferCertificateForm.values.community,
            gender: updateStudentTransferCertificateForm.values.gender,
            dateOfBirth: dateOfBirth,
            dateOfBirthInWord: dateOfBirthInWord,
            admissionDate: updateStudentTransferCertificateForm.values.admissionDate != '' ? updateStudentTransferCertificateForm.values.admissionDate : null,
            degreeAndCourse: updateStudentTransferCertificateForm.values.degreeAndCourse,
            timeOfLeaving: updateStudentTransferCertificateForm.values.timeOfLeaving,
            leavingDate: updateStudentTransferCertificateForm.values.leavingDate != '' ? updateStudentTransferCertificateForm.values.leavingDate : null,
            leavingReason: updateStudentTransferCertificateForm.values.leavingReason,
            qualifiedForHigherStudy: updateStudentTransferCertificateForm.values.qualifiedForHigherStudy,
            transferCertificateDate: updateStudentTransferCertificateForm.values.transferCertificateDate != '' ? updateStudentTransferCertificateForm.values.transferCertificateDate : null,
            conductAndCharacter: updateStudentTransferCertificateForm.values.conductAndCharacter,
            medium: updateStudentTransferCertificateForm.values.medium,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addStudentTransferCertificateSubmit() {
        showLoader(true)
        if (studentTransferCertificateForm.isValid) {
            let addStudentTransferCertificatePost = setStudentTransferCertificateDetails();
            addStudentTransferCertificate(addStudentTransferCertificatePost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        closeAndReset();
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentTransferCertificateForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStudentTransferCertificateSubmit() {
        showLoader(true)
        if (updateStudentTransferCertificateForm.isValid) {
            let editStudentTransferCertificatePost = setEditStudentTransferCertificateDetails();
            updateStudentTransferCertificate(editStudentTransferCertificatePost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentTransferCertificateForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getDiscontinuedStudentList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    function closeAndReset() {
        setAddTcDetailsModal(false);
        setUpdateTcDetailsModal(false);
        studentTransferCertificateForm.resetForm();
        updateStudentTransferCertificateForm.resetForm();
    }


    return (
        <>
            <PageWrapper title="Discontinued Student List">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Discontinued Student List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getDiscontinuedStudentList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("DiscontinuedStudentList", discontinuedStudentData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(discontinuedStudentData, columnVisibilityData, "DiscontinuedStudentList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("DiscontinuedStudentList", discontinuedStudentData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight tag='a'
                                    to={`../academic/addStudent`}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <td className="d-print-none" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => (
                                                (column.isDisplay && rowData[column.keyName]) ?
                                                    <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className='d-print-none' onClick={() => onActivate(rowData)}>
                                                    <Dropdown isButtonGroup>
                                                        <DropdownToggle>
                                                            <Button className="text-nowrap" color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
                                                        </DropdownToggle>
                                                        <DropdownMenu isAlignmentEnd className='card-body-scrollable'>
                                                            <DropdownItem>
                                                                <Button icon="Replay" isLight color="info" onClick={() => rejoinedStudentSubmit()}>Rejoin</Button>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <Button icon="Receipt" isLight color="success" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId}`)}>Fee Details</Button>
                                                            </DropdownItem>
                                                            {isStudentTCData ?
                                                                <>                                                      <DropdownItem>
                                                                    <Button icon="Edit" isLight color="primary"
                                                                        onClick={() => { setUpdateTcDetailsModal(true) }}>Update Tc Details</Button>
                                                                </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="Print" isLight color="primary"
                                                                            onClick={() => { onPrint(rowData) }}>Preview</Button>
                                                                    </DropdownItem>
                                                                </>
                                                                : <DropdownItem>
                                                                    <Button icon="Save" isLight color="primary"
                                                                        onClick={() => { setAddTcDetailsModal(true) }}>Add Tc Details</Button>
                                                                </DropdownItem>}</DropdownMenu></Dropdown></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 3} msg={noDataMsg} />}</tbody></table>}
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>


                <Modal
                    setIsOpen={setAddTcDetailsModal}
                    isOpen={addTcDetailsModal}
                    id='addTcDetailsModal'
                    isStaticBackdrop={true}
                    //isScrollable={true}
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={setAddTcDetailsModal} onClick={closeAndReset}>
                        <ModalTitle id='addTcDetailsModal'>Add Transfer Certificate</ModalTitle>
                    </ModalHeader>
                    <form noValidate onSubmit={studentTransferCertificateForm.handleSubmit}>
                        <ModalBody>
                            <div className='row'>
                                <div className="d-grid gap-4">
                                    <div className='row'>
                                        <div className="d-flex col-lg-12 justify-content-end">
                                            <div className="align-self-center" style={{ marginRight: '10px' }}>
                                                <p className="h6">Admission No :</p>
                                            </div>
                                            <div>
                                                <FormGroup id="admissionNo" label="" >
                                                    <Input type="text" placeholder="Admission Number" onChange={studentTransferCertificateForm.handleChange}
                                                        value={studentTransferCertificateForm.values.admissionNo}
                                                        onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.admissionNo} invalidFeedback={studentTransferCertificateForm.errors.admissionNo}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row  '>
                                        <div className='d-grid gap-4'>
                                            <div className="row justify-content-start">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>1.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Name of the Student (in BLOCK LETTERS) (as entered in +2 or equivalent Certificate)</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="studentName" label="">
                                                        <Input type="text" placeholder="Name Of Student"
                                                            onChange={studentTransferCertificateForm.handleChange}
                                                            value={studentTransferCertificateForm.values.studentName}
                                                            onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.studentName} invalidFeedback={studentTransferCertificateForm.errors.studentName}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>2.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Name of the Father or Mother of the Puplil </p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="fatherOrMotherName" label=""  >
                                                        <Input type="text" placeholder="Name Of Father Or Mother"
                                                            onChange={studentTransferCertificateForm.handleChange}
                                                            value={studentTransferCertificateForm.values.fatherOrMotherName} onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.fatherOrMotherName} invalidFeedback={studentTransferCertificateForm.errors.fatherOrMotherName} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>3.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Nationality</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="nationality" label="">
                                                        <Input type="text" placeholder="Nationality" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.nationality} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>4.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Religion</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="religion" label="">
                                                        <Input type="text" placeholder="Religion"
                                                            onChange={studentTransferCertificateForm.handleChange}
                                                            value={studentTransferCertificateForm.values.religion} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>5.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Caste</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="caste" label="">
                                                        <Input type="text" placeholder="Caste" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.caste} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>6.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Community</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="community" label="">
                                                        <Input type="text" placeholder="Community" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.community} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>7.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Gender</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="gender" label="">
                                                        <Input type="text" placeholder="Male Or Female"
                                                            onChange={studentTransferCertificateForm.handleChange}
                                                            value={studentTransferCertificateForm.values.gender} onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.gender} invalidFeedback={studentTransferCertificateForm.errors.gender} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>8.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth as entered in the Admission</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="dateOfBirth" label="">
                                                        <Input type="date" placeholder="Date Of Birth"
                                                            onChange={convertDateOfBirth}
                                                            value={dateOfBirth} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>9.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth in Word</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="dateOfBirthInWord" label="">
                                                        <Input type="text" placeholder="Date Of Birth"
                                                            onChange={studentTransferCertificateForm.handleChange}
                                                            value={dateOfBirthInWord} disabled />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>10.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date of Admission and class in which admitted</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="admissionDate" label="">
                                                        <Input type="date" placeholder="Date of Admission" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.admissionDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>11.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Degree and Programme to which the student was admitted</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="degreeAndCourse" label="">
                                                        <Input type="text" placeholder="Degree and Programme" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.degreeAndCourse} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>12.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Class in which the pupil was studying at the time of leaving</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="timeOfLeaving" label="">
                                                        <Input type="text" placeholder="time of leaving" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.timeOfLeaving} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>13.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date on which the student actually left the College</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="leavingDate" label="">
                                                        <Input type="date" placeholder="Date" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.leavingDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>14.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Reason for which the student left the College</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="leavingReason" label="">
                                                        <Input type="text" placeholder="Reason" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.leavingReason} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>15.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">
                                                        Whether qualified for promotion to higher studies
                                                    </p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="qualifiedForHigherStudy" label="">
                                                        <Input type="text" placeholder="Qualified for promotion" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.qualifiedForHigherStudy} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>16.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date of the Transfer Certificate</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="transferCertificateDate" label="">
                                                        <Input type="date" placeholder="Date Of Transfer Certificate" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.transferCertificateDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>17.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Conduct and Character</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="conductAndCharacter" label="">
                                                        <Input type="text" placeholder="Conduct And Character" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.conductAndCharacter} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>18.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Medium(for M.Phil Only)</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="medium" label="">
                                                        <Input type="text" placeholder="Medium" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.medium} />
                                                    </FormGroup>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>
                        <ModalFooter >
                            <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                            <Button color='info' icon='Save' type="submit" isDisable={!studentTransferCertificateForm.isValid && !!studentTransferCertificateForm.submitCount}>Save </Button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal
                    setIsOpen={setUpdateTcDetailsModal}
                    isOpen={updateTcDetailsModal}
                    id='updateTcDetailsModal'
                    isStaticBackdrop={true}
                    //isScrollable={true}
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={setUpdateTcDetailsModal} onClick={closeAndReset}>
                        <ModalTitle id='updateTcDetailsModal'>Update Transfer Certificate</ModalTitle>
                    </ModalHeader>
                    <form noValidate onSubmit={updateStudentTransferCertificateForm.handleSubmit}>
                        <ModalBody>
                            <div className='row'>
                                <div className="d-grid gap-4">
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className="d-flex col-lg-5 justify-content-start">
                                            <div className="align-self-center" style={{ marginRight: '10px' }}>
                                                <p className="h6">Serial No :</p>
                                            </div>
                                            <div>
                                                <FormGroup id="serialNo" label="" >
                                                    <Input type="text" placeholder="Serial Number"
                                                        onChange={updateStudentTransferCertificateForm.handleChange}
                                                        value={updateStudentTransferCertificateForm.values.serialNo} disabled />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="d-flex col-lg-6 justify-content-end">
                                            <div className="align-self-center" style={{ marginRight: '10px' }}>
                                                <p className="h6">Admission No :</p>
                                            </div>
                                            <div>
                                                <FormGroup id="admissionNo" label="" >
                                                    <Input type="text" placeholder="Admission Number" onChange={updateStudentTransferCertificateForm.handleChange}
                                                        value={updateStudentTransferCertificateForm.values.admissionNo} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.admissionNo} invalidFeedback={updateStudentTransferCertificateForm.errors.admissionNo} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row  '>
                                        <div className='d-grid gap-4'>
                                            <div className="row justify-content-start">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>1.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Name of the Student (in BLOCK LETTERS) (as entered in +2 or equivalent Certificate)</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="studentName" label="">
                                                        <Input type="text" placeholder="Name Of Student"
                                                            onChange={updateStudentTransferCertificateForm.handleChange}
                                                            value={updateStudentTransferCertificateForm.values.studentName} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.studentName} invalidFeedback={updateStudentTransferCertificateForm.errors.studentName} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>2.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Name of the Father or Mother of the Puplil </p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="fatherOrMotherName" label=""  >
                                                        <Input type="text" placeholder="Name Of Father Or Mother"
                                                            onChange={updateStudentTransferCertificateForm.handleChange}
                                                            value={updateStudentTransferCertificateForm.values.fatherOrMotherName} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.fatherOrMotherName} invalidFeedback={updateStudentTransferCertificateForm.errors.fatherOrMotherName} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>3.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Nationality</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="nationality" label="">
                                                        <Input type="text" placeholder="Nationality" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.nationality} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>4.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Religion</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="religion" label="">
                                                        <Input type="text" placeholder="Religion"
                                                            onChange={updateStudentTransferCertificateForm.handleChange}
                                                            value={updateStudentTransferCertificateForm.values.religion} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>5.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Caste</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="caste" label="">
                                                        <Input type="text" placeholder="Caste" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.caste} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>6.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Community</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="community" label="">
                                                        <Input type="text" placeholder="Community" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.community} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>7.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Gender</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="gender" label="">
                                                        <Input type="text" placeholder="Male Or Female"
                                                            onChange={updateStudentTransferCertificateForm.handleChange}
                                                            value={updateStudentTransferCertificateForm.values.gender} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.gender} invalidFeedback={updateStudentTransferCertificateForm.errors.gender} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>8.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth as entered in the Admission</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="dateOfBirth" label="">
                                                        <Input type="date" placeholder="Date Of Birth"
                                                            onChange={convertDateOfBirth}
                                                            value={dateOfBirth} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>9.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth in Word</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="dateOfBirthInWord" label="">
                                                        <Input type="text" placeholder="Date Of Birth"
                                                            onChange={updateStudentTransferCertificateForm.handleChange}
                                                            value={dateOfBirthInWord} disabled />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>10.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date of Admission and class in which admitted</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="admissionDate" label="">
                                                        <Input type="date" placeholder="Date of Admission" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.admissionDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>11.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Degree and Programme to which the student was admitted</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="degreeAndCourse" label="">
                                                        <Input type="text" placeholder="Degree and Programme" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.degreeAndCourse} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>12.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Class in which the pupil was studying at the time of leaving</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="timeOfLeaving" label="">
                                                        <Input type="text" placeholder="Class" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.timeOfLeaving} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>13.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date on which the student actually left the College</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="leavingDate" label="">
                                                        <Input type="date" placeholder="Date" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.leavingDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>14.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Reason for which the student left the College</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="leavingReason" label="">
                                                        <Input type="text" placeholder="Reason" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.leavingReason} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center">
                                                    <p className='h6'>15.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">
                                                        Whether qualified for promotion to higher studies
                                                    </p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="qualifiedForHigherStudy" label="">
                                                        <Input type="text" placeholder="Qualified for promotion" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.qualifiedForHigherStudy} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>16.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Date of the Transfer Certificate</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="transferCertificateDate" label="">
                                                        <Input type="date" placeholder="Date Of Transfer Certificate" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.transferCertificateDate} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>17.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Conduct and Character</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="conductAndCharacter" label="">
                                                        <Input type="text" placeholder="Conduct And Character" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.conductAndCharacter} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-1 align-self-center ">
                                                    <p className='h6'>18.</p>
                                                </div>
                                                <div className="col-lg-5 align-self-center">
                                                    <p className="h6">Medium(for M.Phil Only)</p>
                                                </div>
                                                <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                                <div className="col-lg-5 justify-content-center">
                                                    <FormGroup id="medium" label="">
                                                        <Input type="text" placeholder="Medium" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.medium} />
                                                    </FormGroup>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>
                        <ModalFooter >
                            <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                            <Button color='info' icon='Save' type="submit" isDisable={!updateStudentTransferCertificateForm.isValid && !!updateStudentTransferCertificateForm.submitCount}>Update </Button>
                        </ModalFooter>
                    </form>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper>
        </>
    )
}
export default DiscontinuedStudentList;