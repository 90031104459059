
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Collapse from "../../../bootstrap/Collapse";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import { useContext, useEffect, useRef, useState } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import { useFormik } from "formik";
import classNames from "classnames";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import Icon from "../../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import Input from "../../../bootstrap/forms/Input";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import { toasts } from "../../../../services/toast.service";
import { getStudentFeeConcessionGeneral, getStudentFeeConcessionReferral } from "../../../../services/concession.service";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import Select from "../../../bootstrap/forms/Select";
import { TableLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";

function ConcessionReport() {

	// useEffect(() => {
	// 	getGeneralConcession()
	// 	getReferralConcession()
	// }, [])

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();

	const [generalConcessionData, setGeneralConcessionData] = useState([]);
	const [referralConcessionData, setReferralConcessionData] = useState([]);

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([])
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false)

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(generalConcessionData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [isOpenListCard, setIsOpenListCard] = useState(true)
	const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
	const [dataSuccess, setDataSuccess] = useState(false)
	const [isReportOrAdd, setIsReportOrAdd] = useState('')

	const [isLoader, setIsLoader] = useState(false)

	const [reportTypeId, setReportTypeId] = useState<any>('')

	const [noDataMsg, setNoDataMsg] = useState('')

	const [reportTypeIdValue, setReportTypeIdValue] = useState<any>('')

	const selectReportType = (e: any) => {
		setIsReportOrAdd('')
		setDataSuccess(false)
		setIsLoader(false)
		let reportTypeId = e
		setReportTypeId(reportTypeId?.value)
		setReportTypeIdValue(reportTypeId)
		setIsOpenListCard(true)
	}

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		validateOnChange: false,
		onSubmit: () => { },
	});

	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	const filteredData1 = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	function getGeneralConcession() {
		setIsLoader(true);
		getStudentFeeConcessionGeneral(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeConcession;
					if (data != undefined) {
						setGeneralConcessionData(data);
						setIsReportOrAdd('generalConcession')
						getColumnsForTable('getStudentFeeConcession', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setIsLoader(false);
						setDataSuccess(true)
						setIsOpenListCard(false);
					} else {
						setIsLoader(false);
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setIsLoader(false);
					// toasts(response.data.message, "Error")
					setNoDataMsg(response.data.message)
					setIsReportOrAdd('generalConcession')
					setGeneralConcessionData([]);
					getColumnsForTable('getStudentFeeConcession', 'get');
					setIsLoader(false);
					setDataSuccess(true)
					setIsOpenListCard(false);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				setIsLoader(false);
				toasts(error, "Error")
			}
		);
	}

	function getReferralConcession() {
		setIsLoader(true);
		getStudentFeeConcessionReferral(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeConcession;
					if (data != undefined) {
						setIsReportOrAdd('referralConcession')
						getColumnsForTable('getStudentFeeConcession', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setReferralConcessionData(data);
						setIsLoader(false);
						setDataSuccess(true)
						setIsOpenListCard(false);
					} else {
						setIsLoader(false);
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {

					// toasts(response.data.message, "Error")
					setNoDataMsg(response.data.message)
					setReferralConcessionData([]);
					setIsReportOrAdd('referralConcession')
					getColumnsForTable('getStudentFeeConcession', 'get');
					setDataSuccess(true)
					setIsOpenListCard(false);

					setIsLoader(false);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				setIsLoader(false);
				toasts(error, "Error")
			}
		);

	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStudentFeeConcession', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	// function getReferralColumnsForDataTable(type: any) {
	// 	console.log("Inside getColumnsForDataTable");
	// 	axios.get(apiUrl + `getColumnsForDataTable/5566/1/getStudentFeeConcession/0`)
	// 		.then((response) => {
	// 			if (response.data.success) {
	// 				let data = response.data.data.columnsForDataTable;
	// 				if (data != undefined) {
	// 					let allColumnsData = data;
	// 					setAllColumnsData(allColumnsData)
	// 					let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
	// 					let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
	// 					setColumnVisibilityData(columnVisibilityData)

	// 					if (type == 'get') {
	// 						const allData = allColumnsData.map((item: any) => item.isDisplay);
	// 						const allColumns = allData.filter((item: any) => item == false);
	// 						setIsChecked(allColumns == '' ? true : false);
	// 					}
	// 					else if (type == 'post') {
	// 						const all = [];
	// 						for (let i = 0; i < allColumnsData.length; i++) {
	// 							const obj = {
	// 								columnVisibilityId: allColumnsData[i].columnVisibilityId,
	// 								isDisplay: allColumnsData[0].isDisplay == 0 ? 1 : 0
	// 							}
	// 							all.push(obj);
	// 						}
	// 						updateColumnsSubmit('', '', all)
	// 					}
	// 				} else {
	// 					toasts("Undefined Data", "Error")
	// 				}
	// 			}
	// 			else if (response.data.success === false) {
	// 				toasts(response.data.message, "Error")
	// 			} else {
	// 				let errorCode = response.data.error[0].error_code;
	// 				let errorDescription = response.data.error[0].error_description;
	// 				toasts(errorDescription, "Error")
	// 			}
	// 		}).catch((error) => {
	// 			console.log(JSON.stringify(error))
	// 			toasts("Error", "Error")
	// 		});
	// }

	// const updateReferralColumnsSubmit = (columnVisibilityId: any, isDisplay: any, type: any) => {

	// 	if (columnVisibilityForm.isValid) {

	// 		setColumnDataById(isDisplay)
	// 		const postData = {
	// 			licenseKey: "5566",
	// 			userAccountId: 230,
	// 			columns: type ? type : [
	// 				{
	// 					columnVisibilityId: columnVisibilityId,
	// 					isDisplay: isDisplay ? 0 : 1
	// 				}
	// 			]
	// 		}
	// 		axios.post(apiUrl + `updateColumnsForDataTable`, postData)
	// 			.then((response) => {
	// 				const data = response.data;
	// 				if (data.success == true) {
	// 					getReferralColumnsForDataTable('get')
	// 				}
	// 				else if (data.success == false) {
	// 				}
	// 				else {
	// 					let errorCode = response.data.error[0].error_code;
	// 					let errorDescription = response.data.error[0].error_description;
	// 					toasts(errorDescription, "Error")
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				toasts("Error", "Error")
	// 			})
	// 	} else if (columnVisibilityForm.isValid == false) {
	// 		toasts("Please fill all the details!", "Error")
	// 	}
	// }

	function generalConcession() {
		setIsReportOrAdd('')
		setIsLoader(true);
		getGeneralConcession()
	}

	function referralConcession() {
		setIsReportOrAdd('')
		setIsLoader(true);
		getReferralConcession()
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	
    const reportTypeData = [{ reportTypeId: 1, reportType: 'General Concession' }, { reportTypeId: 2, reportType: 'Referral Concession' }]

	return (
		<>
			<PageWrapper title="Concession Report">
				<Page container='fluid'>
					<Collapse isOpen={isOpenListCard}>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className="col-lg-6">
									<CardTitle tag='div' className='h5'>Concession Report</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className="row">
									<div className="col-lg-3">
									<FormGroup id="reportTypeId" label="Select Report Type" isFloating>
                                        <SearchableSelect placeholder="Select Report Type" isFloating
                                            ariaLabel='Report Type'
                                            onChange={selectReportType}
                                            value={reportTypeIdValue}
                                            list={reportTypeData.map((data: any) => (
                                                { value: data.reportTypeId, label: data.reportType }
                                            ))}
                                        />
                                    </FormGroup>
									</div>
									{reportTypeId == '1' ?
										<>
											<div className="col-lg-3 align-self-center">
												<FormGroup >
													<Button icon="South" color="primary"
														onClick={generalConcession}>View</Button>
												</FormGroup>
											</div>
										</>
										: null}
									{reportTypeId == '2' ?
										<>
											<div className="col-lg-3 align-self-center">
												<FormGroup >
													<Button icon="South" color="primary"
														onClick={referralConcession}>View</Button>
												</FormGroup>
											</div>
										</>
										: null}
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

					{isReportOrAdd == 'generalConcession' && reportTypeId == '1' && (
						<>
							<Card stretch data-tour='list' ref={componentRef} id='pdf'>
								<CardHeader borderSize={1}>
									<CardLabel icon='List' iconColor='info' className="col-lg-6">
										<CardTitle tag='div' className='h5'>General Concession</CardTitle>
									</CardLabel>&nbsp;&nbsp;

									<ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
										<Icon className="mt-1" icon='Search' size='2x' color='primary' />
										<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
									</ButtonGroup>

									<CardActions className="d-print-none">
										<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
											<DropdownToggle>
												<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
												<DropdownItem>
													<div className='container py-2'>
														<form className='row g-3'>
															<div className='col-12'>
																<FormGroup>
																	<h6>Select All</h6>
																	<Checks
																		id='available'
																		type='switch'
																		label='Select All Columns'
																		onChange={() => getColumnsForTable('getStudentFeeConcession', 'post')}
																		checked={isChecked}
																		ariaLabel='Available status'
																	/>
																</FormGroup>
															</div>
															<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
																<FormGroup>
																	<h6>Columns</h6>
																	<ChecksGroup>
																		{allColumnsData.map((i: any) => (
																			<Checks
																				key={i.columnVisibilityId}
																				id='{i.columnVisibilityId}'
																				label={i.columnName}
																				onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
																				checked={i.isDisplay}
																			/>
																		))}
																	</ChecksGroup>
																</FormGroup>
															</div>
														</form>
													</div>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
										<Dropdown isButtonGroup>
											<DropdownToggle>
												<Button color='primary' isLight icon='CloudDownload'> Export </Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd>
												<DropdownItem>
													<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("GeneralConcessionData", generalConcessionData, columnVisibilityData)}> Excel </Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(generalConcessionData, columnVisibilityData, "GeneralConcessionData")}> PDF
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("GeneralConcessionData", generalConcessionData, columnVisibilityData)}> CSV </Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>

									</CardActions>
								</CardHeader>
								<CardBody className='table-responsive' isScrollable>
									{!dataSuccess ?
										<TableLoader /> :
										<table className='table table-modern table-hover text-nowrap'>
											<thead>
												<tr>
													<th scope='col' onClick={() => requestSort('sno')}
														className='cursor-pointer text-decoration-underline'>S.No{' '}
														<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
													</th>
													{columnVisibilityData.map((column: any) => (
														<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
															className='cursor-pointer text-decoration-underline'>
															{column.columnName}
															<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
														</th>
													))}
													<td />
												</tr>
											</thead>
											<tbody>
												{filteredData != '' ?
													<>
														{filteredData.map((rowData: any) => (
															<tr key={rowData.studentFeeConcessionId}>
																<td>{rowData.sno}</td>
																{columnVisibilityData.map((column: any) => (
																	(column.isDisplay && rowData[column.keyName]) ?
																		<td key={`${rowData.studentFeeConcessionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
																))}
																<td className='d-print-none'><div>
																	<Button
																		isOutline={!darkModeStatus}
																		color='success'
																		isLight={darkModeStatus}
																		className={classNames('text-nowrap', {
																			'border-light': !darkModeStatus,
																		}, 'mg-lg-t-2')}>A
																	</Button>
																	<Button
																		isOutline={!darkModeStatus}
																		color='danger'
																		isLight={darkModeStatus}
																		className={classNames('text-nowrap', {
																			'border-light': !darkModeStatus,
																		}, 'mg-lg-t-2')}>R
																	</Button></div></td>
															</tr>
														))}
													</> :
													<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
											</tbody>
										</table>
									}
								</CardBody>
								<PaginationButtons
									data={items}
									label='items'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</>)}
					{isReportOrAdd == 'referralConcession' && reportTypeId == '2' && (
						<>
							<Card stretch data-tour='list'>
								<CardHeader borderSize={1}>
									<CardLabel icon='List' iconColor='info' className="col-lg-6">
										<CardTitle tag='div' className='h5'>Referral Concession</CardTitle>
									</CardLabel>&nbsp;&nbsp;

									<ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
										<Icon className="mt-1" icon='Search' size='2x' color='primary' />
										<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
									</ButtonGroup>

									<CardActions className="d-print-none">
										<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
											<DropdownToggle>
												<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
												<DropdownItem>
													<div className='container py-2'>
														<form className='row g-3'>
															<div className='col-12'>
																<FormGroup>
																	<h6>Select All</h6>
																	<Checks
																		id='available'
																		type='switch'
																		label='Select All Columns'
																		onChange={() => getColumnsForTable('getStudentFeeConcession', 'post')}
																		checked={isChecked}
																		ariaLabel='Available status'
																	/>
																</FormGroup>
															</div>
															<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
																<FormGroup>
																	<h6>Columns</h6>
																	<ChecksGroup>
																		{allColumnsData.map((i: any) => (
																			<Checks
																				key={i.columnVisibilityId}
																				id='{i.columnVisibilityId}'
																				label={i.columnName}
																				onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
																				checked={i.isDisplay}
																			/>
																		))}
																	</ChecksGroup>
																</FormGroup>
															</div>
														</form>
													</div>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
										<Dropdown isButtonGroup>
											<DropdownToggle>
												<Button color='primary' isLight icon='CloudDownload'> Export </Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd>
												<DropdownItem>
													<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ReferralConcessionData", referralConcessionData, columnVisibilityData)}> Excel </Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(referralConcessionData, columnVisibilityData, "ReferralConcessionData")}> PDF
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ReferralConcessionData", referralConcessionData, columnVisibilityData)}> CSV </Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>

									</CardActions>
								</CardHeader>
								<CardBody className='table-responsive' isScrollable>
									{!dataSuccess ?
										<TableLoader /> :
										<table className='table table-modern table-hover text-nowrap'>
											<thead>
												<tr>
													<th scope='col' onClick={() => requestSort('sno')}
														className='cursor-pointer text-decoration-underline'>S.No{' '}
														<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
													</th>
													{columnVisibilityData.map((column: any) => (
														<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
															className='cursor-pointer text-decoration-underline'>
															{column.columnName}
															<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
														</th>
													))}
													<th scope='col' className='d-print-none'>
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{filteredData1 != '' ?
													<>
														{filteredData1.map((rowData: any) => (
															<tr key={rowData.studentFeeConcessionId}>
																<td>{rowData.sno}</td>
																{columnVisibilityData.map((column: any) => (
																	(column.isDisplay && rowData[column.keyName]) ?
																		<td key={`${rowData.studentFeeConcessionId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
																))}
																<td className='d-print-none'><Button
																	isOutline={!darkModeStatus}
																	color='dark'
																	isLight={darkModeStatus}
																	className={classNames('text-nowrap', {
																		'border-light': !darkModeStatus,
																	})}
																	icon='Edit'>
																</Button></td>
															</tr>
														))}

													</> :
													<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}

											</tbody>
										</table>
									}
								</CardBody>
								<PaginationButtons
									data={items}
									label='items'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</>)}
				</Page>

			</PageWrapper>
		</>
	)
}
export default ConcessionReport;