import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

    export const getStudentFeeConcession = (studentDetailsId : number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeConcessionListByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeConcessionListByStudentDetailsId - " + error.message)
            console.log('error caught in service : getStudentFeeConcessionListByStudentDetailsId')
        },
    );

    export const getStudentFeeConcessionGeneral = ( success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeConcession/${getLicenseKey}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeConcession - " + error.message)
            console.log('error caught in service : getStudentFeeConcession')
        },
    );

    export const getStudentFeeConcessionReferral = ( success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeConcession/${getLicenseKey}/2`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeConcession - " + error.message)
            console.log('error caught in service : getStudentFeeConcession')
        },
    );