import React, { useContext, useEffect, useRef, useState } from 'react'
import Page from '../../../../../layout/Page/Page'
import Collapse from '../../../../bootstrap/Collapse'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../bootstrap/Card'
import FormGroup from '../../../../bootstrap/forms/FormGroup'
import Select from '../../../../bootstrap/forms/Select'
import Input from '../../../../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../../../../bootstrap/Button'
import { toasts } from '../../../../../services/toast.service'
import { useFormik } from 'formik'
import Icon from "../../../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../../bootstrap/Dropdown'
import Checks, { ChecksGroup } from '../../../../bootstrap/forms/Checks'
import PaginationButtons, { dataPagination } from '../../../../PaginationButtons'
import useSortableData from '../../../../../hooks/useSortableData'
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../../services/ExportService";
import { useReactToPrint } from 'react-to-print'
import OpenCardComponent from '../../../../../common/components/OpenCardComponent'
import useDarkMode from '../../../../../hooks/useDarkMode'
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper'
import { convertDateRangeToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getGraduationType, updateColumnsForDataTable, updateFilter } from '../../../../../services/common.service'
import AuthContext from '../../../../../contexts/authContext'
import { getLicenseKey } from '../../../../../services/application.settings'
import NoDataMsg from '../../../../../common/components/NoDataMsg'
import SearchableSelect from '../../../../../common/components/SearchableSelect'
import { getTransferCertificateIssueList } from '../../../../../services/certificate.service'

function IssuedTCList() {

    useEffect(() => {
        getGraduationList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [graduationTypeId, setGraduationTypeId] = useState<any>({ value: 0, label: 'Select All' })

    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [transferCertificateIssueList, setTransferCertificateIssueList] = useState([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const componentRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(transferCertificateIssueList);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    const [columnDataById, setColumnDataById] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)

    const [noDataMsg, setNoDataMsg] = useState('')

    const issuedTCListForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
    }
    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function viewStudentList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = issuedTCListForm.values.fromDate
        let toDate = issuedTCListForm.values.toDate
        let datesArray = convertDateRangeToEpoch(fromDate, toDate);
        getTransferCertificateIssue(graduationTypeId?.value, datesArray[0], datesArray[1]);
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedStudentTransferCertificate = i
            window.open(`../academic/commonTc/${selectedStudentTransferCertificate.studentDetailsId}`)
        }
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        const graduationTypeData = [{ graduationTypeId: 0, graduationType: 'Select All' }];
                        data.forEach((obj: { graduationTypeId: number; graduationType: string }) => {
                            let graduationType = graduationTypeData.push(obj);
                        });
                        setGraduationTypeData(graduationTypeData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransferCertificateIssue(graduationTypeId: number, fromDate: any, toDate: any) {
        setIsLoader(true);
        getTransferCertificateIssueList(graduationTypeId, fromDate, toDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transferCertificateIssueList;
                    if (data != undefined) {
                        getColumnsForTable('getTransferCertificateIssueList', 'get');
                        setTransferCertificateIssueList(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {

                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getTransferCertificateIssueList', 'get');
                    setTransferCertificateIssueList([]);
                    setNoDataMsg(response.data.message)
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getTransferCertificateIssueList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Issued Tc List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Issued Transfer Certificate List
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className='col-3'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, label: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-3'>
                                    <FormGroup id='fromDate' label='From Date' isFloating>
                                        <Input onChange={issuedTCListForm.handleChange}
                                            value={issuedTCListForm.values.fromDate}
                                            onBlur={issuedTCListForm.handleBlur}
                                            isValid={issuedTCListForm.isValid}
                                            isTouched={issuedTCListForm.touched.fromDate}
                                            invalidFeedback={issuedTCListForm.errors.fromDate}
                                            type='date' />
                                    </FormGroup>
                                </div>

                                <div className='col-3'>
                                    <FormGroup id='toDate' label='To Date' isFloating>
                                        <Input onChange={issuedTCListForm.handleChange}
                                            value={issuedTCListForm.values.toDate}
                                            onBlur={issuedTCListForm.handleBlur}
                                            isValid={issuedTCListForm.isValid}
                                            isTouched={issuedTCListForm.touched.toDate}
                                            invalidFeedback={issuedTCListForm.errors.toDate}
                                            type='date' />
                                    </FormGroup>
                                </div>

                                <div className="col-3 mt-2" >
                                    <Button icon='ArrowDownward' color='primary' onClick={viewStudentList} isDisable={graduationTypeId?.value != undefined ? false : true}>View</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list' ref={componentRef}>

                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Issued TC List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getTransferCertificateIssueList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("TransferCertificateIssuedList", transferCertificateIssueList, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(transferCertificateIssueList, columnVisibilityData, "TransferCertificateIssuedList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("TransferCertificateIssuedList", transferCertificateIssueList, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <td className="d-print-none" />
                                    </tr>
                                </thead>
                                <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentTransferCertificateDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentTransferCertificateDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td scope='col' className='d-print-none'><Button onClick={() => { onPrint(rowData) }} style={{ marginRight: '5px' }} isLink icon='Circle' className='text-nowrap' color='success'>Preview</Button></td></tr>))} </> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                    : null}
            </Page>
        </PageWrapper>
    )
}

export default IssuedTCList