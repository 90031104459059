import React, { useEffect, useState } from 'react'
import useSortableData from '../../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../../PaginationButtons'
import { useFormik } from 'formik'
import { toasts } from '../../../services/toast.service'
import Page from '../../../layout/Page/Page'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Collapse from '../../bootstrap/Collapse'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Select from '../../bootstrap/forms/Select'
import Button from '../../bootstrap/Button'
import Icon from '../../icon/Icon'
import Input from '../../bootstrap/forms/Input'
import OpenCardComponent from '../../../common/components/OpenCardComponent'
import { getRouteList, getRouteWiseTravellerList } from '../../../services/transport.service'
import SearchableSelect from '../../../common/components/SearchableSelect'

const AddRouteTraveller = () => {

    useEffect(() => {
        getRoute()
    }, [])

    const [routeMasterId, setRouteMasterId] = useState<any>('')
    const [routeListData, setRouteListData] = useState<any>([])

    const [routeWiseTravellerListData, setRouteWiseTravellerListData] = useState([])

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(routeWiseTravellerListData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name
            i.routeName !== null && i.routeName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.travellerId !== null && i.travellerId.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.firstname !== null && i.firstname.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.userType !== null && i.userType.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    );

    const selectRouteMaster = (e: any) => {
        let routeMasterId = e
        setRouteMasterId(routeMasterId)
    }

    const viewTravellerList = () => {
        getRouteWiseTraveller(routeMasterId?.value)
        columnVisibilityForm.resetForm();
    }

    function getRoute() {
        getRouteList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.routeList;
                    if (data != undefined) {
                        setRouteListData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                   setRouteListData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    function getRouteWiseTraveller(routeMasterId: any) {
        getRouteWiseTravellerList(routeMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.routeWiseTravellerList;
                    if (data != undefined) {
                        setRouteWiseTravellerListData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <>
            <PageWrapper title='Add Route Traveller'>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Add Route Traveller
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup id='routeMasterId' label='Route' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Route'
                                                onChange={selectRouteMaster}
                                                value={routeMasterId}
                                                list={routeListData.map((data: any) => (
                                                    { value: data.routeMasterId, label: data.routeNumberAndRouteName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-3 mt-2">
                                        <Button icon='ArrowDownward' color='primary' isDisable={routeMasterId?.value == undefined} onClick={viewTravellerList}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>


                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} />

                    {dataSuccess ? 
                    <>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <div className='d-flex col-5 justify-content-start'>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Traveller List</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='d-flex col-7 justify-content-end'>
                                <div className='d-flex col-5 justify-content-end' data-tour='search'>
                                    <label className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                                        htmlFor='searchInput'>
                                        <Icon icon='Search' size='2x' color='primary' />
                                    </label>
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody className='table-responsive' >
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('travellerId')}
                                            className='cursor-pointer text-decoration-underline'>Traveller Id{' '}
                                            <Icon size='lg' className={getClassNamesFor('travellerId')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('firstname')}
                                            className='cursor-pointer text-decoration-underline'>Traveller  Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('firstname')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('userType')}
                                            className='cursor-pointer text-decoration-underline'>Type{' '}
                                            <Icon size='lg' className={getClassNamesFor('userType')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('routeName')}
                                            className='cursor-pointer text-decoration-underline'>Area Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('routeName')} icon='FilterList' />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((i: any) => (
                                        <tr key={i.studentOrStaffDetailsId}>
                                            <td>{i.sno}</td>
                                            <td>{i.travellerId} </td>
                                            <td>{i.firstname}</td>
                                            <td>{i.userType}</td>
                                            <td>{i.routeName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>

                     <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <div className='d-flex col-5 justify-content-start'>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Traveller List</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='d-flex col-7 justify-content-end'>
                                <div className='d-flex col-5 justify-content-end' data-tour='search'>
                                    <label className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                                        htmlFor='searchInput'>
                                        <Icon icon='Search' size='2x' color='primary' />
                                    </label>
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody className='table-responsive'  >
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('travellerId')}
                                            className='cursor-pointer text-decoration-underline'>Traveller Id{' '}
                                            <Icon size='lg' className={getClassNamesFor('travellerId')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('firstname')}
                                            className='cursor-pointer text-decoration-underline'>Traveller  Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('firstname')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('userType')}
                                            className='cursor-pointer text-decoration-underline'>Type{' '}
                                            <Icon size='lg' className={getClassNamesFor('userType')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('routeName')}
                                            className='cursor-pointer text-decoration-underline'>Area Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('routeName')} icon='FilterList' />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((i: any) => (
                                        <tr key={i.studentOrStaffDetailsId}>
                                            <td>{i.sno}</td>
                                            <td>{i.travellerId} </td>
                                            <td>{i.firstname}</td>
                                            <td>{i.userType}</td>
                                            <td>{i.routeName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                    </>
                        : null}
                </Page>
            </PageWrapper>
        </>

    )
}

export default AddRouteTraveller
