import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../bootstrap/OffCanvas";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { addStudentCollegeName, deleteStudentCollegeName, getStudentCollegeName, updateStudentCollegeName } from "../../../services/administration.service";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";


function StudentCollegeName() {

    useEffect(() => {
        getStudentCollegeList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [studentCollegeData, setStudentCollegeData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentCollegeData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addStudentCollegeNameOffCanvas, setAddStudentCollegeNameOffCanvas] = useState(false)
    const [editStudentCollegeNameOffCanvas, setEditStudentCollegeNameOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const studentCollegeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            collegeName: '',
        },
        validate: (values) => {
            const errors: {
                collegeName?: string
            } = {};
            if (!values.collegeName) {
                errors.collegeName = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { studentCollegeSubmit() },
    });

    const studentCollegeEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            collegeName: '',
            studentCollegeNameId: '',
        },
        validate: (values) => {
            const errors: {
                collegeName?: string;
                StudentCollegeNameId?: number;
            } = {};
            if (!values.collegeName) {
                errors.collegeName = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { updateStudentCollegeSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });


    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedCollegeName = i
            setEditStudentCollegeNameOffCanvas(true)

            studentCollegeEditForm.setValues({
                collegeName: selectedCollegeName.collegeName,
                studentCollegeNameId: selectedCollegeName.studentCollegeNameId,
            });
        }
    }

    function getStudentCollegeList() {
        setDataStatus(false)
        getStudentCollegeName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentCollegeName;
                    if (data != undefined) {
                        getColumnsForTable('getStudentCollegeName', 'get');
                        setStudentCollegeData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    getColumnsForTable('getStudentCollegeName', 'get');
                        setStudentCollegeData([]);
                        setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function studentCollegeName() {

        return ({
            collegeName: studentCollegeForm.values.collegeName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditStudentCollegeName() {
        return ({
            studentCollegeNameId: studentCollegeEditForm.values.studentCollegeNameId,
            collegeName: studentCollegeEditForm.values.collegeName,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
        })
    }

    function studentCollegeSubmit() {
        showLoader(true)
        if (studentCollegeForm.isValid) {
            let studentCollegePostData = studentCollegeName();
            addStudentCollegeName(studentCollegePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentCollegeList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentCollegeForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStudentCollegeSubmit() {
        showLoader(true)
        if (studentCollegeEditForm.isValid) {
            let studentCollegePostData = setEditStudentCollegeName();
            updateStudentCollegeName(studentCollegePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentCollegeList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentCollegeEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteStudentCollegeSubmit() {
        showLoader(true)
        // if (studentCollegeEditForm.isValid) {
        let studentCollegePostData = setEditStudentCollegeName();
        deleteStudentCollegeName(studentCollegePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getStudentCollegeList();
                    closeAndReset();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (studentCollegeEditForm.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentCollegeName', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddStudentCollegeNameOffCanvas(false);
        setEditStudentCollegeNameOffCanvas(false);
        setIsDeleteOpen(false);
        studentCollegeForm.resetForm();
        studentCollegeEditForm.resetForm();
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Student College Creation">
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'> Student College Name List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentCollegeName', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Student College Name", studentCollegeData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentCollegeData, columnVisibilityData, "Student College Name")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Student College Name", studentCollegeData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button color='primary' icon='Add' isLight onClick={() => setAddStudentCollegeNameOffCanvas(true)}>
                                Add New
                            </Button>
                        </CardActions>
                    </CardHeader>


                    <CardBody className='table-responsive' isScrollable >
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.studentCollegeNameId}>
                                                    <td width={20}>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.studentTransferCertificateId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                    <td className="d-print-none" >
                                                        <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                </tr>
                                            ))
                                            } </>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg}/>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons
                        className="d-print-none"
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            </Page>

            <OffCanvas
                setOpen={setAddStudentCollegeNameOffCanvas}
                isOpen={addStudentCollegeNameOffCanvas}
                titleId='addStudentCollegeNameOffCanvas'
                isBodyScroll
                tag="form" noValidate onSubmit={studentCollegeForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddStudentCollegeNameOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='addStudentCollegeNameOffCanvas'>Add Student College Name</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='col-md-12'>
                        <FormGroup id='collegeName' label='College Name'>
                            <Input placeholder='Enter College Name' onChange={studentCollegeForm.handleChange}
                                value={studentCollegeForm.values.collegeName}
                                isValid={studentCollegeForm.isValid}
                                onBlur={studentCollegeForm.handleBlur}
                                isTouched={studentCollegeForm.touched.collegeName}
                                invalidFeedback={studentCollegeForm.errors.collegeName}
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button
                            color='info' icon="Save"
                            className='w-100' type="submit" isDisable={!studentCollegeForm.isValid && !!studentCollegeForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditStudentCollegeNameOffCanvas}
                isOpen={editStudentCollegeNameOffCanvas}
                titleId='editStudentCollegeNameOffCanvas'
                isBodyScroll
                tag="form" noValidate onSubmit={studentCollegeEditForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditStudentCollegeNameOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='editStudentCollegeNameOffCanvas'>Update Student College Name</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='col-md-12'>
                        <FormGroup id='collegeName' label='College Name'>
                            <Input placeholder='Enter College Name' onChange={studentCollegeEditForm.handleChange}
                                value={studentCollegeEditForm.values.collegeName}
                                isValid={studentCollegeEditForm.isValid}
                                onBlur={studentCollegeEditForm.handleBlur}
                                isTouched={studentCollegeEditForm.touched.collegeName}
                                invalidFeedback={studentCollegeEditForm.errors.collegeName}
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>

                <div className='row'>
                    <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteStudentCollegeSubmit} />
                    </div>
                    <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                    <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                        <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!studentCollegeEditForm.isValid && !!studentCollegeEditForm.submitCount}> Update</Button>
                    </div>
                </div>

            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    )
} export default StudentCollegeName;