import Page from "../../../layout/Page/Page";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useDarkMode from "../../../hooks/useDarkMode";
import classNames from "classnames";
import { toasts } from "../../../services/toast.service";
import { useReactToPrint } from "react-to-print";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import { getAreaWiseStudentCount, getStudentListByTransportAreaMasterId } from "../../../services/report.service";
import AuthContext from "../../../contexts/authContext";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";

function AreaWiseStudentReport() {

  useEffect(() => {
    getAreaWiseStudentReport()
  }, [])

  const { userAccountId, userTypeId } = useContext(AuthContext);
  const { themeStatus, darkModeStatus } = useDarkMode();
  const [areaWiseStudentReportData, setAreaWiseStudentReportData] = useState([])
  const [columnVisibilityData, setColumnVisibilityData] = useState([]);
  const [allColumnsData, setAllColumnsData] = useState([]);
  const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
  const [columnDataById, setColumnDataById] = useState([])
  const componentRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false)
  const [areaWiseStudentReportModal, setAreaWiseStudentReportModal] = useState(false)
  const [studentDetailsData, setStudentDetailsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { items, requestSort, getClassNamesFor } = useSortableData(areaWiseStudentReportData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const [areaName, setAreaName] = useState('')
  const [dataStatus, setDataStatus] = useState(false)

  const [noDataMsg, setNoDataMsg] = useState('')

  const columnVisibilityForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },

    //validateOnChange: false,
    onSubmit: () => { },
  });

  // Filter
  const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

  function onActivate(i: any) {
    if (i != undefined) {
      let selectedArea = i
      setAreaWiseStudentReportModal(true);
      setAreaName(selectedArea.areaName)
      getStudentListByTransportAreaMasterIdList(selectedArea.transportAreaMasterId)
    }
  }

  function getAreaWiseStudentReport() {
    setDataStatus(false)
    getAreaWiseStudentCount(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.areaWiseStudentCount;
          if (data != undefined) {
            setDataStatus(true)
            setAreaWiseStudentReportData(data);
            getColumnsForTable('getAreaWiseStudentCount', 'get');
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            setDataStatus(true)
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setDataStatus(true)
          //toasts(response.data.message, "Error")
          getColumnsForTable('getAreaWiseStudentCount', 'get');
          setAreaWiseStudentReportData([]);
          setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true)
          toasts(errorDescription, "Error")
        }
      }, error => {
        setDataStatus(true)
        toasts(error, "Error")
      }
    )
  }

  function getStudentListByTransportAreaMasterIdList(transportAreaMasterId: any) {
    getStudentListByTransportAreaMasterId(transportAreaMasterId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.studentListByTransportAreaMasterId;
          if (data != undefined) {
            setStudentDetailsData(data);
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getColumnsForTable(apiName: any, type: any) {
    getColumnsForDataTable(userTypeId, apiName,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.columnsForDataTable;
          if (data != undefined) {
            let allColumnsData = data;
            setAllColumnsData(allColumnsData)
            let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
            setColumnVisibilityData(columnVisibilityData)

            if (type == 'get') {
              const allData = allColumnsData.map((item: any) => item.isDisplay);
              const allColumns = allData.filter((item: any) => item == false);
              setIsChecked(allColumns == '' ? true : false);
            }
            else if (type == 'post') {
              const all = [];
              let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

              for (let i = 0; i < nonDefaultValue.length; i++) {

                const obj = {
                  columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                  isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                }
                all.push(obj);
              }
              updateColumnsSubmit('', '', all)
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

    if (columnVisibilityForm.isValid) {

      setColumnDataById(isDisplay)
      const columnVisibilityPostData = {
        licenseKey: getLicenseKey,
        userAccountId: userAccountId,
        columns: type ? type : [
          {
            columnVisibilityId: columnVisibilityId,
            isDisplay: isDisplay ? 0 : 1
          }
        ]
      }

      updateColumnsForDataTable(columnVisibilityPostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            getColumnsForTable('getAreaWiseStudentCount', 'get');
          }
          else if (data.success == false) {
            // toasts(data.success, "Error")
          }
          else {
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            toasts(errorDescription, "Error")
          }
        }
        , (error) => {
          toasts(error, "Error")
        }
      )
    } else if (columnVisibilityForm.isValid == false) {
      toasts("Please fill all the details!", "Error")
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <PageWrapper title="Area Wise Student Report">
      <Page container='fluid'>
        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
          <CardHeader borderSize={1}>
            <CardLabel icon='List' iconColor='info' className="col-lg-6">
              <CardTitle tag='div' className='h5'>Area Wise Student Count</CardTitle>
            </CardLabel>&nbsp;&nbsp;

            <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
              <Icon className="mt-1" icon='Search' size='2x' color='primary' />
              <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
            </ButtonGroup>

            <CardActions className="d-print-none">
              <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                <DropdownToggle>
                  <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                  <DropdownItem>
                    <div className='container py-2'>
                      <form className='row g-3'>
                        <div className='col-12'>
                          <FormGroup>
                            <h6>Select All</h6>
                            <Checks
                              id='available'
                              type='switch'
                              label='Select All Columns'
                              onChange={() => getColumnsForTable('getAreaWiseStudentCount', 'post')}
                              checked={isChecked}
                              ariaLabel='Available status'
                            />
                          </FormGroup>
                        </div>
                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                          <FormGroup>
                            <h6>Columns</h6>
                            <ChecksGroup>
                              {allColumnsData.map((i: any) => (
                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                              ))}
                            </ChecksGroup>
                          </FormGroup>
                        </div>
                      </form>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown isButtonGroup>
                <DropdownToggle>
                  <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                  <DropdownItem>
                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Area Wise Student Report", areaWiseStudentReportData, columnVisibilityData)}> Excel </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(areaWiseStudentReportData, columnVisibilityData, "Area Wise Student Report")}> PDF
                    </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Area Wise Student Report", areaWiseStudentReportData, columnVisibilityData)}> CSV </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </CardActions>
          </CardHeader>
          <CardBody className='table-responsive' isScrollable>
            {!dataStatus ?
              <TableLoader /> :
              <table className='table table-modern table-hover text-nowrap'>
                <thead>
                  <tr>
                    <th scope='col' onClick={() => requestSort('sno')}
                      className='cursor-pointer text-decoration-underline'>S.No{' '}
                      <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                    </th>
                    {columnVisibilityData.map((column: any) => (
                      <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                        className='cursor-pointer text-decoration-underline'>
                        {column.columnName}
                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                      </th>
                    ))}
                    <th scope='col' className='d-print-none'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData != "" ?
                    <>
                      {filteredData.map((rowData: any) => (
                        <tr key={rowData.transportAreaMasterId}>
                          <td>{rowData.sno}</td>
                          {columnVisibilityData.map((column: any) => (
                            (column.isDisplay && rowData[column.keyName]) ?
                              <td key={`${rowData.transportAreaMasterId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                          ))}
                          <td className="d-print-none" >
                            <Button color='dark' isLight icon='More' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                        </tr>
                      ))
                      } </>
                    :
                    <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                </tbody>
              </table>
            }
          </CardBody>
          <PaginationButtons
            className="d-print-none"
            data={items}
            label='items'
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Card>
      </Page>

      <Modal
        titleId='areaWiseStudentReportModal'
        isOpen={areaWiseStudentReportModal}
        setIsOpen={setAreaWiseStudentReportModal}
        isStaticBackdrop={true}
        isCentered
        isScrollable
        size='lg' >
        <ModalHeader setIsOpen={setAreaWiseStudentReportModal} >
          <ModalTitle id="areaWiseStudentReportModal">Student List</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className='col-6 '>
              <div className='d-flex align-items-center'>
                <div className='flex-shrink-0'>
                  <Icon icon='ListAlt' size='3x' color='info' />
                </div>
                <div className='flex-grow-1 ms-3'>
                  <div className='fw-bold fs-5 mb-0'>
                    {areaName}
                  </div>
                  <div className='text-muted'>
                    Area Name
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 " >
              <table className='table table-modern table-hover '>
                <thead className='table-info'>
                  <tr>
                    <th>S.NO</th>
                    <th>ADMISSION NO</th>
                    <th>STUDENT</th>
                    <th>COURSE</th>
                    <th>SEMESTER</th>
                    <th>WAYS</th>
                  </tr>
                </thead>
                <tbody>
                  {studentDetailsData.map((i: any) => (
                    <tr key={i.studentTransportDetailsId}>
                      <td className="align-self-center">{i.sno}</td>
                      <td className="align-self-center">{i.admissionNo} </td>
                      <td className="align-self-center">{i.studentName} </td>
                      <td className="align-self-center">{i.courseName} </td>
                      <td className="align-self-center">{i.semesterNumber} </td>
                      <td className="align-self-center">{i.ways} </td>
                    </tr>))}
                </tbody>
              </table>
            </div>
          </div>

        </ModalBody>
      </Modal>
    </PageWrapper>
  )
}
export default AreaWiseStudentReport;