import { useContext, useEffect, useRef, useState } from 'react';
import Page from '../../../layout/Page/Page';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import Icon from '../../icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Input from '../../bootstrap/forms/Input';
import { useReactToPrint } from 'react-to-print';
import useSortableData from '../../../hooks/useSortableData';
import { useFormik } from 'formik';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import { getStudentSchoolWiseReportByYear, getXIIInstitutionReport } from '../../../services/report.service';
import AuthContext from '../../../contexts/authContext';
import {
    getColumnsForDataTable,
    getGraduationType,
    updateColumnsForDataTable,
    updateFilter,
} from '../../../services/common.service';
import { getLicenseKey } from '../../../services/application.settings';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';

function StudentSchoolWiseReport() {

    useEffect(() => {
        getGraduationList();
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [studentSchoolWiseReportData, setStudentSchoolWiseReportData] = useState([]);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [graduationTypeId, setGraduationTypeId] = useState<any>('');
    const [graduationTypeData, setGraduationTypeData] = useState<any>([]);
    const [isOpenListCard, setIsOpenListCard] = useState(true);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentSchoolWiseReportData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isLoader, setIsLoader] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('');

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(
        onCurrentPageData,
        columnVisibilityData,
        columnVisibilityForm,
    );

    const [yearTypeId, setYearTypeId] = useState<any>('')

    const selectGraduationType = (e: any) => {
        setIsOpenListCard(true);
        setDataSuccess(false);
        setYearTypeId('')
        let graduationTypeId = e;
        setGraduationTypeId(graduationTypeId);
    };

    const selectYear = (e: any) => {
        let yearTypeId = e
        setYearTypeId(yearTypeId)
        setIsOpenListCard(true)
    }

    function view() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStudentSchoolWiseReport(graduationTypeId.value, yearTypeId.value);
    }


    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStudentSchoolWiseReport(graduationTypeId: any, yearTypeId: any) {
        setIsLoader(true);
        getStudentSchoolWiseReportByYear(graduationTypeId, yearTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolWiseReportByYear;
                    if (data != undefined) {
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        getColumnsForTable('getStudentSchoolWiseReportByYear', 'get');
                        setStudentSchoolWiseReportData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStudentSchoolWiseReportByYear', 'get');
                    setStudentSchoolWiseReportData([]);
                    setNoDataMsg(response.data.message);
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(
            userTypeId,
            apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData);
                        let columnVisibilityData = allColumnsData.filter(
                            (item: any) => item.isDisplay == 1,
                        );
                        setColumnVisibilityData(columnVisibilityData);

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        } else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter(
                                (item: any) => item.isDefault == false,
                            );

                            for (let i = 0; i < nonDefaultValue.length; i++) {
                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
                                };
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all);
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
        if (columnVisibilityForm.isValid) {
            setColumnDataById(isDisplay);
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type
                    ? type
                    : [
                        {
                            columnVisibilityId: columnVisibilityId,
                            isDisplay: isDisplay ? 0 : 1,
                        },
                    ],
            };

            updateColumnsForDataTable(
                columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentSchoolWiseReportByYear', 'get');
                    } else if (data.success == false) {
                        // toasts(data.success, "Error")
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, 'Error');
                    }
                },
                (error) => {
                    toasts(error, 'Error');
                },
            );
        } else if (columnVisibilityForm.isValid == false) {
            toasts('Please fill all the details!', 'Error');
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const yearTypeData = [{ yearTypeId: 1, yearType: 'I YEAR' }, { yearTypeId: 2, yearType: 'II YEAR' }, { yearTypeId: 3, yearType: 'III YEAR' }]

    return (
        <PageWrapper title='Student School Wise Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    Student School Wise Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <FormGroup
                                        id='graduationTypeId'
                                        label='Graduation Type'
                                        isFloating>
                                        <SearchableSelect
                                            isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => ({
                                                value: data.graduationTypeId,
                                                label: data.graduationType,
                                            }))}
                                        />
                                    </FormGroup>
                                </div>
                                {graduationTypeId?.value != undefined ?
                                    <div className='col-lg-3'>
                                        <FormGroup id="yearTypeId" label="Select Year" isFloating>
                                            <SearchableSelect placeholder="Select Year" isFloating
                                                ariaLabel='Year Type'
                                                onChange={selectYear}
                                                value={yearTypeId}
                                                list={yearTypeData.map((data: any) => (
                                                    { value: data.yearTypeId, label: data.yearType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}
                                {yearTypeId?.value != undefined ?
                                    <div className='col-3 align-self-center'>
                                        <Button icon='ArrowDownward' color='primary' onClick={view}>
                                            View
                                        </Button>
                                    </div>
                                    : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    Student School Wise List
                                </CardTitle>
                            </CardLabel>
                            &nbsp;&nbsp;
                            <ButtonGroup
                                className='col-lg-3 justify-content-end d-print-none'
                                color='primary'>
                                <Icon className='mt-1' icon='Search' size='2x' color='primary' />
                                <Input
                                    id='searchInput'
                                    type='search'
                                    placeholder='Search...'
                                    onChange={columnVisibilityForm.handleChange}
                                    value={columnVisibilityForm.values.searchInput}
                                />
                            </ButtonGroup>
                            <CardActions className='d-print-none'>
                                <Dropdown
                                    isOpen={columnVisibilityMenu}
                                    setIsOpen={setColumnVisibilityMenu}
                                    isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>
                                            Filter
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu
                                        isAlignmentEnd
                                        size='lg'
                                        isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks
                                                                id='available'
                                                                type='switch'
                                                                label='Select All Columns'
                                                                onChange={() =>
                                                                    getColumnsForTable(
                                                                        'getStudentSchoolWiseReportByYear',
                                                                        'post',
                                                                    )
                                                                }
                                                                checked={isChecked}
                                                                ariaLabel='Available status'
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div
                                                        className='col-12'
                                                        style={{
                                                            maxHeight: '200px',
                                                            overflowY: 'scroll',
                                                        }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks
                                                                        key={i.columnVisibilityId}
                                                                        id='{i.columnVisibilityId}'
                                                                        label={i.columnName}
                                                                        onChange={() =>
                                                                            updateColumnsSubmit(
                                                                                i.columnVisibilityId,
                                                                                i.isDisplay,
                                                                                '',
                                                                            )
                                                                        }
                                                                        checked={i.isDisplay}
                                                                        disabled={
                                                                            i.isDefault == true
                                                                        }
                                                                    />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'>
                                            {' '}
                                            Export{' '}
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='CloudDownload'
                                                onClick={() =>
                                                    downloadExcel(
                                                        'StudentSchoolWiseReport',
                                                        studentSchoolWiseReportData,
                                                        columnVisibilityData,
                                                    )
                                                }>
                                                {' '}
                                                Excel{' '}
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='PictureAsPdf'
                                                onClick={() =>
                                                    convertJsonToPdf(
                                                        studentSchoolWiseReportData,
                                                        columnVisibilityData,
                                                        'StudentSchoolWiseReport',
                                                    )
                                                }>
                                                {' '}
                                                PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='CloudDownload'
                                                onClick={() =>
                                                    downloadFile(
                                                        'StudentSchoolWiseReport',
                                                        studentSchoolWiseReportData,
                                                        columnVisibilityData,
                                                    )
                                                }>
                                                {' '}
                                                CSV{' '}
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button
                                                color='primary'
                                                isLight
                                                icon='Print'
                                                onClick={handlePrint}>
                                                {' '}
                                                Print{' '}
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th
                                            scope='col'
                                            onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>
                                            S.No{' '}
                                            <Icon
                                                size='lg'
                                                className={getClassNamesFor('sno')}
                                                icon='FilterList'
                                            />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th
                                                key={column.keyName}
                                                scope='col'
                                                onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon
                                                    size='lg'
                                                    className={getClassNamesFor(column.keyName)}
                                                    icon='FilterList'
                                                />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>{filteredData != '' ? (<>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}</tr>))}</>) : (<NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />)}</tbody>
                            </table>
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                ) : null}
            </Page>
        </PageWrapper>
    );
}
export default StudentSchoolWiseReport;
