import { useContext, useEffect, useRef, useState } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Button from "../../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import Select from "../../../bootstrap/forms/Select";
import { useFormik } from "formik";
import AlertService from "../../../../services/AlertService";
import Option from "../../../bootstrap/Option";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas";
import { getCity, getGenderDetails, getGraduationType, getStates, onlyAllowNumber, pictNotLoading, profilePic } from "../../../../services/common.service";
import { getCourse, getDepartmentList } from "../../../../services/master.service";
import { addTempStudentDetails } from "../../../../services/student.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import { addStudentCollegeName, addStudentSchoolName, getStudentCollegeName, getStudentSchoolName } from "../../../../services/administration.service";
import { addAdmissionFormFee, getAdmissionFormFee } from "../../../../services/fee.service";
import Spinner from "../../../bootstrap/Spinner";
import { useHoverDirty } from "react-use";
import { useNavigate } from "react-router-dom";
import { showLoader } from "../../../../services/loader.services";
import { toasts } from "../../../../services/toast.service";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import Icon from "../../../icon/Icon";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";

const TempStudentCreation = () => {

    useEffect(() => {
        getGender();
        getGraduationList();
        getDepartment();
        getStatesList();
        getStudentCollegeList();
        getStudentSchoolList();
        getCityList(31)
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [stateId, setStateId] = useState<any>({ value: 31, label: 'Tamil Nadu' })
    const [cityId, setCityId] = useState<any>('')
    const [schoolNameId, setSchoolNameId] = useState<any>('')
    const [collegeNameId, setCollegeNameId] = useState<any>('')
    const [genderId, setGenderId] = useState<any>('')

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [studentSchoolNameData, setStudentSchoolNameData] = useState<any>([])
    const [studentCollegeNameData, setStudentCollegeNameData] = useState<any>([])
    const [showCollegeInfo, setShowCollegeInfo] = useState(false)

    const [alreadyExitStudentModal, setAlreadyExitStudentModal] = useState(false)

    //ForCanvas
    const [addAdmissionCanvas, setAddAdmissionCanvas] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef<HTMLAnchorElement>(null);
    const isHovering = useHoverDirty(ref);

    const [schoolName, setSchoolName] = useState('')
    const [collegeName, setCollegeName] = useState('')
    const [alreadyExitStudentMsg, setAlreadyExitStudentMsg] = useState('')
    const [addDuplicateRecord, setAddDuplicateRecord] = useState(0)

    const addTempStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: '',
            lastname: '',
            genderId: '',
            mobile: '',
            courseId: 0,
            departmentId: 0,
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: 0,
            commAddr_DistrictId: 0,
            commAddr_pincode: '',
            regNumber: '',
            scoredMark: '',
            govtOrPvt: '',
            schoolNameId: 0,
            graduationTypeId: 0,
            collegeNameId: 0,
            collegeName: '',
            schoolName: '',
            addDuplicateRecord: 0
        },
        validate: (values: any) => {
            const errors: {
                firstname?: string;
                genderId?: any;
                departmentId?: any;
                courseId?: any;
                mobile?: any;
                graduationTypeId?: any;
                commAddr_FlatNo?: string;
                commAddr_Street?: string;
                commAddr_Area?: string;
                commAddr_StateId?: any;
                commAddr_DistrictId?: any;
                commAddr_pincode?: string;
                regNumber?: string;
            } = {};

            if (!values.firstname) {
                errors.firstname = 'Required';
            } else if (values.firstname.length < 3) {
                errors.firstname = 'Must be 3 characters or more';
            } else if (values.firstname.length > 20) {
                errors.firstname = 'Must be 20 characters or less';
            }
            if (!genderId) {
                errors.genderId = 'Required';
            }
            if (!departmentId) {
                errors.departmentId = 'Required';
            }
            if (!courseId) {
                errors.courseId = 'Required';
            }
            if (!values.mobile) {
                errors.mobile = 'Required';
            } else if (values.mobile.length < 10 || values.mobile.length > 10) {
                errors.mobile = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.mobile)) {
                errors.mobile = 'Please enter valid mobile number'
            }
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.commAddr_Street) {
                errors.commAddr_Street = 'Required';
            }
            if (!values.commAddr_FlatNo) {
                errors.commAddr_FlatNo = 'Required';
            }
            if (!values.commAddr_Area) {
                errors.commAddr_Area = 'Required';
            }
            if (!stateId) {
                errors.commAddr_StateId = 'Required';
            }
            if (!cityId) {
                errors.commAddr_DistrictId = 'Required';
            }
            if (!values.commAddr_pincode) {
                errors.commAddr_pincode = 'Required';
            }
            else if (!/^\d{6}$/.test(values.commAddr_pincode)) {
                errors.commAddr_pincode = 'Must be 6 Numbers';
            }
            if (!values.regNumber) {
                errors.regNumber = 'Required';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { addTempStudentSubmit() },
    });

    const admissionFeeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            graduationTypeId: 0,
            amount: '',
        },
        validate: (values: any) => {
            const errors: {
                graduationTypeId?: string;
                amount?: any;
            } = {};

            if (!values.graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { admissionFeeMasterSubmit() },
    });

    const selectGraduationType = (e: any) => {
        setCollegeName('')
        setSchoolName('')
        setIsVisible(true)
        setDepartmentId('')
        setCourseId('')
        setCourseData([])
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)

        if (graduationTypeId?.value != 1) {
            setShowCollegeInfo(true)
            setSchoolNameId(0)
        } else {
            setShowCollegeInfo(false)
            setCollegeNameId(0)
        }
        if (graduationTypeId?.value != undefined) {
            checkAdmissionFees(graduationTypeId?.value)
        }
    }

    const selectDepartment = (e: any) => {
        setCourseId('')
        setCourseData([])
        let departmentId = e
        setDepartmentId(departmentId)
        if (departmentId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        let courseId = e
        setCourseId(courseId)
    }

    const selectState = (e: any) => {
        setCityId('')
        setCityData([])
        let commAddr_StateId = e
        setStateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let commAddr_DistrictId = e
        setCityId(commAddr_DistrictId)
    }

    const selectSchool = (e: any) => {
        let schoolNameId = e
        setSchoolNameId(schoolNameId)
    }

    const selectCollege = (e: any) => {
        let collegeNameId = e
        setCollegeNameId(collegeNameId)
    }

    const selectGender = (e: any) => {
        let genderId = e
        setGenderId(genderId)
    }

    function setTempStudentDetails() {
        return ({
            firstname: addTempStudentForm.values.firstname,
            lastname: addTempStudentForm.values.lastname,
            genderId: genderId?.value,
            graduationTypeId: graduationTypeId?.value,
            departmentId: departmentId?.value,
            courseId: courseId?.value,
            mobile: addTempStudentForm.values.mobile,
            commAddr_FlatNo: addTempStudentForm.values.commAddr_FlatNo,
            commAddr_Street: addTempStudentForm.values.commAddr_Street,
            commAddr_Area: addTempStudentForm.values.commAddr_Area,
            commAddr_StateId: stateId?.value,
            commAddr_DistrictId: cityId?.value,
            commAddr_pincode: addTempStudentForm.values.commAddr_pincode,
            regNumber: addTempStudentForm.values.regNumber,
            schoolNameId: schoolNameId?.value,
            scoredMark: addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0,
            govtOrPvt: addTempStudentForm.values.govtOrPvt,
            collegeNameId: collegeNameId?.value,
            addDuplicateRecord: addDuplicateRecord,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setSchoolNameDetails() {
        return ({
            schoolName: schoolName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setCollegeNameDetails() {
        return ({
            collegeName: collegeName,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addTempStudentSubmit1(postData: any) {
        // showLoader(true)
        if (addTempStudentForm.isValid) {
            let tempStudentPostData = postData
            addTempStudentDetails(tempStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal();
                        setIsNavigate(`../academic/tempStudentList`)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        // setAlertStatus({ message: data.message, type: "error" });
                        // setIsOpen(true);
                        setAlreadyExitStudentModal(true)
                        setAlreadyExitStudentMsg(data.message)
                        setAddDuplicateRecord(1)
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (addTempStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function addTempStudentSubmit() {
        showLoader(true)
        if (collegeName == '' && schoolName == '') {
            if (addTempStudentForm.isValid) {
                let tempStudentPostData = setTempStudentDetails()
                addTempStudentDetails(tempStudentPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            closeModal();
                            setIsNavigate(`../academic/tempStudentList`)
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            // setAlertStatus({ message: data.message, type: "error" });
                            // setIsOpen(true);
                            setAlreadyExitStudentMsg(data.message)
                            setAlreadyExitStudentModal(true)
                            setAddDuplicateRecord(1)
                            setIsNavigate(null)

                        }
                        else {
                            showLoader(false)
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                            setIsNavigate(null)
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                )
            } else if (addTempStudentForm.isValid == false) {
                showLoader(false)
                setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                setIsOpen(true);
                setIsNavigate(null)
            }
        } else {
            if (graduationTypeId?.value == 1) {
                if (addTempStudentForm.isValid) {
                    let tempStudentPostData = setSchoolNameDetails();
                    addStudentSchoolName(tempStudentPostData,
                        (response) => {
                            const data = response.data;
                            if (data.success == true) {
                                setSchoolNameId(data.data.studentSchoolNameId)
                                let postData = {
                                    firstname: addTempStudentForm.values.firstname,
                                    lastname: addTempStudentForm.values.lastname,
                                    genderId: genderId?.value,
                                    graduationTypeId: graduationTypeId?.value,
                                    departmentId: departmentId?.value,
                                    courseId: courseId?.value,
                                    mobile: addTempStudentForm.values.mobile,
                                    commAddr_FlatNo: addTempStudentForm.values.commAddr_FlatNo,
                                    commAddr_Street: addTempStudentForm.values.commAddr_Street,
                                    commAddr_Area: addTempStudentForm.values.commAddr_Area,
                                    commAddr_StateId: stateId?.value,
                                    commAddr_DistrictId: cityId?.value,
                                    commAddr_pincode: addTempStudentForm.values.commAddr_pincode,
                                    regNumber: addTempStudentForm.values.regNumber,
                                    schoolNameId: data.data.studentSchoolNameId,
                                    scoredMark: addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0,
                                    govtOrPvt: addTempStudentForm.values.govtOrPvt,
                                    collegeNameId: collegeNameId?.value,
                                    addDuplicateRecord: addDuplicateRecord,
                                    licenseKey: getLicenseKey,
                                    userAccountId: userAccountId
                                }
                                addTempStudentSubmit1(postData);
                                getStudentSchoolList();
                            }
                            else if (data.success == false) {
                                setSchoolNameId(data.data.studentSchoolNameId)

                                let postData = {
                                    firstname: addTempStudentForm.values.firstname,
                                    lastname: addTempStudentForm.values.lastname,
                                    genderId: genderId?.value,
                                    graduationTypeId: graduationTypeId?.value,
                                    departmentId: departmentId?.value,
                                    courseId: courseId?.value,
                                    mobile: addTempStudentForm.values.mobile,
                                    commAddr_FlatNo: addTempStudentForm.values.commAddr_FlatNo,
                                    commAddr_Street: addTempStudentForm.values.commAddr_Street,
                                    commAddr_Area: addTempStudentForm.values.commAddr_Area,
                                    commAddr_StateId: stateId?.value,
                                    commAddr_DistrictId: cityId?.value,
                                    commAddr_pincode: addTempStudentForm.values.commAddr_pincode,
                                    regNumber: addTempStudentForm.values.regNumber,
                                    schoolNameId: data.data.studentSchoolNameId,
                                    scoredMark: addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0,
                                    govtOrPvt: addTempStudentForm.values.govtOrPvt,
                                    collegeNameId: collegeNameId?.value,
                                    addDuplicateRecord: addDuplicateRecord,
                                    licenseKey: getLicenseKey,
                                    userAccountId: userAccountId
                                }
                                addTempStudentSubmit1(postData);
                                //showLoader(false)
                                // setAlertStatus({ message: data.message, type: "error" });
                                // setIsOpen(true);
                            }
                            else {
                                let errorCode = response.data.error[0].error_code;
                                let errorDescription = response.data.error[0].error_description;
                                showLoader(false)
                                setAlertStatus({ message: errorDescription, type: "error" });
                                setIsOpen(true);
                            }
                        }
                        , (error) => {
                            showLoader(false)
                            setAlertStatus({ message: error, type: "error" });
                            setIsOpen(true);
                        }
                    )

                } else if (addTempStudentForm.isValid == false) {
                    // showLoader(false)
                    // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                    // setIsOpen(true);
                    // setIsNavigate(null)
                }
            }
            else {
                if (addTempStudentForm.isValid) {
                    let tempStudentPostData = setCollegeNameDetails();
                    addStudentCollegeName(tempStudentPostData,
                        (response) => {
                            const data = response.data;
                            if (data.success == true) {
                                setCollegeNameId(data.data.studentCollegeNameId)
                                let postData = {
                                    firstname: addTempStudentForm.values.firstname,
                                    lastname: addTempStudentForm.values.lastname,
                                    genderId: genderId?.value,
                                    graduationTypeId: graduationTypeId?.value,
                                    departmentId: departmentId?.value,
                                    courseId: courseId?.value,
                                    mobile: addTempStudentForm.values.mobile,
                                    commAddr_FlatNo: addTempStudentForm.values.commAddr_FlatNo,
                                    commAddr_Street: addTempStudentForm.values.commAddr_Street,
                                    commAddr_Area: addTempStudentForm.values.commAddr_Area,
                                    commAddr_StateId: stateId?.value,
                                    commAddr_DistrictId: cityId?.value,
                                    commAddr_pincode: addTempStudentForm.values.commAddr_pincode,
                                    regNumber: addTempStudentForm.values.regNumber,
                                    schoolNameId: schoolNameId?.value,
                                    scoredMark: addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0,
                                    govtOrPvt: addTempStudentForm.values.govtOrPvt,
                                    collegeNameId: data.data.studentCollegeNameId,
                                    addDuplicateRecord: addDuplicateRecord,
                                    licenseKey: getLicenseKey,
                                    userAccountId: userAccountId
                                }
                                addTempStudentSubmit1(postData);
                                getStudentCollegeList();
                            }
                            else if (data.success == false) {
                                setCollegeNameId(data.data.studentCollegeNameId)

                                let postData = {
                                    firstname: addTempStudentForm.values.firstname,
                                    lastname: addTempStudentForm.values.lastname,
                                    genderId: genderId?.value,
                                    graduationTypeId: graduationTypeId?.value,
                                    departmentId: departmentId?.value,
                                    courseId: courseId?.value,
                                    mobile: addTempStudentForm.values.mobile,
                                    commAddr_FlatNo: addTempStudentForm.values.commAddr_FlatNo,
                                    commAddr_Street: addTempStudentForm.values.commAddr_Street,
                                    commAddr_Area: addTempStudentForm.values.commAddr_Area,
                                    commAddr_StateId: stateId?.value,
                                    commAddr_DistrictId: cityId?.value,
                                    commAddr_pincode: addTempStudentForm.values.commAddr_pincode,
                                    regNumber: addTempStudentForm.values.regNumber,
                                    schoolNameId: schoolNameId?.value,
                                    scoredMark: addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0,
                                    govtOrPvt: addTempStudentForm.values.govtOrPvt,
                                    collegeNameId: data.data.studentCollegeNameId,
                                    addDuplicateRecord: addDuplicateRecord,
                                    licenseKey: getLicenseKey,
                                    userAccountId: userAccountId
                                }
                                addTempStudentSubmit1(postData);
                                //showLoader(false)
                                // setAlertStatus({ message: data.message, type: "error" });
                                // setIsOpen(true);
                            }
                            else {
                                let errorCode = response.data.error[0].error_code;
                                let errorDescription = response.data.error[0].error_description;
                                showLoader(false)
                                setAlertStatus({ message: errorDescription, type: "error" });
                                setIsOpen(true);
                            }
                        }
                        , (error) => {
                            showLoader(false)
                            setAlertStatus({ message: error, type: "error" });
                            setIsOpen(true);
                        }
                    )

                } else if (addTempStudentForm.isValid == false) {
                    showLoader(false)
                    // setAlertStatus({ message: 'Please fill all the details!', type: "error" });
                    // setIsOpen(true);
                    // setIsNavigate(null)
                }
            }
        }
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentCollegeList() {
        getStudentCollegeName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentCollegeName;
                    if (data != undefined) {
                        setStudentCollegeNameData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentCollegeNameData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentSchoolList() {
        getStudentSchoolName(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSchoolName;
                    if (data != undefined) {
                        setStudentSchoolNameData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentSchoolNameData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function checkAdmissionFees(graduationTypeId: number) {
        getAdmissionFormFee(graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.admissionFormFee;
                    if (data != undefined) {
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setAddAdmissionCanvas(true);
                    admissionFeeForm.setValues({
                        graduationTypeId: graduationTypeId,
                        amount: '',
                    })
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setAdmissionFeeFormDetails() {
        return {
            graduationTypeId: admissionFeeForm.values.graduationTypeId,
            amount: admissionFeeForm.values.amount,
            particulars: "Application Form Fees",
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function admissionFeeMasterSubmit() {
        showLoader(true);
        if (admissionFeeForm.isValid) {
            let admissionFeeMasterPostData = setAdmissionFeeFormDetails()
            addAdmissionFormFee(admissionFeeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(null)
                        admissionFeeForm.resetForm();
                        setAddAdmissionCanvas(false)

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (admissionFeeForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function closeModal() {
        addTempStudentForm.resetForm();
        admissionFeeForm.resetForm();
        setAlreadyExitStudentModal(false);
        resetState();
        setIsVisible(true);
    }

    function resetState() {
        setGraduationTypeId('');
        setDepartmentId('')
        setCourseId('')
        setStateId('')
        setCityId('')
        setSchoolNameId('')
        setCollegeNameId('')
        setGenderId('')
        setIsVisible(true)
    }

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
        setCollegeName('')
        setSchoolName('')
        setCollegeNameId(0)
        setSchoolNameId(0)
    }

    function closePopup() {
        setAlreadyExitStudentModal(false);
        setAddDuplicateRecord(0)
    }

    return (
        <PageWrapper title="Temp Student Creation">
            <Page container='fluid'>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info'>
                            <CardTitle tag='div' className='h5'>
                                Temp Student Creation
                            </CardTitle>
                        </CardLabel>
                        <CardActions>
                            <Button color='info' isLight icon={isLoading ? undefined : 'PublishedWithChanges'} onClick={() => { ref?.current?.blur(); closeModal() }}>
                                {isLoading && (
                                    <Spinner color={isHovering ? 'light' : 'info'} inButton isSmall>
                                        Loading...
                                    </Spinner>
                                )}
                                Refresh
                            </Button>
                        </CardActions>
                    </CardHeader>

                    <CardBody>
                        <div className="row g-4">
                            <form noValidate onSubmit={addTempStudentForm.handleSubmit}>
                                <div className='row g-4'>
                                    <div className='col-md-4'>
                                        <FormGroup id='firstname' label='First Name' isFloating>
                                            <Input
                                                placeholder='First Name'
                                                onChange={addTempStudentForm.handleChange}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={addTempStudentForm.values.firstname}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.firstname}
                                                invalidFeedback={addTempStudentForm.errors.firstname}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-4'>
                                        <FormGroup id='lastname' label='Last Name' isFloating>
                                            <Input
                                                placeholder='Last Name'
                                                onChange={addTempStudentForm.handleChange}
                                                value={addTempStudentForm.values.lastname}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-2'>
                                        <FormGroup id='genderId' label='Gender' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Gender'
                                                onChange={selectGender}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={genderId}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.genderId}
                                                invalidFeedback={addTempStudentForm.errors.genderId}
                                                list={genderData.map((data: any) => (
                                                    { value: data.genderId, label: data.gender }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-2'>
                                        <FormGroup id='mobile' label='Mobile No ' isFloating>
                                            <Input
                                                placeholder='Mobile No'
                                                onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                onChange={addTempStudentForm.handleChange}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={addTempStudentForm.values.mobile}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.mobile}
                                                invalidFeedback={addTempStudentForm.errors.mobile}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-md-4'>
                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Graduation Type'
                                                onChange={selectGraduationType}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={graduationTypeId}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.graduationTypeId}
                                                invalidFeedback={addTempStudentForm.errors.graduationTypeId}
                                                list={graduationTypeData.map((data: any) => (
                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-md-4'>
                                        <FormGroup id='departmentId' label='Department ' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Department'
                                                onChange={selectDepartment}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={departmentId}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.departmentId}
                                                invalidFeedback={addTempStudentForm.errors.departmentId}
                                                list={departmentData.map((data: any) => (
                                                    { value: data.departmentId, label: data.departmentName }
                                                ))}
                                                required />
                                        </FormGroup>
                                    </div>

                                    <div className='col-md-4'>
                                        <FormGroup id='courseId' label='Course' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Course'
                                                onChange={selectCourse}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={courseId}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.courseId}
                                                invalidFeedback={addTempStudentForm.errors.courseId}
                                                list={courseData.map((data: any) => (
                                                    { value: data.courseId, label: data.courseName }
                                                ))}
                                                required />
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                        <Card className='rounded-1 mb-2' borderSize={2}>
                                            <CardHeader>
                                                <CardLabel icon='ReceiptLong'>
                                                    <CardTitle>Address Info</CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="row g-4">
                                                    <div className='col-md-6'>
                                                        <FormGroup id='commAddr_FlatNo' label='Flat No\Door No' isFloating>
                                                            <Input
                                                                placeholder='Flat No\Door No'
                                                                onChange={addTempStudentForm.handleChange}
                                                                value={addTempStudentForm.values.commAddr_FlatNo}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.commAddr_FlatNo}
                                                                invalidFeedback={addTempStudentForm.errors.commAddr_FlatNo}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <FormGroup id='commAddr_Street' label='Street' isFloating>
                                                            <Input
                                                                placeholder='Street'
                                                                onChange={addTempStudentForm.handleChange}
                                                                value={addTempStudentForm.values.commAddr_Street}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.commAddr_Street}
                                                                invalidFeedback={addTempStudentForm.errors.commAddr_Street}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <FormGroup id='commAddr_Area' label='Area' isFloating>
                                                            <Input
                                                                placeholder='Area'
                                                                onChange={addTempStudentForm.handleChange}
                                                                value={addTempStudentForm.values.commAddr_Area}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.commAddr_Area}
                                                                invalidFeedback={addTempStudentForm.errors.commAddr_Area}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <FormGroup id='commAddr_StateId' label='State' isFloating>
                                                            <SearchableSelect isFloating
                                                                ariaLabel='State'
                                                                onChange={selectState}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                value={stateId}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.commAddr_StateId}
                                                                invalidFeedback={addTempStudentForm.errors.commAddr_StateId}
                                                                list={statesData.map((data: any) => (
                                                                    { value: data.stateId, label: data.stateName }
                                                                ))}
                                                                required />
                                                        </FormGroup>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <FormGroup id='commAddr_DistrictId' label='City' isFloating>
                                                            <SearchableSelect isFloating
                                                                ariaLabel='City'
                                                                onChange={selectCity}
                                                                value={cityId}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.commAddr_DistrictId}
                                                                invalidFeedback={addTempStudentForm.errors.commAddr_DistrictId}
                                                                list={cityData.map((data: any) => (
                                                                    { value: data.cityId, label: data.cityName }
                                                                ))}
                                                                required />
                                                        </FormGroup>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <FormGroup id='commAddr_pincode' label='Pincode' isFloating>
                                                            <Input
                                                                placeholder='Pincode'
                                                                onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                                onChange={addTempStudentForm.handleChange}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                value={addTempStudentForm.values.commAddr_pincode}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.commAddr_pincode}
                                                                invalidFeedback={addTempStudentForm.errors.commAddr_pincode}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-md-6'>
                                        <Card className='rounded-1 mb-2' borderSize={2}>
                                            <CardHeader>
                                                <CardLabel icon='School'>
                                                    <CardTitle>{showCollegeInfo ? 'College Info' : 'School Info'}</CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className="row g-4">
                                                    <div className='col-md-6'>
                                                        <FormGroup id='regNumber' label='Register Number' isFloating>
                                                            <Input
                                                                placeholder='Register Number'
                                                                onChange={addTempStudentForm.handleChange}
                                                                value={addTempStudentForm.values.regNumber}
                                                                onBlur={addTempStudentForm.handleBlur}
                                                                isValid={addTempStudentForm.isValid}
                                                                isTouched={addTempStudentForm.touched.regNumber}
                                                                invalidFeedback={addTempStudentForm.errors.regNumber}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <FormGroup id='scoredMark' label='Scored Mark' isFloating>
                                                            <Input
                                                                placeholder='Scored Mark'
                                                                onChange={addTempStudentForm.handleChange}
                                                                value={addTempStudentForm.values.scoredMark}
                                                                onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <FormGroup id='govtOrPvt' label='Government / Private' isFloating>
                                                            <Select
                                                                ariaLabel='Govt / Pvt'
                                                                placeholder='Government / Private'
                                                                onChange={addTempStudentForm.handleChange}
                                                                value={addTempStudentForm.values.govtOrPvt}>
                                                                <Option value='Government'>Government</Option>
                                                                <Option value='Private'>Private</Option>
                                                            </Select>
                                                        </FormGroup>
                                                    </div>
                                                    {isVisible ?
                                                        <div className='col-md-11'>
                                                            {showCollegeInfo ?
                                                                <FormGroup id='collegeNameId' label='College Name' isFloating>
                                                                    <SearchableSelect isFloating
                                                                        ariaLabel='College Name'
                                                                        onChange={selectCollege}
                                                                        onBlur={addTempStudentForm.handleBlur}
                                                                        value={collegeNameId}
                                                                        list={studentCollegeNameData.map((data: any) => (
                                                                            { value: data.studentCollegeNameId, label: data.collegeName }
                                                                        ))}
                                                                        required />
                                                                </FormGroup>
                                                                : <FormGroup id='schoolNameId' label='School Name' isFloating>
                                                                    <SearchableSelect isFloating
                                                                        ariaLabel='School Name'
                                                                        onChange={selectSchool}
                                                                        onBlur={addTempStudentForm.handleBlur}
                                                                        value={schoolNameId}
                                                                        list={studentSchoolNameData.map((data: any) => (
                                                                            { value: data.studentSchoolNameId, label: data.schoolName }
                                                                        ))}
                                                                        required />
                                                                </FormGroup>
                                                            }
                                                        </div>
                                                        :
                                                        <div className='col-md-11'>
                                                            {showCollegeInfo ?
                                                                <FormGroup id='collegeName' label='College Name' isFloating>
                                                                    <Input
                                                                        placeholder="College Name"
                                                                        onInput={(e: any) => setCollegeName(e.target.value)}
                                                                        onBlur={addTempStudentForm.handleBlur}
                                                                        value={collegeName}
                                                                        required />
                                                                </FormGroup>
                                                                : <FormGroup id='schoolName' label='School Name' isFloating>
                                                                    <Input
                                                                        placeholder='School Name'
                                                                        onInput={(e: any) => setSchoolName(e.target.value)}
                                                                        onBlur={addTempStudentForm.handleBlur}
                                                                        value={schoolName}
                                                                        required />
                                                                </FormGroup>
                                                            }
                                                        </div>}

                                                    <div className='col-md-1'>
                                                        <FormGroup id='' label={"      "} isFloating>
                                                            <Button className='mt-2'
                                                                color='primary' icon='Add' isLight
                                                                hoverShadow='lg'
                                                                onClick={toggleVisibility}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className="col-md-lg-12 d-flex justify-content-end">
                                        <Button icon="Save" color="info" type="submit" isDisable={!addTempStudentForm.isValid && !!addTempStudentForm.submitCount}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </CardBody>
                </Card>
            </Page>

            <OffCanvas
                setOpen={setAddAdmissionCanvas}
                isOpen={addAdmissionCanvas}
                titleId='addAdmissionCanvas'
                isNotClose
                tag="form" noValidate onSubmit={admissionFeeForm.handleSubmit}>
                <OffCanvasHeader>
                    <OffCanvasTitle id='addAdmissionCanvas'>Add Admission Form Fee</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className="col-md-lg-12">
                            <FormGroup id='graduationTypeId' label='Graduation Type'>
                                <Select
                                    ariaLabel='Graduation Type'
                                    onChange={admissionFeeForm.handleChange}
                                    onBlur={admissionFeeForm.handleBlur}
                                    value={admissionFeeForm.values.graduationTypeId}
                                    isValid={admissionFeeForm.isValid}
                                    isTouched={admissionFeeForm.touched.graduationTypeId}
                                    invalidFeedback={admissionFeeForm.errors.graduationTypeId}
                                    list={graduationTypeData.map((data: any) => (
                                        { value: data.graduationTypeId, text: data.graduationType }
                                    ))} disabled
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-lg-12">
                            <FormGroup id="amount" label='Fee Amount'>
                                <Input type="text"
                                    onChange={admissionFeeForm.handleChange}
                                    onBlur={admissionFeeForm.handleBlur}
                                    value={admissionFeeForm.values.amount}
                                    isValid={admissionFeeForm.isValid}
                                    isTouched={admissionFeeForm.touched.amount}
                                    invalidFeedback={admissionFeeForm.errors.amount}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-md-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!admissionFeeForm.isValid && !!admissionFeeForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <Modal
                setIsOpen={setAlreadyExitStudentModal}
                isOpen={alreadyExitStudentModal}
                titleId='alreadyExitStudentModal'
                isStaticBackdrop
                fullScreen='md'
            >
                <ModalHeader className="'modal-header'"
                    setIsOpen={() => { setAlreadyExitStudentModal(false) }} onClick={closePopup}>
                    <ModalTitle id="alreadyExitStudentModal"> {""}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='row g-2'>
                        <div className='col-12'>
                            <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0'>
                                    <img style={{ borderRadius: '50%' }}
                                        src={profilePic(genderId?.value)}
                                        height='80' width='80' onError={(e: any) => pictNotLoading(e, genderId?.value)}
                                    />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='h2 fw-bold'>
                                        {addTempStudentForm.values.firstname} {addTempStudentForm.values.lastname}
                                    </div>
                                    <div className='h5 text-muted'>Student Name</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row g-2'>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon={genderId.value == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {genderId.label}
                                            </div>
                                            <div className='text-muted'>
                                                Gender
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon
                                                icon='PhoneIphone'
                                                size='3x'
                                                color='info'
                                            />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.mobile}
                                            </div>
                                            <div className='text-muted'>Phone</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='school' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {graduationTypeId.label}
                                            </div>
                                            <div className='text-muted'>Graduation Type</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='HomeWork' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {departmentId.label}
                                            </div>
                                            <div className='text-muted'>Department</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Source' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {courseId.label}
                                            </div>
                                            <div className='text-muted'>Course</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Badge' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.regNumber}
                                            </div>
                                            <div className='text-muted'>Register No</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Subject' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0}
                                            </div>
                                            <div className='text-muted'>Scored Mark</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon
                                                icon='Book'
                                                size='3x'
                                                color='info'
                                            />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.govtOrPvt != '' ? addTempStudentForm.values.govtOrPvt : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                            </div>
                                            <div className='text-muted'>Government / Private</div>
                                        </div>
                                    </div>
                                </div>
                                {showCollegeInfo ?
                                    <div className='col-12'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon
                                                    icon='AccountBalance'
                                                    size='3x'
                                                    color='info'
                                                />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 mb-0'>
                                                    {collegeNameId?.value != undefined ? collegeNameId?.label : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                </div>
                                                <div className='text-muted'>College Name</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-12'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon
                                                    icon='AccountBalance'
                                                    size='3x'
                                                    color='info'
                                                />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 mb-0'>
                                                    {schoolNameId?.value != undefined ? schoolNameId?.label : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                </div>
                                                <div className='text-muted'>School Name</div>
                                            </div>
                                        </div>
                                    </div>}

                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Home' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {`${addTempStudentForm.values.commAddr_FlatNo},`}
                                                {`${addTempStudentForm.values.commAddr_Street},`}
                                                {`${addTempStudentForm.values.commAddr_Area},`}
                                                {`${stateId?.label},`}
                                                {`${cityId?.label},`}
                                                {`${addTempStudentForm.values.commAddr_pincode},`}
                                            </div>
                                            <div className='text-muted'>
                                                Address
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-12 align-middle'>
                            <h5 className="main-content-label mb-1 fw-bold text-center text-danger">{alreadyExitStudentMsg}</h5>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='info' isOutline className='border-0' onClick={closePopup}>Cancel</Button>
                    <Button color='info' icon='Save' onClick={addTempStudentSubmit}>Create </Button>
                </ModalFooter>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </PageWrapper>
    )
}

export default TempStudentCreation;