import React, { useEffect, useState } from 'react';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import { toasts } from '../../../../services/toast.service';
import { useParams } from 'react-router-dom';
import { getCollegeDetails } from '../../../../services/college.service';
import { getCircularDetails } from '../../../../services/dashboard.service';

const CircularContent = () => {
	useEffect(() => {
		getCircularList();
		getCollege();
	}, []);

	const [dataStatus, setDataStatus] = useState(false);
	const [circularData, setCircularData] = useState<any>([]);
	const [collegeDetailsData, setCollegeDetailsData] = useState<any>([]);

	const { circularDetailsId } = useParams();

	function getCircularList() {
		getCircularDetails(
			circularDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.circularDetails[0];
					console.log(data);
					if (data != undefined) {
						setDataStatus(true);
						setCircularData(data);
						console.log(data);
					} else {
						setDataStatus(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getCollege() {
		getCollegeDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.collegeDetails;
					if (data != undefined) {
						setCollegeDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	return (
		<Card>
			<CardBody className='table-responsive'>
				<table>
					<div className='Dashboard'>
						<tbody>
							{collegeDetailsData.map((i: any) => (
								<tr key={i.collegeDetailsId}>
									<td width='45%' rowSpan={1} align='center'>
										<img
											className='mt-3'
											height='80px'
											width='80px'
											src='https://campus-dev.suncolleges.ac.in/assets/img/media/logo.jpg'
										/>
									</td>
									<td width='65%' className='text-center table-border'>
										<strong>
											<h2
												className='text-dark' 
												style={{ fontFamily: '-moz-initial' }}>
												<b>{i.collegeName}</b>
											</h2>
										</strong>
										<p
											className='text-dark'
											style={{ fontFamily: '-moz-initial' }}>
											{' '}
											<br />
											<b> {i.description}, </b> <br />
											<b> {i.address} <br /> {i.website} , {i.phone}, {i.cellNo}</b>
										</p>
										
									</td>
								</tr>
							))}
						</tbody> 
						<hr style={{ borderBottom: 'solid 1px black' }}></hr>
						<h3 className='text-center ' style={{ fontFamily: '-moz-initial' }}>
							{' '}
							<b>Circular - {circularData.subject}</b>
						</h3>
						<br />
						<br />
						<p>{circularData.content}</p>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />

						<h6 style={{ marginLeft: '1050px' }}>Principal</h6>

						<h6>Copy to</h6>

						<ol> 1.Director(Administration)</ol>
						<ol> 2.Head and Staff</ol>
						<ol>3.Read all Classes by Circulation</ol>
						<ol>4.Administrative Officer</ol>
					</div>
				</table>
				<br />
				<br />
				<br />
			</CardBody>
		</Card>
	);
};

export default CircularContent;
