import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import AuthContext from "../../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { getBlood, getCategory, getGenderDetails, getGraduationType, getMotherTongue, getReligion, onFileSelected, onlyAllowNumber, profilePicUpload } from "../../../../services/common.service";
import { toasts } from "../../../../services/toast.service";
import Select from "../../../bootstrap/forms/Select";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Avatar from "../../../Avatar";
import Input from "../../../bootstrap/forms/Input";
import Button from "../../../bootstrap/Button";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Card, { CardBody } from "../../../bootstrap/Card";
import { addStudentDetails, getTempStudentList, getTempStudentListByGraduationTypeId } from "../../../../services/student.service";
import { getLicenseKey } from "../../../../services/application.settings";
import { getCasteDetails } from "../../../../services/master.service";
import USERS from "../../../../common/data/userDummyData";

interface IStudentProps {
    handleNext1: any;
    setSelectedValue(...args: unknown[]): unknown;
    selectedValue?: any;
}

const GeneralInfo: FC<IStudentProps> = ({ handleNext1, setSelectedValue, selectedValue }) => {

    useEffect(() => {
        getGraduationList();
        getGender();
        getBloodList();
        getMotherTongueList();
        getCategoryDetails();
        getReligionDetails();
        getCaste();
        addSetValues()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [tempStudentDetailsId, setTempStudentDetailsId] = useState('')
    const [graduationTypeData, setGraduationTypeData] = useState([])
    const [tempStudentData, setTempStudentData] = useState([])
    const [genderData, setGenderData] = useState([])
    const [bloodData, setBloodData] = useState([])
    const [motherToungeData, setMotherToungeData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [religionData, setReligionData] = useState([])
    const [casteDetailsData, setCasteDetailsData] = useState([])
    const [nationality, setIsNationality] = useState('')
    const [isOtherNation, setIsOtherNation] = useState('isOthers')
    const [physicallyChallenged, setPhysicallyChallenged] = useState('')
    const [isPhysicallyChallengedDetails, setIsPhysicallyChallengedDetails] = useState('isPhysicallyChallengedDetails')
    const [selectedFile, setSelectedFile] = useState<any>()
    const [physicallyChallengedTypeValue, setPhysicallyChallengedTypeValue] = useState('')
    const [physicallyChallengedPercentageValue, setPhysicallyChallengedPercentageValue] = useState('')

    const addStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----General Info----////
            graduationTypeId: '',
            admissionNo: '',
            tempStudentDetailsId: '',
            firstName: '',
            lastName: '',
            surName: '',
            studentNameTamil: '',
            surNameTamil: '',
            dateOfBirth: '',
            genderId: '',
            bloodGroupId: '',
            motherTongue: '',
            nationality: '',
            otherNation: '',
            religionId: '',
            casteUniqueId: '',
            casteDetailsId: '',
            subcaste: '',
            category: '',
            aadharCard: '',
            physicallyChallenged: '',
            physicallyChallengedType: '',
            physicallyChallengedPercentage: '',
            martialStatus: '',
            email: '',
            emisNo: '',
            ///----Address INFO----////
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            permAddr_FlatNo: '',
            permAddr_Street: '',
            permAddr_Area: '',
            permAddr_StateId: '',
            permAddr_CityId: '',
            permAddr_Pincode: '',

            bankName: '',
            bankAccountNo: '',
            branch: '',
            ifscCode: '',
            micrCode: '',
            // ///----Class INFO----////
            departmentId: '',
            courseId: '',
            batchMasterId: '',
            semesterDetailsId: '',
            sectionDetailsId: '',
            shiftId: '',
            studentRollNo: '',
            admissionDateStamp: '',
            sourceName: '',
            transferCourseId: '',
            referralTypeId: '',
            referredId: '',
            referredName: '',
            interimCollegeTransfer: '',
            collegeName: '',
            concessionTypeId: '',
            concessionRemarks: '',
            transportAreaMasterId: '',
            modeOfWays: '',
            isCollegeTransport: '',
            ///----Family INFO----////
            fatherName: '',
            father_AdharNumber: '',
            fatherOccupation: '',
            natureofJoborBusiness: '',
            fatherContactNumber: '',
            fatherEmailId: '',
            motherName: '',
            mother_AdharNumber: '',
            yearlyIncome: '',
            positionHeld: '',
            motherContactNumber: '',
            smsToBeSentNumber: '',
            guardian_Name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_AdharNumber: '',
            isGuardian: 0,
            // // ///----MARK INFO----////
            registerNo_X: '',
            institutionNameAndAddress_X: '',
            boardName_X: '',
            mediumOfInstitution_X: '',
            monthAndYearOfPassing_X: '',

            registerNo_XI: '',
            institutionNameAndAddress_XI: '',
            boardName_XI: '',
            mediumOfInstitution_XI: '',
            monthAndYearOfPassing_XI: '',

            registerNo_XII: '',
            XIISchoolNameId: '',
            boardName_XII: '',
            mediumOfInstitution_XII: '',
            monthAndYearOfPassing_XII: '',

            XI_S1_studentSchoolSubject: '',
            XI_S1_markSecured: '',
            XI_S1_maximumMarks: '',
            XI_S1_monthAndYearOfPassing: '',
            XI_S1_registerNo: '',
            XI_S1_noOfAttempts: '',
            XI_S2_studentSchoolSubject: '',
            XI_S2_markSecured: '',
            XI_S2_maximumMarks: '',
            XI_S2_monthAndYearOfPassing: '',
            XI_S2_registerNo: '',
            XI_S2_noOfAttempts: '',
            XI_S3_studentSchoolSubject: '',
            XI_S3_markSecured: '',
            XI_S3_maximumMarks: '',
            XI_S3_monthAndYearOfPassing: '',
            XI_S3_registerNo: '',
            XI_S3_noOfAttempts: '',
            XI_S4_studentSchoolSubject: '',
            XI_S4_markSecured: '',
            XI_S4_maximumMarks: '',
            XI_S4_monthAndYearOfPassing: '',
            XI_S4_registerNo: '',
            XI_S4_noOfAttempts: '',
            XI_S5_studentSchoolSubject: '',
            XI_S5_markSecured: '',
            XI_S5_maximumMarks: '',
            XI_S5_monthAndYearOfPassing: '',
            XI_S5_registerNo: '',
            XI_S5_noOfAttempts: '',
            XI_S6_studentSchoolSubject: '',
            XI_S6_markSecured: '',
            XI_S6_maximumMarks: '',
            XI_S6_monthAndYearOfPassing: '',
            XI_S6_registerNo: '',
            XI_S6_noOfAttempts: '',

            XII_S1_studentSchoolSubject: '',
            XII_S1_markSecured: '',
            XII_S1_maximumMarks: '',
            XII_S1_monthAndYearOfPassing: '',
            XII_S1_registerNo: '',
            XII_S1_noOfAttempts: '',
            XII_S2_studentSchoolSubject: '',
            XII_S2_markSecured: '',
            XII_S2_maximumMarks: '',
            XII_S2_monthAndYearOfPassing: '',
            XII_S2_registerNo: '',
            XII_S2_noOfAttempts: '',
            XII_S3_studentSchoolSubject: '',
            XII_S3_markSecured: '',
            XII_S3_maximumMarks: '',
            XII_S3_monthAndYearOfPassing: '',
            XII_S3_registerNo: '',
            XII_S3_noOfAttempts: '',
            XII_S4_studentSchoolSubject: '',
            XII_S4_markSecured: '',
            XII_S4_maximumMarks: '',
            XII_S4_monthAndYearOfPassing: '',
            XII_S4_registerNo: '',
            XII_S4_noOfAttempts: '',
            XII_S5_studentSchoolSubject: '',
            XII_S5_markSecured: '',
            XII_S5_maximumMarks: '',
            XII_S5_monthAndYearOfPassing: '',
            XII_S5_registerNo: '',
            XII_S5_noOfAttempts: '',
            XII_S6_studentSchoolSubject: '',
            XII_S6_markSecured: '',
            XII_S6_maximumMarks: '',
            XII_S6_monthAndYearOfPassing: '',
            XII_S6_registerNo: '',
            XII_S6_noOfAttempts: '',

            XI_OverAll_markSecured: '',
            XI_OverAll_maximumMarks: '',
            XII_OverAll_markSecured: '',
            XII_OverAll_maximumMarks: '',
            registerNo_UG: '',
            institutionNameAndAddress_UG: '',
            universityName_UG: '',
            mediumOfInstitution_UG: '',
            monthAndYearOfPassing_UG: '',

            registerNo_PG: '',
            institutionNameAndAddress_PG: '',
            universityName_PG: '',
            mediumOfInstitution_PG: '',
            monthAndYearOfPassing_PG: '',
            registerNo_MPhil: '',
            institutionNameAndAddress_MPhil: '',
            universityName_MPhil: '',
            mediumOfInstitution_MPhil: '',
            monthAndYearOfPassing_MPhil: '',
            UG_courseName: '',
            UG_Part1_SubjectName: '',
            UG_Part1_MarksObtained: '',
            UG_Part1_MaximumMarks: '',
            UG_Part1_Percentage: '',
            UG_Part1_ClassObtained: '',
            UG_Part1_NoOfAttempts: '',
            UG_Part1_RegisterNo: '',
            UG_Part1_Passing: '',
            UG_Part2_SubjectName: '',
            UG_Part2_MarksObtained: '',
            UG_Part2_MaximumMarks: '',
            UG_Part2_Percentage: '',
            UG_Part2_ClassObtained: '',
            UG_Part2_NoOfAttempts: '',
            UG_Part2_RegisterNo: '',
            UG_Part2_Passing: '',
            UG_Part3_SubjectName: '',
            UG_Part3_MarksObtained: '',
            UG_Part3_MaximumMarks: '',
            UG_Part3_Percentage: '',
            UG_Part3_ClassObtained: '',
            UG_Part3_NoOfAttempts: '',
            UG_Part3_RegisterNo: '',
            UG_Part3_Passing: '',
            UG_Allied1_SubjectName: '',
            UG_Allied1_MarksObtained: '',
            UG_Allied1_MaximumMarks: '',
            UG_Allied1_Percentage: '',
            UG_Allied1_ClassObtained: '',
            UG_Allied1_NoOfAttempts: '',
            UG_Allied1_RegisterNo: '',
            UG_Allied1_Passing: '',
            UG_Allied2_SubjectName: '',
            UG_Allied2_MarksObtained: '',
            UG_Allied2_MaximumMarks: '',
            UG_Allied2_Percentage: '',
            UG_Allied2_ClassObtained: '',
            UG_Allied2_NoOfAttempts: '',
            UG_Allied2_RegisterNo: '',
            UG_Allied2_Passing: '',
            UG_Overall_Main_Core: '',
            UG_Overall_Ancy_Allied: '',
            UG_Overall_Part3: '',

            PG_courseName: '',
            PG_Part1_SubjectName: '',
            PG_Part1_MarksObtained: '',
            PG_Part1_MaximumMarks: '',
            PG_Part1_Percentage: '',
            PG_Part1_ClassObtained: '',
            PG_Part1_NoOfAttempts: '',
            PG_Part1_RegisterNo: '',
            PG_Part1_Passing: '',
            PG_Part2_SubjectName: '',
            PG_Part2_MarksObtained: '',
            PG_Part2_MaximumMarks: '',
            PG_Part2_Percentage: '',
            PG_Part2_ClassObtained: '',
            PG_Part2_NoOfAttempts: '',
            PG_Part2_RegisterNo: '',
            PG_Part2_Passing: '',
            PG_Part3_SubjectName: '',
            PG_Part3_MarksObtained: '',
            PG_Part3_MaximumMarks: '',
            PG_Part3_Percentage: '',
            PG_Part3_ClassObtained: '',
            PG_Part3_NoOfAttempts: '',
            PG_Part3_RegisterNo: '',
            PG_Part3_Passing: '',
            PG_Allied1_SubjectName: '',
            PG_Allied1_MarksObtained: '',
            PG_Allied1_MaximumMarks: '',
            PG_Allied1_Percentage: '',
            PG_Allied1_ClassObtained: '',
            PG_Allied1_NoOfAttempts: '',
            PG_Allied1_RegisterNo: '',
            PG_Allied1_Passing: '',
            PG_Allied2_SubjectName: '',
            PG_Allied2_MarksObtained: '',
            PG_Allied2_MaximumMarks: '',
            PG_Allied2_Percentage: '',
            PG_Allied2_ClassObtained: '',
            PG_Allied2_NoOfAttempts: '',
            PG_Allied2_RegisterNo: '',
            PG_Allied2_Passing: '',
            PG_Overall_Main_Core: '',
            PG_Overall_Ancy_Allied: '',
            PG_Overall_Part3: '',

            MPhil_courseName: '',
            MPhil_Part1_SubjectName: '',
            MPhil_Part1_MarksObtained: '',
            MPhil_Part1_MaximumMarks: '',
            MPhil_Part1_Percentage: '',
            MPhil_Part1_ClassObtained: '',
            MPhil_Part1_NoOfAttempts: '',
            MPhil_Part1_RegisterNo: '',
            MPhil_Part1_Passing: '',
            MPhil_Part2_SubjectName: '',
            MPhil_Part2_MarksObtained: '',
            MPhil_Part2_MaximumMarks: '',
            MPhil_Part2_Percentage: '',
            MPhil_Part2_ClassObtained: '',
            MPhil_Part2_NoOfAttempts: '',
            MPhil_Part2_RegisterNo: '',
            MPhil_Part2_Passing: '',
            MPhil_Part3_SubjectName: '',
            MPhil_Part3_MarksObtained: '',
            MPhil_Part3_MaximumMarks: '',
            MPhil_Part3_Percentage: '',
            MPhil_Part3_ClassObtained: '',
            MPhil_Part3_NoOfAttempts: '',
            MPhil_Part3_RegisterNo: '',
            MPhil_Part3_Passing: '',
            MPhil_Allied1_SubjectName: '',
            MPhil_Allied1_MarksObtained: '',
            MPhil_Allied1_MaximumMarks: '',
            MPhil_Allied1_Percentage: '',
            MPhil_Allied1_ClassObtained: '',
            MPhil_Allied1_NoOfAttempts: '',
            MPhil_Allied1_RegisterNo: '',
            MPhil_Allied1_Passing: '',
            MPhil_Allied2_SubjectName: '',
            MPhil_Allied2_MarksObtained: '',
            MPhil_Allied2_MaximumMarks: '',
            MPhil_Allied2_Percentage: '',
            MPhil_Allied2_ClassObtained: '',
            MPhil_Allied2_NoOfAttempts: '',
            MPhil_Allied2_RegisterNo: '',
            MPhil_Allied2_Passing: '',
            MPhil_Overall_Main_Core: '',
            MPhil_Overall_Ancy_Allied: '',
            MPhil_Overall_Part3: '',

            ///----Admission----////
            quotaTypeId: '',
            discription: '',
            isCitizenOfIndia: '',
            isClaimingNriQuotaSeat: '',
            isMedicalCertificateEnclosed: '',
            extraCurricularActivities: '',

            submittedDate: '',
            status: '',

        },
        validate: (values: any) => {
            const errors: {
                graduationTypeId?: string;
                admissionNo?: string;
                firstName?: string;
                aadharCard?: string;
                email?: string;
                smsToBeSentNumber?: string;
            } = {};

            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.firstName) {
                errors.firstName = 'Required'
            }
            else if (values.firstName.length < 3) {
                errors.firstName = 'Must be 3 characters or more';
            } else if (values.firstName.length > 20) {
                errors.firstName = 'Must be 20 characters or less';
            }
            if (!values.aadharCard) {
                errors.aadharCard = 'Required'
            }
            else if (!/^\d{12}$/.test(values.aadharCard)) {
                errors.aadharCard = 'Must be 12 Numbers';
            }
            if (!values.email) {
                errors.email = 'Required'
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.smsToBeSentNumber) {
                errors.smsToBeSentNumber = 'Required'
            }
            else if (!/^\d{10}$/.test(values.smsToBeSentNumber)) {
                errors.smsToBeSentNumber = 'Must be 10 Numbers';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { addStudentSubmit() },
    })

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId);
        getTempStudentListByGraduationType(graduationTypeId)
    }

    const selectTempStudent = (e: any) => {
        let tempStudentDetailsId = e
        setTempStudentDetailsId(tempStudentDetailsId)
        getTempStudent(tempStudentDetailsId)
    }

    const [otherNationValue, setOtherNationValue] = useState('')

    const selectNationality = (e: any) => {
        let nationality = e.target.value
        setIsNationality(nationality)
        if (nationality == 'Others') {
            setIsOtherNation('Other')
        }
        else {
            setIsOtherNation('isOthers')
            setOtherNationValue('')
        }
    }

    const selectPhysicallyChallenged = (e: any) => {
        let physicallyChallenged = e.target.value
        setPhysicallyChallenged(physicallyChallenged)
        if (physicallyChallenged == 'Yes') {
            setIsPhysicallyChallengedDetails('YES')
        }
        else {
            setIsPhysicallyChallengedDetails('isPhysicallyChallengedDetails')
            setPhysicallyChallengedPercentageValue('')
            setPhysicallyChallengedTypeValue('')
        }
    }

    function onUpload(studentOrStaffDetailsId: any) {
        const filedata = new FormData();
        filedata.append('profilePic', selectedFile, selectedFile.name)
        filedata.append('licenseKey', getLicenseKey.toString())
        filedata.append('userAccountId', userAccountId)
        filedata.append('studentOrStaffDetailsId', studentOrStaffDetailsId)
        filedata.append('userTypeId', "10")

        profilePicUpload(filedata,
            (response) => {
                console.log('Profile pic upload');
            }
            , (error) => {
                console.log('Profile pic not upload');

            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudentListByGraduationType(graduationTypeId: any) {
        getTempStudentListByGraduationTypeId(graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentListByGraduationTypeId;
                    if (data != undefined) {

                        setTempStudentData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTempStudent(tempStudentDetailsId: any) {
        getTempStudentList(tempStudentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentList[0];
                    if (data != undefined) {
                        setGraduationTypeId(data.graduationTypeId);
                        addStudentForm.setValues({
                            graduationTypeId: graduationTypeId,
                            admissionNo: addStudentForm.values.admissionNo,
                            tempStudentDetailsId: tempStudentDetailsId,
                            firstName: data['firstname'],
                            lastName: data['lastname'],
                            surName: addStudentForm.values.surName,
                            studentNameTamil: addStudentForm.values.studentNameTamil,
                            surNameTamil: addStudentForm.values.surNameTamil,
                            dateOfBirth: addStudentForm.values.dateOfBirth,
                            genderId: data['genderId'],
                            bloodGroupId: addStudentForm.values.bloodGroupId,
                            motherTongue: addStudentForm.values.motherTongue,
                            nationality: nationality,
                            otherNation: otherNationValue,
                            religionId: addStudentForm.values.religionId,
                            casteUniqueId: addStudentForm.values.casteUniqueId,
                            casteDetailsId: addStudentForm.values.casteDetailsId,
                            subcaste: addStudentForm.values.subcaste,
                            category: addStudentForm.values.category,
                            aadharCard: addStudentForm.values.aadharCard,
                            physicallyChallenged: physicallyChallenged,
                            physicallyChallengedType: physicallyChallengedTypeValue,
                            physicallyChallengedPercentage: physicallyChallengedPercentageValue,
                            martialStatus: addStudentForm.values.martialStatus,
                            email: addStudentForm.values.email,
                            emisNo: addStudentForm.values.emisNo,
                            smsToBeSentNumber: addStudentForm.values.smsToBeSentNumber,

                            ///----Address INFO----////
                            commAddr_FlatNo: '',
                            commAddr_Street: '',
                            commAddr_Area: '',
                            commAddr_StateId: '',
                            commAddr_CityId: '',
                            commAddr_Pincode: '',
                            permAddr_FlatNo: '',
                            permAddr_Street: '',
                            permAddr_Area: '',
                            permAddr_StateId: '',
                            permAddr_CityId: '',
                            permAddr_Pincode: '',

                            bankName: '',
                            bankAccountNo: '',
                            branch: '',
                            ifscCode: '',
                            micrCode: '',
                            // ///----Class INFO----////
                            departmentId: '',
                            courseId: '',
                            batchMasterId: '',
                            semesterDetailsId: '',
                            sectionDetailsId: '',
                            shiftId: '',
                            studentRollNo: '',
                            admissionDateStamp: '',
                            sourceName: '',
                            transferCourseId: '',
                            referralTypeId: '',
                            referredId: '',
                            referredName: '',
                            interimCollegeTransfer: '',
                            collegeName: '',
                            concessionTypeId: '',
                            concessionRemarks: '',
                            transportAreaMasterId: '',
                            modeOfWays: '',
                            isCollegeTransport: '',
                            ///----Family INFO----////
                            fatherName: '',
                            father_AdharNumber: '',
                            fatherOccupation: '',
                            natureofJoborBusiness: '',
                            fatherContactNumber: '',
                            fatherEmailId: '',
                            motherName: '',
                            mother_AdharNumber: '',
                            yearlyIncome: '',
                            positionHeld: '',
                            motherContactNumber: '',
                            guardian_Name: '',
                            guardian_email: '',
                            guardian_phone: '',
                            guardian_AdharNumber: '',
                            isGuardian: 0,
                            // // ///----MARK INFO----////
                            registerNo_X: '',
                            institutionNameAndAddress_X: '',
                            boardName_X: '',
                            mediumOfInstitution_X: '',
                            monthAndYearOfPassing_X: '',

                            registerNo_XI: '',
                            institutionNameAndAddress_XI: '',
                            boardName_XI: '',
                            mediumOfInstitution_XI: '',
                            monthAndYearOfPassing_XI: '',

                            registerNo_XII: '',
                            XIISchoolNameId: '',
                            boardName_XII: '',
                            mediumOfInstitution_XII: '',
                            monthAndYearOfPassing_XII: '',

                            XI_S1_studentSchoolSubject: '',
                            XI_S1_markSecured: '',
                            XI_S1_maximumMarks: '',
                            XI_S1_monthAndYearOfPassing: '',
                            XI_S1_registerNo: '',
                            XI_S1_noOfAttempts: '',
                            XI_S2_studentSchoolSubject: '',
                            XI_S2_markSecured: '',
                            XI_S2_maximumMarks: '',
                            XI_S2_monthAndYearOfPassing: '',
                            XI_S2_registerNo: '',
                            XI_S2_noOfAttempts: '',
                            XI_S3_studentSchoolSubject: '',
                            XI_S3_markSecured: '',
                            XI_S3_maximumMarks: '',
                            XI_S3_monthAndYearOfPassing: '',
                            XI_S3_registerNo: '',
                            XI_S3_noOfAttempts: '',
                            XI_S4_studentSchoolSubject: '',
                            XI_S4_markSecured: '',
                            XI_S4_maximumMarks: '',
                            XI_S4_monthAndYearOfPassing: '',
                            XI_S4_registerNo: '',
                            XI_S4_noOfAttempts: '',
                            XI_S5_studentSchoolSubject: '',
                            XI_S5_markSecured: '',
                            XI_S5_maximumMarks: '',
                            XI_S5_monthAndYearOfPassing: '',
                            XI_S5_registerNo: '',
                            XI_S5_noOfAttempts: '',
                            XI_S6_studentSchoolSubject: '',
                            XI_S6_markSecured: '',
                            XI_S6_maximumMarks: '',
                            XI_S6_monthAndYearOfPassing: '',
                            XI_S6_registerNo: '',
                            XI_S6_noOfAttempts: '',

                            XII_S1_studentSchoolSubject: '',
                            XII_S1_markSecured: '',
                            XII_S1_maximumMarks: '',
                            XII_S1_monthAndYearOfPassing: '',
                            XII_S1_registerNo: '',
                            XII_S1_noOfAttempts: '',
                            XII_S2_studentSchoolSubject: '',
                            XII_S2_markSecured: '',
                            XII_S2_maximumMarks: '',
                            XII_S2_monthAndYearOfPassing: '',
                            XII_S2_registerNo: '',
                            XII_S2_noOfAttempts: '',
                            XII_S3_studentSchoolSubject: '',
                            XII_S3_markSecured: '',
                            XII_S3_maximumMarks: '',
                            XII_S3_monthAndYearOfPassing: '',
                            XII_S3_registerNo: '',
                            XII_S3_noOfAttempts: '',
                            XII_S4_studentSchoolSubject: '',
                            XII_S4_markSecured: '',
                            XII_S4_maximumMarks: '',
                            XII_S4_monthAndYearOfPassing: '',
                            XII_S4_registerNo: '',
                            XII_S4_noOfAttempts: '',
                            XII_S5_studentSchoolSubject: '',
                            XII_S5_markSecured: '',
                            XII_S5_maximumMarks: '',
                            XII_S5_monthAndYearOfPassing: '',
                            XII_S5_registerNo: '',
                            XII_S5_noOfAttempts: '',
                            XII_S6_studentSchoolSubject: '',
                            XII_S6_markSecured: '',
                            XII_S6_maximumMarks: '',
                            XII_S6_monthAndYearOfPassing: '',
                            XII_S6_registerNo: '',
                            XII_S6_noOfAttempts: '',

                            XI_OverAll_markSecured: '',
                            XI_OverAll_maximumMarks: '',
                            XII_OverAll_markSecured: '',
                            XII_OverAll_maximumMarks: '',
                            registerNo_UG: '',
                            institutionNameAndAddress_UG: '',
                            universityName_UG: '',
                            mediumOfInstitution_UG: '',
                            monthAndYearOfPassing_UG: '',

                            registerNo_PG: '',
                            institutionNameAndAddress_PG: '',
                            universityName_PG: '',
                            mediumOfInstitution_PG: '',
                            monthAndYearOfPassing_PG: '',
                            registerNo_MPhil: '',
                            institutionNameAndAddress_MPhil: '',
                            universityName_MPhil: '',
                            mediumOfInstitution_MPhil: '',
                            monthAndYearOfPassing_MPhil: '',
                            UG_courseName: '',
                            UG_Part1_SubjectName: '',
                            UG_Part1_MarksObtained: '',
                            UG_Part1_MaximumMarks: '',
                            UG_Part1_Percentage: '',
                            UG_Part1_ClassObtained: '',
                            UG_Part1_NoOfAttempts: '',
                            UG_Part1_RegisterNo: '',
                            UG_Part1_Passing: '',
                            UG_Part2_SubjectName: '',
                            UG_Part2_MarksObtained: '',
                            UG_Part2_MaximumMarks: '',
                            UG_Part2_Percentage: '',
                            UG_Part2_ClassObtained: '',
                            UG_Part2_NoOfAttempts: '',
                            UG_Part2_RegisterNo: '',
                            UG_Part2_Passing: '',
                            UG_Part3_SubjectName: '',
                            UG_Part3_MarksObtained: '',
                            UG_Part3_MaximumMarks: '',
                            UG_Part3_Percentage: '',
                            UG_Part3_ClassObtained: '',
                            UG_Part3_NoOfAttempts: '',
                            UG_Part3_RegisterNo: '',
                            UG_Part3_Passing: '',
                            UG_Allied1_SubjectName: '',
                            UG_Allied1_MarksObtained: '',
                            UG_Allied1_MaximumMarks: '',
                            UG_Allied1_Percentage: '',
                            UG_Allied1_ClassObtained: '',
                            UG_Allied1_NoOfAttempts: '',
                            UG_Allied1_RegisterNo: '',
                            UG_Allied1_Passing: '',
                            UG_Allied2_SubjectName: '',
                            UG_Allied2_MarksObtained: '',
                            UG_Allied2_MaximumMarks: '',
                            UG_Allied2_Percentage: '',
                            UG_Allied2_ClassObtained: '',
                            UG_Allied2_NoOfAttempts: '',
                            UG_Allied2_RegisterNo: '',
                            UG_Allied2_Passing: '',
                            UG_Overall_Main_Core: '',
                            UG_Overall_Ancy_Allied: '',
                            UG_Overall_Part3: '',

                            PG_courseName: '',
                            PG_Part1_SubjectName: '',
                            PG_Part1_MarksObtained: '',
                            PG_Part1_MaximumMarks: '',
                            PG_Part1_Percentage: '',
                            PG_Part1_ClassObtained: '',
                            PG_Part1_NoOfAttempts: '',
                            PG_Part1_RegisterNo: '',
                            PG_Part1_Passing: '',
                            PG_Part2_SubjectName: '',
                            PG_Part2_MarksObtained: '',
                            PG_Part2_MaximumMarks: '',
                            PG_Part2_Percentage: '',
                            PG_Part2_ClassObtained: '',
                            PG_Part2_NoOfAttempts: '',
                            PG_Part2_RegisterNo: '',
                            PG_Part2_Passing: '',
                            PG_Part3_SubjectName: '',
                            PG_Part3_MarksObtained: '',
                            PG_Part3_MaximumMarks: '',
                            PG_Part3_Percentage: '',
                            PG_Part3_ClassObtained: '',
                            PG_Part3_NoOfAttempts: '',
                            PG_Part3_RegisterNo: '',
                            PG_Part3_Passing: '',
                            PG_Allied1_SubjectName: '',
                            PG_Allied1_MarksObtained: '',
                            PG_Allied1_MaximumMarks: '',
                            PG_Allied1_Percentage: '',
                            PG_Allied1_ClassObtained: '',
                            PG_Allied1_NoOfAttempts: '',
                            PG_Allied1_RegisterNo: '',
                            PG_Allied1_Passing: '',
                            PG_Allied2_SubjectName: '',
                            PG_Allied2_MarksObtained: '',
                            PG_Allied2_MaximumMarks: '',
                            PG_Allied2_Percentage: '',
                            PG_Allied2_ClassObtained: '',
                            PG_Allied2_NoOfAttempts: '',
                            PG_Allied2_RegisterNo: '',
                            PG_Allied2_Passing: '',
                            PG_Overall_Main_Core: '',
                            PG_Overall_Ancy_Allied: '',
                            PG_Overall_Part3: '',

                            MPhil_courseName: '',
                            MPhil_Part1_SubjectName: '',
                            MPhil_Part1_MarksObtained: '',
                            MPhil_Part1_MaximumMarks: '',
                            MPhil_Part1_Percentage: '',
                            MPhil_Part1_ClassObtained: '',
                            MPhil_Part1_NoOfAttempts: '',
                            MPhil_Part1_RegisterNo: '',
                            MPhil_Part1_Passing: '',
                            MPhil_Part2_SubjectName: '',
                            MPhil_Part2_MarksObtained: '',
                            MPhil_Part2_MaximumMarks: '',
                            MPhil_Part2_Percentage: '',
                            MPhil_Part2_ClassObtained: '',
                            MPhil_Part2_NoOfAttempts: '',
                            MPhil_Part2_RegisterNo: '',
                            MPhil_Part2_Passing: '',
                            MPhil_Part3_SubjectName: '',
                            MPhil_Part3_MarksObtained: '',
                            MPhil_Part3_MaximumMarks: '',
                            MPhil_Part3_Percentage: '',
                            MPhil_Part3_ClassObtained: '',
                            MPhil_Part3_NoOfAttempts: '',
                            MPhil_Part3_RegisterNo: '',
                            MPhil_Part3_Passing: '',
                            MPhil_Allied1_SubjectName: '',
                            MPhil_Allied1_MarksObtained: '',
                            MPhil_Allied1_MaximumMarks: '',
                            MPhil_Allied1_Percentage: '',
                            MPhil_Allied1_ClassObtained: '',
                            MPhil_Allied1_NoOfAttempts: '',
                            MPhil_Allied1_RegisterNo: '',
                            MPhil_Allied1_Passing: '',
                            MPhil_Allied2_SubjectName: '',
                            MPhil_Allied2_MarksObtained: '',
                            MPhil_Allied2_MaximumMarks: '',
                            MPhil_Allied2_Percentage: '',
                            MPhil_Allied2_ClassObtained: '',
                            MPhil_Allied2_NoOfAttempts: '',
                            MPhil_Allied2_RegisterNo: '',
                            MPhil_Allied2_Passing: '',
                            MPhil_Overall_Main_Core: '',
                            MPhil_Overall_Ancy_Allied: '',
                            MPhil_Overall_Part3: '',

                            ///----Admission----////
                            quotaTypeId: '',
                            discription: '',
                            isCitizenOfIndia: '',
                            isClaimingNriQuotaSeat: '',
                            isMedicalCertificateEnclosed: '',
                            extraCurricularActivities: '',

                            submittedDate: '',
                            status: '',
                        })
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBloodList() {
        getBlood(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.blood;
                    if (data != undefined) {
                        setBloodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMotherTongueList() {
        getMotherTongue(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.motherTongue;
                    if (data != undefined) {
                        setMotherToungeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getReligionDetails() {
        getReligion(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.religion;
                    if (data != undefined) {
                        setReligionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCategoryDetails() {
        getCategory(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.category;
                    if (data != undefined) {
                        setCategoryData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCaste() {
        getCasteDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.casteDetails;
                    if (data != undefined) {
                        setCasteDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setAddStudentDetails() {
        return ({
            ///----General Info----////
            graduationTypeId: graduationTypeId,
            admissionNo: addStudentForm.values.admissionNo,
            tempNo: tempStudentDetailsId,
            firstName: addStudentForm.values.firstName ? addStudentForm.values.firstName : null,
            lastName: addStudentForm.values.lastName ? addStudentForm.values.lastName : null,
            surName: addStudentForm.values.surName ? addStudentForm.values.surName : null,
            studentNameTamil: addStudentForm.values.studentNameTamil ? addStudentForm.values.studentNameTamil : null,
            surNameTamil: addStudentForm.values.surNameTamil ? addStudentForm.values.surNameTamil : null,
            dateOfBirth: addStudentForm.values.dateOfBirth ? addStudentForm.values.dateOfBirth : null,
            genderId: addStudentForm.values.genderId ? addStudentForm.values.genderId : null,
            bloodGroupId: addStudentForm.values.bloodGroupId ? addStudentForm.values.bloodGroupId : null,
            motherTongue: addStudentForm.values.motherTongue ? addStudentForm.values.motherTongue : null,
            nationality: nationality ? nationality : null,
            otherNation: otherNationValue ? otherNationValue : null,
            religionId: addStudentForm.values.religionId ? addStudentForm.values.religionId : null,
            casteUniqueId: addStudentForm.values.casteUniqueId ? addStudentForm.values.casteUniqueId : null,
            casteDetailsId: addStudentForm.values.casteDetailsId ? addStudentForm.values.casteDetailsId : null,
            subcaste: addStudentForm.values.subcaste ? addStudentForm.values.subcaste : null,
            category: addStudentForm.values.category ? addStudentForm.values.category : null,
            aadharCard: addStudentForm.values.aadharCard ? addStudentForm.values.aadharCard : null,
            physicallyChallenged: physicallyChallenged ? physicallyChallenged : null,
            physicallyChallengedType: physicallyChallengedTypeValue ? physicallyChallengedTypeValue : null,
            physicallyChallengedPercentage: physicallyChallengedPercentageValue ? physicallyChallengedPercentageValue : null,
            martialStatus: addStudentForm.values.martialStatus ? addStudentForm.values.martialStatus : null,
            email: addStudentForm.values.email ? addStudentForm.values.email : null,
            emisNo: addStudentForm.values.emisNo ? addStudentForm.values.emisNo : null,
            smsToBeSentNumber: addStudentForm.values.smsToBeSentNumber ? addStudentForm.values.smsToBeSentNumber : null,

            ///----Address INFO----////
            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            ddepartmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
            certificateTypeId: [],
            submittedDate: "",
            status: "",
            scholarshipTypeId: [],
            assignedBy: "",
            licenseKey: "",
            createdBy: "",
            userAccountId: ""
        })
    }

    function addStudentSubmit() {
        let studentPostData = setAddStudentDetails();
        setSelectedValue(studentPostData);
        handleNext();
    }

    function handleNext() {
        handleNext1()
    }

    function addSetValues() {
        console.log(selectedValue);
        if (selectedValue.graduationTypeId != "") {
            selectGraduationType(selectedValue.graduationTypeId)
        }
        if (selectedValue.tempNo != "") {
            setTempStudentDetailsId(selectedValue.tempNo)
        }

        addStudentForm.setValues({
            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo ? selectedValue.admissionNo : '',
            tempStudentDetailsId: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : '',
            surName: selectedValue.surName ? selectedValue.surName : '',
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : '',
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : '',
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : '',
            genderId: selectedValue.genderId ? selectedValue.genderId : '',
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : '',
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : '',
            nationality: selectedValue.nationality ? selectedValue.nationality : '',
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : '',
            religionId: selectedValue.religionId ? selectedValue.religionId : '',
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : '',
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : '',
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : '',
            category: selectedValue.category ? selectedValue.category : '',
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : '',
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : '',
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : '',
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : '',
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : '',
            email: selectedValue.email ? selectedValue.email : '',
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : '',
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : '',

            ///----Address INFO----////
            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            departmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
            // certificateTypeId: [],
            submittedDate: "",
            status: "",
            // scholarshipTypeId: [],
            // assignedBy: "",
            // licenseKey: "",
            // createdBy: "",
            // userAccountId: ""
        })
    }

    return (
        <PageWrapper>
            <Page>
                <Card shadow='none'>
                    <CardBody>
                        <form noValidate onSubmit={addStudentForm.handleSubmit}>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <div className='row g-4 align-items-center'>
                                        <div className="col-6 g-4">
                                            <div className="col-lg-12">
                                                <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                    <Select
                                                        ariaLabel='Graduation Type'
                                                        onChange={(e: any) => selectGraduationType(e.target.value)}
                                                        value={graduationTypeId}
                                                        list={graduationTypeData.map((data: any) => (
                                                            { value: data.graduationTypeId, text: data.graduationType }
                                                        ))}
                                                        onBlur={addStudentForm.handleBlur}
                                                        isValid={addStudentForm.isValid}
                                                        isTouched={addStudentForm.touched.graduationTypeId}
                                                        invalidFeedback={addStudentForm.errors.graduationTypeId}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-lg-12 mt-3">
                                                <FormGroup id="tempStudentDetailsId" label="Temp No" isFloating>
                                                    <Select ariaLabel="" placeholder="Select Temp No"
                                                        onChange={(e: any) => selectTempStudent(e.target.value)}
                                                        value={tempStudentDetailsId}
                                                        list={tempStudentData.map((data: any) => (
                                                            { value: data.tempStudentDetailsId, text: data.tempNoAndStudentName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='col-12'>
                                                <div className='row g-4 align-items-center'>
                                                    <div className='col-lg-auto'>
                                                        {selectedFile ?
                                                            <Avatar
                                                                srcSet={URL.createObjectURL(selectedFile)}
                                                                src={URL.createObjectURL(selectedFile)} />
                                                            :
                                                            <Avatar
                                                                srcSet={USERS.JOHN.srcSet || URL.createObjectURL(selectedFile)}
                                                                src={USERS.JOHN.src || URL.createObjectURL(selectedFile)}
                                                                color={USERS.JOHN.color || URL.createObjectURL(selectedFile)}
                                                            />}
                                                    </div>
                                                    <div className='col-lg mt-3'>
                                                        <div className='row g-4'>
                                                            <div className='col-auto'>
                                                                <Input
                                                                    type='file'
                                                                    autoComplete='photo'
                                                                    ariaLabel='Upload image file'
                                                                    onChange={(e: any) => { onFileSelected(e, setSelectedFile) }}
                                                                />
                                                            </div>
                                                            <div className='col-auto'>
                                                                <Button
                                                                    color='dark'
                                                                    isLight
                                                                    icon='Delete'>
                                                                    Delete Avatar
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                    <FormGroup id="admissionNo" label="Addmission No / Roll Number" isFloating>
                                        <Input type="text" placeholder="Addmission No / Roll Number"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.admissionNo}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.admissionNo}
                                            invalidFeedback={addStudentForm.errors.admissionNo} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                    <FormGroup id="firstName" label="First Name" isFloating>
                                        <Input type="text" placeholder="Enter First Name"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.firstName}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.firstName}
                                            invalidFeedback={addStudentForm.errors.firstName} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-0">
                                    <FormGroup id="lastName" label="Last Name" isFloating>
                                        <Input type="text" placeholder="Enter Last Name"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.lastName} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="studentNameTamil" label="Student Name (Tamil)" isFloating>
                                        <Input type="text" placeholder="Enter Student Name (Tamil)"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.studentNameTamil} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15" >
                                    <FormGroup id="genderId" label="Gender" isFloating>
                                        <Select ariaLabel="" placeholder="Select Gender"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.genderId}
                                            list={genderData.map((data: any) => (
                                                { value: data.genderId, text: data.gender }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="dateOfBirth" label="Date Of Birth" isFloating>
                                        <Input type="date" placeholder="Date Of Birth"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.dateOfBirth} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="smsToBeSentNumber" label="SMS Sent Number" isFloating>
                                        <Input type="text" placeholder="SMS Sent Number"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.smsToBeSentNumber}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.smsToBeSentNumber}
                                            invalidFeedback={addStudentForm.errors.smsToBeSentNumber} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="email" label="Email" isFloating>
                                        <Input type="text" placeholder="Enter Email"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.email}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.email}
                                            invalidFeedback={addStudentForm.errors.email} />
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="emisNo" label="EMIS No" isFloating>
                                        <Input type="text" placeholder="Enter EMIS No"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.emisNo}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="aadharCard" label="Aadhar Number" isFloating>
                                        <Input type="text" placeholder="Enter Aadhar Number"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.aadharCard}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                            onBlur={addStudentForm.handleBlur}
                                            isValid={addStudentForm.isValid}
                                            isTouched={addStudentForm.touched.aadharCard}
                                            invalidFeedback={addStudentForm.errors.aadharCard} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="bloodGroupId" label="Blood Group" isFloating>
                                        <Select ariaLabel="" placeholder="Select Blood Group"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.bloodGroupId}
                                            list={bloodData.map((data: any) => (
                                                { value: data.bloodGroupId, text: data.groupName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="martialStatus" label="Martial Status" isFloating>
                                        <Select ariaLabel="" placeholder="Select Martial Status"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.martialStatus}>
                                            <option value='Married'>Married</option>
                                            <option value='Un Married'>Un Married</option>
                                        </Select>
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="motherTongue" label="Mother Tounge" isFloating>
                                        <Select ariaLabel="" placeholder="Select Mother Tounge"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.motherTongue}
                                            list={motherToungeData.map((data: any) => (
                                                { value: data.motherTongue, text: data.motherTongue }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="nationality" label="Nationality" isFloating>
                                        <Select ariaLabel="" placeholder="Select Nationality"
                                            onChange={selectNationality}
                                            value={nationality}>
                                            <option value={'Indian'}>Indian</option>
                                            <option value={'Others'}>Others</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                                {isOtherNation == 'Other' ?
                                    <div className="col-lg-3 mg-t-20 mg-lg-t-15 ">
                                        <FormGroup id="otherNation" label="Other Nation" isFloating>
                                            <Input type="text" placeholder="Enter Country"
                                                onChange={(e: any) => setOtherNationValue(e.target.value)}
                                                //onChange={addStudentForm.handleChange}
                                                value={otherNationValue} />
                                        </FormGroup>
                                    </div> : <div className="col-lg-3 mg-t-20 mg-lg-t-15 ">
                                        <FormGroup id="otherNation" label="Other Nation" isFloating>
                                            <Input type="text" placeholder="Enter Country"
                                                onChange={addStudentForm.handleChange}
                                                value={''}
                                                readOnly />
                                        </FormGroup>
                                    </div>}
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="religionId" label="Religion" isFloating>
                                        <Select ariaLabel="" placeholder="Select Religion"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.religionId}
                                            list={religionData.map((data: any) => (
                                                { value: data.religionId, text: data.religion }
                                            ))} />
                                    </FormGroup>
                                </div>

                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="category" label="Category" isFloating>
                                        <Select ariaLabel="" placeholder="Select Category"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.category}
                                            list={categoryData.map((data: any) => (
                                                { value: data.category, text: data.category }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="casteDetailsId" label="Caste" isFloating>
                                        <Select ariaLabel="" placeholder="Select Caste"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.casteDetailsId}
                                            list={casteDetailsData.map((data: any) => (
                                                { value: data.casteDetailsId, text: data.casteName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                    <FormGroup id="subcaste" label="Sub Caste" isFloating>
                                        <Input type="text" placeholder="Select Sub Caste"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.subcaste} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-3 mg-t-20 mg-lg-t-15" >
                                    <FormGroup id="physicallyChallenged" label="Physically Challenged" isFloating>
                                        <Select ariaLabel="" placeholder="Select Physically Challenged"
                                            onChange={selectPhysicallyChallenged}
                                            value={physicallyChallenged}>
                                            <option value={'Yes'}>Yes</option>
                                            <option value={'No'}>No</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                                {isPhysicallyChallengedDetails == 'YES' ? <>
                                    <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                        <FormGroup id="physicallyChallengedType" label="physically Challenged Type" isFloating>
                                            <Input type="text" placeholder="physically Challenged Type"
                                                onChange={(e: any) => setPhysicallyChallengedTypeValue(e.target.value)}
                                                //onChange={addStudentForm.handleChange}
                                                value={physicallyChallengedTypeValue} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3 mg-t-20 mg-lg-t-15">
                                        <FormGroup id="physicallyChallengedPercentage" label="physically Challenged Percentage" isFloating>
                                            <Input type="text" placeholder="physically Challenged Percentage"
                                                onChange={(e: any) => setPhysicallyChallengedPercentageValue(e.target.value)}
                                                //onChange={addStudentForm.handleChange}
                                                value={physicallyChallengedPercentageValue}
                                                onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </div>
                                </> : null}

                            </div>
                            <div style={{ textAlign: 'right' }} className="mt-4">
                                <Button color="info" type="submit">Next</Button>
                            </div>
                        </form>
                    </CardBody>

                </Card>
            </Page>
        </PageWrapper>
    )
}
export default GeneralInfo;
// export default reduxForm({
//     form: 'wizard', //Form name is same
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
//     //validate
//   })(GeneralInfo)