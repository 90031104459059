import React, { useContext, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import useDarkMode from '../../../hooks/useDarkMode';
import { demoPagesMenu } from '../../../menu';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import ThemeContext from '../../../contexts/themeContext';
import TotalPresentAbsenteesReport from './dashboard-component/TotalPresentAbsenteesReport';
import TodayFeeCollection from './dashboard-component/TodayFeeCollection';
import FeesCollection from './dashboard-component/FeesCollection';
import AttendanceNotSubmitted from './dashboard-component/AttendanceNotSubmitted';
import PendingFees from './dashboard-component/PendingFees';
import StaffTimeTable from './dashboard-component/StaffTimeTable';
import Circular from './dashboard-component/Circular';
import AuthContext from '../../../contexts/authContext';
import Icon from '../../../components/icon/Icon';
import TodayAbsentCount from './dashboard-component/TodayAbsentCount';
import PeriodSchedule from './dashboard-component/PeriodSchedule';

const DashboardPage = () => {
	const { mobileDesign } = useContext(ThemeContext);
	const { userAccountId, userData, userTypeId } = useContext(AuthContext);

	/**
	 * Tour Start
	 */

	const { setIsOpen } = useTour();
	useEffect(() => {
		if (localStorage.getItem('tourModalStarted') !== 'shown' && !mobileDesign) {
			setTimeout(() => {
				setIsOpen(true);
				localStorage.setItem('tourModalStarted', 'shown');
			}, 7000);
		}
		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { themeStatus } = useDarkMode();


	return (
		<PageWrapper title={demoPagesMenu.sales.subMenu.dashboard.text}>
			<SubHeader>
				<SubHeaderLeft>
					<span className='fs-5'>Today Overview </span>
					<SubheaderSeparator />
					<strong className='fs-5'>Hi, <span className='text-primary'>{`${userData.fullname}`}</span> !</strong>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row' >


					{/* ----------------For Admin DashBoard----------------*/}
					{userTypeId == 1 || userTypeId == 6 ?
						<>
							<div className='col-xl-6'>
								<TodayFeeCollection />
							</div>
							<div className='col-xl-6'>
								<TodayAbsentCount />
							</div>
							<div className='col-xl-6'>
								<FeesCollection />
							</div>
							<div className='col-xl-6 ' >
								<TotalPresentAbsenteesReport />
							</div>
							<div className='col-xl-6'>
								<PendingFees />
							</div>
							<div className='col-xl-6'>
								<AttendanceNotSubmitted />
							</div>
						</>
						: null
					}

					{userTypeId == 8 ?
						<>
							<div className='row'>
								<div className='col-xl-6'>
									<div className=' col-xl-12'>
										<TodayFeeCollection />
									</div>
									<div className=' col-xl-12'>
										<FeesCollection />
									</div>
								</div>
								<div className='col-xl-6'>
									<div className='row col-xl-12 h-100'>
										<PendingFees />
									</div>
								</div>
							</div>
						</>
						: null}


					{/* ----------------For Staff DashBoard----------------*/}
					{userTypeId == 7 ? (
						<div className='col-12'>
							<StaffTimeTable />
							{/* <PeriodSchedule /> */}
						</div>
					) : null}

				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;
