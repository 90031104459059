import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getExamMonth = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamMonth/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamMonth - " + error.message)
            console.log('error caught in service : getExamMonth')
        },
    );

    export const addExamMonth = (examMonthPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExamMonth`, examMonthPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExamMonth')
        }
    );

export const updateExamMonth = (examMonthPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateExamMonth`, examMonthPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateExamMonth')
        }
    );

export const deleteExamMonth = (examMonthPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteExamMonth`, examMonthPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteExamMonth')
        }
    );