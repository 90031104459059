import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()
export const getRouteList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRouteList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getRouteList - " + error.message)
            console.log('error caught in service : getRouteList')
        },
    );

    export const getTransportAreaMaster = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTransportAreaMaster/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getTransportAreaMaster - " + error.message)
            console.log('error caught in service : getTransportAreaMaster')
        },
    );

    export const addTransportAreaMaster = (areaPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTransportAreaMaster`, areaPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTransportAreaMaster')
        }
    );

    export const updateTransportAreaMaster = (areaPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTransportAreaMaster`, areaPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTransportAreaMaster')
        }
    );

    export const deleteTransportAreaMaster = (areaPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTransportAreaMaster`, areaPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTransportAreaMaster')
        }
    );

    export const getRouteWiseTravellerList = (routeMasterId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRouteWiseTravellerList/${getLicenseKey}/${routeMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getRouteWiseTravellerList - " + error.message)
            console.log('error caught in service : getRouteWiseTravellerList')
        },
    );

    export const getVehicleType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleType - " + error.message)
            console.log('error caught in service : getVehicleType')
        },
    );

    export const getVehicleDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleDetails - " + error.message)
            console.log('error caught in service : getVehicleDetails')
        },
    );

    export const addVehicle = (createVehicleEntryPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addVehicle`, createVehicleEntryPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addVehicle')
        }
    );

    export const updateVehicleDetails = (updateVehiclePost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateVehicleDetails`, updateVehiclePost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateVehicleDetails')
        }
    );
    
    export const deleteVehicle = (updateVehiclePost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteVehicle`, updateVehiclePost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteVehicle')
        }
    );

    export const getDeletedVehicleList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDeletedVehicleList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDeletedVehicleList - " + error.message)
            console.log('error caught in service : getDeletedVehicleList')
        },
    );

    export const getSalesVehicleList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSalesVehicleList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSalesVehicleList - " + error.message)
            console.log('error caught in service : getSalesVehicleList')
        },
    );

    export const getRoutePointNumber = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRoutePointNumber/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getRoutePointNumber - " + error.message)
            console.log('error caught in service : getRoutePointNumber')
        },
    );

    export const getRouteTravellerCountByRouteMasterId = (routeMasterId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRouteTravellerCountByRouteMasterId/${getLicenseKey}/${routeMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getRouteTravellerCountByRouteMasterId - " + error.message)
            console.log('error caught in service : getRouteTravellerCountByRouteMasterId')
        },
    );
    
    export const addRouteMaster = (routeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addRouteMaster`, routeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addRouteMaster')
        }
    );

    
    export const getRouteTravellerListByRouteMasterId = (routeMasterId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRouteTravellerListByRouteMasterId/${getLicenseKey}/${routeMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getRouteTravellerListByRouteMasterId - " + error.message)
            console.log('error caught in service : getRouteTravellerListByRouteMasterId')
        },
    );

    export const addRouteTraveller = (routeTravellerPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addRouteTraveller`, routeTravellerPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addRouteTraveller')
        }
    );

    export const updateRouteMaster = (routeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateRouteMaster`, routeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateRouteMaster')
        }
    );

    export const deleteRouteArea = (routeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteRouteArea`, routeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteRouteArea')
        }
    );

    export const addRouteArea = (routeAreaPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addRouteArea`, routeAreaPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addRouteArea')
        }
    );
    
    export const getVehicleTaxDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleTaxDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleTaxDetails - " + error.message)
            console.log('error caught in service : getVehicleTaxDetails')
        },
    );

    export const addVehicleTaxDetails = (vehicleTaxPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addVehicleTaxDetails`, vehicleTaxPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addVehicleTaxDetails')
        }
    );

    export const deleteVehicleTaxDetails = (vehicleTaxPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteVehicleTaxDetails`, vehicleTaxPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteVehicleTaxDetails')
        }
    );


    export const getVehicleSparePartsDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getVehicleSparePartsDetails/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getVehicleSparePartsDetails - " + error.message)
            console.log('error caught in service : getVehicleSparePartsDetails')
        },
    );

    export const getSparePartsType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSparePartsType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSparePartsType - " + error.message)
            console.log('error caught in service : getSparePartsType')
        },
    );

    
    export const addVehicleSparePartsDetails = (sparePartsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addVehicleSparePartsDetails`, sparePartsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addVehicleSparePartsDetails')
        }
    );

    
    export const updateVehicleSparePartsDetails = (sparePartsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateVehicleSparePartsDetails`, sparePartsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateVehicleSparePartsDetails')
        }
    );

    export const getVehicleDetailsbyVehicleDetailsId = (vehicleDetailsId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getVehicleDetails/${getLicenseKey}/${vehicleDetailsId}`,
            response => {
                success(response)
            },
            error => {
                failure("getVehicleDetails - " + error.message)
                console.log('error caught in service : getVehicleDetails')
            },
        );

        export const updateVehicleTaxDetails = (vehicleEditTaxPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

            api.post(getAPIURL() + `updateVehicleTaxDetails`, vehicleEditTaxPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : updateVehicleTaxDetails')
                }
            );

        export const deleteVehicleSparePartsDetails = (sparePartsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

            api.post(getAPIURL() + `deleteVehicleSparePartsDetails`, sparePartsPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : deleteVehicleSparePartsDetails')
                }
            );

    