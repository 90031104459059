import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Button from "../../../bootstrap/Button";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import Select from "../../../bootstrap/forms/Select";
import Page from "../../../../layout/Page/Page";
import Popovers from "../../../bootstrap/Popovers";
import classNames from "classnames";
import Icon from "../../../icon/Icon";
import { useFormik } from "formik";
import AuthContext from "../../../../contexts/authContext";
import { addVehicle, getVehicleType } from "../../../../services/transport.service";
import { toasts } from "../../../../services/toast.service";
import { getCity, getStates, onlyAllowNumber } from "../../../../services/common.service";
import { getLicenseKey } from "../../../../services/application.settings";
import useDarkMode from "../../../../hooks/useDarkMode";
import AlertService from "../../../../services/AlertService";
import { showLoader } from "../../../../services/loader.services";
import SearchableSelect from "../../../../common/components/SearchableSelect";

const CreateVehicle = () => {
    const [step, setStep] = useState<number>(0);
    const totalSteps: number = 3;

    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleStepClick = (newStep: any) => {
        setStep(newStep);
    };

    const titles = [
        'Create Vehicle',
        'Details',
        'Document'
    ];

    useEffect(() => {
        getVehicleTypeList();
        getStatesList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const[vehicleTypeId,setVehicleTypeId] = useState<any>('')
    const [stateId, setStateId] = useState<any>('')
    const [cityId, setCityId] = useState<any>([])

    const [vehicleTypeData, setVehicleTypeData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [isNavigate, setIsNavigate] = useState<any>(null);

    const createVehicleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleId: "",
            vehicleTypeId: '',
            registrationNo: "",
            registrationDate: "",
            regValidUpto: "",
            assetNo: "",
            chassisNo: "",
            brandAndModel: "",
            seatingCapacity: "",
            engineNo: "",
            fuelType: "",
            gps: "",
            hypothecatedTo: "",
            hypothecatedToDate: "",
        },
        validate: (values) => {
            const errors: {
                vehicleId?: string;
                vehicleTypeId?: string;
                registrationNo?: string;
                brandAndModel?: string;
                seatingCapacity?: string;
                fuelType?: string;
            } = {};
            if (!values.vehicleId) {
                errors.vehicleId = 'Required';
            }
            if (!vehicleTypeId) {
                errors.vehicleTypeId = 'Required';
            }
            if (!values.registrationNo) {
                errors.registrationNo = 'Required';
            }
            if (!values.brandAndModel) {
                errors.brandAndModel = 'Required';
            }
            if (!values.seatingCapacity) {
                errors.seatingCapacity = 'Required';
            }
            if (!values.fuelType) {
                errors.fuelType = 'Required';
            }
            return errors;
        },

        validateOnChange: true,
        onSubmit: () => { handleNext() },
    });

    const detailsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ownerType: "",
            registerOwner: "",
            registeredAddress: "",
            contactNo: "",
            insuranceNo: "",
            insuredBy: "",
            insuranceRenewalDate: "",
            certificateNo: "",
            goveranceRenewelDate: "",
            busPermitRenewelDate: "",
            yearOfMake: "",
            stateId: "",
            cityId: "",
            fromDate: "",
            toDate: ""
        },
        validate: (values) => {
            const errors: {
                ownerType?: string;
                insuranceNo?: string;
                insuredBy?: string;
                insuranceRenewalDate?: string;
                busPermitRenewelDate?: string;
                stateId?: string;
                cityId?: string
                contactNo?: string
            } = {};
            if (!values.ownerType) {
                errors.ownerType = 'Required';
            }
            if (!values.insuranceNo) {
                errors.insuranceNo = 'Required';
            }
            if (!values.insuredBy) {
                errors.insuredBy = 'Required';
            }
            if (!values.insuranceRenewalDate) {
                errors.insuranceRenewalDate = 'Required';
            }
            if (!values.busPermitRenewelDate) {
                errors.busPermitRenewelDate = 'Required';
            }
            if (!stateId) {
                errors.stateId = 'Required';
            }
            if (cityDetailsData.length == 0) {
                errors.cityId = 'Required';
            }
            if (values.contactNo && !/^[6-9]\d{9}$/i.test(values.contactNo)) {
                errors.contactNo = 'Please enter valid mobile number'
            }
            return errors;
        },

        validateOnChange: true,
        onSubmit: () => { handleNext() },
    });

    const documentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
        },
        validate: (values) => { },

        validateOnChange: true,
        onSubmit: () => { addCreateVehicleSubmit() },
    });

    const selectVehicleType = (e:any) => {
        let vehicleTypeId = e
        setVehicleTypeId(vehicleTypeId);
    }

    const selectState = (e: any) => {
        let stateId = e
        setStateId(stateId)
        if(stateId?.value != undefined){
            getCityList(stateId?.value)
        }
    }

    const [cityDetailsData, setCityDetailsData] = useState<any>([])
    const [cityDetailsData1, setCityDetailsData1] = useState<any>([])

    const selectCity = (e: any) => {
        let cityId = e.map((item: any) => item.value)
         //setCityId(cityId)
         setCityDetailsData1(e)
        setCityDetailsData(cityId)
    }

    function getVehicleTypeList() {
        getVehicleType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleType;
                    if (data != undefined) {
                        setVehicleTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setVehicleTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setVehicleDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            vehicleId: createVehicleForm.values.vehicleId ? createVehicleForm.values.vehicleId : null,
            vehicleTypeId:vehicleTypeId?.value? vehicleTypeId?.value : null,
            registrationNo: createVehicleForm.values.registrationNo ? createVehicleForm.values.registrationNo : null,
            registrationDate: createVehicleForm.values.registrationDate ? createVehicleForm.values.registrationDate : null,
            regValidUpto: createVehicleForm.values.regValidUpto ? createVehicleForm.values.regValidUpto : null,
            assetNo: createVehicleForm.values.assetNo ? createVehicleForm.values.assetNo : null,
            chassisNo: createVehicleForm.values.chassisNo ? createVehicleForm.values.chassisNo : null,
            brandAndModel: createVehicleForm.values.brandAndModel ? createVehicleForm.values.brandAndModel : null,
            seatingCapacity: createVehicleForm.values.seatingCapacity ? createVehicleForm.values.seatingCapacity : null,
            engineNo: createVehicleForm.values.engineNo ? createVehicleForm.values.engineNo : null,
            fuelType: createVehicleForm.values.fuelType ? createVehicleForm.values.fuelType : null,
            gps: createVehicleForm.values.gps ? createVehicleForm.values.gps : null,
            hypothecatedTo: createVehicleForm.values.hypothecatedTo ? createVehicleForm.values.hypothecatedTo : null,
            hypothecatedToDate: createVehicleForm.values.hypothecatedToDate ? createVehicleForm.values.hypothecatedToDate : null,
            ownerType: detailsForm.values.ownerType ? detailsForm.values.ownerType : null,
            registerOwner: detailsForm.values.registerOwner ? detailsForm.values.registerOwner : null,
            registeredAddress: detailsForm.values.registeredAddress ? detailsForm.values.registeredAddress : null,
            contactNo: detailsForm.values.contactNo ? detailsForm.values.contactNo : null,
            insuranceNo: detailsForm.values.insuranceNo ? detailsForm.values.insuranceNo : null,
            insuredBy: detailsForm.values.insuredBy ? detailsForm.values.insuredBy : null,
            insuranceRenewalDate: detailsForm.values.insuranceRenewalDate ? detailsForm.values.insuranceRenewalDate : null,
            certificateNo: detailsForm.values.certificateNo ? detailsForm.values.certificateNo : null,
            goveranceRenewelDate: detailsForm.values.goveranceRenewelDate ? detailsForm.values.goveranceRenewelDate : null,
            busPermitRenewelDate: detailsForm.values.busPermitRenewelDate ? detailsForm.values.busPermitRenewelDate : null,
            yearOfMake: detailsForm.values.yearOfMake ? detailsForm.values.yearOfMake : null,
            stateId: stateId?.value ? stateId?.value : null,
            cityId: cityDetailsData ? cityDetailsData : null,
            fromDate: detailsForm.values.fromDate ? detailsForm.values.fromDate : null,
            toDate: detailsForm.values.toDate ? detailsForm.values.toDate : null,
        })
    }

    function addCreateVehicleSubmit() {
        showLoader(true)
        if (createVehicleForm.isValid) {
            let createVehicleEntryPost = setVehicleDetails()
            addVehicle(createVehicleEntryPost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../management/vehicleList`)
                        createVehicleForm.resetForm();
                        detailsForm.resetForm();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (createVehicleForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    const [rows, setRows] = useState<any>([{ id: Date.now() }]);

    const addRow = () => {
        const newRow = { id: Date.now() };
        setRows([...rows, newRow]);
    };

    const deleteRow = (id: number) => {
        setRows(rows.filter((row : any) => row.id !== id));
    };
    return (
        <PageWrapper title="Create Vehicle">
            <Page container='fluid'>
                <Card>
                    <CardHeader>
                        <CardLabel icon="Assignment" color="info">
                            {[...Array(totalSteps)].map((_, index) => (
                                <CardTitle
                                    key={index}
                                    className={index !== step ? 'd-none' : undefined}
                                >{titles[step]}
                                </CardTitle>
                            ))}
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='wizard-progress position-relative'>
                            <div className='progress'>
                                <div
                                    className='progress-bar bg-primary'
                                    role='progressbar'
                                    style={{ width: `${(100 / (totalSteps - 1)) * step}%` }}
                                    aria-valuenow={(100 / (totalSteps - 1)) * step}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    aria-label='progress'
                                />
                            </div>
                            {[...Array(totalSteps)].map((child: any, index: any) => (
                                <Popovers
                                    key={index}
                                    desc={titles[index]}
                                    trigger='hover'>
                                    <button
                                        type='button'
                                        className={classNames(
                                            'wizard-progress-btn',
                                            'position-absolute p-0 top-0',
                                            'translate-middle',
                                            'btn btn-sm',
                                            {
                                                [`btn-primary`]: step >= index,
                                                [`btn-${themeStatus}`]: step < index,
                                            },
                                            'rounded-pill',
                                        )}
                                        style={{
                                            left: `${(100 / (totalSteps - 1)) * index}%`,
                                        }}
                                        onClick={() => { handleStepClick(index) }}>
                                        {index + 1}
                                    </button>
                                </Popovers>
                            ))}
                        </div>

                        {step == 0 && (
                            <Card shadow='none' title="GeneralInfo">
                                <form noValidate onSubmit={createVehicleForm.handleSubmit}>
                                    <div className="row g-4">
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Vehicle Info </p>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="vehicleId" label="Vehicle Id" isFloating>
                                                <Input type="text" placeholder="Enter Vehicle ID"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.vehicleId}
                                                    onBlur={createVehicleForm.handleBlur}
                                                    isValid={createVehicleForm.isValid}
                                                    isTouched={createVehicleForm.touched.vehicleId}
                                                    invalidFeedback={createVehicleForm.errors.vehicleId} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="vehicleTypeId" label="Vehicle Type" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select Vehicle Type"
                                                    onChange={selectVehicleType}
                                                    value={vehicleTypeId}
                                                    list={vehicleTypeData.map((data: any) => (
                                                        { value: data.vehicleTypeId, label: data.vehicleType }
                                                    ))}
                                                    onBlur={createVehicleForm.handleBlur}
                                                    isValid={createVehicleForm.isValid}
                                                    isTouched={createVehicleForm.touched.vehicleTypeId}
                                                    invalidFeedback={createVehicleForm.errors.vehicleTypeId} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="registrationNo" label="Registration No" isFloating>
                                                <Input type="text" placeholder="Enter Registration No"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.registrationNo}
                                                    onBlur={createVehicleForm.handleBlur}
                                                    isValid={createVehicleForm.isValid}
                                                    isTouched={createVehicleForm.touched.registrationNo}
                                                    invalidFeedback={createVehicleForm.errors.registrationNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="registrationDate" label="Registration Date" isFloating>
                                                <Input type="date" placeholder="Enter Registration Date"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.registrationDate} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="regValidUpto" label="Reg Valid Upto" isFloating>
                                                <Input type="date" placeholder="Enter Reg Valid Upto"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.regValidUpto} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="assetNo" label="Asset No" isFloating>
                                                <Input type="text" placeholder="Enter Asset No"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.assetNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="chassisNo" label="Chassis No" isFloating>
                                                <Input type="text" placeholder="Enter Chassis No"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.chassisNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="brandAndModel" label="Brand & Model" isFloating>
                                                <Input type="text" placeholder="Enter Brand & Model"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.brandAndModel}
                                                    onBlur={createVehicleForm.handleBlur}
                                                    isValid={createVehicleForm.isValid}
                                                    isTouched={createVehicleForm.touched.brandAndModel}
                                                    invalidFeedback={createVehicleForm.errors.brandAndModel} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="engineNo" label="Engine No" isFloating>
                                                <Input type="text" placeholder="Enter Engine No"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.engineNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="seatingCapacity" label="Seating Capacity" isFloating>
                                                <Input type="text" placeholder="Seating Capacity"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.seatingCapacity}
                                                    onBlur={createVehicleForm.handleBlur}
                                                    isValid={createVehicleForm.isValid}
                                                    isTouched={createVehicleForm.touched.seatingCapacity}
                                                    invalidFeedback={createVehicleForm.errors.seatingCapacity}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="hypothecatedTo" label="Hypothecated To" isFloating>
                                                <Input type="text" placeholder="Hypothecated To"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.hypothecatedTo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="hypothecatedToDate" label="Hypothecated To Date" isFloating>
                                                <Input type="date" placeholder="Hypothecated To Date"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.hypothecatedToDate} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="fuelType" label="Fuel Type" isFloating>
                                                <Select  ariaLabel="" placeholder="Select Fuel Type"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.fuelType}
                                                    onBlur={createVehicleForm.handleBlur}
                                                    isValid={createVehicleForm.isValid}
                                                    isTouched={createVehicleForm.touched.fuelType}
                                                    invalidFeedback={createVehicleForm.errors.fuelType}>
                                                    <option>Petrol</option>
                                                    <option>Diesel</option>
                                                    <option>Gas</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="gps" label="Gps" isFloating>
                                                <Select  ariaLabel="" placeholder="GPS"
                                                    onChange={createVehicleForm.handleChange}
                                                    value={createVehicleForm.values.gps}>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <CardFooter>
                                        <CardFooterRight>
                                            <Button color="primary" type="submit" isLight>Next</Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </form>

                            </Card>
                        )}
                        {step == 1 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={detailsForm.handleSubmit}>
                                    <div className="row g-4">
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Owner/Contractor Details </p>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="ownerType" label="Type of Owner" isFloating>
                                                <Select ariaLabel="" placeholder="Select Type of Owner"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.ownerType}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.ownerType}
                                                    invalidFeedback={detailsForm.errors.ownerType}>
                                                    <option>Owner</option>
                                                    <option>Contractor</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="registerOwner" label="Register Owner" isFloating>
                                                <Input type="text" placeholder="Enter Register Owner"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.registerOwner} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="contactNo" label="Contact No" isFloating>
                                                <Input type="text" placeholder="Enter Contact No"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.contactNo}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.contactNo}
                                                    invalidFeedback={detailsForm.errors.contactNo}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="registeredAddress" label="Registered Address" isFloating>
                                                <Input type="text" placeholder="Enter Registered Address"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.registeredAddress} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Insurance Details </p>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="insuranceNo" label="Insurance No" isFloating>
                                                <Input type="text" placeholder="Enter Insurance No"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.insuranceNo}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.insuranceNo}
                                                    invalidFeedback={detailsForm.errors.insuranceNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="insuredBy" label="Insured By" isFloating>
                                                <Input type="text" placeholder="Enter Insured By"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.insuredBy}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.insuredBy}
                                                    invalidFeedback={detailsForm.errors.insuredBy} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="insuranceRenewalDate" label="Insurance Renewal Date" isFloating>
                                                <Input type="date" placeholder="Enter Date"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.insuranceRenewalDate}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.insuranceRenewalDate}
                                                    invalidFeedback={detailsForm.errors.insuranceRenewalDate} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'> <Icon icon='List' color="info" size={"2x"} />Speed Governance Details </p>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="certificateNo" label="Certificate No" isFloating>
                                                <Input type="text" placeholder="Enter Certificate No"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.certificateNo} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="goveranceRenewelDate" label="Renewal Date" isFloating>
                                                <Input type="date" placeholder="Enter Renewal Date"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.goveranceRenewelDate} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'> <Icon icon='List' color="info" size={"2x"} />Bus Permit Details </p>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="busPermitRenewelDate" label="Renewal Date" isFloating>
                                                <Input type="date" placeholder="Enter Date"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.busPermitRenewelDate}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.busPermitRenewelDate}
                                                    invalidFeedback={detailsForm.errors.busPermitRenewelDate} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="yearOfMake" label="Year of Make" isFloating>
                                                <Input type="date" placeholder="Enter Date"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.yearOfMake} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="stateId" label="State" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select State"
                                                    onChange={selectState}
                                                    value={stateId}
                                                    list={statesData.map((data: any) => (
                                                        { value: data.stateId, label: data.stateName }
                                                    ))}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.stateId}
                                                    invalidFeedback={detailsForm.errors.stateId}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="cityId" label="City" isFloating>
                                                <SearchableSelect isFloating ariaLabel="" placeholder="Select City"
                                                    onChange={selectCity}
                                                    value={cityDetailsData1} multiple
                                                    list={cityData.map((data: any) => (
                                                        { value: data.cityId, label: data.cityName }
                                                    ))}
                                                    onBlur={detailsForm.handleBlur}
                                                    isValid={detailsForm.isValid}
                                                    isTouched={detailsForm.touched.cityId}
                                                    invalidFeedback={detailsForm.errors.cityId} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Fitness Details </p>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="fromDate" label="From Date" isFloating>
                                                <Input type="date" placeholder="Enter Date"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.fromDate} />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-3">
                                            <FormGroup id="toDate" label="To Date" isFloating>
                                                <Input type="date" placeholder="Enter Date"
                                                    onChange={detailsForm.handleChange}
                                                    value={detailsForm.values.toDate} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <CardFooter>
                                        <CardFooterLeft>
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        </CardFooterLeft>
                                        <CardFooterRight>
                                            <Button color="primary" type="submit" isLight>Next</Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </form>
                            </Card>
                        )}
                        {step == 2 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={documentForm.handleSubmit}>
                                    <div className="row g-4">
                                        <div className="col-lg-12 align-self-center">
                                            <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Vehicle Scanned Document </p>
                                        </div>
                                     </div>

                                    <div className="row col-12">
                                        {rows.map((row : any) => (
                                            <div key={row.id} className="col-lg-10 row mt-4">
                                                <div className="col-lg-4">
                                                    <FormGroup>
                                                        <Input type="text" onChange={documentForm.handleChange} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Input type='file' autoComplete='photo' aria-label='Upload image file' />
                                                </div>
                                                <div className='col-lg-2'>
                                                    {rows.length > 1 && (
                                                        <Button color='danger' isLight rounded={1} icon='Delete' onClick={() => deleteRow(row.id)} />
                                                    )}
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <div className='col-lg-2 mt-4'>
                                                <Button color='info' isLight rounded={1} icon='Add' onClick={addRow}>
                                                    Add
                                                </Button>
                                            </div>
                                        
                                    </div>
                                    <CardFooter>
                                        <CardFooterLeft>
                                            <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        </CardFooterLeft>
                                        <CardFooterRight>
                                            <Button color="info" type="submit">Submit</Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </form>
                            </Card>
                        )}
                    </CardBody>
                </Card>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
        </PageWrapper>
    )
}
export default CreateVehicle;