import dayjs from "dayjs";
import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { toasts } from "./toast.service";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const checkIsWorkingDay = (dateInMilliSec :number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `checkIsWorkingDay/${getLicenseKey}/${dateInMilliSec}`,
        response => {
            success(response)
        },
        error => {
            failure("checkIsWorkingDay - " + error.message)
            console.log('error caught in service : checkIsWorkingDay')
        },
    );

    export const getStudentAttendanceReport = (dateOfAttendance: number, courseId: string, semesterDetailsId: string, sectionDetailsId: string, periodDetailsId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceReportList/${getLicenseKey}/${dateOfAttendance}/${courseId}/${semesterDetailsId}/${sectionDetailsId}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceReportList - " + error.message)
            console.log('error caught in service : getStudentAttendanceReportList')
        },
    );

    export const getStudentAttendanceSummaryReport = (dateOfAttendance: number, courseId: string, batchMasterId: string, semesterDetailsId: string, sectionDetailsId: string, shift: number, periodDetailsId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceSummaryReport/${getLicenseKey}/${dateOfAttendance}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}/${shift}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceSummaryReport - " + error.message)
            console.log('error caught in service : getStudentAttendanceSummaryReport')
        },
    );

    export const addStudentAttendanceForWeb = (studentAttendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentAttendanceForWeb`, studentAttendancePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentAttendanceForWeb')
        }
    );

    export const getStudentLateAttendanceList = (userAccount : any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentLateAttendanceList/${getLicenseKey}/${userAccount}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentLateAttendanceList - " + error.message)
            console.log('error caught in service : getStudentLateAttendanceList')
        },
    );
    
    export const addStudentLateAttendance = (attendencaPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentLateAttendance`, attendencaPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentLateAttendance')
        }
    );

    export const updateLateAttendance = (attendencaReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLateAttendance`, attendencaReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateLateAttendance')
        }
    );

    export const getLateAttendanceReason = ( success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getLateAttendanceReason/${getLicenseKey}`,
            response => {
                success(response)
            },
            error => {
                failure("getLateAttendanceReason - " + error.message)
                console.log('error caught in service : getLateAttendanceReason')
            },
        );

        export const addStudentLateAttendanceReason = (attendencaReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

            api.post(getAPIURL() + `addStudentLateAttendanceReason`, attendencaReasonPostData,
                response => {
                    success(response)
                },
                error => {
                    failure(error.message)
                    console.log('error caught in service : addStudentLateAttendanceReason')
                }
            );
