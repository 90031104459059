import { useContext, useEffect, useRef, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, {
	CardHeader,
	CardLabel,
	CardTitle,
	CardBody,
	CardActions,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import Collapse from '../../bootstrap/Collapse';
import Icon from '../../icon/Icon';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Input from '../../bootstrap/forms/Input';
import useDarkMode from '../../../hooks/useDarkMode';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Avatar from '../../Avatar';
import UserImage2Webp from '../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../assets/img/wanna/wanna1.png';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import {
	getDepartmentWiseRouteList,
	getStudentListByTransportAreaMasterIdForReport,
} from '../../../services/report.service';
import {
	getColumnsForDataTable,
	getGraduationType,
	pictNotLoading,
	profilePic,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { getDepartmentList } from '../../../services/master.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';

function DepartmentWiseRoute() {
	useEffect(() => {
		getGraduation();
		getDepartment();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [departmentId, setDepartmentId] = useState<any>('');
	const [graduationTypeId, setGraduationTypeId] = useState<any>('');
	const [departmentWiseRouteData, setDepartmentWiseRouteData] = useState([]);
	const [departmentData, setDepartmentData] = useState<any>([]);
	const [graduationTypeData, setGraduationTypeData] = useState<any>([]);
	const [transportAreaMasterData, setTransportAreaMAsterData] = useState([]);
	const [departmentName, setDepartmentName] = useState('');
	const [areaName, setAreaName] = useState('');
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(departmentWiseRouteData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isLoader, setIsLoader] = useState(false);
	//Modal
	const [departmentWiseRouteModal, setDepartmentWiseRouteModal] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('');

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedTransportAreaMaster = i;
			setDepartmentWiseRouteModal(true);
			setDepartmentName(selectedTransportAreaMaster.departmentName);
			setAreaName(selectedTransportAreaMaster.areaName);
			getStudentListByTransportAreaMasterIdList(
				departmentId?.value,
				graduationTypeId?.value,
				selectedTransportAreaMaster.transportAreaMasterId,
			);
		}
	}

	const selectDepartment = (e: any) => {
		setGraduationTypeId('');
		setIsOpenListCard(true);
		setDataSuccess(false);
		let departmentId = e;
		setDepartmentId(departmentId);
	};

	const selectGraduationType = (e: any) => {
		setIsOpenListCard(true);
		setDataSuccess(false);
		let graduationTypeId = e;
		setGraduationTypeId(graduationTypeId);
	};

	function view() {
		setDataSuccess(false);
		setIsLoader(true);
		getDepartmentWiseRoute(departmentId?.value, graduationTypeId?.value);
		columnVisibilityForm.resetForm();
	}

	function getDepartment() {
		getDepartmentList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.department;
					if (data != undefined) {
						const departmentData = [
							{ departmentId: 0, departmentName: 'All Department' },
						];
						data.forEach((obj: { departmentId: number; departmentName: string }) => {
							let departmentName = departmentData.push(obj);
						});
						setDepartmentData(departmentData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setDepartmentData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getGraduation() {
		getGraduationType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.graduationType;
					if (data != undefined) {
						const graduationTypeData = [
							{ graduationTypeId: 0, graduationType: 'All Graduation' },
						];
						data.forEach(
							(obj: { graduationTypeId: number; graduationType: string }) => {
								let graduationType = graduationTypeData.push(obj);
							},
						);
						setGraduationTypeData(graduationTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setGraduationTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getDepartmentWiseRoute(departmentId: string, graduationTypeId: string) {
		setIsLoader(true);
		getDepartmentWiseRouteList(
			departmentId,
			graduationTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.departmentWiseRouteList;
					if (data != undefined) {
						setIsLoader(false);
						setDepartmentWiseRouteData(data);
						setIsOpenListCard(false);
						setDataSuccess(true);
						getColumnsForTable('getDepartmentWiseRouteList', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getDepartmentWiseRouteList', 'get');
					setDepartmentWiseRouteData([]);
					setNoDataMsg(response.data.message);
					setIsOpenListCard(false);
					setDataSuccess(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getStudentListByTransportAreaMasterIdList(
		departmentId: any,
		graduationTypeId: any,
		transportAreaMasterId: any,
	) {
		getStudentListByTransportAreaMasterIdForReport(
			departmentId,
			graduationTypeId,
			transportAreaMasterId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentListByTransportAreaMasterIdForReport;
					if (data != undefined) {
						setTransportAreaMAsterData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getDepartmentWiseRouteList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function closeModal() {
		setDepartmentWiseRouteModal(false);
	}

	return (
		<PageWrapper title='Department Wise Route List'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									Department Wise Route List
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='departmentId' label='Department' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Department'
											onChange={selectDepartment}
											value={departmentId}
											list={departmentData.map((data: any) => ({
												value: data.departmentId,
												label: data.departmentName,
											}))}
										/>
									</FormGroup>
								</div>
								{departmentId?.value != undefined ?
									<div className='col-lg-3'>
										<FormGroup
											id='graduationTypeId'
											label='Graduation Type'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Graduation Type'
												onChange={selectGraduationType}
												value={graduationTypeId}
												list={graduationTypeData.map((data: any) => ({
													value: data.graduationTypeId,
													label: data.graduationType,
												}))}
											/>
										</FormGroup>
									</div>
									: null}
								{graduationTypeId?.value != undefined ?
									<div className='col-3 align-self-center'>
										<Button icon='ArrowDownward' color='primary' onClick={view}>
											View
										</Button>
									</div> : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									Department Wise Route List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup
								className='col-lg-3 justify-content-end d-print-none'
								color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getDepartmentWiseRouteList',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'Department Wise Route List',
														departmentWiseRouteData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														departmentWiseRouteData,
														columnVisibilityData,
														'Department Wise Route List',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'Department Wise Route List',
														departmentWiseRouteData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th
											scope='col'
											onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>
											S.No{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('sno')}
												icon='FilterList'
											/>
										</th>
										{columnVisibilityData.map((column: any) => (
											<th
												key={column.keyName}
												scope='col'
												onClick={() => requestSort(column.keyName)}
												className='cursor-pointer text-decoration-underline'>
												{column.columnName}
												<Icon
													size='lg'
													className={getClassNamesFor(column.keyName)}
													icon='FilterList'
												/>
											</th>
										))}
										<th scope='col' className='d-print-none'>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{filteredData != '' ? (
										<>
											{filteredData.map((rowData: any) => (
												<tr key={rowData.transportAreaMasterId}>
													<td>{rowData.sno}</td>
													{columnVisibilityData.map((column: any) =>
														column.isDisplay &&
															rowData[column.keyName] ? (
															<td
																key={`${rowData.transportAreaMasterId}-${column.keyName}`}>
																{rowData[column.keyName]}
															</td>
														) : (
															<td key={`empty-${column.keyName}`} />
														),
													)}
													<td className='d-print-none'>
														<Button
															color='dark'
															isLight
															icon='More'
															aria-label='Edit'
															onClick={() => {
																onActivate(rowData);
															}}
														/>
													</td>
												</tr>
											))}{' '}
										</>
									) : (
										<NoDataMsg
											columnsCount={columnVisibilityData.length + 2}
											msg={noDataMsg}
										/>
									)}
								</tbody>
							</table>
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				) : null}
			</Page>

			<Modal
				titleId='departmentWiseRouteModal'
				isOpen={departmentWiseRouteModal}
				setIsOpen={setDepartmentWiseRouteModal}
				isStaticBackdrop={true}
				isCentered
				size='lg'>
				<ModalHeader setIsOpen={setDepartmentWiseRouteModal} onClick={closeModal}>
					<ModalTitle id='departmentWiseRouteModal'>Student List</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row g-4'>
						<div className='col-6'>
							<div className='d-flex align-items-center'>
								<div className='flex-shrink-0'>
									<Icon icon='ListAlt' size='3x' color='info' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-5 mb-0'>{departmentName}</div>
									<div className='text-muted'>Department Name</div>
								</div>
							</div>
						</div>
						<div className='col-6'>
							<div className='d-flex align-items-center'>
								<div className='flex-shrink-0'>
									<Icon icon='DirectionsBus' size='3x' color='info' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-5 mb-0'>{areaName}</div>
									<div className='text-muted'>Area Name</div>
								</div>
							</div>
						</div>
						<div className='col-lg-12'>
							<table className='table table-modern table-hover text-nowrap'>
								<thead className='table-info'>
									<tr>
										<th>S.NO</th>
										<th>ADMISSION NO</th>
										<th>STUDENT NAME</th>
										<th>ROUTE NAME</th>
										<th>STUDENT PIC</th>
									</tr>
								</thead>
								<tbody>
									{transportAreaMasterData.map((i: any) => (
										<tr key={i.routemaster_Id}>
											<td className='align-self-center'>{i.sno}</td>
											<td className='align-self-center'>{i.admissionNo} </td>
											<td className='align-self-center'>{i.studentName} </td>
											<td className='align-self-center'>{i.routeName} </td>
											<td className='align-self-center'>
													<img src={i.profilePath != null ? i.profilePath : profilePic(i.genderId)} height={75} width={75} onError={(e : any) => pictNotLoading(e, i.genderId)}/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</PageWrapper >
	);
}
export default DepartmentWiseRoute;
