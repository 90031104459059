import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getStudentTransferCertificate = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTransferCertificate/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTransferCertificate - " + error.message)
            console.log('error caught in service : getStudentTransferCertificate')
        },
    );

export const addStudentTransferCertificate = (addStudentTransferCertificatePost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentTransferCertificate`, addStudentTransferCertificatePost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentTransferCertificate')
        }
    );

export const updateStudentTransferCertificate = (editStudentTransferCertificatePost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentTransferCertificate`, editStudentTransferCertificatePost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentTransferCertificate')
        }
    );

export const getStudentOrStaffCertificateTrackingDetails = (userTypeId: number, studentOrStaffDetailsId: any, studentOrStaffCertificateTrackerId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentOrStaffCertificateTrackingDetails/${getLicenseKey}/${userTypeId}/${studentOrStaffDetailsId}/${studentOrStaffCertificateTrackerId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentOrStaffCertificateTrackingDetails - " + error.message)
            console.log('error caught in service : getStudentOrStaffCertificateTrackingDetails')
        },
    );

export const getCertificateType = (userTypeId: number, certificateTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCertificateType/${getLicenseKey}/${userTypeId}/${certificateTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCertificateType - " + error.message)
            console.log('error caught in service : getCertificateType')
        },
    );

export const getOriginalCertificateListByUserAccountId = (userTypeId: number, studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getOriginalCertificateListByUserAccountId/${getLicenseKey}/${userTypeId}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getOriginalCertificateListByUserAccountId - " + error.message)
            console.log('error caught in service : getOriginalCertificateListByUserAccountId')
        },
    );


export const addStudentCertificateDetails = (addStudentCertificateDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentCertificateDetails`, addStudentCertificateDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentCertificateDetails')
        }
    );

export const addCertificateTracker = (addStudentCertificateTrackerDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCertificateTracker`, addStudentCertificateTrackerDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCertificateTracker')
        }
    );

export const deleteCertificateTrackingDetails = (deleteStudentCertificateTrackerDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteCertificateTrackingDetails`, deleteStudentCertificateTrackerDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteCertificateTrackingDetails')
        }
    );

export const addStudentManualTc = (manualTcEntryPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentManualTc`, manualTcEntryPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentManualTc')
        }
    );

export const getStudentManualTc = (academicPeriodId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentManualTc/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentManualTc - " + error.message)
            console.log('error caught in service : getStudentManualTc')
        },
    );

export const updateStudentManualTc = (updateManualTcEntryPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentManualTc`, updateManualTcEntryPost,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentManualTc')
        }
    );

export const getTransferCertificateIssueList = (graduationTypeId: number, fromDate: any, toDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTransferCertificateIssueList/${getLicenseKey}/${graduationTypeId}/${fromDate}/${toDate}`,
        response => {
            success(response)
        },
        error => {
            failure("getTransferCertificateIssueList - " + error.message)
            console.log('error caught in service : getTransferCertificateIssueList')
        },
    );

export const getTcListForApprovedByStaffDetailsId = (staffDetailsId : any ,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTcListForApprovedByStaffDetailsId/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTcListForApprovedByStaffDetailsId - " + error.message)
            console.log('error caught in service : getTcListForApprovedByStaffDetailsId')
        },
    );

    export const getStudentManualTcDetails = (studentManualTcId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentManualTc/${getLicenseKey}/0/${studentManualTcId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentManualTc - " + error.message)
            console.log('error caught in service : getStudentManualTc')
        },
    );

    export const getNotIssuedTCList = (courseId: number, semesterDetailsId: number, sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getNotIssuedTCList/${getLicenseKey}/${courseId}/${semesterDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getNotIssuedTCList - " + error.message)
            console.log('error caught in service : getNotIssuedTCList')
        },
    );

    export const addCertificateType = (certificatePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCertificateType`, certificatePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCertificateType')
        }
    );

    export const updateStudentTcStatus = (studentTcStatusPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentTcStatus`, studentTcStatusPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentTcStatus')
        }
    );

    export const approveTcDetailsByHod = (approveTcPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `approveTcDetailsByHod`, approveTcPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : approveTcDetailsByHod')
        }
    );

    export const addStudentCertificateIssue = (bonafideCertificatePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentCertificateIssue`, bonafideCertificatePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentCertificateIssue')
        }
    );

    export const getOverAllTransferCertificateDetails = (courseId: number,semesterDetailsId: number,sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getOverAllTransferCertificateDetails/${getLicenseKey}/${courseId}/${semesterDetailsId}/${sectionDetailsId}`,
            response => {
                success(response)
            },
            error => {
                failure("getOverAllTransferCertificateDetails - " + error.message)
                console.log('error caught in service : getOverAllTransferCertificateDetails')
            },
        );