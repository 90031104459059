import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import { toasts } from "../../../../services/toast.service";
import Page from "../../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import Select from "../../../bootstrap/forms/Select";
import AlertService from "../../../../services/AlertService";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import AuthContext from "../../../../contexts/authContext";
import { addCourse, deleteCourse, getCourseDetails, getCourseType, getDegreeType, getDepartmentList, getYearByCourseTypeId, updateCourse } from "../../../../services/master.service";
import { getLicenseKey } from "../../../../services/application.settings";
import { getColumnsForDataTable, getGraduationType, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";


function CourseDetails() {

    useEffect(() => {
        getCourseList();
        getGraduationList();
        getDepartment();
        getCourse();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [courseDetailsData, setCourseDetailsData] = useState([]);
    const [departmentData, setDepartmentData] = useState<any>([])
    const [departmentId, setDepartmentId] = useState<any>('')
    const [degreeTypeId, setDegreeTypeId] = useState<any>('')
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [degreeTypeData, setDegreeTypeData] = useState<any>([])
    const [courseTypeData, setCourseTypeData] = useState<any>([])
    const [noOfYearsData, setNoOfYearsData] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [courseTypeId, setCourseTypeId] = useState<any>('')
    const [noOfYears, setNoOfYears] = useState<any>('')
    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(courseDetailsData);

    const [editCourseOffcanvas, setEditCourseOffcanvas] = useState(false);
    const [addCourseOffcanvas, setAddCourseOffcanvas] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState(false)
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')
    const courseForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentId: '',
            degreeTypeId: '',
            courseCode: '',
            noOfYears: '',
            courseTypeId: '',
            graduationTypeId: '',
        },
        validate: (values) => {
            const errors: {
                courseCode?: string;
                departmentId?: string;
                degreeTypeId?: string;
                noOfYears?: string;
                courseTypeId?: string;
                graduationTypeId?: string;
            } = {};
            if (!values.courseCode) {
                errors.courseCode = 'Required';
            }
            if (!departmentId?.value == undefined) {
                errors.departmentId = 'Required';
            }
            if (!degreeTypeId?.value) {
                errors.degreeTypeId = 'Required';
            }
            if (!noOfYears?.value) {
                errors.noOfYears = 'Required';
            }
            if (!courseTypeId?.value) {
                errors.courseTypeId = 'Required';
            }
            if (!graduationTypeId?.value) {
                errors.graduationTypeId = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { addCourseSubmit() },
    });

    const courseEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentId: '',
            degreeTypeId: '',
            courseCode: '',
            noOfYears: '',
            courseTypeId: '',
            graduationTypeId: '',
            courseId: '',
            courseName: '',
        },
        validate: (values) => {
            const errors: {
                courseCode?: string;
                departmentId?: string;
                noOfYears?: string;
                courseTypeId?: string;
                graduationTypeId?: string;
                courseName?: string;
            } = {};
            if (!values.courseCode) {
                errors.courseCode = 'Required';
            }
            if (!departmentId) {
                errors.departmentId = 'Required';
            }
            if (!noOfYears) {
                errors.noOfYears = 'Required';
            }
            if (!courseTypeId) {
                errors.courseTypeId = 'Required';
            }
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!values.courseName) {
                errors.courseName = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateCourseSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedCourse = i
            setEditCourseOffcanvas(true)

            setDepartmentId({ value: selectedCourse.departmentId, label: selectedCourse.departmentName })
            setDegreeTypeId({ value: selectedCourse.degreeTypeId, label: selectedCourse.degreeType })
            setNoOfYears({ value: selectedCourse.noOfYears, label: selectedCourse.noOfYears })
            setCourseTypeId({ value: selectedCourse.courseTypeId, label: selectedCourse.courseType })
            setGraduationTypeId({ value: selectedCourse.graduationTypeId, label: selectedCourse.graduationType })
            courseEditForm.setValues({
                departmentId: departmentId,
                degreeTypeId: degreeTypeId,
                courseCode: selectedCourse.courseCode,
                noOfYears: noOfYears,
                courseTypeId: courseTypeId,
                graduationTypeId: graduationTypeId,
                courseId: selectedCourse.courseId,
                courseName: selectedCourse.courseName,
            });
            getYearByCourseType(selectedCourse.courseTypeId)
        }
    }

    // Filter
    const filteredData = updateFilter(items, columnVisibilityData, columnVisibilityForm);
    const onCurrentPageData = dataPagination(filteredData, currentPage, perPage);

    const selectGraduationType = (e: any) => {
        setDegreeTypeId('')
        setDegreeTypeData([])
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)

        if (graduationTypeId?.value != undefined) {
            getDegree(graduationTypeId?.value)
        }
    }

    const selectDegreeType = (e: any) => {
        let degreeTypeId = e
        setDegreeTypeId(degreeTypeId)
    }

    const selectDepartment = (e: any) => {
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectCourseType = (e: any) => {
        setNoOfYears('')
        setNoOfYearsData([]);
        let courseTypeId = e
        setCourseTypeId(courseTypeId)
        if (courseTypeId?.value != undefined) {
            getYearByCourseType(courseTypeId?.value)
        }
    }

    const selectNoOfYears = (e: any) => {
        let noOfYears = e
        setNoOfYears(noOfYears)
    }

    function getCourseList() {
        setDataStatus(false)
        getCourseDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        getColumnsForTable('getCourse', 'get');
                        setCourseDetailsData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getCourse', 'get');
                    setCourseDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setCourseDetails() {

        return ({
            departmentId: departmentId?.value,
            degreeTypeId: degreeTypeId?.value,
            courseCode: courseForm.values.courseCode,
            noOfYears: noOfYears?.value,
            courseTypeId: courseTypeId?.value,
            graduationTypeId: graduationTypeId?.value,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setCourseEditDetails() {
        return ({
            courseId: courseEditForm.values.courseId,
            departmentId: departmentId?.value,
            courseName: courseEditForm.values.courseName,
            courseCode: courseEditForm.values.courseCode,
            noOfYears: noOfYears?.value,
            courseTypeId: courseTypeId?.value,
            graduationTypeId: graduationTypeId?.value,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            isActive: 1
        })
    }

    function addCourseSubmit() {
        showLoader(true)
        if (courseForm.isValid) {
            let coursePostData = setCourseDetails()
            addCourse(coursePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getCourseList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (courseForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateCourseSubmit() {
        showLoader(true)
        if (courseEditForm.isValid) {
            let coursePostData = setCourseEditDetails()
            updateCourse(coursePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getCourseList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (courseEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteCourseSubmit() {
        showLoader(true)
        // if (courseEditForm.isValid) {
        let coursePostData = setCourseEditDetails()
        deleteCourse(coursePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    getCourseList();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (courseEditForm.isValid == false) {
        //     showLoader(false)
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDegree(graduationTypeId: number) {
        getDegreeType(graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.degreeType;
                    if (data != undefined) {
                        setDegreeTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDegreeTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourse() {
        getCourseType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseType;
                    if (data != undefined) {
                        setCourseTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getYearByCourseType(courseTypeId: number) {
        getYearByCourseTypeId(courseTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.yearByCourseTypeId;
                    if (data != undefined) {
                        setNoOfYearsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setNoOfYearsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getCourse', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddCourseOffcanvas(false)
        setEditCourseOffcanvas(false)
        setIsDeleteOpen(false)
        courseForm.resetForm();
        courseEditForm.resetForm();
        resetState();
    }

    function resetState() {
        setDegreeTypeData([]);
        setNoOfYearsData([]);
        setGraduationTypeId('');
        setCourseTypeId('');
        setDepartmentId('');
        setNoOfYears('');
        setDegreeTypeId('');
    }

    function resetStateAddNew() {
        setDegreeTypeData([]);
        setNoOfYearsData([]);
        setGraduationTypeId('');
        setCourseTypeId('');
        setDepartmentId('');
        setNoOfYears('');
        setDegreeTypeId('');
        courseForm.resetForm();
        courseEditForm.resetForm();
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Course Details`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Course Details</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getCourse', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Course List", courseDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(courseDetailsData, columnVisibilityData, "Course List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Course List", courseDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() =>{ setAddCourseOffcanvas(true); resetStateAddNew()}}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>

                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {onCurrentPageData.map((rowData: any) => (
                                                    <tr key={rowData.courseId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.courseId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>

                <OffCanvas setOpen={setAddCourseOffcanvas} isOpen={addCourseOffcanvas} titleId='addCourseOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={courseForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddCourseOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='addCourseOffcanvas'>Course Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12' >
                                <FormGroup id='departmentId' label='Department' onBlur={courseForm.handleBlur}>
                                    <SearchableSelect ariaLabel='Department' placeholder="Select Department"
                                        onChange={(e: any) => selectDepartment(e)}
                                        onBlur={courseForm.handleBlur}
                                        value={departmentId}
                                        isValid={courseForm.isValid}
                                        isTouched={courseForm.touched.departmentId} invalidFeedback={courseForm.errors.departmentId}
                                        list={departmentData.map((data: any) => ({ value: data.departmentId, label: data.departmentName }))} required />

                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='courseCode' label='Course Code'>
                                    <Input onChange={courseForm.handleChange} value={courseForm.values.courseCode} isValid={courseForm.isValid} onBlur={courseForm.handleBlur} isTouched={courseForm.touched.courseCode} invalidFeedback={courseForm.errors.courseCode} type='text' placeholder="Enter Course Code" required />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='graduationTypeId' label='Graduation Type'>
                                    <SearchableSelect ariaLabel='Graduation Type' placeholder="Select Graduation Type" onChange={selectGraduationType} onBlur={courseForm.handleBlur} value={graduationTypeId} isValid={courseForm.isValid} isTouched={courseForm.touched.graduationTypeId} invalidFeedback={courseForm.errors.graduationTypeId} list={graduationTypeData.map((data: any) => ({ value: data.graduationTypeId, label: data.graduationType }))} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='degreeTypeId' label='Degree Type'>
                                    <SearchableSelect ariaLabel='Degree Type' placeholder="Select Degree Type"
                                        onChange={selectDegreeType} onBlur={courseForm.handleBlur} value={degreeTypeId} isValid={courseForm.isValid} isTouched={courseForm.touched.degreeTypeId} invalidFeedback={courseForm.errors.degreeTypeId} list={degreeTypeData.map((data: any) => ({ value: data.degreeTypeId, label: data.degreeType }))} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='courseTypeId' label='Type of Course'>
                                    <SearchableSelect ariaLabel='Type of Course' placeholder="Select Type of Course" onChange={selectCourseType} onBlur={courseForm.handleBlur} value={courseTypeId} isValid={courseForm.isValid} isTouched={courseForm.touched.courseTypeId} invalidFeedback={courseForm.errors.courseTypeId}
                                        list={courseTypeData.map((data: any) => ({ value: data.courseTypeId, label: data.courseTypeName }))} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfYears' label='No Of Years'>
                                    <SearchableSelect ariaLabel='No Of Years' placeholder="Select No Of Years" onChange={selectNoOfYears} onBlur={courseForm.handleBlur} value={noOfYears} isValid={courseForm.isValid} isTouched={courseForm.touched.noOfYears} invalidFeedback={courseForm.errors.noOfYears} list={noOfYearsData.map((data: any) => ({ value: data.noOfYears, label: data.noOfYears }))} />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" isDisable={!courseForm.isValid && !!courseForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas setOpen={setEditCourseOffcanvas} isOpen={editCourseOffcanvas} titleId='editCourseOffcanvas' isBodyScroll placement='end' tag="form" onSubmit={courseEditForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setEditCourseOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='editCourseOffcanvas'>Edit Course</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='departmentId' label='Department'>
                                    <SearchableSelect ariaLabel='Department' placeholder="Select Department"
                                        onChange={(e: any) => selectDepartment(e)}
                                        onBlur={courseEditForm.handleBlur}
                                        value={departmentId}
                                        isValid={courseEditForm.isValid}
                                        isTouched={courseEditForm.touched.departmentId} invalidFeedback={courseEditForm.errors.departmentId}
                                        list={departmentData.map((data: any) => ({ value: data.departmentId, label: data.departmentName }))} required />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='courseName' label='Course Name'>
                                    <Input placeholder="Enter Course Name" onChange={courseEditForm.handleChange} value={courseEditForm.values.courseName} type='text' onBlur={courseEditForm.handleBlur} isValid={courseEditForm.isValid} isTouched={courseEditForm.touched.courseName} invalidFeedback={courseEditForm.errors.courseName} required />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='courseCode' label='Course Code'>
                                    <Input placeholder="Enter Course Code" onChange={courseEditForm.handleChange} value={courseEditForm.values.courseCode} type='text' onBlur={courseEditForm.handleBlur} isValid={courseEditForm.isValid} isTouched={courseEditForm.touched.courseCode} invalidFeedback={courseEditForm.errors.courseCode} />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='graduationTypeId' label='Graduation Type'>
                                    <SearchableSelect ariaLabel='Graduation Type' placeholder="Select Graduation Type" onChange={(e: any) => selectGraduationType(e)} onBlur={courseEditForm.handleBlur} value={graduationTypeId} isValid={courseEditForm.isValid} isTouched={courseEditForm.touched.graduationTypeId} invalidFeedback={courseEditForm.errors.graduationTypeId} list={graduationTypeData.map((data: any) => ({ value: data.graduationTypeId, label: data.graduationType }))} />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='courseTypeId' label='Type of Course'>
                                    <SearchableSelect ariaLabel='Type of Course' placeholder="Select Type of Course" onChange={(e: any) => selectCourseType(e)} onBlur={courseEditForm.handleBlur} value={courseTypeId} isValid={courseEditForm.isValid} isTouched={courseEditForm.touched.courseTypeId} invalidFeedback={courseEditForm.errors.courseTypeId}
                                        list={courseTypeData.map((data: any) => ({ value: data.courseTypeId, label: data.courseTypeName }))} />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='noOfYears' label='No Of Years'>
                                    <SearchableSelect ariaLabel='No Of Years' placeholder="Select No Of Years" onChange={(e: any) => selectNoOfYears(e)} onBlur={courseEditForm.handleBlur} value={noOfYears} isValid={courseEditForm.isValid} isTouched={courseEditForm.touched.noOfYears} invalidFeedback={courseEditForm.errors.noOfYears} list={noOfYearsData.map((data: any) => ({ value: data.noOfYears, label: data.noOfYears }))} />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteCourseSubmit} />
                        </div>
                        <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                            <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!courseEditForm.isValid && !!courseEditForm.submitCount}>Update</Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default CourseDetails;