import { useContext, useEffect, useRef, useState } from "react"
import Page from "../../../layout/Page/Page"
import Collapse from "../../bootstrap/Collapse"
import Icon from "../../icon/Icon"
import { toasts } from "../../../services/toast.service"
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card"
import FormGroup from "../../bootstrap/forms/FormGroup"
import Button, { ButtonGroup } from "../../bootstrap/Button"
import { useFormik } from "formik"
import useSortableData from "../../../hooks/useSortableData"
import PaginationButtons, { dataPagination } from "../../PaginationButtons"
import Input from "../../bootstrap/forms/Input"
import PageWrapper from "../../../layout/PageWrapper/PageWrapper"
import AlertService from "../../../services/AlertService"
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown"
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService"
import { useReactToPrint } from "react-to-print"
import useDarkMode from "../../../hooks/useDarkMode"
import classNames from "classnames"
import DeleteComponents from "../../../common/components/DeleteComponents"
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal"
import OpenCardComponent from "../../../common/components/OpenCardComponent"
import { getColumnsForDataTable, getCurrentDateAndTime, getGraduationType, updateColumnsForDataTable, updateFilter } from "../../../services/common.service"
import AuthContext from "../../../contexts/authContext"
import { getLicenseKey } from "../../../services/application.settings"
import { getBatchByCourseId, getCourse, getCourseTypeByCourseTypeId, getDepartmentList, getSemester } from "../../../services/master.service"
import { addExamMonth, deleteExamMonth, getExamMonth, updateExamMonth } from "../../../services/exam.service"
import { addStudentMarkSheetDetails, getStudentMarkSheetReport } from "../../../services/student.service"
import { showLoader } from "../../../services/loader.services"
import SearchableSelect from "../../../common/components/SearchableSelect"
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas"


const StudentMarksheetEntry = () => {
    useEffect(() => {
        getGraduationList();
        getDepartment();
        getExamMonthList();
        getExamSemester();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [examMonthId, setExamMonthId] = useState<any>(Number)
    const [examSemester, setExamSemester] = useState<any>(Number)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [showExamMonth, setShowExamMonth] = useState(false)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [examMonthData, setExamMonthData] = useState<any>([])
    const [examSemesterData, setExamSemesterData] = useState<any>([])
    const [studentMarkSheetData, setStudentMarkSheetData] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    const [columnDataById, setColumnDataById] = useState([])
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [modalStatus, setModalStatus] = useState<boolean>(false);

    const [addExamMonthCanvas, setAddMonthCanvas] = useState(false)
    const [editExamMonthCanvas, setEditExamMonthOffCanvas] = useState(false)

    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [studentMarkSheetDetails, setStudentMarkSheetDetails] = useState<any>([])
    const [isLoader, setIsLoader] = useState(false)

    // For Table
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isReportOrAdd, setIsReportOrAdd] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5);

    const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(examMonthData);
    const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(studentMarkSheetData);

    const currentPageData1 = dataPagination(items1, currentPage, perPage);
    const currentPageData = dataPagination(items, currentPage, perPage);

    const componentRef = useRef(null);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const addExamMonthForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            examMonthName: '',

        },
        validate: (values) => {
            const errors: {
                examMonthName?: string;
            } = {};
            if (!values.examMonthName) {
                errors.examMonthName = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addExamMonthSubmit() },
    });

    const updateExamMonthForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            examMonthName: '',
            examMonthId: '',
        },
        validate: (values) => {
            const errors: {
                examMonthName?: string;
            } = {};
            if (!values.examMonthName) {
                errors.examMonthName = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateExamMonthSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedExamMonth = i
            setEditExamMonthOffCanvas(true)
            updateExamMonthForm.setValues({
                examMonthName: selectedExamMonth.examMonthName,
                examMonthId: selectedExamMonth.examMonthId
            });
        }
    }

    function setAddExamMonthDetails() {
        return ({
            licenseKey: getLicenseKey,
            examMonthName: addExamMonthForm.values.examMonthName
        })
    }

    function setUpdateExamMonthDetails() {
        return ({
            licenseKey: getLicenseKey,
            examMonthId: updateExamMonthForm.values.examMonthId,
            examMonthName: updateExamMonthForm.values.examMonthName,
            isActive: 1
        })
    }

    // Filter
    const filteredData = updateFilter(items, columnVisibilityData, columnVisibilityForm);

    const filteredData1 = studentMarkSheetData.filter(
        (i: any) =>
            i.admissionNo != null && i.admissionNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.uniRegNo != null && i.uniRegNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName != null && i.studentName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.courseName != null && i.courseName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.examSemester != null && i.examSemester.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    )
    // Filter
    const filteredDataForExamMonth = currentPageData1.filter(
        (i) =>
            // Name
            i.examMonthName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    );

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setExamMonthId('')
        setExamSemester('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setExamMonthId('')
        setExamSemester('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setExamMonthId('')
        setExamSemester('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }

    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setExamMonthId('')
        setExamSemester('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setExamMonthId('')
        setExamSemester('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectExamMonth = (e: any) => {
        setExamSemester('')
        let examMonthId = e
        setExamMonthId(examMonthId)
        if (examMonthId?.value == 16) {
            setShowExamMonth(true)
        } else {
            setShowExamMonth(false)
        }
    }

    const selectExamSemester = (e: any) => {
        let examSemester = e
        setExamSemester(examSemester)
    }

    function viewStudentList() {
        setDataSuccess(false)
        setIsLoader(true)
        setStudentMarkSheetData([])
        columnVisibilityForm.resetForm();
        // setFilteredDataToRefresh([])
        getStudentMarkSheetView(courseId?.value, batchMasterId?.value, semesterDetailsId?.value, examMonthId?.value, examSemester?.value)
    }

    function viewMarkSheetReport() {
        setDataSuccess(false)
        setIsLoader(true)
        setStudentMarkSheetData([])
        // setFilteredDataToRefresh([])
        getStudentMarkSheetReportList(courseId?.value, batchMasterId?.value, semesterDetailsId?.value, examMonthId?.value, examSemester?.value)
    }

    const updateFolioNumberValue = (cell: number, folioNumber: any) => {
        const newData: any = [];

        filteredDataToRefresh.forEach((item: any, index: any) => {
            if (item['studentDetailsId'] == cell) {
                item['folioNumber'] = folioNumber
            }
            newData.push(item)
        });

        setFilteredDataToRefresh(newData)

        if (studentMarkSheetDetails.length == 0) {
            const obj = {
                studentDetailsId: cell,
                folioNumber: folioNumber,
            };
            studentMarkSheetDetails.push(obj);
        } else {
            let selectedItem: any;
            studentMarkSheetDetails.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['folioNumber'] = folioNumber
                }
            });
            if (selectedItem != undefined) {
                selectedItem['folioNumber'] = folioNumber
            } else {
                var obj
                obj = {
                    studentDetailsId: cell,
                    folioNumber: folioNumber,
                };
                studentMarkSheetDetails.push(obj);
            }
        }
    }

    function setMarkSheetDetails() {
        return ({
            courseId: courseId?.value,
            batchMasterId: batchMasterId?.value,
            semesterDetailsId: semesterDetailsId?.value,
            examSemester: examSemester?.value,
            examMonthId: examMonthId?.value,
            studentMarkSheetDetails: studentMarkSheetDetails,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function markSheetEntrySubmit() {
        showLoader(true)
        if (studentMarkSheetDetails.length > 0) {
            let markSheetEntryPostData = setMarkSheetDetails();
            addStudentMarkSheetDetails(markSheetEntryPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        viewMarkSheetReport();
                        setStudentMarkSheetDetails([]);
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentMarkSheetDetails.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getStudentMarkSheetView(courseId: number, batchMasterId: number, semesterDetailsId: number, examMonthId: number, examSemester: number) {
        setIsLoader(true)
        getStudentMarkSheetReport(courseId, batchMasterId, semesterDetailsId, examMonthId, examSemester, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentMarkSheetReport;
                    if (data != undefined) {
                        setStudentMarkSheetData(data);
                        setFilteredDataToRefresh(data)
                        setIsLoader(false)
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        setIsReportOrAdd("add");
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].examSemester = examSemester;
                        }
                    } else {
                        setIsLoader(false)
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                        // toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false)
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                    // toasts(response.data.message, "Error")
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);
                } else {
                    setIsLoader(false)
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    // toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false)
                setDataSuccess(false);
                setIsOpenListCard(true);
                // toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getStudentMarkSheetReportList(courseId: number, batchMasterId: number, semesterDetailsId: number, examMonthId: number, examSemester: number) {
        setIsLoader(true)
        getStudentMarkSheetReport(courseId, batchMasterId, semesterDetailsId, examMonthId, examSemester, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentMarkSheetReport;
                    if (data != undefined) {
                        setStudentMarkSheetData(data);
                        getColumnsForTable('getStudentMarkSheetReport', 'get');
                        setIsLoader(false)
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        setIsReportOrAdd("report");
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].examSemester = examSemester;
                        }
                    } else {
                        setIsLoader(false)
                        // toasts("Undefined Data", "Error")
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false)
                    // toasts(response.data.message, "Error")
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);
                } else {
                    setIsLoader(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    // toasts(errorDescription, "Error")
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false)
                // toasts(error, "Error")
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExamMonthList() {
        getExamMonth(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examMonth;
                    if (data != undefined) {
                        setExamMonthData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setExamMonthData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function addExamMonthSubmit() {

        if (addExamMonthForm.isValid) {
            let examMonthPostData = setAddExamMonthDetails()
            addExamMonth(examMonthPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getExamMonthList();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addExamMonthForm.isValid == false) {
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateExamMonthSubmit() {

        if (updateExamMonthForm.isValid) {
            let examMonthPostData = setUpdateExamMonthDetails()
            updateExamMonth(examMonthPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getExamMonthList();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateExamMonthForm.isValid == false) {
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteExamMonthSubmit() {

        if (updateExamMonthForm.isValid) {
            let examMonthPostData = setUpdateExamMonthDetails()
            deleteExamMonth(examMonthPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getExamMonthList();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateExamMonthForm.isValid == false) {
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getExamSemester() {
        getCourseTypeByCourseTypeId(1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseTypeByCourseTypeId;
                    if (data != undefined) {
                        setExamSemesterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setExamSemesterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentMarkSheetReport', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function closeAndFormReset() {
        addExamMonthForm.resetForm();
        updateExamMonthForm.resetForm();
        setEditExamMonthOffCanvas(false);
        setAddMonthCanvas(false);
    }

    return (
        <PageWrapper title='Mark Sheet Entry'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Mark Sheet Entry
                                </CardTitle>
                            </CardLabel>
                            <CardActions>
                                <Button color='primary' isLight icon='Add' onClick={() => { setModalStatus(true) }}>
                                    Add New Exam Month
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='departmentId' label='Department' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Department'
                                            onChange={selectDepartment}
                                            value={departmentId}
                                            list={departmentData.map((data: any) => (
                                                { value: data.departmentId, label: data.departmentName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {departmentId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Graduation Type'
                                                onChange={selectGraduationType}
                                                value={graduationTypeId}
                                                list={graduationTypeData.map((data: any) => (
                                                    { value: data.graduationTypeId, label: data.graduationType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                {graduationTypeId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='courseId' label='Course' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Course'
                                                onChange={selectCourse}
                                                value={courseId}
                                                list={courseData.map((data: any) => (
                                                    { value: data.courseId, label: data.courseName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}

                                {courseId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Batch'
                                                onChange={selectBatch}
                                                value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                {batchMasterId ? <div className='col-md-3'>
                                    <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Semester'
                                            onChange={selectSemester}
                                            value={semesterDetailsId}
                                            list={semesterDetailsData.map((data: any) => (
                                                { value: data.semesterDetailsId, label: data.semesterNumber }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                    : null}

                                {semesterDetailsId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='examMonthId' label='Exam Month' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Exam Month'
                                                onChange={selectExamMonth}
                                                value={examMonthId}
                                                list={examMonthData.map((data: any) => (
                                                    { value: data.examMonthId, label: data.examMonthName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}

                                {showExamMonth ?
                                    <div className='col-md-3'>
                                        <FormGroup id='' label='Exam Month' isFloating>
                                            <Input
                                                ariaLabel='Exam Month'
                                                onChange={selectExamSemester}
                                                value={examSemester}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                {examMonthId?.value != undefined ?
                                    <div className='col-md-3'>
                                        <FormGroup id='courseType' label='Exam Semester' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Exam Semester'
                                                onChange={selectExamSemester}
                                                value={examSemester}
                                                list={examSemesterData.map((data: any) => (
                                                    { value: data.courseType, label: data.courseType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}

                                {examSemester?.value != undefined ?
                                    <div className='col-md-3'>
                                        <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>Add</Button>
                                        &nbsp;
                                        <Button icon='Report' color='primary' onClick={viewMarkSheetReport}>Report</Button>
                                    </div>
                                    : null}

                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <> {isReportOrAdd == 'add' ?
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-md-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>Student Mark Sheet Entry List</CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-md-7 justify-content-end'>
                                    <div className='d-flex col-8 justify-content-end' data-tour='search'>
                                        <ButtonGroup className="col-lg-8 d-print-none" color='primary'>
                                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </ButtonGroup>
                                    </div>&nbsp;&nbsp;
                                    <div className='col-md-2 justify-content-center'>
                                        <Button color='info' icon="Save" type="submit" isDisable={studentMarkSheetDetails.length == 0} onClick={markSheetEntrySubmit}>Save</Button>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>

                                            <th scope='col' onClick={() => requestSort('admissionNo')}
                                                className='cursor-pointer text-decoration-underline'>Admission No{' '}
                                                <Icon size='lg' className={getClassNamesFor('admissionNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('studentName')}
                                                className='cursor-pointer text-decoration-underline'>Student Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                            </th>
                                            <th>Course Name</th>
                                            <th>Semester</th>
                                            <th>Folio Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData1.map((i: any) => (
                                            <tr key={i.admissionNo}>
                                                <td>{i.sno}</td>
                                                {i.uniRegNo != null ? <td>{i.admissionNo} / {i.uniRegNo}</td> :
                                                    <td>{i.admissionNo}</td>}
                                                <td>{i.studentName}</td>
                                                <td>{i.courseName}</td>
                                                <td>{i.examSemester}</td>
                                                <td scope='col'>
                                                    <FormGroup id='folioNumber'>
                                                        <Input className="w-60" placeholder="" value={i.folioNumber ? i.folioNumber : ''} onInput={(e: any) => updateFolioNumberValue(i.studentDetailsId, e.target.value)} />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                        :
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Student Mark Sheet Entry
                                        </CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-6 justify-content-end'>
                                    <div className='d-flex col-12 justify-content-end gap-2'>
                                        <CardActions className='d-print-none'>
                                            <Dropdown isButtonGroup>
                                                <DropdownToggle>
                                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StudentMarkSheetNoDetails", studentMarkSheetData, columnVisibilityData)}> Excel </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentMarkSheetData, columnVisibilityData, "StudentMarkSheetNoDetails")}> PDF
                                                        </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StudentMarkSheetNoDetails", studentMarkSheetData, columnVisibilityData)}> CSV </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </CardActions>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <div className="row mg-lg-t-20" style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                    <div className="col-lg-8 mg-t-10 mg-lg-t-0"></div>
                                    <div className="col-lg-4 mg-t-10 mg-lg-t-0">
                                        <table className="table text-nowrap text-md-nowrap table-bordered mg-b-0">
                                            <tbody>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th className="text-center text-white bg-info fs-6">Date</th>
                                                    <td><strong>{getCurrentDateAndTime('dateTime')}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <table className="table table-modern table-hover text-center text-nowrap text-lg-nowrap  lg-b-0">
                                    <thead className="text-center text-uppercase">
                                        <tr className='table-primary text-decoration-underline'>
                                            <td ><strong>S.No</strong></td>
                                            <td ><strong>Admission No / University No</strong></td>
                                            <td><strong>Student Name</strong></td>
                                            <td><strong>Course Name</strong></td>
                                            <td><strong>Semester</strong></td>
                                            <td><strong>Folio Number</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {studentMarkSheetData.map((student: any) => (
                                            <tr key={student.admissionNo}>
                                                <td className="fs-6">{student.sno}</td>
                                                {student.uniRegNo != null ? <td>{student.admissionNo} / {student.uniRegNo}</td> :
                                                    <td>{student.admissionNo}</td>}
                                                {/* <td className="fs-6">{student.admissionNo} / {student.uniRegNo}</td> */}
                                                <td className="fs-6">{student.studentName}</td>
                                                <td className="fs-6">{student.courseName}</td>
                                                <td className="fs-6">{student.examSemester}</td>
                                                <td className="fs-6">{student.folioNumber}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </CardBody>
                            {/* <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} /> */}
                        </Card>
                    } </> : null}

            </Page>

            <OffCanvas
                setOpen={setModalStatus} isOpen={modalStatus} titleId='addNewExamMonth' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setModalStatus} onClick={closeAndFormReset}>
                    <OffCanvasTitle id='addNewExamMonth'>Month Creation</OffCanvasTitle>
                </OffCanvasHeader>
                <form noValidate onSubmit={addExamMonthForm.handleSubmit}>
                    <OffCanvasBody>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <FormGroup id='examMonthName' label="Month Name">
                                    <Input
                                        onChange={addExamMonthForm.handleChange} value={addExamMonthForm.values.examMonthName}
                                        isValid={addExamMonthForm.isValid}
                                        onBlur={addExamMonthForm.handleBlur}
                                        isTouched={addExamMonthForm.touched.examMonthName}
                                        invalidFeedback={addExamMonthForm.errors.examMonthName}
                                        type="text"
                                        placeholder="Enter Month Name"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button color='info' className='w-100' type="submit" icon="Save"
                                isDisable={!addExamMonthForm.isValid && !!addExamMonthForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper >
    )
}
export default StudentMarksheetEntry

