import { useFormik } from "formik"
import Page from "../../../../layout/Page/Page"
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper"
import Button, { ButtonGroup } from "../../../bootstrap/Button"
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card"
import FormGroup from "../../../bootstrap/forms/FormGroup"
import Input from "../../../bootstrap/forms/Input"
import classNames from "classnames"
import { toasts } from "../../../../services/toast.service"
import { useContext, useRef, useState } from "react"
import useDarkMode from "../../../../hooks/useDarkMode"
import useSortableData from "../../../../hooks/useSortableData"
import PaginationButtons, { dataPagination } from "../../../PaginationButtons"
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService"
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown"
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks"
import Icon from "../../../icon/Icon"
import { useReactToPrint } from "react-to-print"
import Collapse from "../../../bootstrap/Collapse"
import Select from "react-select/dist/declarations/src/Select"
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas"
import AlertService from "../../../../services/AlertService"
import OpenCardComponent from "../../../../common/components/OpenCardComponent"
import { convertDateToEpoch, getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service"
import AuthContext from "../../../../contexts/authContext"
import { getLicenseKey } from "../../../../services/application.settings"
import { getVehicleTyreServiceLists, updateVehicleTyreService } from "../../../../services/tyre.service"
import { showLoader } from "../../../../services/loader.services"
import NoDataMsg from "../../../../common/components/NoDataMsg"

const TyreServiceList = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [tyreServiceList, setTyreServiceList] = useState([]);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [tyreServiceListOffCanvas, setTyreServiceListOffCanvas] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(tyreServiceList);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isLoader, setIsLoader] = useState(false)

    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });

    const [noDataMsg, setNoDataMsg] = useState('')

    const addTyreServiceListForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: '',
            toDate: ''
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    })

    const updateTyreServiceListForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleTyreServiceDetailsId: '',
            tyreId: '',
            tyreNo: '',
            actualReturnedDate: '',
            costOfService: '',
            remarks: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { updateTyreServiceList() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewTyreServiceList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = addTyreServiceListForm.values.fromDate
        let toDate = addTyreServiceListForm.values.toDate

        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getVehicleTyreService(fromDateInMilliSec, toDateInMilliSec)
    }

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedTyreServiceList = i
            setTyreServiceListOffCanvas(true);
            updateTyreServiceListForm.setValues({
                vehicleTyreServiceDetailsId: selectedTyreServiceList.vehicleTyreServiceDetailsId,
                tyreId: selectedTyreServiceList.tyreId,
                tyreNo: selectedTyreServiceList.tyreNo,
                actualReturnedDate: selectedTyreServiceList.actualReturnedDate,
                costOfService: selectedTyreServiceList.costOfService,
                remarks: selectedTyreServiceList.remarks,
            })
        }
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);


    function setUpdateTyreServiceList() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            vehicleTyreServiceDetailsId: updateTyreServiceListForm.values.vehicleTyreServiceDetailsId,
            actualReturnedDate: updateTyreServiceListForm.values.actualReturnedDate,
            costOfService: updateTyreServiceListForm.values.costOfService,
            remarks: updateTyreServiceListForm.values.remarks,
        })
    }

    function getVehicleTyreService(fromDate: number, toDate: number) {
        setIsLoader(true);
        getVehicleTyreServiceLists(fromDate, toDate,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleTyreServiceList;
                    if (data != undefined) {
                        setIsLoader(false);
                        setTyreServiceList(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        getColumnsForTable('getVehicleTyreServiceList', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true);
                    getColumnsForTable('getVehicleTyreServiceList', 'get');
                    setTyreServiceList([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function updateTyreServiceList() {
        showLoader(true)
        if (updateTyreServiceListForm.isValid) {
            let updateTyreServiceListPost = setUpdateTyreServiceList()
            updateVehicleTyreService(updateTyreServiceListPost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        closeAndReset();
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);

                        viewTyreServiceList();

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateTyreServiceListForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getVehicleTyreServiceList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function closeAndReset() {
        setTyreServiceListOffCanvas(false)
        updateTyreServiceListForm.resetForm();
    }
    return (
        <>
            <PageWrapper title="Tyre Service List">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Service Report
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-3'>
                                        <FormGroup id='fromDate' label='From Date' isFloating>
                                            <Input type='date'
                                                onChange={addTyreServiceListForm.handleChange}
                                                value={addTyreServiceListForm.values.fromDate} />
                                        </FormGroup>
                                    </div>
                                    <div className='col-3'>
                                        <FormGroup id='toDate' label='To Date' isFloating>
                                            <Input type='date'
                                                onChange={addTyreServiceListForm.handleChange}
                                                value={addTyreServiceListForm.values.toDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-2 mt-2" >
                                        <Button icon='ArrowDownward' color='primary' isDisable={addTyreServiceListForm.values.fromDate== '' || addTyreServiceListForm.values.toDate==''} onClick={viewTyreServiceList}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>
                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                    {dataSuccess ?
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Tyre Service List</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getVehicleTyreServiceList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("TyreServiceListDetails", tyreServiceList, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(tyreServiceList, columnVisibilityData, "TyreServiceListDetails")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("TyreServiceListDetails", tyreServiceList, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button color='primary' isLight icon='Add' tag='a'
                                        to={`../management/tyreService`} >
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable >
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.vehicleTyreServiceDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.vehicleTyreServiceDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                        : null}

                </Page>
            </PageWrapper>

            <OffCanvas
                setOpen={setTyreServiceListOffCanvas}
                isOpen={tyreServiceListOffCanvas}
                titleId='tyreServiceListOffCanvas'
                isBodyScroll
                isBackdrop={true}
                placement='end'
                tag="form" noValidate onSubmit={updateTyreServiceListForm.handleSubmit}>
                <OffCanvasHeader setOpen={setTyreServiceListOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='addCourseOffcanvas'>Tyre Service</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-lg-12'>
                            <FormGroup id='tyreId' label='Tyre Id' >
                                <Input type='text' disabled
                                    onChange={updateTyreServiceListForm.handleChange}
                                    value={updateTyreServiceListForm.values.tyreId || ''} />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12'>
                            <FormGroup id='tyreNo' label='Tyre No' >
                                <Input type='text'
                                    onChange={updateTyreServiceListForm.handleChange}
                                    value={updateTyreServiceListForm.values.tyreNo || ''} />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12'>
                            <FormGroup id='actualReturnedDate' label='Expected Return Date'>
                                <Input type='date'
                                    onChange={updateTyreServiceListForm.handleChange}
                                    value={updateTyreServiceListForm.values.actualReturnedDate || ''} />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12'>
                            <FormGroup id='costOfService' label='Cost Of Service' >
                                <Input type='text'
                                    onChange={updateTyreServiceListForm.handleChange}
                                    value={updateTyreServiceListForm.values.costOfService || ''} />
                            </FormGroup>
                        </div>
                        <div className='col-lg-12 '>
                            <FormGroup id='remarks' label='Remarks' >
                                <Input type="text" placeholder='EnterRemarks'
                                    onChange={updateTyreServiceListForm.handleChange}
                                    value={updateTyreServiceListForm.values.remarks || ''} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button
                            color='info'
                            className='w-100' type="submit" isDisable={!updateTyreServiceListForm.isValid && !!updateTyreServiceListForm.submitCount}>
                            Update
                        </Button>
                    </div>
                </div>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </>
    )
}
export default TyreServiceList