import React, { useContext, useEffect, useRef, useState } from 'react'
import useDarkMode from '../../../hooks/useDarkMode'
import useSortableData from '../../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../../PaginationButtons'
import { useFormik } from 'formik'
import Button, { ButtonGroup } from '../../bootstrap/Button'
import classNames from 'classnames'
import { toasts } from '../../../services/toast.service'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Icon from '../../icon/Icon'
import Input from '../../bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas'
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown'
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks'
import { useReactToPrint } from 'react-to-print'
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService'
import AlertService from '../../../services/AlertService'
import { addTransportAreaMaster, deleteTransportAreaMaster, getTransportAreaMaster, updateTransportAreaMaster } from '../../../services/transport.service'
import AuthContext from '../../../contexts/authContext'
import { getColumnsForDataTable, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from '../../../services/common.service'
import { getLicenseKey } from '../../../services/application.settings'
import DeleteComponents from '../../../common/components/DeleteComponents'
import { TableLoader, showLoader } from '../../../services/loader.services'
import NoDataMsg from '../../../common/components/NoDataMsg'

const AreaMaster = () => {

    useEffect(() => {
        getTransportAreaMasterList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [areaData, setAreaData] = useState([])
    const [dataStatus, setDataStatus] = useState(false)

    const [editAreaMasterOffcanvas, setEditAreaMasterOffcanvas] = useState(false);
    const [addAreaMasterOffcanvas, setAddAreaMasterOffcanvas] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(areaData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const areaForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            areaName: '',
            feeAmount: '',
        },
        validate: (values) => {
            const errors: {
                areaName?: string;
                feeAmount?: string;
            } = {};
            if (!values.areaName) {
                errors.areaName = "Required"
            }
            if (!values.feeAmount) {
                errors.feeAmount = "Required"
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: (values) => { areaSubmit() },
    });

    const areaEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            transportAreaMasterId: '',
            areaName: '',
            feeAmount: '',
        },
        validate: (values) => {
            const errors: {
                areaName?: string;
                feeAmount?: string;
            } = {};
            if (!values.areaName) {
                errors.areaName = "Required"
            }
            if (!values.feeAmount) {
                errors.feeAmount = "Required"
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { updateTransportAreaMasterSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedTransportAreaMaster = i
            setEditAreaMasterOffcanvas(true)

            areaEditForm.setValues({
                transportAreaMasterId: selectedTransportAreaMaster.transportAreaMasterId,
                areaName: selectedTransportAreaMaster.areaName,
                feeAmount: selectedTransportAreaMaster.feeAmount,
            })
        }
    }

    function setTransportAreaDetails() {
        return ({
            areaName: areaForm.values.areaName,
            feeAmount: areaForm.values.feeAmount,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setTransportAreaEditDetails() {
        return ({
            transportAreaMasterId: areaEditForm.values.transportAreaMasterId,
            areaName: areaEditForm.values.areaName,
            feeAmount: areaEditForm.values.feeAmount,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function getTransportAreaMasterList() {
        setDataStatus(false)
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setAreaData(data);
                        getColumnsForTable('getTransportAreaMaster', 'get');
                        setDataStatus(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].action = <div>
                                <Button
                                    isOutline={!darkModeStatus}
                                    color='dark'
                                    isLight={darkModeStatus}
                                    className={classNames('text-nowrap', {
                                        'border-light': !darkModeStatus,
                                    })}
                                    icon='Edit'>Edit
                                </Button>
                            </div>
                        }
                    } else {
                        setDataStatus(true);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true);
                   // toasts(response.data.message, "Error")
                   getColumnsForTable('getTransportAreaMaster', 'get');
                   setAreaData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true);
                toasts(error, "Error")
            }
        )
    }

    function areaSubmit() {
        showLoader(true);
        if (areaForm.isValid) {
            let areaPostData = setTransportAreaDetails()
            addTransportAreaMaster(areaPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getTransportAreaMasterList();

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (areaForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateTransportAreaMasterSubmit() {
        showLoader(true);
        if (areaEditForm.isValid) {
            let areaPostData = setTransportAreaEditDetails()
            updateTransportAreaMaster(areaPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getTransportAreaMasterList();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (areaEditForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteTransportAreaMasterSubmit() {
        showLoader(true);
        // if (areaEditForm.isValid) {
        let areaPostData = setTransportAreaEditDetails()
        deleteTransportAreaMaster(areaPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getTransportAreaMasterList();
                    closeAndReset();
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } 
        //else if (areaEditForm.isValid == false) {
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getTransportAreaMaster', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddAreaMasterOffcanvas(false)
        setEditAreaMasterOffcanvas(false)
        areaForm.resetForm();
        areaEditForm.resetForm();
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Area Master'>
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'>Transport Area List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getTransportAreaMaster', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Transport Area Master List", areaData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(areaData, columnVisibilityData, "Transport Area Master List")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Transport Area Master List", areaData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button color='primary' isLight icon='Add' onClick={() => { setAddAreaMasterOffcanvas(true) }}>
                                Add New
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody className='table-responsive' isScrollable>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {filteredData != "" ?
                                        <>
                                    {filteredData.map((rowData: any) => (
                                        <tr key={rowData.transportAreaMasterId}>
                                            <td>{rowData.sno}</td>
                                            {columnVisibilityData.map((column: any) => (
                                                (column.isDisplay && rowData[column.keyName]) ?
                                                    <td key={`${rowData.transportAreaMasterId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                            ))}
                                            <td className="d-print-none">
                                                <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                            </td>
                                        </tr>
                                    ))
                                } </>
                                : 
                                <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg}/>}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons
                        className='d-print-none'
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>

                <OffCanvas
                    setOpen={setAddAreaMasterOffcanvas}
                    isOpen={addAreaMasterOffcanvas}
                    titleId='addAreaMasterOffcanvas'
                    isBodyScroll
                    placement='end'>
                    <OffCanvasHeader setOpen={setAddAreaMasterOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='addAreaMasterOffcanvas'>Transport Area Information</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={areaForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='areaName' label='Area Name' >
                                        <Input placeholder=' Enter Area Name'
                                            onChange={areaForm.handleChange}
                                            value={areaForm.values.areaName}
                                            onBlur={areaForm.handleBlur}
                                            isValid={areaForm.isValid}
                                            isTouched={areaForm.touched.areaName}
                                            invalidFeedback={areaForm.errors.areaName} />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='feeAmount' label='Fees Amount (Per Sem)'>
                                        <Input placeholder='Enter Fees Amount'
                                            onChange={areaForm.handleChange}
                                            value={areaForm.values.feeAmount}
                                            onBlur={areaForm.handleBlur}
                                            isValid={areaForm.isValid}
                                            isTouched={areaForm.touched.feeAmount}
                                            invalidFeedback={areaForm.errors.feeAmount} 
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}/>
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button
                                    color='info'
                                    className='w-100' type="submit" isDisable={!areaForm.isValid} >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </OffCanvas>

                <OffCanvas
                    setOpen={setEditAreaMasterOffcanvas}
                    isOpen={editAreaMasterOffcanvas}
                    titleId='editAreaMasterOffcanvas'
                    isBodyScroll
                    placement='end'
                    tag='form' noValidate onSubmit={areaEditForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setEditAreaMasterOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='editAreaMasterOffcanvas'>Update Transport Area Master</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='areaName' label='Area Name' >
                                    <Input placeholder='Enter Area Name'
                                        onChange={areaEditForm.handleChange}
                                        value={areaEditForm.values.areaName}
                                        onBlur={areaEditForm.handleBlur}
                                        isValid={areaEditForm.isValid}
                                        isTouched={areaEditForm.touched.areaName}
                                        invalidFeedback={areaEditForm.errors.areaName} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='feeAmount' label='Fees Amount (Per Sem)'>
                                    <Input placeholder='Enter Fees Amount'
                                        onChange={areaEditForm.handleChange}
                                        value={areaEditForm.values.feeAmount}
                                        onBlur={areaEditForm.handleBlur}
                                        isValid={areaEditForm.isValid}
                                        isTouched={areaEditForm.touched.feeAmount}
                                        invalidFeedback={areaEditForm.errors.feeAmount} 
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}/>
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteTransportAreaMasterSubmit} />
                        </div>
                        <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                            <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!areaEditForm.isValid && !!areaEditForm.submitCount}>Update</Button>
                        </div>
                    </div>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    );
}

export default AreaMaster