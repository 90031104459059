import { useFormik } from "formik";
import { useEffect, useContext, useState, useRef } from "react";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../../services/AlertService";
import { getLicenseKey } from "../../../../services/application.settings";
import { getColumnsForDataTable, getGraduationType, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { TableLoader } from "../../../../services/loader.services";
import { getBatchByGraduationTypeId } from "../../../../services/master.service";
import { getAlumniStudentDetails } from "../../../../services/student.service";
import { toasts } from "../../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from "../../../bootstrap/Card";
import Collapse from "../../../bootstrap/Collapse";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import { useReactToPrint } from "react-to-print";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import { useNavigate } from "react-router-dom";

const AluminiStudentList = () => {

    useEffect(() => {
        getGraduationList()
    }, [])

    const navigate = useNavigate();
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alumniStudentData, setAlumniStudentData] = useState([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)

    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])

    // Table States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(alumniStudentData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStudent = i
            setStudentDetailsId(selectedStudent.studentDetailsId)
        }
    }

    const selectGraduationType = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getBatchList(0, 0, graduationTypeId?.value)
        }
    }

    const selectBatchMaster = (e: any) => {
        setSemesterDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
    }

    function viewStudentList() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getAlumniStudentList(graduationTypeId?.value, batchMasterId?.value)
    }

    function getAlumniStudentList(graduationTypeId: number, batchMasterId: number) {
        getAlumniStudentDetails(graduationTypeId, batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.alumniStudentDetails;
                    if (data != undefined) {
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        getColumnsForTable('getStudentListBySectionId', 'get');
                        setAlumniStudentData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    getColumnsForTable('getStudentListBySectionId', 'get');
                    setAlumniStudentData([]);
                    setNoDataMsg(response.data.message)
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsOpenListCard(false);
                    //setDataSuccess(true);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setIsOpenListCard(true);
                setDataSuccess(false);
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, batchMasterId: number, semesterDetailsId: number) {
        getBatchByGraduationTypeId(courseId, batchMasterId, semesterDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentListBySectionId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title={`Alumini Student List`}>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Alumini Student List
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, label: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {graduationTypeId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Batch'
                                                onChange={selectBatchMaster}
                                                value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {batchMasterId ?
                                    <div className='col-md-3 align-self-center'>
                                        <Button  icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>
                                    </div> : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'> Alumini Student List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentListBySectionId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("AluminiStudentList", alumniStudentData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(alumniStudentData, columnVisibilityData, "AluminiStudentList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("AluminiStudentList", alumniStudentData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataSuccess ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <td className="d-print-none" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => (
                                                (column.isDisplay && rowData[column.keyName]) ?
                                                    <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className='d-print-none' onClick={() => onActivate(rowData)}>
                                                    <Dropdown isButtonGroup>
                                                        <DropdownToggle>
                                                            <Button className="text-nowrap" color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
                                                        </DropdownToggle>
                                                        <DropdownMenu isAlignmentEnd>
                                                            <DropdownItem>
                                                                <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/studentProfile/${studentDetailsId}`)}>Student Profile</Button>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId}`)}>Fee Details</Button>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 3} msg={noDataMsg} />}</tbody></table>}</CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card> : null
                }
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default AluminiStudentList;
