import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const addStudentMarkSheetDetails = (markSheetEntryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentMarkSheetDetails`, markSheetEntryPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentMarkSheetDetails')
        }
    );

export const getStudentMarkSheetReport = (courseId: number, batchMasterId: number, semesterDetailsId: number, examMonthId: number, examSemester: number, isReport: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentMarkSheetReport/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${examMonthId}/${examSemester}/${isReport}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentMarkSheetReport - " + error.message)
            console.log('error caught in service : getStudentMarkSheetReport')
        },
    );

export const getTempStudentList = (tempStudentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTempStudentList/${getLicenseKey}/${tempStudentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTempStudentList - " + error.message)
            console.log('error caught in service : getTempStudentList')
        },
    );

export const updateTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTempStudentDetails')
        }
    );

export const deleteTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTempStudentDetails')
        }
    );

export const addTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTempStudentDetails')
        }
    );

export const getProfileForStudent = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentProfile/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentProfile - " + error.message)
            console.log('error caught in service : getStudentProfile')
        },
    );

export const getStudentDetails = (departmentId: number, courseId: number, batchMasterId: number, semesterDetailsId: number, sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetails/${getLicenseKey}/${departmentId}/${courseId}/${batchMasterId}/${semesterDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetails - " + error.message)
            console.log('error caught in service : getStudentDetails')
        },
    );

export const getAllStudent = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetails - " + error.message)
            console.log('error caught in service : getStudentDetails')
        },
    );

export const getStudentTransportDetails = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTransportDetails/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTransportDetails - " + error.message)
            console.log('error caught in service : getStudentTransportDetails')
        },
    );

export const addStudentTransportDetails = (addStudentTransportDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentTransportDetails`, addStudentTransportDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentTransportDetails')
        }
    );

export const deleteStudentDetails = (deleteStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentDetails`, deleteStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentDetails')
        }
    );

export const addStudentDetails = (studentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentDetails`, studentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentDetails')
        }
    );

export const getTempStudentListByGraduationTypeId = (graduationTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTempStudentListByGraduationTypeId/${getLicenseKey}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTempStudentListByGraduationTypeId - " + error.message)
            console.log('error caught in service : getTempStudentListByGraduationTypeId')
        },
    );

export const getStudentRollback = (batchMasterId: number, semesterDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentRollback/${getLicenseKey}/${batchMasterId}/${semesterDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentRollback - " + error.message)
            console.log('error caught in service : getStudentRollback')
        },
    );

export const updateStudentSemester = (studentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentSemester`, studentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentSemester')
        }
    );

export const getDiscontinuedStudentList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDiscontinuedStudentList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDiscontinuedStudentList - " + error.message)
            console.log('error caught in service : getDiscontinuedStudentList')
        },
    );

export const rejoinStudentDetails = (rejoinedStudentFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `rejoinStudentDetails`, rejoinedStudentFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStudentDetails')
        }
    );

export const getStudentDetailsBySemesterId = (courseId: number, semesterDetailsId: number, sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsBySemesterId/${getLicenseKey}/${courseId}/${semesterDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsBySemesterId - " + error.message)
            console.log('error caught in service : getStudentDetailsBySemesterId')
        },
    );


export const updateUniversityNumberForStudent = (editUniversityNumberPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateUniversityNumberForStudent`, editUniversityNumberPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateUniversityNumberForStudent')
        }
    );

export const updateStudentCourse = (courseChangePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentCourse`, courseChangePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentCourse')
        }
    );

export const getStudentCourseChangedList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentCourseChangedList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentCourseChangedList - " + error.message)
            console.log('error caught in service : getStudentCourseChangedList')
        },
    );

export const getStudentProfile = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentProfile/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentProfile - " + error.message)
            console.log('error caught in service : getStudentProfile')
        },
    );
export const checkCourseCapacity = (courseCapacityStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `checkCourseCapacity`, courseCapacityStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : checkCourseCapacity')
        }
    );

export const getStudentDetailsByStudentDetailsId = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByStudentDetailsId - " + error.message)
            console.log('error caught in service : getStudentDetailsByStudentDetailsId')
        },
    );

export const updateStudentDetails = (updateStudentDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentDetails`, updateStudentDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentDetails')
        }
    );

export const getStudentListByGenderId = (genderId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentListByGenderId/${getLicenseKey}/${genderId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentListByGenderId - " + error.message)
            console.log('error caught in service : getStudentListByGenderId')
        },
    );

export const updateStudentAsCourseCompleted = (studentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentAsCourseCompleted`, studentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentAsCourseCompleted')
        }
    );


export const getAlumniStudentDetails = (graduationTypeId: number, batchMasterId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAlumniStudentDetails/${getLicenseKey}/${graduationTypeId}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAlumniStudentDetails - " + error.message)
            console.log('error caught in service : getAlumniStudentDetails')
        },
    );




