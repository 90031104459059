import { useContext, useEffect, useRef, useState } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import { useFormik } from "formik";
import { toasts } from "../../../../services/toast.service";
import Page from "../../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import Select from "../../../bootstrap/forms/Select";
import Wizard, { WizardItem } from "../../../Wizard";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { useReactToPrint } from "react-to-print";
import { getCity, getColumnsForDataTable, getStates, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { deleteVehicle, getVehicleDetails, getVehicleType, updateVehicleDetails } from "../../../../services/transport.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import AlertService from "../../../../services/AlertService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import Textarea from "../../../bootstrap/forms/Textarea";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import { Label } from "../../../icon/material-icons";

const VehicleList = () => {

    useEffect(() => {
        getVehicleDetailsList();
        getVehicleTypeList();
        getStatesList();

    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [vehicleDetailsData, setVehicleDetailsData] = useState([])

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [updateVehicleList, setUpdateVehicleList] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(vehicleDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [vehicleTypeId, setVehicleTypeId] = useState<any>('')
    const [stateId, setStateId] = useState<any>('')
    const [cityId, setCityId] = useState<any>('')
    const [cityDetailsData, setCityDetailsData] = useState<any>([])

    const [vehicleTypeData, setVehicleTypeData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [deleteVehicleListCanvas, setDeleteVehicleListCanvas] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const updateVehicleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleDetailsId: '',
            vehicleId: "",
            vehicleTypeId: '',
            registrationNo: "",
            registrationDate: "",
            regValidUpto: "",
            assetNo: "",
            chassisNo: "",
            brandAndModel: "",
            seatingCapacity: "",
            engineNo: "",
            fuelType: "",
            gps: "",
            hypothecatedTo: "",
            hypothecatedToDate: "",
            ownerType: "",
            registerOwner: "",
            registeredAddress: "",
            contactNo: "",
            insuranceNo: "",
            insuredBy: "",
            insuranceRenewalDate: "",
            certificateNo: "",
            goveranceRenewelDate: "",
            busPermitRenewelDate: "",
            yearOfMake: "",
            stateId: "",
            cityId: "",
            fromDate: "",
            toDate: ""
        },
        validate: (values) => {
            const errors: {
                vehicleId?: string;
                vehicleTypeId?: string;
                registrationNo?: string;
                brandAndModel?: string;
                seatingCapacity?: string;
                fuelType?: string;
                ownerType?: string;
                insuranceNo?: string;
                insuredBy?: string;
                insuranceRenewalDate?: string;
                busPermitRenewelDate?: string;
                stateId?: string;
                cityId?: string
                contactNo?: string
            } = {};
            if (!values.vehicleId) {
                errors.vehicleId = 'Required';
            }
            if (!vehicleTypeId) {
                errors.vehicleTypeId = 'Required';
            }
            if (!values.registrationNo) {
                errors.registrationNo = 'Required';
            }
            if (!values.brandAndModel) {
                errors.brandAndModel = 'Required';
            }
            if (!values.seatingCapacity) {
                errors.seatingCapacity = 'Required';
            }
            if (!values.fuelType) {
                errors.fuelType = 'Required';
            }
            if (!values.ownerType) {
                errors.ownerType = 'Required';
            }
            if (!values.insuranceNo) {
                errors.insuranceNo = 'Required';
            }
            if (!values.insuredBy) {
                errors.insuredBy = 'Required';
            }
            if (!values.insuranceRenewalDate) {
                errors.insuranceRenewalDate = 'Required';
            }
            if (!values.busPermitRenewelDate) {
                errors.busPermitRenewelDate = 'Required';
            }
            if (!stateId) {
                errors.stateId = 'Required';
            }
            if (cityDetailsData.length == 0) {
                errors.cityId = 'Required';
            }
            if (values.contactNo && !/^[6-9]\d{9}$/i.test(values.contactNo)) {
                errors.contactNo = 'Please enter valid mobile number'
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { updateVehicle() },
    });

    const deleteVehicleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            vehicleDetailsId: '',
            remarks: ''
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { deleteVehicleSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedVehicle = i

            setUpdateVehicleList(true)

            setVehicleTypeId({ value: selectedVehicle.vehicleTypeId, label: selectedVehicle.vehicleType })
            setStateId({ value: selectedVehicle.stateId, label: selectedVehicle.stateName })

            let cityId = selectedVehicle.districtArray.map((item: any) => item.cityData)
            setCityId(cityId)
            console.log(cityId);

            updateVehicleForm.setValues({
                vehicleDetailsId: selectedVehicle.vehicleDetailsId,
                vehicleId: selectedVehicle.vehicleId,
                vehicleTypeId: vehicleTypeId?.value,
                registrationNo: selectedVehicle.registrationNo,
                registrationDate: selectedVehicle.registrationDate,
                regValidUpto: selectedVehicle.regValidUpto,
                assetNo: selectedVehicle.assetNo,
                chassisNo: selectedVehicle.chassisNo,
                brandAndModel: selectedVehicle.brandAndModel,
                seatingCapacity: selectedVehicle.seatingCapacity,
                engineNo: selectedVehicle.engineNo,
                fuelType: selectedVehicle.fuelType,
                gps: selectedVehicle.gps,
                hypothecatedTo: selectedVehicle.hypothecatedTo,
                hypothecatedToDate: selectedVehicle.hypothecatedToDate,
                ownerType: selectedVehicle.ownerType,
                registerOwner: selectedVehicle.registerOwner,
                registeredAddress: selectedVehicle.registeredAddress,
                contactNo: selectedVehicle.contactNo,
                insuranceNo: selectedVehicle.insuranceNo,
                insuredBy: selectedVehicle.insuredBy,
                insuranceRenewalDate: selectedVehicle.insuranceRenewalDate,
                certificateNo: selectedVehicle.certificateNo,
                goveranceRenewelDate: selectedVehicle.goveranceRenewelDate,
                busPermitRenewelDate: selectedVehicle.busPermitRenewelDate,
                yearOfMake: selectedVehicle.yearOfMake,
                stateId: stateId?.value,
                cityId: cityId?.value,
                fromDate: selectedVehicle.fromDate,
                toDate: selectedVehicle.toDate,
            });

            getCityList(selectedVehicle.stateId);

        }

    }

    const selectVehicleType = (e: any) => {
        let vehicleTypeId = e
        setVehicleTypeId(vehicleTypeId);
    }

    function onDelete(i: any) {
        if (i != undefined) {
            let selectedVehicle = i

            setDeleteVehicleListCanvas(true)
            deleteVehicleForm.setValues({
                vehicleDetailsId: selectedVehicle.vehicleDetailsId,
                remarks: ''
            });
        }


    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const selectState = (e: any) => {
        let stateId = e
        setStateId(stateId)
        if (stateId?.value != undefined) {
            getCityList(stateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let cityId = e.map((item: any) => item.value)
        setCityId(e)
        setCityDetailsData(cityId)
    }

    function updateVehicleSubmit() {
        return ({
            vehicleDetailsId: updateVehicleForm.values.vehicleDetailsId,
            vehicleId: updateVehicleForm.values.vehicleId,
            vehicleTypeId: vehicleTypeId?.value ? vehicleTypeId?.value : null,
            registrationNo: updateVehicleForm.values.registrationNo,
            registrationDate: updateVehicleForm.values.registrationDate,
            regValidUpto: updateVehicleForm.values.regValidUpto,
            assetNo: updateVehicleForm.values.assetNo,
            chassisNo: updateVehicleForm.values.chassisNo,
            brandAndModel: updateVehicleForm.values.brandAndModel,
            seatingCapacity: updateVehicleForm.values.seatingCapacity,
            engineNo: updateVehicleForm.values.engineNo,
            fuelType: updateVehicleForm.values.fuelType,
            gps: updateVehicleForm.values.gps,
            hypothecatedTo: updateVehicleForm.values.hypothecatedTo,
            hypothecatedToDate: updateVehicleForm.values.hypothecatedToDate,
            ownerType: updateVehicleForm.values.ownerType,
            registerOwner: updateVehicleForm.values.registerOwner,
            registeredAddress: updateVehicleForm.values.registeredAddress,
            contactNo: updateVehicleForm.values.contactNo,
            insuranceNo: updateVehicleForm.values.insuranceNo,
            insuredBy: updateVehicleForm.values.insuredBy,
            insuranceRenewalDate: updateVehicleForm.values.insuranceRenewalDate,
            certificateNo: updateVehicleForm.values.certificateNo,
            goveranceRenewelDate: updateVehicleForm.values.goveranceRenewelDate,
            busPermitRenewelDate: updateVehicleForm.values.busPermitRenewelDate,
            yearOfMake: updateVehicleForm.values.yearOfMake,
            stateId: stateId?.value,
            cityId: cityDetailsData,
            fromDate: updateVehicleForm.values.fromDate,
            toDate: updateVehicleForm.values.toDate,
            licenseKey: getLicenseKey,
            isActive: 1,
            userAccountId: userAccountId
        })
    }

    function deleteVehicleDetails() {
        return {
            vehicleDetailsId: deleteVehicleForm.values.vehicleDetailsId,
            remarks: deleteVehicleForm.values.remarks,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function updateVehicle() {
        showLoader(true)
        if (updateVehicleForm.isValid) {
            let updateVehiclePost = updateVehicleSubmit()
            updateVehicleDetails(updateVehiclePost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setUpdateVehicleList(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        updateVehicleForm.resetForm();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateVehicleForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteVehicleSubmit() {
        showLoader(true)
        if (deleteVehicleForm.isValid) {
            let updateVehiclePost = deleteVehicleDetails()
            deleteVehicle(updateVehiclePost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        deleteVehicleForm.resetForm()
                        setDeleteVehicleListCanvas(false)
                        getVehicleDetailsList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (deleteVehicleForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getVehicleTypeList() {
        getVehicleType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleType;
                    if (data != undefined) {
                        setVehicleTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setVehicleTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getVehicleDetailsList() {
        setDataStatus(false)
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            for (let j = 0; j < data[i].districtArray.length; j++) {
                                data[i].districtArray[j].cityData = { value: data[i].districtArray[j].cityId, label: data[i].districtArray[j].cityName };
                            }
                        }
                        getColumnsForTable('getVehicleDetails', 'get');
                        setVehicleDetailsData(data);
                        console.log(data);

                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getVehicleDetails', 'get');
                    setVehicleDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getVehicleDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        updateVehicleForm.resetForm();
        setUpdateVehicleList(false);
        setDeleteVehicleListCanvas(false);
    }

    // Excel Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Vehicle List">
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Vehicle List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getVehicleDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("VehicleDetails", vehicleDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(vehicleDetailsData, columnVisibilityData, "VehicleDetails")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("VehicleDetails", vehicleDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' isLight icon='Add' tag='a'
                                    to={`../management/createVehicle`}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable >
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.vehicleDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.vehicleDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                                            <Button className="ms-1" isLight icon="Delete" color="danger" onClick={() => { onDelete(rowData) }}></Button>
                                                        </td>
                                                    </tr>
                                                ))
                                                } </>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>}
                        </CardBody>
                        <PaginationButtons
                            className="d-print-none"
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                </Page>

                <Modal
                    isOpen={updateVehicleList}
                    setIsOpen={setUpdateVehicleList}
                    isStaticBackdrop={true}
                    isScrollable={true}
                    isCentered
                    size='lg' >
                    <ModalHeader setIsOpen={setUpdateVehicleList} onClick={closeAndReset}>
                        <ModalTitle id="updateVehicleList">Update Vehicle</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Wizard
                            isHeader={false}
                            color='info'
                            shadow='none'
                            tag='form'
                            noValidate onSubmit={updateVehicleForm.handleSubmit}>
                            <WizardItem id="">
                                <div className="row g-4">
                                    <div className="col-lg-12 align-self-center">
                                        <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Vehicle Info </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="vehicleId" label="Vehicle Id" isFloating>
                                            <Input type="text" placeholder="Enter Vehicle ID"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.vehicleId}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.vehicleId}
                                                invalidFeedback={updateVehicleForm.errors.vehicleId} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="vehicleTypeId" label="Vehicle Type" isFloating>
                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Vehicle Type"
                                                onChange={selectVehicleType}
                                                value={vehicleTypeId}
                                                list={vehicleTypeData.map((data: any) => (
                                                    { value: data.vehicleTypeId, label: data.vehicleType }
                                                ))}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.vehicleTypeId}
                                                invalidFeedback={updateVehicleForm.errors.vehicleTypeId} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="registrationNo" label="Registration No" isFloating>
                                            <Input type="text" placeholder="Enter Registration No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.registrationNo}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.registrationNo}
                                                invalidFeedback={updateVehicleForm.errors.registrationNo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="registrationDate" label="Registration Date" isFloating>
                                            <Input type="date" placeholder="Enter Registration Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.registrationDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="regValidUpto" label="Reg Valid Upto" isFloating>
                                            <Input type="date" placeholder="Enter Reg Valid Upto"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.regValidUpto} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="assetNo" label="Asset No" isFloating>
                                            <Input type="text" placeholder="Enter Asset No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.assetNo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="chassisNo" label="Chassis No" isFloating>
                                            <Input type="text" placeholder="Enter Chassis No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.chassisNo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="brandAndModel" label="Brand & Model" isFloating>
                                            <Input type="text" placeholder="Enter Brand & Model"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.brandAndModel}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.brandAndModel}
                                                invalidFeedback={updateVehicleForm.errors.brandAndModel} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="engineNo" label="Engine No" isFloating>
                                            <Input type="text" placeholder="Enter Engine No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.engineNo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="seatingCapacity" label="Seating Capacity" isFloating>
                                            <Input type="text" placeholder="Seating Capacity"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.seatingCapacity}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.seatingCapacity}
                                                invalidFeedback={updateVehicleForm.errors.seatingCapacity} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="hypothecatedTo" label="Hypothecated To" isFloating>
                                            <Input type="text" placeholder="Hypothecated To"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.hypothecatedTo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="hypothecatedToDate" label="Hypothecated To Date" isFloating>
                                            <Input type="date" placeholder="Hypothecated To Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.hypothecatedToDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="fuelType" label="Fuel Type" isFloating>
                                            <Select ariaLabel="" placeholder="Select Fuel Type"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.fuelType}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.fuelType}
                                                invalidFeedback={updateVehicleForm.errors.fuelType}>
                                                <option>Petrol</option>
                                                <option>Diesel</option>
                                                <option>Gas</option>
                                            </Select>
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="gps" label="Gps" isFloating>
                                            <Select ariaLabel="" placeholder="GPS"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.gps}>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </div>
                            </WizardItem>
                            <WizardItem id="Details">
                                <div className="row g-4">
                                    <div className="col-lg-12 align-self-center">
                                        <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Owner/Contractor Details </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="ownerType" label="Type of Owner" isFloating>
                                            <Select ariaLabel="" placeholder="Select Type of Owner"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.ownerType}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.ownerType}
                                                invalidFeedback={updateVehicleForm.errors.ownerType}>
                                                <option>Owner</option>
                                                <option>Contractor</option>
                                            </Select>
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="registerOwner" label="Register Owner" isFloating>
                                            <Input type="text" placeholder="Enter Register Owner"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.registerOwner} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="contactNo" label="Contact No" isFloating>
                                            <Input type="text" placeholder="Enter Contact No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.contactNo}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.contactNo}
                                                invalidFeedback={updateVehicleForm.errors.contactNo}
                                                onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="registeredAddress" label="Registered Address" isFloating>
                                            <Input type="text" placeholder="Enter Registered Address"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.registeredAddress} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-12 align-self-center">
                                        <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Insurance Details </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="insuranceNo" label="Insurance No" isFloating>
                                            <Input type="text" placeholder="Enter Insurance No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.insuranceNo}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.insuranceNo}
                                                invalidFeedback={updateVehicleForm.errors.insuranceNo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="insuredBy" label="Insured By" isFloating>
                                            <Input type="text" placeholder="Enter Insured By"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.insuredBy}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.insuredBy}
                                                invalidFeedback={updateVehicleForm.errors.insuredBy} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="insuranceRenewalDate" label="Insurance Renewal Date" isFloating>
                                            <Input type="date" placeholder="Enter Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.insuranceRenewalDate}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.insuranceRenewalDate}
                                                invalidFeedback={updateVehicleForm.errors.insuranceRenewalDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-12 align-self-center">
                                        <p className='lead fw-bold'> <Icon icon='List' color="info" size={"2x"} />Speed Governance Details </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="certificateNo" label="Certificate No" isFloating>
                                            <Input type="text" placeholder="Enter Certificate No"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.certificateNo} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="goveranceRenewelDate" label="Renewal Date" isFloating>
                                            <Input type="date" placeholder="Enter Renewal Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.goveranceRenewelDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-12 align-self-center">
                                        <p className='lead fw-bold'> <Icon icon='List' color="info" size={"2x"} />Bus Permit Details </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="busPermitRenewelDate" label="Renewal Date" isFloating>
                                            <Input type="date" placeholder="Enter Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.busPermitRenewelDate}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.busPermitRenewelDate}
                                                invalidFeedback={updateVehicleForm.errors.busPermitRenewelDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="yearOfMake" label="Year of Make" isFloating>
                                            <Input type="date" placeholder="Enter Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.yearOfMake} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="stateId" label="State" isFloating>
                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select State"
                                                onChange={selectState}
                                                value={stateId}
                                                list={statesData.map((data: any) => (
                                                    { value: data.stateId, label: data.stateName }
                                                ))}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.stateId}
                                                invalidFeedback={updateVehicleForm.errors.stateId}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="cityId" label="City" isFloating>
                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select City"
                                                onChange={selectCity}
                                                value={cityId} multiple
                                                list={cityData.map((data: any) => (
                                                    { value: data.cityId, label: data.cityName }
                                                ))}
                                                onBlur={updateVehicleForm.handleBlur}
                                                isValid={updateVehicleForm.isValid}
                                                isTouched={updateVehicleForm.touched.cityId}
                                                invalidFeedback={updateVehicleForm.errors.cityId} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-12 align-self-center">
                                        <p className='lead fw-bold'><Icon icon='List' color="info" size={"2x"} /> Fitness Details </p>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="fromDate" label="From Date" isFloating>
                                            <Input type="date" placeholder="Enter Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.fromDate} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="toDate" label="To Date" isFloating>
                                            <Input type="date" placeholder="Enter Date"
                                                onChange={updateVehicleForm.handleChange}
                                                value={updateVehicleForm.values.toDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                            </WizardItem>
                        </Wizard>
                    </ModalBody>
                </Modal>

                <OffCanvas
                    setOpen={setDeleteVehicleListCanvas}
                    isOpen={deleteVehicleListCanvas}
                    titleId='deleteVehicleListCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={deleteVehicleForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setDeleteVehicleListCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="deleteVehicleListCanvas">Delete Vehicle</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row">
                            <div className='col-lg-12 mt-3'>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                    }-warning-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0'>
                                                <Icon icon='Delete' size='3x' color='danger' />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-6 text-danger mb-0'>
                                                    Are you sure you want to delete ?
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-12 mt-3">
                                <FormGroup id='remarks' label="Remarks" >
                                    <Textarea
                                        placeholder="Enter Remarks"
                                        onChange={deleteVehicleForm.handleChange}
                                        value={deleteVehicleForm.values.remarks}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button
                                    color='danger'
                                    icon='Delete'
                                    className='w-100' type="submit">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </OffCanvasBody>
                </OffCanvas>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}

export default VehicleList


