import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getQuotaType, getQuotaTypeDetails } from "../../../../services/common.service";
import { toasts } from "../../../../services/toast.service";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Card, { CardBody } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Select from "../../../bootstrap/forms/Select";
import Input from "../../../bootstrap/forms/Input";
import Checks from "../../../bootstrap/forms/Checks";
import Button from "../../../bootstrap/Button";

const AdmissionInfo: React.FC<{ handlesubmit1: () => void; handlePrevious: () => void }> = ({ handlesubmit1, handlePrevious }) => {
    
    useEffect(() => {
        getQuota();
    }, [])

    const [quotaDetailsData, setQuotaDetailsData] = useState([])
    const [quotaTypeId, setQuotaTypeId] = useState('')
    const [isQuotaTypeDetails, setIsQuotaTypeDetails] = useState('')
    const [quotaData, setQuotaData] = useState([])

    const addStudentForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            ///----Admission----////
            quotaTypeId: '',
            discription: '',
            isCitizenOfIndia: '',
            isClaimingNriQuotaSeat: '',
            isMedicalCertificateEnclosed: '',
            extraCurricularActivities: '',
        },
        validate: (values: any) => {
        },
        validateOnChange: true,
        onSubmit: (values) => { handlesubmit1() },
    })

    const selectQuota = (e: any) => {
        let quotaTypeId = e.target.value
        setQuotaTypeId(quotaTypeId)


        if (quotaTypeId == '1') {
            setIsQuotaTypeDetails('isGovernment');
            getQuotaList()
        }
        else if (quotaTypeId == '2') {
            setIsQuotaTypeDetails('isManagement');
        }
    }

    function getQuota() {
        getQuotaType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.quotaType;
                    if (data != undefined) {
                        setQuotaData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getQuotaList() {
        getQuotaTypeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.quotaTypeDetails;
                    if (data != undefined) {
                        setQuotaDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function handleNext() {
        handlesubmit1()
    }

    function handlePrevious1() {
        handlePrevious()
    }
    return (
        <PageWrapper>
            <Page>
                <Card shadow='none'>
                    <CardBody>
                        <form noValidate onSubmit={addStudentForm.handleSubmit}>
                            <div className="d-grid gap-5">
                                <div className="row g-4">
                                    <div className="col-lg-3">
                                        <FormGroup id="quotaTypeId" label="Quota Type" isFloating>
                                            <Select placeholder="Select Quota Type"
                                                ariaLabel='Quota Type'
                                                onChange={selectQuota}
                                                value={quotaTypeId}
                                                list={quotaData.map((data: any) => (
                                                    { value: data.quotaTypeId, text: data.quotaType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    {isQuotaTypeDetails == 'isGovernment' ?
                                        <div className="col-lg-3">
                                            <FormGroup id="discription" label="Quota Type Details" isFloating>
                                                <Select placeholder="Select Quota Type"
                                                    ariaLabel='Quota Type'
                                                    onChange={addStudentForm.handleChange}
                                                    value={addStudentForm.values.discription}
                                                    list={quotaDetailsData.map((data: any) => (
                                                        { value: data.discription, text: data.discription }
                                                    ))}
                                                /></FormGroup>
                                        </div> : null}
                                    {isQuotaTypeDetails == 'isManagement' ?
                                        <div className="col-lg-3 ">
                                            <div className="row g-4">
                                                <div className="col-lg-6">
                                                    <FormGroup id="discription" label="discription" isFloating>
                                                        <Input type="text" placeholder="Enter discription"
                                                            onChange={addStudentForm.handleChange}
                                                            value={addStudentForm.values.discription} />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div> : null}

                                </div>
                                <div className="row g-4">
                                    <div className="col-lg-4">Are you a citizen of India ?</div>
                                    <div className="col-lg-3">
                                        <FormGroup id="isCitizenOfIndia">
                                            <Checks
                                                type='radio'
                                                label='Yes'
                                                value={'YES'}
                                                onChange={addStudentForm.handleChange}
                                                checked={addStudentForm.values.isCitizenOfIndia}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="isCitizenOfIndia">
                                            <Checks
                                                type='radio'
                                                label='No'
                                                value={'NO'}
                                                onChange={addStudentForm.handleChange}
                                                checked={addStudentForm.values.isCitizenOfIndia}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-lg-4">Are you claiming NRI quota Seat ?</div>
                                    <div className="col-lg-3">
                                        <FormGroup id="isClaimingNriQuotaSeat">
                                            <Checks
                                                type='radio'
                                                label='Yes'
                                                value={'YES'}
                                                onChange={addStudentForm.handleChange}
                                                checked={addStudentForm.values.isClaimingNriQuotaSeat}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="isClaimingNriQuotaSeat">
                                            <Checks
                                                type='radio'
                                                label='No'
                                                value={'NO'}
                                                onChange={addStudentForm.handleChange}
                                                checked={addStudentForm.values.isClaimingNriQuotaSeat}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-lg-4">Is Medical Certificate enclosed ?</div>
                                    <div className="col-lg-3">
                                        <FormGroup id="isMedicalCertificateEnclosed">
                                            <Checks
                                                type='radio'
                                                label='Yes'
                                                value={'YES'}
                                                onChange={addStudentForm.handleChange}
                                                checked={addStudentForm.values.isMedicalCertificateEnclosed}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-lg-3">
                                        <FormGroup id="isMedicalCertificateEnclosed">
                                            <Checks
                                                type='radio'
                                                label='No'
                                                value={'NO'}
                                                onChange={addStudentForm.handleChange}
                                                checked={addStudentForm.values.isMedicalCertificateEnclosed}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <FormGroup id="extraCurricularActivities" label="Any extra curricular activities" isFloating>
                                        <Input type="text" placeholder="Any extra curricular activities"
                                            onChange={addStudentForm.handleChange}
                                            value={addStudentForm.values.extraCurricularActivities} />
                                    </FormGroup>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                <Button color="info" onClick={handlePrevious1} type="submit">previous</Button>
                                <Button color="info" type="submit">Submit</Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>
        </PageWrapper>
    )
}
export default AdmissionInfo;