import React, { useContext, useEffect, useRef, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Icon from '../../icon/Icon';
import Collapse from '../../bootstrap/Collapse';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Input from '../../bootstrap/forms/Input';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';
import { useFormik } from 'formik';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import {
	getColumnsForDataTable,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import {
	getStaffReferralReport,
	getStaffReferralReportByStaffDetailsId,
} from '../../../services/report.service';
import { getLicenseKey } from '../../../services/application.settings';
import { getAcademicPeriod } from '../../../services/master.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';

function StaffReferralReport() {
	useEffect(() => {
		getAcademicPeriodList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [staffReferralData, setStaffReferralData] = useState([]);
	const [studentDetailsData, setStudentDetailsData] = useState([]);

	const [dataSuccess, setDataSuccess] = useState(false);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(staffReferralData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [staffReferralModal, setStaffReferralModal] = useState(false);
	const [referredFullName, setReferredFullName] = useState('');
	const [userType, setUserType] = useState('');
	const [isLoader, setIsLoader] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('');

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedStaffReferral = i;
			setStaffReferralModal(true);

			getStaffReferralReportByStaffDetailsIdList(
				academicPeriodId?.value,
				selectedStaffReferral.referredId,
			);
		}
	}

	const selectAcademicPeriod = (e: any) => {
		setDataSuccess(false);
		setIsOpenListCard(true);
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
	};

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAcademicPeriodData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffReferral(academicPeriodId: string) {
		setIsLoader(true);
		getStaffReferralReport(
			academicPeriodId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffReferralReport;
					if (data != undefined) {
						setIsLoader(false);
						setStaffReferralData(data);
						setIsOpenListCard(false);
						setDataSuccess(true);
						getColumnsForTable('getStaffReferralReport', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getStaffReferralReport', 'get');
					setStaffReferralData([]);
					setDataSuccess(true);
					setIsOpenListCard(false);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getStaffReferralReportByStaffDetailsIdList(academicPeriodId: string, referredId: any) {
		getStaffReferralReportByStaffDetailsId(
			academicPeriodId,
			referredId,
			(response) => {
				if (response.data.success) {
					let data =
						response.data.data.staffReferralReportByStaffDetailsId[0].studentDetails;
					let referredData = response.data.data.staffReferralReportByStaffDetailsId[0];
					if (data != undefined) {
						setStudentDetailsData(data);
						setReferredFullName(referredData.referredFullName);
						setUserType(referredData.userType);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStaffReferralReport', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function view() {
		setDataSuccess(false);
		setIsLoader(true);
		columnVisibilityForm.resetForm();
		if (academicPeriodId?.value != undefined) {
			getStaffReferral(academicPeriodId?.value);
		}
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function closeAndReset() {
		setStaffReferralModal(false);
	}

	return (
		<>
			<PageWrapper title='Staff Referral Report'>
				<Page container='fluid'>
					<Collapse isOpen={isOpenListCard}>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='list' iconColor='primary'>
									<CardTitle>Staff Referral Report</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-4'>
										<FormGroup
											id='academicPeriodId'
											label='Academic Period'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Academic Period'
												onChange={selectAcademicPeriod}
												value={academicPeriodId}
												list={academicPeriodData.map((data: any) => ({
													value: data.academicPeriodId,
													label: data.period,
												}))}
											/>
										</FormGroup>
									</div>
									<div className='col-3 mt-2'>
										<Button icon='ArrowDownward' color='primary' onClick={view} isDisable={academicPeriodId?.value == undefined}>
											View
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent
						isOpenListCard={isOpenListCard}
						setIsOpenListCard={setIsOpenListCard}
						isLoader={isLoader}
					/>

					{dataSuccess ? (
						<Card stretch data-tour='list' ref={componentRef} id='pdf'>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className='col-lg-6'>
									<CardTitle tag='div' className='h5'>
										Staff Referral List
									</CardTitle>
								</CardLabel>
								&nbsp;&nbsp;
								<ButtonGroup
									className='col-lg-3 justify-content-end d-print-none'
									color='primary'>
									<Icon
										className='mt-1'
										icon='Search'
										size='2x'
										color='primary'
									/>
									<Input
										id='searchInput'
										type='search'
										placeholder='Search...'
										onChange={columnVisibilityForm.handleChange}
										value={columnVisibilityForm.values.searchInput}
									/>
								</ButtonGroup>
								<CardActions className='d-print-none'>
									<Dropdown
										isOpen={columnVisibilityMenu}
										setIsOpen={setColumnVisibilityMenu}
										isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>
												Filter
											</Button>
										</DropdownToggle>
										<DropdownMenu
											isAlignmentEnd
											size='lg'
											isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks
																	id='available'
																	type='switch'
																	label='Select All Columns'
																	onChange={() =>
																		getColumnsForTable(
																			'getStaffReferralReport',
																			'post',
																		)
																	}
																	checked={isChecked}
																	ariaLabel='Available status'
																/>
															</FormGroup>
														</div>
														<div
															className='col-12'
															style={{
																maxHeight: '200px',
																overflowY: 'scroll',
															}}>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map(
																		(i: any) => (
																			<Checks
																				key={
																					i.columnVisibilityId
																				}
																				id='{i.columnVisibilityId}'
																				label={i.columnName}
																				onChange={() =>
																					updateColumnsSubmit(
																						i.columnVisibilityId,
																						i.isDisplay,
																						'',
																					)
																				}
																				checked={
																					i.isDisplay
																				}
																				disabled={
																					i.isDefault ==
																					true
																				}
																			/>
																		),
																	)}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'>
												{' '}
												Export{' '}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadExcel(
															'Staff Referral Data',
															staffReferralData,
															columnVisibilityData,
														)
													}>
													{' '}
													Excel{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='PictureAsPdf'
													onClick={() =>
														convertJsonToPdf(
															staffReferralData,
															columnVisibilityData,
															'Staff Referral Data',
														)
													}>
													{' '}
													PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadFile(
															'Staff Referral Data',
															staffReferralData,
															columnVisibilityData,
														)
													}>
													{' '}
													CSV{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='Print'
													onClick={handlePrint}>
													{' '}
													Print{' '}
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.referredId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
																rowData[column.keyName] ? (
																<td
																	key={`${rowData.referredId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
														<td className='d-print-none'>
															<Button
																color='dark'
																isLight
																icon='More'
																aria-label='Edit'
																onClick={() => {
																	onActivate(rowData);
																}}
															/>
														</td>
													</tr>
												))}{' '}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					) : null}
				</Page>

				<Modal
					titleId='staffReferralModal'
					isOpen={staffReferralModal}
					setIsOpen={setStaffReferralModal}
					isStaticBackdrop={true}
					isCentered
					isScrollable
					size='lg'>
					<ModalHeader setIsOpen={setStaffReferralModal} onClick={closeAndReset}>
						<ModalTitle id='staffReferralModal'>Student List</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='row g-4'>
							<div className='col-6 '>
								<div className='d-flex align-items-center'>
									<div className='flex-shrink-0'>
										<Icon icon='ListAlt' size='3x' color='info' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-5 mb-0'>{userType}</div>
										<div className='text-muted'>User Type</div>
									</div>
								</div>
							</div>
							<div className='col-6 '>
								<div className='d-flex align-items-center'>
									<div className='flex-shrink-0'>
										<Icon icon='ListAlt' size='3x' color='info' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-5 mb-0'>{referredFullName}</div>
										<div className='text-muted'>Referred Name</div>
									</div>
								</div>
							</div>
							<div className='col-lg-12 '>
								<table className='table table-modern table-hover '>
									<thead className='table-info'>
										<tr>
											<th>S.NO</th>
											<th>ADMISSION NO</th>
											<th>STUDENT NAME</th>
										</tr>
									</thead>
									<tbody>
										{studentDetailsData.map((i: any) => (
											<tr key={i.studentDetailsId}>
												<td className='align-self-center'>{i.sno}</td>
												<td className='align-self-center'>
													{i.admissionNo}{' '}
												</td>
												<td className='align-self-center'>
													{i.studentName}{' '}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</PageWrapper>
		</>
	);
}

export default StaffReferralReport;
