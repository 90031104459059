import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { getCity, getStates, onlyAllowNumber } from "../../../../services/common.service";
import { toasts } from "../../../../services/toast.service";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Card, { CardBody } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";
import Select from "../../../bootstrap/forms/Select";
import Button from "../../../bootstrap/Button";
import Checks from "../../../bootstrap/forms/Checks";

interface IStudentProps {
    handleNext1(...args: unknown[]): unknown;
    handlePrevious(...args: unknown[]): unknown;
    setSelectedValue(...args: unknown[]): unknown;
    selectedValue?: any;
}

const AddressInfo: FC<IStudentProps> = ({ handleNext1, handlePrevious, setSelectedValue, selectedValue }) => {

    useEffect(() => {
        getStatesList();
        getPermAddr_State();
        addSetValues();
    }, [])

    const [commAddr_StateId, setCommAddr_StateId] = useState('')
    const [commAddr_CityId, setCommAddr_CityId] = useState('')

    const [permAddr_StateId, setPermAddr_StateId] = useState('')
    const [permAddr_CityId, setPermAddr_CityId] = useState('')
    const [statesData, setStatesData] = useState([])
    const [cityData, setCityData] = useState([])
    const [permAddr_StateData, setPermAddr_StateData] = useState([])
    const [permAddr_CityData, setPermAddr_CityData] = useState([])
    const [permAddr_FlatNo, setPermAddr_FlatNo] = useState<any>('')
    const [permAddr_Street, setPermAddr_Street] = useState<any>('')
    const [permAddr_Area, setPermAddr_Area] = useState<any>('')
    const [permAddr_Pincode, setPermAddr_Pincode] = useState<any>('')
    const [isChecked, setIsChecked] = useState(false)

    const addressForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ///----General Info----////
            graduationTypeId: '',
            admissionNo: '',
            tempNo: 0,
            firstName: '',
            lastName: '',
            surName: '',
            studentNameTamil: '',
            surNameTamil: '',
            dateOfBirth: '',
            genderId: '',
            bloodGroupId: '',
            motherTongue: '',
            nationality: '',
            otherNation: '',
            religionId: '',
            casteUniqueId: '',
            casteDetailsId: '',
            subcaste: '',
            category: '',
            aadharCard: '',
            physicallyChallenged: '',
            physicallyChallengedType: '',
            physicallyChallengedPercentage: '',
            martialStatus: '',
            email: '',
            emisNo: '',
            ///----Address INFO----////
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_CityId: '',
            commAddr_Pincode: '',
            permAddr_FlatNo: '',
            permAddr_Street: '',
            permAddr_Area: '',
            permAddr_StateId: '',
            permAddr_CityId: '',
            permAddr_Pincode: '',

            bankName: '',
            bankAccountNo: '',
            branch: '',
            ifscCode: '',
            micrCode: '',
            // ///----Class INFO----////
            departmentId: '',
            courseId: '',
            batchMasterId: '',
            semesterDetailsId: '',
            sectionDetailsId: '',
            shiftId: '',
            studentRollNo: '',
            admissionDateStamp: '',
            sourceName: '',
            transferCourseId: '',
            referralTypeId: '',
            referredId: '',
            referredName: '',
            interimCollegeTransfer: '',
            collegeName: '',
            concessionTypeId: '',
            concessionRemarks: '',
            transportAreaMasterId: '',
            modeOfWays: '',
            isCollegeTransport: '',
            ///----Family INFO----////
            fatherName: '',
            father_AdharNumber: '',
            fatherOccupation: '',
            natureofJoborBusiness: '',
            fatherContactNumber: '',
            fatherEmailId: '',
            motherName: '',
            mother_AdharNumber: '',
            yearlyIncome: '',
            positionHeld: '',
            motherContactNumber: '',
            smsToBeSentNumber: '',
            guardian_Name: '',
            guardian_email: '',
            guardian_phone: '',
            guardian_AdharNumber: '',
            isGuardian: 0,
            // // ///----MARK INFO----////
            registerNo_X: '',
            institutionNameAndAddress_X: '',
            boardName_X: '',
            mediumOfInstitution_X: '',
            monthAndYearOfPassing_X: '',

            registerNo_XI: '',
            institutionNameAndAddress_XI: '',
            boardName_XI: '',
            mediumOfInstitution_XI: '',
            monthAndYearOfPassing_XI: '',

            registerNo_XII: '',
            XIISchoolNameId: '',
            boardName_XII: '',
            mediumOfInstitution_XII: '',
            monthAndYearOfPassing_XII: '',

            XI_S1_studentSchoolSubject: '',
            XI_S1_markSecured: '',
            XI_S1_maximumMarks: '',
            XI_S1_monthAndYearOfPassing: '',
            XI_S1_registerNo: '',
            XI_S1_noOfAttempts: '',
            XI_S2_studentSchoolSubject: '',
            XI_S2_markSecured: '',
            XI_S2_maximumMarks: '',
            XI_S2_monthAndYearOfPassing: '',
            XI_S2_registerNo: '',
            XI_S2_noOfAttempts: '',
            XI_S3_studentSchoolSubject: '',
            XI_S3_markSecured: '',
            XI_S3_maximumMarks: '',
            XI_S3_monthAndYearOfPassing: '',
            XI_S3_registerNo: '',
            XI_S3_noOfAttempts: '',
            XI_S4_studentSchoolSubject: '',
            XI_S4_markSecured: '',
            XI_S4_maximumMarks: '',
            XI_S4_monthAndYearOfPassing: '',
            XI_S4_registerNo: '',
            XI_S4_noOfAttempts: '',
            XI_S5_studentSchoolSubject: '',
            XI_S5_markSecured: '',
            XI_S5_maximumMarks: '',
            XI_S5_monthAndYearOfPassing: '',
            XI_S5_registerNo: '',
            XI_S5_noOfAttempts: '',
            XI_S6_studentSchoolSubject: '',
            XI_S6_markSecured: '',
            XI_S6_maximumMarks: '',
            XI_S6_monthAndYearOfPassing: '',
            XI_S6_registerNo: '',
            XI_S6_noOfAttempts: '',

            XII_S1_studentSchoolSubject: '',
            XII_S1_markSecured: '',
            XII_S1_maximumMarks: '',
            XII_S1_monthAndYearOfPassing: '',
            XII_S1_registerNo: '',
            XII_S1_noOfAttempts: '',
            XII_S2_studentSchoolSubject: '',
            XII_S2_markSecured: '',
            XII_S2_maximumMarks: '',
            XII_S2_monthAndYearOfPassing: '',
            XII_S2_registerNo: '',
            XII_S2_noOfAttempts: '',
            XII_S3_studentSchoolSubject: '',
            XII_S3_markSecured: '',
            XII_S3_maximumMarks: '',
            XII_S3_monthAndYearOfPassing: '',
            XII_S3_registerNo: '',
            XII_S3_noOfAttempts: '',
            XII_S4_studentSchoolSubject: '',
            XII_S4_markSecured: '',
            XII_S4_maximumMarks: '',
            XII_S4_monthAndYearOfPassing: '',
            XII_S4_registerNo: '',
            XII_S4_noOfAttempts: '',
            XII_S5_studentSchoolSubject: '',
            XII_S5_markSecured: '',
            XII_S5_maximumMarks: '',
            XII_S5_monthAndYearOfPassing: '',
            XII_S5_registerNo: '',
            XII_S5_noOfAttempts: '',
            XII_S6_studentSchoolSubject: '',
            XII_S6_markSecured: '',
            XII_S6_maximumMarks: '',
            XII_S6_monthAndYearOfPassing: '',
            XII_S6_registerNo: '',
            XII_S6_noOfAttempts: '',

            XI_OverAll_markSecured: '',
            XI_OverAll_maximumMarks: '',
            XII_OverAll_markSecured: '',
            XII_OverAll_maximumMarks: '',
            registerNo_UG: '',
            institutionNameAndAddress_UG: '',
            universityName_UG: '',
            mediumOfInstitution_UG: '',
            monthAndYearOfPassing_UG: '',

            registerNo_PG: '',
            institutionNameAndAddress_PG: '',
            universityName_PG: '',
            mediumOfInstitution_PG: '',
            monthAndYearOfPassing_PG: '',
            registerNo_MPhil: '',
            institutionNameAndAddress_MPhil: '',
            universityName_MPhil: '',
            mediumOfInstitution_MPhil: '',
            monthAndYearOfPassing_MPhil: '',
            UG_courseName: '',
            UG_Part1_SubjectName: '',
            UG_Part1_MarksObtained: '',
            UG_Part1_MaximumMarks: '',
            UG_Part1_Percentage: '',
            UG_Part1_ClassObtained: '',
            UG_Part1_NoOfAttempts: '',
            UG_Part1_RegisterNo: '',
            UG_Part1_Passing: '',
            UG_Part2_SubjectName: '',
            UG_Part2_MarksObtained: '',
            UG_Part2_MaximumMarks: '',
            UG_Part2_Percentage: '',
            UG_Part2_ClassObtained: '',
            UG_Part2_NoOfAttempts: '',
            UG_Part2_RegisterNo: '',
            UG_Part2_Passing: '',
            UG_Part3_SubjectName: '',
            UG_Part3_MarksObtained: '',
            UG_Part3_MaximumMarks: '',
            UG_Part3_Percentage: '',
            UG_Part3_ClassObtained: '',
            UG_Part3_NoOfAttempts: '',
            UG_Part3_RegisterNo: '',
            UG_Part3_Passing: '',
            UG_Allied1_SubjectName: '',
            UG_Allied1_MarksObtained: '',
            UG_Allied1_MaximumMarks: '',
            UG_Allied1_Percentage: '',
            UG_Allied1_ClassObtained: '',
            UG_Allied1_NoOfAttempts: '',
            UG_Allied1_RegisterNo: '',
            UG_Allied1_Passing: '',
            UG_Allied2_SubjectName: '',
            UG_Allied2_MarksObtained: '',
            UG_Allied2_MaximumMarks: '',
            UG_Allied2_Percentage: '',
            UG_Allied2_ClassObtained: '',
            UG_Allied2_NoOfAttempts: '',
            UG_Allied2_RegisterNo: '',
            UG_Allied2_Passing: '',
            UG_Overall_Main_Core: '',
            UG_Overall_Ancy_Allied: '',
            UG_Overall_Part3: '',

            PG_courseName: '',
            PG_Part1_SubjectName: '',
            PG_Part1_MarksObtained: '',
            PG_Part1_MaximumMarks: '',
            PG_Part1_Percentage: '',
            PG_Part1_ClassObtained: '',
            PG_Part1_NoOfAttempts: '',
            PG_Part1_RegisterNo: '',
            PG_Part1_Passing: '',
            PG_Part2_SubjectName: '',
            PG_Part2_MarksObtained: '',
            PG_Part2_MaximumMarks: '',
            PG_Part2_Percentage: '',
            PG_Part2_ClassObtained: '',
            PG_Part2_NoOfAttempts: '',
            PG_Part2_RegisterNo: '',
            PG_Part2_Passing: '',
            PG_Part3_SubjectName: '',
            PG_Part3_MarksObtained: '',
            PG_Part3_MaximumMarks: '',
            PG_Part3_Percentage: '',
            PG_Part3_ClassObtained: '',
            PG_Part3_NoOfAttempts: '',
            PG_Part3_RegisterNo: '',
            PG_Part3_Passing: '',
            PG_Allied1_SubjectName: '',
            PG_Allied1_MarksObtained: '',
            PG_Allied1_MaximumMarks: '',
            PG_Allied1_Percentage: '',
            PG_Allied1_ClassObtained: '',
            PG_Allied1_NoOfAttempts: '',
            PG_Allied1_RegisterNo: '',
            PG_Allied1_Passing: '',
            PG_Allied2_SubjectName: '',
            PG_Allied2_MarksObtained: '',
            PG_Allied2_MaximumMarks: '',
            PG_Allied2_Percentage: '',
            PG_Allied2_ClassObtained: '',
            PG_Allied2_NoOfAttempts: '',
            PG_Allied2_RegisterNo: '',
            PG_Allied2_Passing: '',
            PG_Overall_Main_Core: '',
            PG_Overall_Ancy_Allied: '',
            PG_Overall_Part3: '',

            MPhil_courseName: '',
            MPhil_Part1_SubjectName: '',
            MPhil_Part1_MarksObtained: '',
            MPhil_Part1_MaximumMarks: '',
            MPhil_Part1_Percentage: '',
            MPhil_Part1_ClassObtained: '',
            MPhil_Part1_NoOfAttempts: '',
            MPhil_Part1_RegisterNo: '',
            MPhil_Part1_Passing: '',
            MPhil_Part2_SubjectName: '',
            MPhil_Part2_MarksObtained: '',
            MPhil_Part2_MaximumMarks: '',
            MPhil_Part2_Percentage: '',
            MPhil_Part2_ClassObtained: '',
            MPhil_Part2_NoOfAttempts: '',
            MPhil_Part2_RegisterNo: '',
            MPhil_Part2_Passing: '',
            MPhil_Part3_SubjectName: '',
            MPhil_Part3_MarksObtained: '',
            MPhil_Part3_MaximumMarks: '',
            MPhil_Part3_Percentage: '',
            MPhil_Part3_ClassObtained: '',
            MPhil_Part3_NoOfAttempts: '',
            MPhil_Part3_RegisterNo: '',
            MPhil_Part3_Passing: '',
            MPhil_Allied1_SubjectName: '',
            MPhil_Allied1_MarksObtained: '',
            MPhil_Allied1_MaximumMarks: '',
            MPhil_Allied1_Percentage: '',
            MPhil_Allied1_ClassObtained: '',
            MPhil_Allied1_NoOfAttempts: '',
            MPhil_Allied1_RegisterNo: '',
            MPhil_Allied1_Passing: '',
            MPhil_Allied2_SubjectName: '',
            MPhil_Allied2_MarksObtained: '',
            MPhil_Allied2_MaximumMarks: '',
            MPhil_Allied2_Percentage: '',
            MPhil_Allied2_ClassObtained: '',
            MPhil_Allied2_NoOfAttempts: '',
            MPhil_Allied2_RegisterNo: '',
            MPhil_Allied2_Passing: '',
            MPhil_Overall_Main_Core: '',
            MPhil_Overall_Ancy_Allied: '',
            MPhil_Overall_Part3: '',

            ///----Admission----////
            quotaTypeId: '',
            discription: '',
            isCitizenOfIndia: '',
            isClaimingNriQuotaSeat: '',
            isMedicalCertificateEnclosed: '',
            extraCurricularActivities: '',

            ///----Certificate----////
            submittedDate: '',
            status: '',
            certificateTypeId: [],
            scholarshipTypeId: [],
            assignedBy: '',


        },
        validate: (values: any) => {
            const errors: {
                commAddr_Pincode?: string;
                permAddr_Pincode?: string;
            } = {};
            if (values.commAddr_Pincode && !/^\d{6}$/.test(values.commAddr_Pincode)) {
                errors.commAddr_Pincode = 'Must be 6 Numbers';
            }
            if (permAddr_Pincode && !/^\d{6}$/.test(permAddr_Pincode)) {
                errors.permAddr_Pincode = 'Must be 6 Numbers';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { addStudentSubmit() },
    })

    const selectState = (e: any) => {
        let commAddr_StateId = e.target.value
        setCommAddr_StateId(commAddr_StateId)
        getCityList(commAddr_StateId)
    }

    const selectCity = (e: any) => {
        let commAddr_CityId = e.target.value
        setCommAddr_CityId(commAddr_CityId)
    }

    const selectPermAddr_State = (e: any) => {
        let permAddr_StateId = e.target.value
        setPermAddr_StateId(permAddr_StateId)
        getPermAddr_StateAndCity(permAddr_StateId)
    }

    const selectPermAddr_City = (e: any) => {
        let permAddr_CityId = e.target.value
        setPermAddr_CityId(permAddr_CityId)
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: any) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPermAddr_State() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setPermAddr_StateData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getPermAddr_StateAndCity(stateId: any) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setPermAddr_CityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function address(e: any) {
        setIsChecked(e.target.checked)
        if (e.target.checked == true) {
            getPermAddr_StateAndCity(commAddr_StateId)
            setPermAddr_FlatNo(addressForm.values.commAddr_FlatNo)
            setPermAddr_Street(addressForm.values.commAddr_Street)
            setPermAddr_Area(addressForm.values.commAddr_Area)
            setPermAddr_Pincode(addressForm.values.commAddr_Pincode)
            setPermAddr_StateId(commAddr_StateId)
            setPermAddr_CityId(commAddr_CityId)
        }
        else {
            setPermAddr_FlatNo('')
            setPermAddr_Street('')
            setPermAddr_Area('')
            setPermAddr_Pincode('')
            setPermAddr_StateId('')
            setPermAddr_CityId('')
        }
    }

    function addSetValues() {
        console.log(selectedValue);
        if (selectedValue.commAddr_StateId) {
            getCityList(selectedValue.commAddr_StateId)
        }

        if (selectedValue.permAddr_StateId) {
            getPermAddr_StateAndCity(selectedValue.permAddr_StateId)
        }

        setPermAddr_FlatNo(selectedValue.permAddr_FlatNo)
        setPermAddr_Street(selectedValue.permAddr_Street)
        setPermAddr_Area(selectedValue.permAddr_Area)
        setPermAddr_Pincode(selectedValue.permAddr_Pincode)
        setPermAddr_StateId(selectedValue.permAddr_StateId)
        setPermAddr_CityId(selectedValue.permAddr_CityId)

        setCommAddr_CityId(selectedValue.commAddr_CityId)
        setCommAddr_StateId(selectedValue.commAddr_StateId)

        addressForm.setValues({

            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo,
            tempNo: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : "",
            surName: selectedValue.surName ? selectedValue.surName : "",
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : "",
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : "",
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : "",
            genderId: selectedValue.genderId ? selectedValue.genderId : "",
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : "",
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : "",
            nationality: selectedValue.nationality ? selectedValue.nationality : "",
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : "",
            religionId: selectedValue.religionId ? selectedValue.religionId : "",
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : "",
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : "",
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : "",
            category: selectedValue.category ? selectedValue.category : "",
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : "",
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : "",
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : "",
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : "",
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : "",
            email: selectedValue.email ? selectedValue.email : "",
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : "",
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : "",

            commAddr_FlatNo: selectedValue.commAddr_FlatNo ? selectedValue.commAddr_FlatNo : "",
            commAddr_Street: selectedValue.commAddr_Street ? selectedValue.commAddr_Street : "",
            commAddr_Area: selectedValue.commAddr_Area ? selectedValue.commAddr_Area : "",
            commAddr_StateId: selectedValue.commAddr_StateId ? selectedValue.commAddr_StateId : "",
            commAddr_CityId: selectedValue.commAddr_CityId ? selectedValue.commAddr_CityId : "",
            commAddr_Pincode: selectedValue.commAddr_Pincode ? selectedValue.commAddr_Pincode : "",

            permAddr_FlatNo: selectedValue.permAddr_FlatNo ? selectedValue.permAddr_FlatNo : "",
            permAddr_Street: selectedValue.permAddr_Street ? selectedValue.permAddr_Street : "",
            permAddr_Area: selectedValue.permAddr_Area ? selectedValue.permAddr_Area : "",
            permAddr_StateId: selectedValue.permAddr_StateId ? selectedValue.permAddr_StateId : "",
            permAddr_CityId: selectedValue.permAddr_CityId ? selectedValue.permAddr_CityId : "",
            permAddr_Pincode: selectedValue.permAddr_Pincode ? selectedValue.permAddr_Pincode : "",

            bankName: selectedValue.bankName ? selectedValue.bankName : "",
            bankAccountNo: selectedValue.bankAccountNo ? selectedValue.bankAccountNo : "",
            branch: selectedValue.branch ? selectedValue.branch : "",
            ifscCode: selectedValue.ifscCode ? selectedValue.ifscCode : "",
            micrCode: selectedValue.micrCode ? selectedValue.micrCode : "",

            departmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
           
            ///----CertificateType Details----////
            certificateTypeId: selectedValue.checkArray,
            submittedDate: selectedValue.submittedDate,
            status: selectedValue.status,

            ///----Scholarship Details----////
            scholarshipTypeId: selectedValue.scholarshipTypeIdArray,
            assignedBy: selectedValue.userAccountId,
        })
    }

    function setAddStudentDetails() {

        return ({
            graduationTypeId: selectedValue.graduationTypeId,
            admissionNo: selectedValue.admissionNo ? selectedValue.admissionNo : '',
            tempNo: selectedValue.tempNo,
            firstName: selectedValue.firstName ? selectedValue.firstName : '',
            lastName: selectedValue.lastName ? selectedValue.lastName : '',
            surName: selectedValue.surName ? selectedValue.surName : '',
            studentNameTamil: selectedValue.studentNameTamil ? selectedValue.studentNameTamil : '',
            surNameTamil: selectedValue.surNameTamil ? selectedValue.surNameTamil : '',
            dateOfBirth: selectedValue.dateOfBirth ? selectedValue.dateOfBirth : '',
            genderId: selectedValue.genderId ? selectedValue.genderId : '',
            bloodGroupId: selectedValue.bloodGroupId ? selectedValue.bloodGroupId : '',
            motherTongue: selectedValue.motherTongue ? selectedValue.motherTongue : '',
            nationality: selectedValue.nationality ? selectedValue.nationality : '',
            otherNation: selectedValue.otherNationValue ? selectedValue.otherNationValue : '',
            religionId: selectedValue.religionId ? selectedValue.religionId : '',
            casteUniqueId: selectedValue.casteUniqueId ? selectedValue.casteUniqueId : '',
            casteDetailsId: selectedValue.casteDetailsId ? selectedValue.casteDetailsId : '',
            subcaste: selectedValue.subcaste ? selectedValue.subcaste : '',
            category: selectedValue.category ? selectedValue.category : '',
            aadharCard: selectedValue.aadharCard ? selectedValue.aadharCard : '',
            physicallyChallenged: selectedValue.physicallyChallenged ? selectedValue.physicallyChallenged : '',
            physicallyChallengedType: selectedValue.physicallyChallengedTypeValue ? selectedValue.physicallyChallengedTypeValue : '',
            physicallyChallengedPercentage: selectedValue.physicallyChallengedPercentageValue ? selectedValue.physicallyChallengedPercentageValue : '',
            martialStatus: selectedValue.martialStatus ? selectedValue.martialStatus : '',
            email: selectedValue.email ? selectedValue.email : '',
            emisNo: selectedValue.emisNo ? selectedValue.emisNo : '',
            smsToBeSentNumber: selectedValue.smsToBeSentNumber ? selectedValue.smsToBeSentNumber : '',

            ///----Address INFO----////
            commAddr_FlatNo: addressForm.values.commAddr_FlatNo ? addressForm.values.commAddr_FlatNo : "",
            commAddr_Street: addressForm.values.commAddr_Street ? addressForm.values.commAddr_Street : "",
            commAddr_Area: addressForm.values.commAddr_Area ? addressForm.values.commAddr_Area : "",
            commAddr_StateId: commAddr_StateId ? commAddr_StateId : "",
            commAddr_CityId: commAddr_CityId ? commAddr_CityId : "",
            commAddr_Pincode: addressForm.values.commAddr_Pincode ? addressForm.values.commAddr_Pincode : "",

            permAddr_FlatNo: permAddr_FlatNo ? permAddr_FlatNo : "",
            permAddr_Street: permAddr_Street ? permAddr_Street : "",
            permAddr_Area: permAddr_Area ? permAddr_Area : "",
            permAddr_StateId: permAddr_StateId ? permAddr_StateId : "",
            permAddr_CityId: permAddr_CityId ? permAddr_CityId : "",
            permAddr_Pincode: permAddr_Pincode ? permAddr_Pincode : "",

            bankName: addressForm.values.bankName ? addressForm.values.bankName : "",
            bankAccountNo: addressForm.values.bankAccountNo ? addressForm.values.bankAccountNo : "",
            branch: addressForm.values.branch ? addressForm.values.branch : "",
            ifscCode: addressForm.values.ifscCode ? addressForm.values.ifscCode : "",
            micrCode: addressForm.values.micrCode ? addressForm.values.micrCode : "",

            departmentId: selectedValue.departmentId ? selectedValue.departmentId : "",
            courseId: selectedValue.courseId ? selectedValue.courseId : "",
            batchMasterId: selectedValue.batchMasterId ? selectedValue.batchMasterId : "",
            semesterDetailsId: selectedValue.semesterDetailsId ? selectedValue.semesterDetailsId : "",
            sectionDetailsId: selectedValue.sectionDetailsId ? selectedValue.sectionDetailsId : "",
            shiftId: selectedValue.shiftId ? selectedValue.shiftId : "",
            studentRollNo: selectedValue.studentRollNo ? selectedValue.studentRollNo : "",
            admissionDateStamp: selectedValue.admissionDateStamp ? selectedValue.admissionDateStamp : "",
            sourceName: selectedValue.sourceName ? selectedValue.sourceName : "",
            transferCourseId: selectedValue.transferCourseId ? selectedValue.transferCourseId : "",
            referralTypeId: selectedValue.referralTypeId ? selectedValue.referralTypeId : "",
            referredId: selectedValue.referredId ? selectedValue.referredId : "",
            referredName: selectedValue.referredName ? selectedValue.referredName : "",
            interimCollegeTransfer: selectedValue.interimCollegeTransfer ? selectedValue.interimCollegeTransfer : "",
            collegeName: selectedValue.collegeNameValue ? selectedValue.collegeNameValue : "",
            concessionTypeId: selectedValue.concessionTypeId ? selectedValue.concessionTypeId : "",
            concessionRemarks: selectedValue.concessionRemarks ? selectedValue.concessionRemarks : "",
            transportAreaMasterId: selectedValue.transportAreaMasterId ? selectedValue.transportAreaMasterId : "",
            modeOfWays: selectedValue.modeOfWays ? selectedValue.modeOfWays : "",
            isCollegeTransport: selectedValue.isCollegeTransport ? selectedValue.isCollegeTransport : "",

            fatherName: selectedValue.fatherName ? selectedValue.fatherName : "",
            father_AdharNumber: selectedValue.father_AdharNumber ? selectedValue.father_AdharNumber : "",
            fatherOccupation: selectedValue.fatherOccupation ? selectedValue.fatherOccupation : "",
            natureofJoborBusiness: selectedValue.natureofJoborBusiness ? selectedValue.natureofJoborBusiness : "",
            fatherContactNumber: selectedValue.fatherContactNumber ? selectedValue.fatherContactNumber : "",
            fatherEmailId: selectedValue.fatherEmailId ? selectedValue.fatherEmailId : "",
            motherName: selectedValue.motherName ? selectedValue.motherName : "",
            mother_AdharNumber: selectedValue.mother_AdharNumber ? selectedValue.mother_AdharNumber : "",
            yearlyIncome: selectedValue.yearlyIncome ? selectedValue.yearlyIncome : "",
            positionHeld: selectedValue.positionHeld ? selectedValue.positionHeld : "",
            motherContactNumber: selectedValue.motherContactNumber ? selectedValue.motherContactNumber : "",
            guardian_Name: selectedValue.guardian_Name ? selectedValue.guardian_Name : "",
            guardian_email: selectedValue.guardian_email ? selectedValue.guardian_email : "",
            guardian_phone: selectedValue.guardian_phone ? selectedValue.guardian_phone : "",
            guardian_AdharNumber: selectedValue.guardian_AdharNumber ? selectedValue.guardian_AdharNumber : "",
            isGuardian: selectedValue.isGuardian ? 1 : 0,

            ///----MARK INFO----////
            registerNo_X: selectedValue.registerNo_X,
            institutionNameAndAddress_X: selectedValue.institutionNameAndAddress_X,
            boardName_X: selectedValue.boardName_X,
            mediumOfInstitution_X: selectedValue.mediumOfInstitution_X,
            monthAndYearOfPassing_X: selectedValue.monthAndYearOfPassing_X,

            registerNo_XI: selectedValue.registerNo_XI,
            institutionNameAndAddress_XI: selectedValue.institutionNameAndAddress_XI,
            boardName_XI: selectedValue.boardName_XI,
            mediumOfInstitution_XI: selectedValue.mediumOfInstitution_XI,
            monthAndYearOfPassing_XI: selectedValue.monthAndYearOfPassing_XI,

            registerNo_XII: selectedValue.registerNo_XII,
            XIISchoolNameId: selectedValue.XIISchoolNameId,
            boardName_XII: selectedValue.boardName_XII,
            mediumOfInstitution_XII: selectedValue.mediumOfInstitution_XII,
            monthAndYearOfPassing_XII: selectedValue.monthAndYearOfPassing_XII,

            registerNo_UG: selectedValue.registerNo_UG,
            institutionNameAndAddress_UG: selectedValue.institutionNameAndAddress_UG,
            universityName_UG: selectedValue.universityName_UG,
            mediumOfInstitution_UG: selectedValue.mediumOfInstitution_UG,
            monthAndYearOfPassing_UG: selectedValue.monthAndYearOfPassing_UG,

            registerNo_PG: selectedValue.registerNo_PG,
            institutionNameAndAddress_PG: selectedValue.institutionNameAndAddress_PG,
            universityName_PG: selectedValue.universityName_PG,
            mediumOfInstitution_PG: selectedValue.mediumOfInstitution_PG,
            monthAndYearOfPassing_PG: selectedValue.monthAndYearOfPassing_PG,

            registerNo_MPhil: selectedValue.registerNo_MPhil,
            institutionNameAndAddress_MPhil: selectedValue.institutionNameAndAddress_MPhil,
            universityName_MPhil: selectedValue.universityName_MPhil,
            mediumOfInstitution_MPhil: selectedValue.mediumOfInstitution_MPhil,
            monthAndYearOfPassing_MPhil: selectedValue.monthAndYearOfPassing_MPhil,

            XI_S1_studentSchoolSubject: selectedValue.XI_S1_studentSchoolSubject,
            XI_S1_markSecured: selectedValue.XI_S1_markSecured,
            XI_S1_maximumMarks: selectedValue.XI_S1_maximumMarks,
            XI_S1_monthAndYearOfPassing: selectedValue.XI_S1_monthAndYearOfPassing,
            XI_S1_registerNo: selectedValue.XI_S1_registerNo,
            XI_S1_noOfAttempts: selectedValue.XI_S1_noOfAttempts,
            XI_S2_studentSchoolSubject: selectedValue.XI_S2_studentSchoolSubject,
            XI_S2_markSecured: selectedValue.XI_S2_markSecured,
            XI_S2_maximumMarks: selectedValue.XI_S2_maximumMarks,
            XI_S2_monthAndYearOfPassing: selectedValue.XI_S2_monthAndYearOfPassing,
            XI_S2_registerNo: selectedValue.XI_S2_registerNo,
            XI_S2_noOfAttempts: selectedValue.XI_S2_noOfAttempts,
            XI_S3_studentSchoolSubject: selectedValue.XI_S3_studentSchoolSubject,
            XI_S3_markSecured: selectedValue.XI_S3_markSecured,
            XI_S3_maximumMarks: selectedValue.XI_S3_maximumMarks,
            XI_S3_monthAndYearOfPassing: selectedValue.XI_S3_monthAndYearOfPassing,
            XI_S3_registerNo: selectedValue.XI_S3_registerNo,
            XI_S3_noOfAttempts: selectedValue.XI_S3_noOfAttempts,
            XI_S4_studentSchoolSubject: selectedValue.XI_S4_studentSchoolSubject,
            XI_S4_markSecured: selectedValue.XI_S4_markSecured,
            XI_S4_maximumMarks: selectedValue.XI_S4_maximumMarks,
            XI_S4_monthAndYearOfPassing: selectedValue.XI_S4_monthAndYearOfPassing,
            XI_S4_registerNo: selectedValue.XI_S4_registerNo,
            XI_S4_noOfAttempts: selectedValue.XI_S4_noOfAttempts,
            XI_S5_studentSchoolSubject: selectedValue.XI_S5_studentSchoolSubject,
            XI_S5_markSecured: selectedValue.XI_S5_markSecured,
            XI_S5_maximumMarks: selectedValue.XI_S5_maximumMarks,
            XI_S5_monthAndYearOfPassing: selectedValue.XI_S5_monthAndYearOfPassing,
            XI_S5_registerNo: selectedValue.XI_S5_registerNo,
            XI_S5_noOfAttempts: selectedValue.XI_S5_noOfAttempts,
            XI_S6_studentSchoolSubject: selectedValue.XI_S6_studentSchoolSubject,
            XI_S6_markSecured: selectedValue.XI_S6_markSecured,
            XI_S6_maximumMarks: selectedValue.XI_S6_maximumMarks,
            XI_S6_monthAndYearOfPassing: selectedValue.XI_S6_monthAndYearOfPassing,
            XI_S6_registerNo: selectedValue.XI_S6_registerNo,
            XI_S6_noOfAttempts: selectedValue.XI_S6_noOfAttempts,

            XII_S1_studentSchoolSubject: selectedValue.XII_S1_studentSchoolSubject,
            XII_S1_markSecured: selectedValue.XII_S1_markSecured,
            XII_S1_maximumMarks: selectedValue.XII_S1_maximumMarks,
            XII_S1_monthAndYearOfPassing: selectedValue.XII_S1_monthAndYearOfPassing,
            XII_S1_registerNo: selectedValue.XII_S1_registerNo,
            XII_S1_noOfAttempts: selectedValue.XII_S1_noOfAttempts,
            XII_S2_studentSchoolSubject: selectedValue.XII_S2_studentSchoolSubject,
            XII_S2_markSecured: selectedValue.XII_S2_markSecured,
            XII_S2_maximumMarks: selectedValue.XII_S2_maximumMarks,
            XII_S2_monthAndYearOfPassing: selectedValue.XII_S2_monthAndYearOfPassing,
            XII_S2_registerNo: selectedValue.XII_S2_registerNo,
            XII_S2_noOfAttempts: selectedValue.XII_S2_noOfAttempts,
            XII_S3_studentSchoolSubject: selectedValue.XII_S3_studentSchoolSubject,
            XII_S3_markSecured: selectedValue.XII_S3_markSecured,
            XII_S3_maximumMarks: selectedValue.XII_S3_maximumMarks,
            XII_S3_monthAndYearOfPassing: selectedValue.XII_S3_monthAndYearOfPassing,
            XII_S3_registerNo: selectedValue.XII_S3_registerNo,
            XII_S3_noOfAttempts: selectedValue.XII_S3_noOfAttempts,
            XII_S4_studentSchoolSubject: selectedValue.XII_S4_studentSchoolSubject,
            XII_S4_markSecured: selectedValue.XII_S4_markSecured,
            XII_S4_maximumMarks: selectedValue.XII_S4_maximumMarks,
            XII_S4_monthAndYearOfPassing: selectedValue.XII_S4_monthAndYearOfPassing,
            XII_S4_registerNo: selectedValue.XII_S4_registerNo,
            XII_S4_noOfAttempts: selectedValue.XII_S4_noOfAttempts,
            XII_S5_studentSchoolSubject: selectedValue.XII_S5_studentSchoolSubject,
            XII_S5_markSecured: selectedValue.XII_S5_markSecured,
            XII_S5_maximumMarks: selectedValue.XII_S5_maximumMarks,
            XII_S5_monthAndYearOfPassing: selectedValue.XII_S5_monthAndYearOfPassing,
            XII_S5_registerNo: selectedValue.XII_S5_registerNo,
            XII_S5_noOfAttempts: selectedValue.XII_S5_noOfAttempts,
            XII_S6_studentSchoolSubject: selectedValue.XII_S6_studentSchoolSubject,
            XII_S6_markSecured: selectedValue.XII_S6_markSecured,
            XII_S6_maximumMarks: selectedValue.XII_S6_maximumMarks,
            XII_S6_monthAndYearOfPassing: selectedValue.XII_S6_monthAndYearOfPassing,
            XII_S6_registerNo: selectedValue.XII_S6_registerNo,
            XII_S6_noOfAttempts: selectedValue.XII_S6_noOfAttempts,
            XI_OverAll_markSecured: selectedValue.XI_OverAll_markSecured,
            XI_OverAll_maximumMarks: selectedValue.XI_OverAll_maximumMarks,
            XII_OverAll_markSecured: selectedValue.XII_OverAll_markSecured,
            XII_OverAll_maximumMarks: selectedValue.XII_OverAll_maximumMarks,

            UG_courseName: selectedValue.UG_courseName,
            UG_Part1_SubjectName: selectedValue.UG_Part1_SubjectName,
            UG_Part1_MarksObtained: selectedValue.UG_Part1_MarksObtained,
            UG_Part1_MaximumMarks: selectedValue.UG_Part1_MaximumMarks,
            UG_Part1_Percentage: selectedValue.UG_Part1_Percentage,
            UG_Part1_ClassObtained: selectedValue.UG_Part1_ClassObtained,
            UG_Part1_NoOfAttempts: selectedValue.UG_Part1_NoOfAttempts,
            UG_Part1_RegisterNo: selectedValue.UG_Part1_RegisterNo,
            UG_Part1_Passing: selectedValue.UG_Part1_Passing,

            UG_Part2_SubjectName: selectedValue.UG_Part2_SubjectName,
            UG_Part2_MarksObtained: selectedValue.UG_Part2_MarksObtained,
            UG_Part2_MaximumMarks: selectedValue.UG_Part2_MaximumMarks,
            UG_Part2_Percentage: selectedValue.UG_Part2_Percentage,
            UG_Part2_ClassObtained: selectedValue.UG_Part2_ClassObtained,
            UG_Part2_NoOfAttempts: selectedValue.UG_Part2_NoOfAttempts,
            UG_Part2_RegisterNo: selectedValue.UG_Part2_RegisterNo,
            UG_Part2_Passing: selectedValue.UG_Part2_Passing,

            UG_Part3_SubjectName: selectedValue.UG_Part3_SubjectName,
            UG_Part3_MarksObtained: selectedValue.UG_Part3_MarksObtained,
            UG_Part3_MaximumMarks: selectedValue.UG_Part3_MaximumMarks,
            UG_Part3_Percentage: selectedValue.UG_Part3_Percentage,
            UG_Part3_ClassObtained: selectedValue.UG_Part3_ClassObtained,
            UG_Part3_NoOfAttempts: selectedValue.UG_Part3_NoOfAttempts,
            UG_Part3_RegisterNo: selectedValue.UG_Part3_RegisterNo,
            UG_Part3_Passing: selectedValue.UG_Part3_Passing,

            UG_Allied1_SubjectName: selectedValue.UG_Allied1_SubjectName,
            UG_Allied1_MarksObtained: selectedValue.UG_Allied1_MarksObtained,
            UG_Allied1_MaximumMarks: selectedValue.UG_Allied1_MaximumMarks,
            UG_Allied1_Percentage: selectedValue.UG_Allied1_Percentage,
            UG_Allied1_ClassObtained: selectedValue.UG_Allied1_ClassObtained,
            UG_Allied1_NoOfAttempts: selectedValue.UG_Allied1_NoOfAttempts,
            UG_Allied1_RegisterNo: selectedValue.UG_Allied1_RegisterNo,
            UG_Allied1_Passing: selectedValue.UG_Allied1_Passing,

            UG_Allied2_SubjectName: selectedValue.UG_Allied2_SubjectName,
            UG_Allied2_MarksObtained: selectedValue.UG_Allied2_MarksObtained,
            UG_Allied2_MaximumMarks: selectedValue.UG_Allied2_MaximumMarks,
            UG_Allied2_Percentage: selectedValue.UG_Allied2_Percentage,
            UG_Allied2_ClassObtained: selectedValue.UG_Allied2_ClassObtained,
            UG_Allied2_NoOfAttempts: selectedValue.UG_Allied2_NoOfAttempts,
            UG_Allied2_RegisterNo: selectedValue.UG_Allied2_RegisterNo,
            UG_Allied2_Passing: selectedValue.UG_Allied2_Passing,

            UG_Overall_Main_Core: selectedValue.UG_Overall_Main_Core,
            UG_Overall_Ancy_Allied: selectedValue.UG_Overall_Ancy_Allied,
            UG_Overall_Part3: selectedValue.UG_Overall_Part3,

            PG_courseName: selectedValue.PG_courseName,
            PG_Part1_SubjectName: selectedValue.PG_Part1_SubjectName,
            PG_Part1_MarksObtained: selectedValue.PG_Part1_MarksObtained,
            PG_Part1_MaximumMarks: selectedValue.PG_Part1_MaximumMarks,
            PG_Part1_Percentage: selectedValue.PG_Part1_Percentage,
            PG_Part1_ClassObtained: selectedValue.PG_Part1_ClassObtained,
            PG_Part1_NoOfAttempts: selectedValue.PG_Part1_NoOfAttempts,
            PG_Part1_RegisterNo: selectedValue.PG_Part1_RegisterNo,
            PG_Part1_Passing: selectedValue.PG_Part1_Passing,
            PG_Part2_SubjectName: selectedValue.PG_Part2_SubjectName,
            PG_Part2_MarksObtained: selectedValue.PG_Part2_MarksObtained,
            PG_Part2_MaximumMarks: selectedValue.PG_Part2_MaximumMarks,
            PG_Part2_Percentage: selectedValue.PG_Part2_Percentage,
            PG_Part2_ClassObtained: selectedValue.PG_Part2_ClassObtained,
            PG_Part2_NoOfAttempts: selectedValue.PG_Part2_NoOfAttempts,
            PG_Part2_RegisterNo: selectedValue.PG_Part2_RegisterNo,
            PG_Part2_Passing: selectedValue.PG_Part2_Passing,
            PG_Part3_SubjectName: selectedValue.PG_Part3_SubjectName,
            PG_Part3_MarksObtained: selectedValue.PG_Part3_MarksObtained,
            PG_Part3_MaximumMarks: selectedValue.PG_Part3_MaximumMarks,
            PG_Part3_Percentage: selectedValue.PG_Part3_Percentage,
            PG_Part3_ClassObtained: selectedValue.PG_Part3_ClassObtained,
            PG_Part3_NoOfAttempts: selectedValue.PG_Part3_NoOfAttempts,
            PG_Part3_RegisterNo: selectedValue.PG_Part3_RegisterNo,
            PG_Part3_Passing: selectedValue.PG_Part3_Passing,
            PG_Allied1_SubjectName: selectedValue.PG_Allied1_SubjectName,
            PG_Allied1_MarksObtained: selectedValue.PG_Allied1_MarksObtained,
            PG_Allied1_MaximumMarks: selectedValue.PG_Allied1_MaximumMarks,
            PG_Allied1_Percentage: selectedValue.PG_Allied1_Percentage,
            PG_Allied1_ClassObtained: selectedValue.PG_Allied1_ClassObtained,
            PG_Allied1_NoOfAttempts: selectedValue.PG_Allied1_NoOfAttempts,
            PG_Allied1_RegisterNo: selectedValue.PG_Allied1_RegisterNo,
            PG_Allied1_Passing: selectedValue.PG_Allied1_Passing,
            PG_Allied2_SubjectName: selectedValue.PG_Allied2_SubjectName,
            PG_Allied2_MarksObtained: selectedValue.PG_Allied2_MarksObtained,
            PG_Allied2_MaximumMarks: selectedValue.PG_Allied2_MaximumMarks,
            PG_Allied2_Percentage: selectedValue.PG_Allied2_Percentage,
            PG_Allied2_ClassObtained: selectedValue.PG_Allied2_ClassObtained,
            PG_Allied2_NoOfAttempts: selectedValue.PG_Allied2_NoOfAttempts,
            PG_Allied2_RegisterNo: selectedValue.PG_Allied2_RegisterNo,
            PG_Allied2_Passing: selectedValue.PG_Allied2_Passing,
            PG_Overall_Main_Core: selectedValue.PG_Overall_Main_Core,
            PG_Overall_Ancy_Allied: selectedValue.PG_Overall_Ancy_Allied,
            PG_Overall_Part3: selectedValue.PG_Overall_Part3,

            MPhil_courseName: selectedValue.MPhil_courseName,
            MPhil_Part1_SubjectName: selectedValue.MPhil_Part1_SubjectName,
            MPhil_Part1_MarksObtained: selectedValue.MPhil_Part1_MarksObtained,
            MPhil_Part1_MaximumMarks: selectedValue.MPhil_Part1_MaximumMarks,
            MPhil_Part1_Percentage: selectedValue.MPhil_Part1_Percentage,
            MPhil_Part1_ClassObtained: selectedValue.MPhil_Part1_ClassObtained,
            MPhil_Part1_NoOfAttempts: selectedValue.MPhil_Part1_NoOfAttempts,
            MPhil_Part1_RegisterNo: selectedValue.MPhil_Part1_RegisterNo,
            MPhil_Part1_Passing: selectedValue.MPhil_Part1_Passing,
            MPhil_Part2_SubjectName: selectedValue.MPhil_Part2_SubjectName,
            MPhil_Part2_MarksObtained: selectedValue.MPhil_Part2_MarksObtained,
            MPhil_Part2_MaximumMarks: selectedValue.MPhil_Part2_MaximumMarks,
            MPhil_Part2_Percentage: selectedValue.MPhil_Part2_Percentage,
            MPhil_Part2_ClassObtained: selectedValue.MPhil_Part2_ClassObtained,
            MPhil_Part2_NoOfAttempts: selectedValue.MPhil_Part2_NoOfAttempts,
            MPhil_Part2_RegisterNo: selectedValue.MPhil_Part2_RegisterNo,
            MPhil_Part2_Passing: selectedValue.MPhil_Part2_Passing,
            MPhil_Part3_SubjectName: selectedValue.MPhil_Part3_SubjectName,
            MPhil_Part3_MarksObtained: selectedValue.MPhil_Part3_MarksObtained,
            MPhil_Part3_MaximumMarks: selectedValue.MPhil_Part3_MaximumMarks,
            MPhil_Part3_Percentage: selectedValue.MPhil_Part3_Percentage,
            MPhil_Part3_ClassObtained: selectedValue.MPhil_Part3_ClassObtained,
            MPhil_Part3_NoOfAttempts: selectedValue.MPhil_Part3_NoOfAttempts,
            MPhil_Part3_RegisterNo: selectedValue.MPhil_Part3_RegisterNo,
            MPhil_Part3_Passing: selectedValue.MPhil_Part3_Passing,
            MPhil_Allied1_SubjectName: selectedValue.MPhil_Allied1_SubjectName,
            MPhil_Allied1_MarksObtained: selectedValue.MPhil_Allied1_MarksObtained,
            MPhil_Allied1_MaximumMarks: selectedValue.MPhil_Allied1_MaximumMarks,
            MPhil_Allied1_Percentage: selectedValue.MPhil_Allied1_Percentage,
            MPhil_Allied1_ClassObtained: selectedValue.MPhil_Allied1_ClassObtained,
            MPhil_Allied1_NoOfAttempts: selectedValue.MPhil_Allied1_NoOfAttempts,
            MPhil_Allied1_RegisterNo: selectedValue.MPhil_Allied1_RegisterNo,
            MPhil_Allied1_Passing: selectedValue.MPhil_Allied1_Passing,
            MPhil_Allied2_SubjectName: selectedValue.MPhil_Allied2_SubjectName,
            MPhil_Allied2_MarksObtained: selectedValue.MPhil_Allied2_MarksObtained,
            MPhil_Allied2_MaximumMarks: selectedValue.MPhil_Allied2_MaximumMarks,
            MPhil_Allied2_Percentage: selectedValue.MPhil_Allied2_Percentage,
            MPhil_Allied2_ClassObtained: selectedValue.MPhil_Allied2_ClassObtained,
            MPhil_Allied2_NoOfAttempts: selectedValue.MPhil_Allied2_NoOfAttempts,
            MPhil_Allied2_RegisterNo: selectedValue.MPhil_Allied2_RegisterNo,
            MPhil_Allied2_Passing: selectedValue.MPhil_Allied2_Passing,
            MPhil_Overall_Main_Core: selectedValue.MPhil_Overall_Main_Core,
            MPhil_Overall_Ancy_Allied: selectedValue.MPhil_Overall_Ancy_Allied,
            MPhil_Overall_Part3: selectedValue.MPhil_Overall_Part3,

            quotaTypeId: "",
            discription: "",
            isCitizenOfIndia: "",
            isClaimingNriQuotaSeat: "",
            isMedicalCertificateEnclosed: "",
            extraCurricularActivities: "",
            
            ///----CertificateType Details----////
            certificateTypeId: selectedValue.checkArray,
            submittedDate: selectedValue.submittedDate,
            status: selectedValue.status,

            ///----Scholarship Details----////
            scholarshipTypeId: selectedValue.scholarshipTypeIdArray,
            assignedBy: selectedValue.userAccountId,
        })
    }


    function addStudentSubmit() {
        let studentPostData = setAddStudentDetails()
        setSelectedValue(studentPostData)
        handleNext()
    }

    function handleNext() {
        handleNext1()
    }

    function handlePrevious1() {
        let studentPostData = setAddStudentDetails()
        setSelectedValue(studentPostData)
        handlePrevious()
    }

    return (
        <PageWrapper>
            <Page>
                <Card shadow='none'>
                    <CardBody>
                        <form noValidate onSubmit={addressForm.handleSubmit}>
                            <div className="row g-4">
                                <div className="col-lg-12  align-self-center">
                                    <FormGroup className="align-self-center">
                                        <span className="text-info h5" >
                                            <Icon icon="Home" color="info" size={'2x'} style={{ marginRight: '4px' }} />
                                            ADDRESS INFO
                                        </span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="commAddr_FlatNo" label="Flat No Or Name" isFloating>
                                        <Input type="text" placeholder="Flat No or Name"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.commAddr_FlatNo} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="commAddr_Street" label="Street" isFloating>
                                        <Input type="text" placeholder="Street"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.commAddr_Street} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="commAddr_Area" label="Area/Locatity" isFloating>
                                        <Input type="text" placeholder="Area/Locality"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.commAddr_Area} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="commAddr_StateId" label="State" isFloating>
                                        <Select ariaLabel="" placeholder="Select State"
                                            onChange={selectState}
                                            value={commAddr_StateId}
                                            list={statesData.map((data: any) => (
                                                { value: data.stateId, text: data.stateName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="commAddr_CityId" label="City" isFloating>
                                        <Select ariaLabel="" placeholder="Select City"
                                            onChange={selectCity}
                                            value={commAddr_CityId}
                                            list={cityData.map((data: any) => (
                                                { value: data.cityId, text: data.cityName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="commAddr_Pincode" label="PinCode" isFloating>
                                        <Input type="text" placeholder="Enter PinCode"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.commAddr_Pincode}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                            onBlur={addressForm.handleBlur}
                                            isValid={addressForm.isValid}
                                            isTouched={addressForm.touched.commAddr_Pincode}
                                            invalidFeedback={addressForm.errors.commAddr_Pincode} />
                                    </FormGroup>
                                </div>

                                <div className="col-lg-12">
                                    <FormGroup id="checkbox">
                                        <Checks
                                            type='checkbox'
                                            label='Permanent Address Same As Address For Communication Click Checkbox.'
                                            onChange={address}
                                            checked={isChecked}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="permAddr_FlatNo" label="Flat No Or Name" isFloating>
                                        <Input type="text" placeholder="Flat No or Name"
                                            onInput={(e: any) => setPermAddr_FlatNo(e.target.value)}
                                            value={permAddr_FlatNo} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="permAddr_Street" label="Street" isFloating>
                                        <Input type="text" placeholder="Street"
                                            onInput={(e: any) => setPermAddr_Street(e.target.value)}
                                            value={permAddr_Street} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="permAddr_Area" label="Area/Locatity" isFloating>
                                        <Input type="text" placeholder="Area/Locality"
                                            onInput={(e: any) => setPermAddr_Area(e.target.value)}
                                            value={permAddr_Area} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="permAddr_StateId" label="State" isFloating>
                                        <Select ariaLabel="" placeholder="Select State"
                                            onChange={selectPermAddr_State}
                                            value={permAddr_StateId}
                                            list={permAddr_StateData.map((data: any) => (
                                                { value: data.stateId, text: data.stateName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="permAddr_CityId" label="City" isFloating>
                                        <Select ariaLabel="" placeholder="Select City"
                                            onChange={selectPermAddr_City}
                                            value={permAddr_CityId}
                                            list={permAddr_CityData.map((data: any) => (
                                                { value: data.cityId, text: data.cityName }
                                            ))} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="permAddr_Pincode" label="PinCode" isFloating>
                                        <Input type="text" placeholder="Enter PinCode"
                                            onInput={(e: any) => setPermAddr_Pincode(e.target.value)}
                                            onChange={addressForm.handleChange}
                                            value={permAddr_Pincode}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                            onBlur={addressForm.handleBlur}
                                            isValid={addressForm.isValid}
                                            isTouched={addressForm.touched.permAddr_Pincode}
                                            invalidFeedback={addressForm.errors.permAddr_Pincode} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12  align-self-center">
                                    <FormGroup className="align-self-center">
                                        <span className="text-info h5" >
                                            <Icon icon="MonetizationOn" color="info" size={'2x'} style={{ marginRight: '4px' }} />
                                            BANK INFO
                                        </span>
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="bankName" label="Bank Name" isFloating>
                                        <Input type="text" placeholder="Enter Bank Name"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.bankName} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="branch" label="Branch" isFloating>
                                        <Input type="text" placeholder="Enter Branch"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.branch} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="bankAccountNo" label="Bank Account No" isFloating>
                                        <Input type="text" placeholder="Enter Bank Account No"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.bankAccountNo}
                                            onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="ifscCode" label="IFSC Code" isFloating>
                                        <Input type="text" placeholder="Enter IFSC Code"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.ifscCode} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup id="micrCode" label="MICR Code" isFloating>
                                        <Input type="text" placeholder="Enter MICR Code"
                                            onChange={addressForm.handleChange}
                                            value={addressForm.values.micrCode} />
                                    </FormGroup>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                <Button color="info" onClick={handlePrevious1} type="submit">previous</Button>
                                <Button color="info" type="submit">Next</Button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>
        </PageWrapper>
    )
}
export default AddressInfo;