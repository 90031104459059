import { useEffect, useRef, useState } from "react";
import useDarkMode from "../../../../../hooks/useDarkMode";
import useSortableData from "../../../../../hooks/useSortableData";
import { dataPagination } from "../../../../PaginationButtons";
import { useFormik } from "formik";
import { toasts } from "../../../../../services/toast.service";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";
import Collapse from "../../../../bootstrap/Collapse";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../bootstrap/Card";
import FormGroup from "../../../../bootstrap/forms/FormGroup";
import Select from "../../../../bootstrap/forms/Select";
import Button from "../../../../bootstrap/Button";
import Input from "../../../../bootstrap/forms/Input";
import { useReactToPrint } from 'react-to-print';
import { getStudentDetailsBySemesterId } from "../../../../../services/student.service";
import OpenCardComponent from "../../../../../common/components/OpenCardComponent";
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../../../services/master.service";
import { getGraduationType, getSectionDetails, pictNotLoading, profilePic } from "../../../../../services/common.service";
import SearchableSelect from "../../../../../common/components/SearchableSelect";
import noImage from '../../../../../assets/img/noImage.png';
import CollegeLogo from '../../../../../assets/logos/logo.jpg';
import { getCollegeDetails } from "../../../../../services/college.service";



const HallTicketIssue = () => {

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
        getCollege();
    }, [])

    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [filteredData1, setFilteredData1] = useState<any>([])

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [isLoader, setIsLoader] = useState(false)

    const [filteredData, setFilteredData] = useState<any>([])
    const [updateUniRegNo, setUpdateUniRegNo] = useState<any>([])

    const [studentDetailsData, setStudentDetailsData] = useState([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const componentRef = useRef(null);
    const [collegeDetailsData, setCollegeDetailsData] = useState<any>([]);

    const editHallTicketForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            examName: '',
            monthName: '',
        },
        validate: (values) => { },

        validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        validateOnChange: false,
        onSubmit: () => { },
    });

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }

    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false)
        setIsLoader(true);
        let examName = editHallTicketForm.values.examName
        let monthName = editHallTicketForm.values.monthName
        getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value, examName, monthName)
    }
    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCollege() {
        getCollegeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.collegeDetails;
                    if (data != undefined) {
                        setCollegeDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }


    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: any) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number, examName: string, monthName: string) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setIsOpenListCard(false);
                        setFilteredData1(data)
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        for (let i = 0; i < data.length; i++) {
                            data[i].examName = examName;
                            data[i].monthName = monthName;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    toasts(response.data.message, "Error")
                    setDataSuccess(false);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                    setDataSuccess(false);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <PageWrapper title="Hall Ticket Issue">
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Hall Ticket Issue
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className='col-3'>
                                    <FormGroup id='departmentId' label='Department' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Department'
                                            onChange={selectDepartment}
                                            value={departmentId}
                                            list={departmentData.map((data: any) => (
                                                { value: data.departmentId, label: data.departmentName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {departmentId ? <div className='col-2'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, label: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {graduationTypeId ? <div className='col-3'>
                                    <FormGroup id='courseId' label='Course' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Course'
                                            onChange={selectCourse}
                                            value={courseId}
                                            list={courseData.map((data: any) => (
                                                { value: data.courseId, label: data.courseName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {courseId ? <div className='col-3'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Batch'
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, label: data.batchName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {batchMasterId ? <div className='col-3 mt-3'>
                                    <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Semester'
                                            onChange={selectSemester}
                                            value={semesterDetailsId}
                                            list={semesterDetailsData.map((data: any) => (
                                                { value: data.semesterDetailsId, label: data.semesterNumber }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {semesterDetailsId ? <div className='col-2 mt-3'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Section'
                                            onChange={selectSection}
                                            value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}


                                {sectionDetailsId ? <><div className='col-3 mt-3'>
                                    <FormGroup id='examName' label='Exam Name' isFloating>
                                        <Input
                                            placeholder='Enter Exam Name'
                                            onChange={editHallTicketForm.handleChange}
                                            value={editHallTicketForm.values.examName}
                                        />
                                    </FormGroup>
                                </div>
                                    <div className='col-3 mt-3'>
                                        <FormGroup id='monthName' label='Month Name ' isFloating>
                                            <Input
                                                placeholder='Enter Month Name '
                                                onChange={editHallTicketForm.handleChange}
                                                value={editHallTicketForm.values.monthName}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-1 mt-3">
                                        <Button icon='' color='primary' onClick={viewStudentList}>View</Button>
                                    </div>
                                </> : null}


                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <div className='d-flex col-7 justify-content-end d-print-none' style={{ marginLeft: "420px" }}>
                                <Button icon="Print" isLight color="primary" onClick={handlePrint} >Print</Button>
                            </div>
                        </CardHeader>

                        <CardBody ref={componentRef}>
                            {studentDetailsData.map((studentData: any) => (
                                <div key={studentData.studentDetailsId}>
                                    <table className="table table-border border border-black align-middle" style={{ fontFamily: "-moz-initial" }}>
                                    {collegeDetailsData.map((college: any) => (
                                        <thead key={college.collegeDetailsId}>
                                            <tr>
                                                <td width="15%" rowSpan={1} align="center"><img height="100px" width="100px"
                                                    src={CollegeLogo} />
                                                </td>
                                                <td>
                                                    <h2 className="text-dark text-center text-uppercase" style={{ fontFamily: '-moz-initial' }}
                                                    ><b>{college.collegeName}</b></h2>
                                                    <h2 className="text-dark text-center" style={{ fontFamily: '-moz-initial' }}><b>HALL TICKET</b></h2>
                                                </td>
                                                <td width="15%"></td>
                                            </tr>
                                        </thead>
                                    ))}
                                        <tbody className="align-middle">
                                            <tr>
                                                <td></td>
                                                <td className="text-center fs-4">
                                                    {/* <td></td> */}
                                                    <span><b>{studentData.examName} - {studentData.monthName}</b></span>
                                                    <br />
                                                    Dept. of<b> {studentData.courseName} - ({studentData.batchName})</b>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr className="align-middle">
                                                <td className="text-left border border-black align-self-center">
                                                    <div className="d-flex align-items-center mt-3">

                                                        <h5>Signature of Student</h5>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="row mt-2">
                                                        <p>
                                                        </p>
                                                    </div>
                                                </td>
                                                <td rowSpan={4} width="25%" className="text-center border border-black">
                                                    <img className="rounded-0 " src={studentData.profilePath != null ? studentData.profilePath : profilePic(studentData.genderId)}
                                                        width="100" height="100"  onError={(e : any) => pictNotLoading(e, studentData.genderId)}/>
                                                    <br />
                                                    <br />
                                                    <h3> <label className="text-center"><b>{studentData.studentName}</b></label></h3>
                                                    <h3> <label className="text-center"><b>{studentData.uniRegNo != null ? studentData.uniRegNo : studentData.admissionNo}</b></label></h3>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left border border-black">
                                                    <div className="row align-middle mt-3">
                                                        <h5>Signature of Tutor</h5>
                                                    </div>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left border border-black">
                                                    <div className="row mt-3">
                                                        <h5>Signature of H O D</h5>
                                                    </div>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left border border-black">
                                                    <div className="row mt-3">
                                                        <h5>Remarks</h5>
                                                    </div>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            ))}
                        </CardBody>
                    </Card> : null}
            </Page>
        </PageWrapper >
    )
}
export default HallTicketIssue