import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Select from '../../../components/bootstrap/forms/Select';
import PaginationButtons, {
    dataPagination,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import Icon from '../../../components/icon/Icon';
import useSelectTable from '../../../hooks/useSelectTable';
import useDarkMode from '../../../hooks/useDarkMode';
import { toasts } from '../../../services/toast.service';
import Collapse from '../../bootstrap/Collapse';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import AlertService from '../../../services/AlertService';
import { getStudentRollback, updateStudentSemester } from '../../../services/student.service';
import { getGraduationType, updateFilter } from '../../../services/common.service';
import { getBatchByGraduationTypeId, getSemester } from '../../../services/master.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { showLoader } from '../../../services/loader.services';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';

const StudentRollBack = () => {

    useEffect(() => {
        getGraduationList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [studentRollbackData, setStudentRollbackData] = useState([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)

    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])

    // Table States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10000);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentRollbackData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const { selectTable, SelectAllCheck, selectItemHandleChange } = useSelectTable(onCurrentPageData);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name
            i.admissionNo && i.admissionNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName && i.studentName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.courseName && i.courseName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.batchName && i.batchName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.semesterNumber && i.semesterNumber.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    );

    const selectGraduationType = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getBatchList(0, 0, graduationTypeId?.value)
        }
    }

    const selectBatchMaster = (e: any) => {
        setSemesterDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        selectTable.values.selectedList = [];
        getStudentRollbackList(batchMasterId?.value, semesterDetailsId?.value)
    }

    function getStudentRollbackList(batchMasterId: number, semesterDetailsId: number) {
        getStudentRollback(batchMasterId, semesterDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentRollback;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentRollbackData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].id = data[i].studentDetailsId.toString();
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                    setStudentRollbackData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsOpenListCard(false);
                    //setDataSuccess(true);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setIsOpenListCard(true);
                setDataSuccess(false);
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, batchMasterId: number, semesterDetailsId: number) {
        getBatchByGraduationTypeId(courseId, batchMasterId, semesterDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStudentSemester() {
        const studentDetailsIdArray = selectTable.values.selectedList
        return {
            batchMasterId: batchMasterId?.value,
            semesterDetailsId: semesterDetailsId?.value,
            studentDetailsId: studentDetailsIdArray,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function updateStudentSemesterSubmit() {
        showLoader(true)
        if (selectTable.values.selectedList.length > 0) {
            let studentPostData = setStudentSemester();
            updateStudentSemester(studentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        // getStudentRollbackList(batchMasterId, semesterDetailsId)
                        resetForm()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (selectTable.values.selectedList.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function resetForm() {
        setIsOpenListCard(true);
        setDataSuccess(false);
        setBatchMasterId('')
        setSemesterDetailsId('')
        setGraduationTypeId('')
        selectTable.values.selectedList = [];
    }

    return (
        <PageWrapper title={`Student Roll Back`}>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Student Roll Back
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, label: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                {graduationTypeId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='batchMasterId' label='Batch' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Batch'
                                                onChange={selectBatchMaster}
                                                value={batchMasterId}
                                                list={batchMasterData.map((data: any) => (
                                                    { value: data.batchMasterId, label: data.batchName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {batchMasterId ?
                                    <div className='col-md-3'>
                                        <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Semester'
                                                onChange={selectSemester}
                                                value={semesterDetailsId}
                                                list={semesterDetailsData.map((data: any) => (
                                                    { value: data.semesterDetailsId, label: data.semesterNumber }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {semesterDetailsId ?
                                    <div className='col-md-3'>
                                        <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>
                                    </div> : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <div className='d-flex col-md-5 justify-content-start'>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Student Roll Back List</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='d-flex col-md-7 justify-content-end'>
                                <div className='d-flex col-8 justify-content-end' data-tour='search'>
                                    <ButtonGroup className="col-lg-8 d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>
                                </div>&nbsp;&nbsp;
                                <div className='col-md-2 justify-content-center'>
                                    <Button icon='Save' color='info' isDisable={selectTable.values.selectedList.length == 0} onClick={updateStudentSemesterSubmit}>Submit</Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col'>{SelectAllCheck}</th>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('admissionNo')}
                                            className='cursor-pointer text-decoration-underline'>Admission Number{' '}
                                            <Icon size='lg' className={getClassNamesFor('admissionNo')} icon='FilterList' />
                                        </th>

                                        <th scope='col' onClick={() => requestSort('studentName')}
                                            className='cursor-pointer text-decoration-underline'>Student Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                        </th>
                                        <th scope='col' onClick={() => requestSort('studentName')}
                                            className='cursor-pointer text-decoration-underline'>Course Name{' '}
                                            <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                        </th>
                                        <th>Batch</th>
                                        <th>Semester Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length != 0 ?
                                        <>{filteredData.map((i: any) => (<tr key={i.studentDetailsId}><td><Checks
                                            id={i.studentDetailsId.toString()}
                                            name='selectedList'
                                            value={i.studentDetailsId}
                                            onChange={selectTable.handleChange}
                                            checked={selectTable.values.selectedList.includes(
                                                // @ts-ignore
                                                i.studentDetailsId.toString(),
                                            )}
                                        /></td><td>{i.sno}</td><td>{i.admissionNo}</td><td>{i.studentName}</td><td>{i.courseName}</td><td>{i.batchName}</td><td>{i.semesterNumber}</td></tr>))}</>
                                        :
                                        <NoDataMsg columnsCount={7} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        </CardBody>
                        {/* <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        /> */}
                    </Card> : null
                }
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default StudentRollBack;
