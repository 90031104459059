import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';
import AuthContext from '../../contexts/authContext';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title mt-4'>
					<Link to={userTypeId == 10 ? '../academic/studentFeeDetails' : '/dashboard'} aria-label='Logo'>
						<Logo height={65} width={65}/>
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
