import React, { FC } from 'react'
import Alert, { AlertHeading } from '../components/bootstrap/Alert'
import Modal from '../components/bootstrap/Modal'
import useEventListener from '../hooks/useEventListener';


interface IModalProps {
    isOpen: boolean;
    alertStatus: any;
    setIsOpen(...args: unknown[]): unknown;
    isNavigate?: any | undefined;
}


const AlertService: FC<IModalProps> = ({ setIsOpen, isOpen, alertStatus, isNavigate }) => {

    // For Mobile view alert close
    function closeModal() {
        setIsOpen(false)
    }

    useEventListener('mousedown', closeModal);
    useEventListener('touchstart', closeModal);
    useEventListener('touchmove', closeModal);
    useEventListener('touchend', closeModal);

    return (
        <>
            {alertStatus.type == 'success' ?
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} titleId='addAcademicPeriod' isCentered fullScreen='md' isAnimation={false} isNavigate={isNavigate}>
                    <></>
                    <Alert style={{ marginBottom: "0px" }} color='success' shadow='md' isLight icon='Recommend' className='flex-nowrap'>
                        <AlertHeading>Well Done!</AlertHeading>
                        <p>{alertStatus.message}</p>
                    </Alert>
                </Modal>
                : alertStatus.type == 'warning' ?
                    <Modal isOpen={isOpen} setIsOpen={setIsOpen} titleId='addAcademicPeriod' isCentered fullScreen='md' isAnimation={false}>
                        <></>
                        <Alert style={{ marginBottom: "0px" }} color='danger' shadow='md' isLight icon='Report' className='flex-nowrap'>
                            <AlertHeading>Warning!</AlertHeading>
                            <p>{alertStatus.message}</p>
                        </Alert>
                    </Modal>
                    :
                    <Modal isOpen={isOpen} setIsOpen={setIsOpen} titleId='addAcademicPeriod' isCentered fullScreen='md' isAnimation={false}>
                        <></>
                        <Alert style={{ marginBottom: "0px" }} color='danger' shadow='md' isLight icon='Report' className='flex-nowrap'>
                            <AlertHeading>Error!</AlertHeading>
                            <p>{alertStatus.message}</p>
                        </Alert>
                    </Modal>
            }
        </>
    )
}

export default AlertService