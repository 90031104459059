import { useEffect, useState } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import Button from "../../../bootstrap/Button";
import Collapse from "../../../bootstrap/Collapse";
import { toasts } from "../../../../services/toast.service";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import { getVehicleDetails, getVehicleDetailsbyVehicleDetailsId } from "../../../../services/transport.service";
import SearchableSelect from "../../../../common/components/SearchableSelect";

function VehicleScannedDocument() {
    useEffect(() => {
        getVehicleDetailsList();
    }, [])

    const [isOpenListCard, setIsOpenListCard] = useState(true)


    const [vehicleDetailsId, setVehicleDetailsId] = useState<any>([])
    const [vehicleDetailsData, setVehicleDetailsData] = useState<any>([])
    const [vehicleDetails, setVehicleDetails] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)

    const [isLoader, setIsLoader] = useState(false)

    const selectVehicleDetails = (e: any) => {
        let vehicleDetailsId = e
        setVehicleDetailsId(vehicleDetailsId)
    }

    function view() {
        setIsLoader(true)
        setDataSuccess(false)
        getVehicleDetailsbyVehicleDetailsList(vehicleDetailsId?.value)
      }

    function getVehicleDetailsList() {
        getVehicleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails;
                    if (data != undefined) {
                        setVehicleDetails(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setVehicleDetails([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getVehicleDetailsbyVehicleDetailsList(vehicleDetailsId: any) {
        getVehicleDetailsbyVehicleDetailsId(vehicleDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.vehicleDetails[0];
                    if (data != undefined) {
                        setVehicleDetailsData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true)
                        setIsLoader(false)
                    } else {
                        toasts("Undefined Data", "Error")
                        setIsLoader(false)
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setIsLoader(false)
                    setIsOpenListCard(true);
                }
            }, error => {
                toasts(error, "Error")
                setIsLoader(false)
            }
        )
    }

    const [rows, setRows] = useState<any>([{ id: Date.now() }]);

    const addRow = () => {
        const newRow = { id: Date.now() };
        setRows([...rows, newRow]);
    };

    const deleteRow = (id: number) => {
        setRows(rows.filter((row: any) => row.id !== id));
    };

    return (
        <>
            <PageWrapper title="Vehicle Scanned Document">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Vehicle Scanned Document
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row'>
                                    <div className='col-4'>
                                        <FormGroup id='vehicleDetailsData' label='Vehicle' isFloating>
                                            <SearchableSelect isFloating ariaLabel=""
                                                onChange={selectVehicleDetails}
                                                value={vehicleDetailsId}
                                                list={vehicleDetails.map((data: any) => (
                                                    { value: data.vehicleDetailsId, label: data.vehicleId }
                                                ))} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-3 mt-2">
                                        <Button icon='ArrowDownward' color='primary' isDisable={vehicleDetailsId?.value == undefined} onClick={view}>View</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <div>
                            <Card stretch data-tour='list'>
                                <CardHeader borderSize={1}>
                                    <CardLabel>
                                        <CardTitle>FILE UPLOAD</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    {/* <div className="row">
                                        <div className="col-lg-4">
                                            <FormGroup id="documentName">
                                                <Input type='text' placeholder="Enter Document Name" />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <Input type='file' autoComplete='photo' ariaLabel='Upload image file' />
                                        </div>
                                        <div className='col-lg-2'>
                                            <Button
                                                color='info'
                                                isLight
                                                rounded={1}
                                                icon='CloudUpload'>Upload
                                            </Button>
                                        </div>
                                    </div> */}

                                    <div className="row col-12">
                                        {rows.map((row: any) => (
                                            <div key={row.id} className="col-lg-10 row mt-4">
                                                <div className="col-lg-4">
                                                    <FormGroup id="documentName">
                                                        <Input type='text' placeholder="Enter Document Name" />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-lg-4">
                                                    <Input type='file' autoComplete='photo' ariaLabel='Upload image file' />
                                                </div>
                                                <div className='col-lg-2'>
                                                    <Button
                                                        color='info'
                                                        isLight
                                                        rounded={1}
                                                        icon='CloudUpload'>Upload
                                                    </Button>
                                                </div>
                                                <div className='col-lg-2'>
                                                    {rows.length > 1 && (
                                                        <Button color='danger' isLight rounded={1} icon='Delete' onClick={() => deleteRow(row.id)} />
                                                    )}
                                                </div>

                                            </div>
                                        ))}
                                        <div className='col-lg-2 mt-4'>
                                            <Button color='info' isLight rounded={1} icon='Add' onClick={addRow}>
                                                Add
                                            </Button>
                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                            <Card stretch data-tour='list'>
                                <CardHeader borderSize={1}>
                                    <CardLabel>
                                        <CardTitle>Document</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                            </Card>
                        </div> : null}
                </Page>
            </PageWrapper>
        </>
    )
}
export default VehicleScannedDocument;