import service from "../api/api.services";
import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getTrustList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getTrustList/${getLicenseKey}`,
    response => {
        success(response)
    },
    error => {
        failure("getTrustList - " + error.message)
        console.log('error caught in service : getTrustList')
    },
);

export const getCollegeDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getCollegeDetails/${getLicenseKey}`,
    response => {
        success(response)
    },
    error => {
        failure("getCollegeDetails - " + error.message)
        console.log('error caught in service : getCollegeDetails')
    },
);

export const updateCollegeDetails = (updateCollegeDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.post(getAPIURL() + `updateCollegeDetails`, updateCollegeDetailsPostData,
    response => {
        success(response)
    },
    error => {
        failure(error.message)
        console.log('error caught in service : updateCollegeDetails')
    }
);