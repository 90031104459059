import { useFormik } from "formik"
import { useState, useEffect } from "react"
import Page from "../../../layout/Page/Page"
import { toasts } from "../../../services/toast.service"
import Button from "../../bootstrap/Button"
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from "../../bootstrap/Card"
import FormGroup from "../../bootstrap/forms/FormGroup"
import Select from "../../bootstrap/forms/Select"
import Input from "../../bootstrap/forms/Input"
import Collapse from "../../bootstrap/Collapse"
import Icon from "../../icon/Icon"
import { getGraduationType, getSectionDetails } from "../../../services/common.service"
import { getBatchByCourseId, getCourse, getDepartmentList } from "../../../services/master.service"

function StudentExamAttendence(props: any) {

    const [isVisible, setIsVisible] = useState(true)
    const [isVisible1, setIsVisible1] = useState(false)

    const [departmentId, setDepartmentId] = useState('')
    const [courseId, setCourseId] = useState('')
    const [batchMasterId, setBatchMasterId] = useState('')
    const [semesterDetailsId, setSemesterDetailsId] = useState('')
    const [sectionDetailsId, setSectionDetailsId] = useState('')
    const [graduationTypeId, setGraduationTypeId] = useState('')
    const [studentDetailsId, setStudentDetailsId] = useState('')

    const [departmentData, setDepartmentData] = useState([])
    const [courseData, setCourseData] = useState([])
    const [graduationTypeData, setGraduationTypeData] = useState([])
    const [batchMasterData, setBatchMasterData] = useState([])
    const [semesterDetailsData, setSemesterDetailsData] = useState([])
    const [sectionDetailsData, setSectionDetailsData] = useState([])
    const [studentDetailsData, setStudentDetailsData] = useState([])
    const [allStudentData, setAllStudentData] = useState([])
    const [isPageContent, setIsPageContent] = useState('')

    const [isOpenListCard, setIsOpenListCard] = useState(true)

    useEffect(() => {
        // getGraduationType();
        getDepartment();
        // getSectionDetails();
        // getStudentDetails();
    }, [])


    const selectionForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectDepartment = (e: any) => {
        let departmentId = e.target.value
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e.target.value
        setGraduationTypeId(graduationTypeId)
        getCourseList(departmentId, graduationTypeId)
    }

    const selectCourse = (e: any) => {
        let courseId = e.target.value
        setCourseId(courseId)
        getBatchList(courseId, graduationTypeId)
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e.target.value
        setBatchMasterId(batchMasterId)
        // getSem(batchMasterId)
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e.target.value
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e.target.value
        setSectionDetailsId(sectionDetailsId)
        // getStudentListBySectionId(courseId, semesterDetailsId, sectionDetailsId)
    }

    const selectStudent = (e: any) => {
        let studentDetailsId = e.target.value
        setStudentDetailsId(studentDetailsId)
    }

    const studentList = () => {
        props.getSelectionValue(departmentId, courseId, batchMasterId, semesterDetailsId, sectionDetailsId)
    }

    const getParticularStudentDetails = () => {
        props.getSelectionValue(studentDetailsId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    // function getStudentListBySectionId(courseId: string, semesterDetailsId: string, sectionDetailsId: string) {
    //     console.log("Inside getStudentDgetStudentListBySectionIdetails");
    //     axios.get(apiUrl + `getStudentDetailsBySemesterId/5566/` + courseId + '/' + semesterDetailsId + '/' + sectionDetailsId)
    //         .then((response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.studentDetailsBySemesterId;
    //                 console.log("getStudentDetails: " + data);
    //                 if (data != undefined) {
    //                     setStudentDetailsData(data);
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 toasts(response.data.message, "Error")
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         });
    // }

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Set the current date as the default value
    const [selectedDate, setSelectedDate] = useState<string>(getCurrentDate());

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(event.target.value);
    };

    return (
        <Page>
            <Collapse isOpen={isOpenListCard}>
                <Card stretch data-tour='List'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-6">
                            <CardTitle tag='div' className='h5'>Student Exam Attendance</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>

                        <div className="row g-4">
                            <div className='col-lg-3'>
                                <FormGroup id='departmentId' label='Department' isFloating>
                                    <Select
                                        ariaLabel='Department'
                                        onChange={selectDepartment}
                                        value={departmentId}
                                        list={departmentData.map((data: any) => (
                                            { value: data.departmentId, text: data.departmentName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            {departmentId ? <div className='col-lg-3'>
                                <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                    <Select
                                        ariaLabel='Graduation Type'
                                        onChange={selectGraduationType}
                                        value={graduationTypeId}
                                        list={graduationTypeData.map((data: any) => (
                                            { value: data.graduationTypeId, text: data.graduationType }
                                        ))}
                                    />
                                </FormGroup>
                            </div> : null}

                            {graduationTypeId ? <div className='col-lg-3'>
                                <FormGroup id='courseId' label='Course' isFloating>
                                    <Select
                                        ariaLabel='Course'
                                        onChange={selectCourse}
                                        value={courseId}
                                        list={courseData.map((data: any) => (
                                            { value: data.courseId, text: data.courseName }
                                        ))}
                                    />
                                </FormGroup>
                            </div> : null}

                            {courseId ? <div className='col-lg-3'>
                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                    <Select
                                        ariaLabel='Batch'
                                        onChange={selectBatch}
                                        value={batchMasterId}
                                        list={batchMasterData.map((data: any) => (
                                            { value: data.batchMasterId, text: data.batchName }
                                        ))}
                                    />
                                </FormGroup>
                            </div> : null}

                            {batchMasterId ? <div className='col-lg-3 '>
                                <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                    <Select
                                        ariaLabel='Semester'
                                        onChange={selectSemester}
                                        value={semesterDetailsId}
                                        list={semesterDetailsData.map((data: any) => (
                                            { value: data.semesterDetailsId, text: data.semesterNumber }
                                        ))}
                                    />
                                </FormGroup>
                            </div> : null}

                            {semesterDetailsId ? <div className='col-lg-3 '>
                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                    <Select
                                        ariaLabel='Section'
                                        onChange={selectSection}
                                        value={sectionDetailsId}
                                        list={sectionDetailsData.map((data: any) => (
                                            { value: data.sectionDetailsId, text: data.sectionName }
                                        ))}
                                    />
                                </FormGroup>
                            </div> : null}

                            {semesterDetailsId ?
                                <div className="col-lg-3">
                                    <FormGroup id="date" label="Date" isFloating>
                                        <Input type="date" value={selectedDate} defaultValue={selectedDate} onChange={handleDateChange} />
                                    </FormGroup>
                                </div> : null}

                            {sectionDetailsId ? <div className="col-lg-3 ">
                                <FormGroup id='exam' label='Exam' isFloating>
                                    <Select
                                        ariaLabel='Exam'
                                        onChange={selectStudent}
                                        value={studentDetailsId}
                                        list={studentDetailsData.map((data: any) => (
                                            { value: data.studentDetailsId, text: data.studentNameAndAdmissionNo }
                                        ))}
                                    />
                                </FormGroup>
                            </div> : null}

                            {studentDetailsId ? <div className="col-lg-3 justify-content-evenly" >
                                <Button icon='ArrowDownward' color='primary' style={{ marginRight: '4px' }} onClick={getParticularStudentDetails}>View</Button>
                                <Button icon='ArrowDownward' color='primary' onClick={getParticularStudentDetails}>Report</Button>
                            </div> : null}

                        </div>

                    </CardBody>
                </Card>
            </Collapse>
            {
                studentDetailsData ?
                    <div className='row justify-content-center'>
                        < div className='col-5 justify-content-center' ></div >
                        <div className='col-6 justify-content-center'>
                            {isOpenListCard ?
                                <Icon icon='ExpandLess' className='mb-0 text-dark h2' onClick={() => setIsOpenListCard(!isOpenListCard)} /> : <Icon icon='ExpandMore' className='mb-0 text-dark h2' onClick={() => setIsOpenListCard(!isOpenListCard)} />}
                        </div>
                    </div >
                    : null
            }
        </Page>
    )
}
export default StudentExamAttendence;