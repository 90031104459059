import { FC, useEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import GeneralInfo from "./add-student-components/GeneralInfo";
import AddressInfo from "./add-student-components/AddressInfo";
import ClassInfo from "./add-student-components/ClassInfo";
import FamilyInfo from "./add-student-components/FamilyInfo";
import MarkInfo from "./add-student-components/MarkInfo";
import CertificateInfo from "./add-student-components/CertificateInfo";
import AdmissionInfo from "./add-student-components/AdmissionInfo";



//const AddStudentPage: FC<> = ( onSubmit) => {

function AddStudentPage() {
    const [step, setStep] = useState<number>(1);
    //const [totalSteps, setTotalSteps] = useState<number>(3);
    const totalSteps: number = 7;

    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleSubmit = () => {
        alert('Wizard completed!');
    };

    const handleNext1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (step < totalSteps) {
            setStep(step + 1);
            event.preventDefault();
        }
    };

    const studentPostData = {
        graduationTypeId: "",
        admissionNo: "",
        tempNo: "",
        firstName: "",
        lastName: "",
        surName: "",
        studentNameTamil: "",
        surNameTamil: "",
        dateOfBirth: "",
        genderId: "",
        bloodGroupId: "",
        motherTongue: "",
        nationality: "",
        otherNation: "",
        religionId: "",
        casteUniqueId: "",
        casteDetailsId: "",
        subcaste: "",
        category: "",
        aadharCard: "",
        physicallyChallenged: "",
        physicallyChallengedType: "",
        physicallyChallengedPercentage: "",
        martialStatus: "",
        email: "",
        emisNo: "",
        commAddr_FlatNo: "",
        commAddr_Street: "",
        commAddr_Area: "",
        commAddr_StateId: "",
        commAddr_CityId: "",
        commAddr_Pincode: "",
        permAddr_FlatNo: "",
        permAddr_Street: "",
        permAddr_Area: "",
        permAddr_StateId: "",
        permAddr_CityId: "",
        permAddr_Pincode: "",
        bankName: "",
        bankAccountNo: "",
        branch: "",
        ifscCode: "",
        micrCode: "",
        departmentId: "",
        courseId: "",
        batchMasterId: "",
        semesterDetailsId: "",
        sectionDetailsId: "",
        shiftId: "",
        studentRollNo: "",
        admissionDateStamp: "",
        sourceName: "",
        transferCourseId: "",
        referralTypeId: "",
        referredId: "",
        referredName: "",
        interimCollegeTransfer: "",
        collegeName: "",
        concessionTypeId: "",
        concessionRemarks: "",
        transportAreaMasterId: "",
        modeOfWays: "",
        isCollegeTransport: "",
        fatherName: "",
        father_AdharNumber: "",
        fatherOccupation: "",
        natureofJoborBusiness: "",
        fatherContactNumber: "",
        fatherEmailId: "",
        motherName: "",
        mother_AdharNumber: "",
        yearlyIncome: "",
        positionHeld: "",
        motherContactNumber: "",
        smsToBeSentNumber: "",
        guardian_Name: "",
        guardian_email: "",
        guardian_phone: "",
        guardian_AdharNumber: "",
        isGuardian: "",
        registerNo_X: "",
        institutionNameAndAddress_X: "",
        boardName_X: "",
        mediumOfInstitution_X: "",
        monthAndYearOfPassing_X: "",
        registerNo_XI: "",
        institutionNameAndAddress_XI: "",
        boardName_XI: "",
        mediumOfInstitution_XI: "",
        monthAndYearOfPassing_XI: "",
        registerNo_XII: "",
        XIISchoolNameId: "",
        boardName_XII: "",
        mediumOfInstitution_XII: "",
        monthAndYearOfPassing_XII: "",
        registerNo_UG: "",
        institutionNameAndAddress_UG: "",
        universityName_UG: "",
        mediumOfInstitution_UG: "",
        monthAndYearOfPassing_UG: "",
        registerNo_PG: "",
        institutionNameAndAddress_PG: "",
        universityName_PG: "",
        mediumOfInstitution_PG: "",
        monthAndYearOfPassing_PG: "",
        registerNo_MPhil: "",
        institutionNameAndAddress_MPhil: "",
        universityName_MPhil: "",
        mediumOfInstitution_MPhil: "",
        monthAndYearOfPassing_MPhil: "",
        XI_S1_studentSchoolSubject: "",
        XI_S1_markSecured: "",
        XI_S1_maximumMarks: "",
        XI_S1_monthAndYearOfPassing: "",
        XI_S1_registerNo: "",
        XI_S1_noOfAttempts: "",
        XI_S2_studentSchoolSubject: "",
        XI_S2_markSecured: "",
        XI_S2_maximumMarks: "",
        XI_S2_monthAndYearOfPassing: "",
        XI_S2_registerNo: "",
        XI_S2_noOfAttempts: "",
        XI_S3_studentSchoolSubject: "",
        XI_S3_markSecured: "",
        XI_S3_maximumMarks: "",
        XI_S3_monthAndYearOfPassing: "",
        XI_S3_registerNo: "",
        XI_S3_noOfAttempts: "",
        XI_S4_studentSchoolSubject: "",
        XI_S4_markSecured: "",
        XI_S4_maximumMarks: "",
        XI_S4_monthAndYearOfPassing: "",
        XI_S4_registerNo: "",
        XI_S4_noOfAttempts: "",
        XI_S5_studentSchoolSubject: "",
        XI_S5_markSecured: "",
        XI_S5_maximumMarks: "",
        XI_S5_monthAndYearOfPassing: "",
        XI_S5_registerNo: "",
        XI_S5_noOfAttempts: "",
        XI_S6_studentSchoolSubject: "",
        XI_S6_markSecured: "",
        XI_S6_maximumMarks: "",
        XI_S6_monthAndYearOfPassing: "",
        XI_S6_registerNo: "",
        XI_S6_noOfAttempts: "",
        XII_S1_studentSchoolSubject: "",
        XII_S1_markSecured: "",
        XII_S1_maximumMarks: "",
        XII_S1_monthAndYearOfPassing: "",
        XII_S1_registerNo: "",
        XII_S1_noOfAttempts: "",
        XII_S2_studentSchoolSubject: "",
        XII_S2_markSecured: "",
        XII_S2_maximumMarks: "",
        XII_S2_monthAndYearOfPassing: "",
        XII_S2_registerNo: "",
        XII_S2_noOfAttempts: "",
        XII_S3_studentSchoolSubject: "",
        XII_S3_markSecured: "",
        XII_S3_maximumMarks: "",
        XII_S3_monthAndYearOfPassing: "",
        XII_S3_registerNo: "",
        XII_S3_noOfAttempts: "",
        XII_S4_studentSchoolSubject: "",
        XII_S4_markSecured: "",
        XII_S4_maximumMarks: "",
        XII_S4_monthAndYearOfPassing: "",
        XII_S4_registerNo: "",
        XII_S4_noOfAttempts: "",
        XII_S5_studentSchoolSubject: "",
        XII_S5_markSecured: "",
        XII_S5_maximumMarks: "",
        XII_S5_monthAndYearOfPassing: "",
        XII_S5_registerNo: "",
        XII_S5_noOfAttempts: "",
        XII_S6_studentSchoolSubject: "",
        XII_S6_markSecured: "",
        XII_S6_maximumMarks: "",
        XII_S6_monthAndYearOfPassing: "",
        XII_S6_registerNo: "",
        XII_S6_noOfAttempts: "",
        XI_OverAll_markSecured: "",
        XI_OverAll_maximumMarks: "",
        XII_OverAll_markSecured: "",
        XII_OverAll_maximumMarks: "",
        UG_courseName: "",
        UG_Part1_SubjectName: "",
        UG_Part1_MarksObtained: "",
        UG_Part1_MaximumMarks: "",
        UG_Part1_Percentage: "",
        UG_Part1_ClassObtained: "",
        UG_Part1_NoOfAttempts: "",
        UG_Part1_RegisterNo: "",
        UG_Part1_Passing: "",
        UG_Part2_SubjectName: "",
        UG_Part2_MarksObtained: "",
        UG_Part2_MaximumMarks: "",
        UG_Part2_Percentage: "",
        UG_Part2_ClassObtained: "",
        UG_Part2_NoOfAttempts: "",
        UG_Part2_RegisterNo: "",
        UG_Part2_Passing: "",
        UG_Part3_SubjectName: "",
        UG_Part3_MarksObtained: "",
        UG_Part3_MaximumMarks: "",
        UG_Part3_Percentage: "",
        UG_Part3_ClassObtained: "",
        UG_Part3_NoOfAttempts: "",
        UG_Part3_RegisterNo: "",
        UG_Part3_Passing: "",
        UG_Allied1_SubjectName: "",
        UG_Allied1_MarksObtained: "",
        UG_Allied1_MaximumMarks: "",
        UG_Allied1_Percentage: "",
        UG_Allied1_ClassObtained: "",
        UG_Allied1_NoOfAttempts: "",
        UG_Allied1_RegisterNo: "",
        UG_Allied1_Passing: "",
        UG_Allied2_SubjectName: "",
        UG_Allied2_MarksObtained: "",
        UG_Allied2_MaximumMarks: "",
        UG_Allied2_Percentage: "",
        UG_Allied2_ClassObtained: "",
        UG_Allied2_NoOfAttempts: "",
        UG_Allied2_RegisterNo: "",
        UG_Allied2_Passing: "",
        UG_Overall_Main_Core: "",
        UG_Overall_Ancy_Allied: "",
        UG_Overall_Part3: "",
        PG_courseName: "",
        PG_Part1_SubjectName: "",
        PG_Part1_MarksObtained: "",
        PG_Part1_MaximumMarks: "",
        PG_Part1_Percentage: "",
        PG_Part1_ClassObtained: "",
        PG_Part1_NoOfAttempts: "",
        PG_Part1_RegisterNo: "",
        PG_Part1_Passing: "",
        PG_Part2_SubjectName: "",
        PG_Part2_MarksObtained: "",
        PG_Part2_MaximumMarks: "",
        PG_Part2_Percentage: "",
        PG_Part2_ClassObtained: "",
        PG_Part2_NoOfAttempts: "",
        PG_Part2_RegisterNo: "",
        PG_Part2_Passing: "",
        PG_Part3_SubjectName: "",
        PG_Part3_MarksObtained: "",
        PG_Part3_MaximumMarks: "",
        PG_Part3_Percentage: "",
        PG_Part3_ClassObtained: "",
        PG_Part3_NoOfAttempts: "",
        PG_Part3_RegisterNo: "",
        PG_Part3_Passing: "",
        PG_Allied1_SubjectName: "",
        PG_Allied1_MarksObtained: "",
        PG_Allied1_MaximumMarks: "",
        PG_Allied1_Percentage: "",
        PG_Allied1_ClassObtained: "",
        PG_Allied1_NoOfAttempts: "",
        PG_Allied1_RegisterNo: "",
        PG_Allied1_Passing: "",
        PG_Allied2_SubjectName: "",
        PG_Allied2_MarksObtained: "",
        PG_Allied2_MaximumMarks: "",
        PG_Allied2_Percentage: "",
        PG_Allied2_ClassObtained: "",
        PG_Allied2_NoOfAttempts: "",
        PG_Allied2_RegisterNo: "",
        PG_Allied2_Passing: "",
        PG_Overall_Main_Core: "",
        PG_Overall_Ancy_Allied: "",
        PG_Overall_Part3: "",
        MPhil_courseName: "",
        MPhil_Part1_SubjectName: "",
        MPhil_Part1_MarksObtained: "",
        MPhil_Part1_MaximumMarks: "",
        MPhil_Part1_Percentage: "",
        MPhil_Part1_ClassObtained: "",
        MPhil_Part1_NoOfAttempts: "",
        MPhil_Part1_RegisterNo: "",
        MPhil_Part1_Passing: "",
        MPhil_Part2_SubjectName: "",
        MPhil_Part2_MarksObtained: "",
        MPhil_Part2_MaximumMarks: "",
        MPhil_Part2_Percentage: "",
        MPhil_Part2_ClassObtained: "",
        MPhil_Part2_NoOfAttempts: "",
        MPhil_Part2_RegisterNo: "",
        MPhil_Part2_Passing: "",
        MPhil_Part3_SubjectName: "",
        MPhil_Part3_MarksObtained: "",
        MPhil_Part3_MaximumMarks: "",
        MPhil_Part3_Percentage: "",
        MPhil_Part3_ClassObtained: "",
        MPhil_Part3_NoOfAttempts: "",
        MPhil_Part3_RegisterNo: "",
        MPhil_Part3_Passing: "",
        MPhil_Allied1_SubjectName: "",
        MPhil_Allied1_MarksObtained: "",
        MPhil_Allied1_MaximumMarks: "",
        MPhil_Allied1_Percentage: "",
        MPhil_Allied1_ClassObtained: "",
        MPhil_Allied1_NoOfAttempts: "",
        MPhil_Allied1_RegisterNo: "",
        MPhil_Allied1_Passing: "",
        MPhil_Allied2_SubjectName: "",
        MPhil_Allied2_MarksObtained: "",
        MPhil_Allied2_MaximumMarks: "",
        MPhil_Allied2_Percentage: "",
        MPhil_Allied2_ClassObtained: "",
        MPhil_Allied2_NoOfAttempts: "",
        MPhil_Allied2_RegisterNo: "",
        MPhil_Allied2_Passing: "",
        MPhil_Overall_Main_Core: "",
        MPhil_Overall_Ancy_Allied: "",
        MPhil_Overall_Part3: "",
        quotaTypeId: "",
        discription: "",
        isCitizenOfIndia: "",
        isClaimingNriQuotaSeat: "",
        isMedicalCertificateEnclosed: "",
        extraCurricularActivities: "",
        certificateTypeId: [],
        submittedDate: "",
        status: "",
        scholarshipTypeId: [],
        assignedBy: "",
        licenseKey: "",
        createdBy: "",
        userAccountId: ""

    }
    const [selectedValue, setSelectedValue] = useState<any>(studentPostData);

    console.log(selectedValue);

    const handleSelect = (value: any) => {
        setSelectedValue(value);
    };

    return (
        <PageWrapper>
            <Page container='fluid'>
                <div style={{ margin: '0 auto' }} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        {[...Array(totalSteps)].map((steps, index) => (
                            <>
                                <div key={steps}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        border: '2px solid ',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        color: index + 1 === step ? 'white' : 'blue',
                                        backgroundColor: index + 1 === step ? 'blue' : 'transparent',
                                        zIndex: 1
                                    }}
                                >
                                    {index + 1}
                                </div>
                                {index < totalSteps - 1 && (
                                    <div
                                        style={{
                                            flex: '1',
                                            height: '4px',
                                            backgroundColor: step > index + 1 ? 'blue' : 'lightgray',
                                            margin: '0 5px'
                                        }}
                                    ></div>
                                )}
                            </>
                        ))}
                    </div>

                    <div style={{ marginBottom: '20px' }} >
                        {step == 1 && (
                            <div>
                                <GeneralInfo handleNext1={handleNext1} setSelectedValue={handleSelect} selectedValue={selectedValue} />
                            </div>
                        )}
                        {step == 2 && (
                            <AddressInfo handleNext1={handleNext} handlePrevious={handlePrevious} setSelectedValue={handleSelect} selectedValue={selectedValue} />
                        )}
                        {step == 3 && (
                            <ClassInfo handleNext1={handleNext} handlePrevious={handlePrevious} setSelectedValue={handleSelect} selectedValue={selectedValue} />
                        )}
                        {step == 4 && (
                            <FamilyInfo handleNext1={handleNext} handlePrevious={handlePrevious} setSelectedValue={handleSelect} selectedValue={selectedValue} />
                        )}
                        {step == 5 && (
                            <MarkInfo handleNext1={handleNext} handlePrevious={handlePrevious} setSelectedValue={handleSelect} selectedValue={selectedValue} />
                        )}
                        {step == 6 && (
                            <CertificateInfo handleNext1={handleNext} handlePrevious={handlePrevious} setSelectedValue={handleSelect} selectedValue={selectedValue} />
                        )}
                        {step == 7 && (
                            <AdmissionInfo handlesubmit1={handleSubmit} handlePrevious={handlePrevious} />
                        )}

                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        // {/* <Button onClick={handlePrevious} hidden={step == 1} color="danger">Previous</Button> 
                        <div>
                            {step === totalSteps ? (
                                <Button onClick={handleSubmit} color="success" type="submit">Submit</Button>
                            ) : (
                                // <Button onClick={handleNext} color="info" type="submit">Next</Button>
                                <></>
                            )}
                        </div> 
                    </div>*/}


                </div>

            </Page>
        </PageWrapper >
    )
}
export default AddStudentPage;