import { useContext, useEffect, useState } from "react"
import Page from "../../layout/Page/Page"
import PageWrapper from "../../layout/PageWrapper/PageWrapper"
import { toasts } from "../../services/toast.service"
import Button, { ButtonGroup } from "../bootstrap/Button"
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from "../bootstrap/Card"
import FormGroup from "../bootstrap/forms/FormGroup"
import Select from "../bootstrap/forms/Select"
import Input from "../bootstrap/forms/Input"
import { getStudentListByGenderId } from "../../services/student.service"
import Checks from "../bootstrap/forms/Checks"
import Label from "../bootstrap/forms/Label"
import { addTournamentParticipants, getTournamentDetailsListByTournamentDetailsId } from "../../services/sportsManagement.service"
import AlertService from "../../services/AlertService"
import { getLicenseKey } from "../../services/application.settings"
import AuthContext from "../../contexts/authContext"
import { useNavigate, useParams } from "react-router-dom"
import Icon from "../icon/Icon"
import { showLoader } from "../../services/loader.services"
import SearchableSelect from "../../common/components/SearchableSelect"
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../layout/SubHeader/SubHeader"

const AddParticipant = () => {

  const navigate = useNavigate();
  const { tournamentDetailsId } = useParams();

  useEffect(() => {
    getTournamentDetails(tournamentDetailsId)
  }, [])

  const [studentListByGenderData, setStudentListByGenderData] = useState([]);
  const [tournamentDetailsData, setTournamentDetailsData] = useState<any>([]);
  const [noOfPlayerData, setNoOfPlayerData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
  const [isNavigate, setIsNavigate] = useState<any>(null);
  const { userAccountId, userTypeId } = useContext(AuthContext);
  const [playerData, setPlayerData] = useState<any>([]);
  const [submitCount, setSubmitCount] = useState<any>('');


  const addParticipantsDetails = (noOfPlayer: any, studentDetailsId: any, isChecked: any) => {
    let selStu = playerData.filter((item: any) => item.studentDetailsId == studentDetailsId?.value && item.noOfPlayer != noOfPlayer)

    const newData1: any = [];
    inputFields.forEach((item: any) => {
      if (item['noOfPlayer'] == noOfPlayer) {
        item['studentDetailsId'] = studentDetailsId?.value
        item['studentDetailsIdForShow'] = studentDetailsId
        item['isSubstitute'] = isChecked
        item['selectedStudent'] = selStu != '' ? true : false
      }
      newData1.push(item)
    });
    setInputFields(newData1)

    const newData: any = [];
    noOfPlayerData.forEach((item: any) => {
      if (item['noOfPlayer'] == noOfPlayer) {
        item['studentDetailsId'] = studentDetailsId?.value
        item['studentDetailsIdForShow'] = studentDetailsId
        item['isSubstitute'] = isChecked
        item['selectedStudent'] = selStu != '' ? true : false
      }
      newData.push(item)
    });
    setNoOfPlayerData(newData)

    if (playerData.length == 0) {
      var obj
      obj = {
        noOfPlayer: noOfPlayer,
        studentDetailsId: studentDetailsId?.value,
        isSubstitute: isChecked ? 1 : 0,
        selectedStudent: false,

      };
      playerData.push(obj);
    } else {
      let selectedItem: any
      playerData.forEach((item: any) => {
        if (item['noOfPlayer'] == noOfPlayer) {
          selectedItem = item
        }
      });
      if (selectedItem != undefined) {
        selectedItem['studentDetailsId'] = studentDetailsId?.value
        selectedItem['isSubstitute'] = isChecked ? 1 : 0
        selectedItem['noOfPlayer'] = noOfPlayer
        selectedItem['selectedStudent'] = selStu != '' ? true : false
      }
      else {

        var obj
        obj = {
          noOfPlayer: noOfPlayer,
          studentDetailsId: studentDetailsId?.value,
          isSubstitute: isChecked ? 1 : 0,
          selectedStudent: selStu != '' ? true : false,
        };
        playerData.push(obj);
      }
    }
  }

  function getStudentListByGender(genderId: any) {
    getStudentListByGenderId(genderId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.studentListByGenderId;
          if (data != undefined) {
            setStudentListByGenderData(data);
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getTournamentDetails(tournamentDetailsId: any) {
    getTournamentDetailsListByTournamentDetailsId(tournamentDetailsId,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.tournamentDetailsList;
          if (data != undefined) {
            setTournamentDetailsData(data[0]);
            let genderId = data[0].genderId
            getStudentListByGender(genderId)
            setPlayerCount(data[0].totalMember);
            setInputFields([])
            let participantsList = data[0].participantsList

            let noOfPlayer = []
            if (participantsList != '') {
              for (let i = 0; i < data[0].totalMember; i++) {
                noOfPlayer.push({ noOfPlayer: i + 1, studentDetailsId: participantsList[i].studentDetailsId, studentDetailsIdForShow: { value: participantsList[i].studentDetailsId, label: participantsList[i].studentName }, isSubstitute: participantsList[i].isSubstitute ? 1 : 0, selectedStudent: false })
              }
            } else {
              for (let i = 0; i < data[0].totalMember; i++) {
                noOfPlayer.push({ noOfPlayer: i + 1, studentDetailsId: '', studentDetailsIdForShow: '', isSubstitute: false, selectedStudent: false })
              }
            }

            if (participantsList != '') {
              setNoOfPlayerData(noOfPlayer)
              setPlayerData(noOfPlayer)
              setSubmitCount(noOfPlayer.length)
            } else {
              setNoOfPlayerData(noOfPlayer)
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function addParticipantsSubmit() {
    showLoader(true);
    if (playerData.length > submitCount) {
      let tournamentPostData = setParticipantsDetails()
      addTournamentParticipants(tournamentPostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "success" });
            setIsOpen(true);
            setIsNavigate(`../sportsManagement/eventManager`);
            setPlayerData([]);
          }
          else if (data.success == false) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
          }
          else {
            showLoader(false);
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            setAlertStatus({ message: errorDescription, type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
          }
        }
        , (error) => {
          showLoader(false);
          setAlertStatus({ message: error, type: "error" });
          setIsOpen(true);
          setIsNavigate(null);
        }
      )
    } else if (playerData.length == submitCount) {
      showLoader(false);
      setAlertStatus({ message: 'Please Select The Students!.', type: "error" });
      setIsOpen(true);
      setIsNavigate(null);
    }
  }

  function setParticipantsDetails() {
    return ({
      licenseKey: getLicenseKey,
      tournamentDetailsId: tournamentDetailsId,
      userAccountId: userAccountId,
      studentDetails: playerData
    })
  }

  const [playerCount, setPlayerCount] = useState<any>('')
  const [inputFields, setInputFields] = useState<any>([])

  function add() {
    for (let i = 0; i < playerCount; i++) {
      setPlayerCount(playerCount + 1)
    }
    setInputFields([...inputFields, { noOfPlayer: playerCount + 1, studentDetailsId: '', studentDetailsIdForShow: '', isSubstitute: false, selectedStudent: false }])
  }

  function handleRemove(studentDetailsId: any, index: any) {

    const list = [...inputFields];
    list.splice(index, 1)
    setInputFields(list)

    let selectedItem
    playerData.forEach((item: any) => {
      if (item['studentDetailsId'] == studentDetailsId) {
        selectedItem = item
        playerData.splice(playerData.indexOf(selectedItem), 1);
      }
    });
  }


  return (
    <PageWrapper title='Add Participant'>
      <SubHeader>
        <SubHeaderLeft>
          <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../sportsManagement/eventManager`)}>
            Back to List
          </Button>
          <SubheaderSeparator />
        </SubHeaderLeft>
      </SubHeader>
      <Page container='fluid'>
        <div className='row px-4'>
          <div className='col-xl-4 '>
            <Card stretch>
              <CardHeader borderSize={1}>
                <CardLabel icon='list' iconColor='primary'>
                  <CardTitle>
                    Event Details
                  </CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody>
                <div className='row g-4'>
                  <div className='col-12'>
                    <FormGroup id='eventTypeName' label='Event Type Name' isFloating>
                      <Input
                        placeholder='EventTypeName'
                        value={tournamentDetailsData.eventTypeName}
                        disabled
                      />
                    </FormGroup>
                  </div>
                  <div className='col-12'>
                    <FormGroup id='tournamentName' label='Tournament Name' isFloating>
                      <Input
                        placeholder='TournamentName'
                        value={tournamentDetailsData.tournamentName}
                        disabled
                      />
                    </FormGroup>
                  </div>
                  <div className='col-12'>
                    <FormGroup id='tournamentDate' label='Tournament Date' isFloating >
                      <Input
                        placeholder='TournamentDate'
                        value={tournamentDetailsData.tournamentDate}
                        disabled
                      />
                    </FormGroup>
                  </div>
                  <div className='col-12'>
                    <FormGroup id='gender' label='Gender' isFloating>
                      <Input
                        placeholder='Gender'
                        value={tournamentDetailsData.gender}
                        disabled
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className='col-xl-8'>
            <Card stretch>
              <CardHeader borderSize={1}>
                <CardLabel icon='PersonAddAlt' iconColor='primary'>
                  <CardTitle>
                    Add Participants
                  </CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                    <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                      <thead>
                        <tr className='text-center table-primary fs-6'>
                          <th>S.NO</th>
                          <th>PLAYER NAME</th>
                          <th>Substitute</th>
                          <td />
                        </tr>
                      </thead>
                      {noOfPlayerData.map((i: any) => (
                        <tbody key={i.noOfPlayer}>
                          <tr>
                            <td className='text-center'><Label className="mt-3">{i.noOfPlayer}</Label></td>
                            <td className='' style={{ width: '350px', alignItems: 'flex-end' }}>
                              <div >
                                <FormGroup id="studentDetailsId" label="student Name" isFloating >
                                  <SearchableSelect isFloating
                                    className={i.selectedStudent ? 'border border-danger' : ''}
                                    ariaLabel='Select Student'
                                    onChange={(e: any) => addParticipantsDetails(i.noOfPlayer, e, i.isSubstitute)}
                                    value={i.studentDetailsIdForShow}
                                    list={studentListByGenderData.map((data: any) => (
                                      { value: data.studentDetailsId, label: data.studentName }
                                    ))}
                                  />
                                </FormGroup>
                                <>
                                  {i.selectedStudent ?
                                    <div className='mt-2 text-danger'>
                                      this student already selected.{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                    </div> : null}
                                </>
                              </div>
                            </td>
                            <td>
                              <div className='col-12 d-flex justify-content-center mt-3'>
                                <Checks className="border border-primary"
                                  disabled={i.studentDetailsId == 0}
                                  name='selectedList'
                                  value={i.isSubstitute ? 1 : 0}
                                  onChange={(e: any) => addParticipantsDetails(i.noOfPlayer, i.studentDetailsIdForShow, e.target.checked)}
                                  checked={i.isSubstitute == 1 ? true : false}
                                />
                              </div>
                            </td>
                            <td />
                          </tr>
                        </tbody>
                      ))}

                      <tbody>
                        {inputFields.map((x: any, i: any) => (
                          <tr key={x.noOfPlayer}>
                            <td className='text-center'><Label className="mt-3">{x.noOfPlayer}</Label></td>
                            <td>
                              <FormGroup id="studentDetailsId" label="student Name" isFloating>
                                <SearchableSelect isFloating
                                  className={x.selectedStudent ? 'border border-danger' : ''}
                                  ariaLabel='Select Student'
                                  onChange={(e: any) => addParticipantsDetails(x.noOfPlayer, e, x.isSubstitute)}
                                  value={x.studentDetailsIdForShow}
                                  list={studentListByGenderData.map((data: any) => (
                                    { value: data.studentDetailsId, label: data.studentName }
                                  ))}
                                />
                              </FormGroup>
                              <>
                                {x.selectedStudent ?
                                  <div className='mt-2 text-danger'>
                                    this student already selected.{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                  </div> : null}
                              </>
                            </td>
                            <td>
                              <div className='col-12 d-flex justify-content-center mt-3'>
                                <Checks
                                  className="border border-primary"
                                  disabled={x.studentDetailsId == 0}
                                  name='selectedList'
                                  value={x.isSubstitute ? 1 : 0}
                                  onChange={(e: any) => addParticipantsDetails(x.noOfPlayer, x.studentDetailsId, e.target.checked)}
                                  checked={x.isSubstitute}
                                />
                              </div>
                            </td>
                            <td>
                              <Button color='danger' isLight icon='Close' onClick={() => handleRemove(x.studentDetailsId, i)} />
                            </td>
                          </tr>
                        ))}

                        {playerData.length >= tournamentDetailsData.totalMember ?
                          <tr>
                            <td colSpan={4} className='align-middle'>
                              <Button onClick={add} icon='Add' isOutline color='info' size={'sm'} className='w-100'>Add
                              </Button>
                            </td>
                          </tr> : null
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="col-lg-12 d-flex justify-content-end">
                    <Button icon="Save" color="info" type="submit" isDisable={playerData.length > 0 ? false : true} onClick={addParticipantsSubmit}>
                      Save
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
      <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
    </PageWrapper>
  )
}

export default AddParticipant