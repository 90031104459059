import { useContext, useEffect, useRef, useState } from "react"
import Page from "../../../layout/Page/Page"
import Collapse from "../../bootstrap/Collapse"
import Icon from "../../icon/Icon";
import { toasts } from "../../../services/toast.service";
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Select from "../../bootstrap/forms/Select";
import Input from "../../bootstrap/forms/Input";
import Button from "../../bootstrap/Button";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { useFormik } from "formik";
import AlertService from "../../../services/AlertService";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import { useReactToPrint } from "react-to-print";
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import { getColumnsForDataTable, getGraduationType, getSectionDetails, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getAllStudent, getProfileForStudent, getStudentCourseChangedList, getStudentDetailsBySemesterId, updateStudentCourse } from "../../../services/student.service";
import { getLicenseKey } from "../../../services/application.settings";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import AuthContext from "../../../contexts/authContext";
import Avatar from "../../Avatar";
import UserImage2Webp from '../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../assets/img/wanna/wanna1.png';
import { showLoader } from "../../../services/loader.services";
import SearchableSelect from "../../../common/components/SearchableSelect";
import NoDataMsg from "../../../common/components/NoDataMsg";
import { getCourseFees } from "../../../services/fee.service";


const ChangeCourse = () => {

    useEffect(() => {
        getDepartment();
        getGraduationList();
        getSectionList();
        getAllStudentDetails();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [changedDepartmentId, setChangedDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [changedCourseId, setChangedCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [changedCourseData, setChangedCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentCourseChangeData, setStudentCourseChangeData] = useState([])
    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    const [columnDataById, setColumnDataById] = useState([])
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const [allStudentData, setAllStudentData] = useState<any>([])

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isReportOrAdd, setIsReportOrAdd] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentCourseChangeData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const componentRef = useRef(null);
    const [isLoader, setIsLoader] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')
    const [previousCourseId, setPreviousCourseId] = useState<any>(Number)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const courseChangeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            changedDepartmentId: '',
            changedCourseId: '',
            changedAdmissionNo: '',
        },
        validate: (values) => {
            const errors: {
                changedDepartmentId?: string;
                changedCourseId?: string;
                changedAdmissionNo?: string;
            } = {};
            if (!changedDepartmentId) {
                errors.changedDepartmentId = 'Required'
            }
            if (!changedCourseId) {
                errors.changedCourseId = 'Required'
            }
            if (!values.changedAdmissionNo) {
                errors.changedAdmissionNo = "Required"
            }
            return errors;
        },
        //validateOnChange: false,
        // onSubmit: () => { updateStudentCourseSubmit() },
        onSubmit: () => { getFeeMasterList(changedCourseId?.value, batchMasterId?.value) },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        setStudentDetailsData([])
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
        }

    }

    const selectStudent = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    function viewStudentList() {
        resetState();
        setDataSuccess(false)
        setIsLoader(true);
        getStudentProfile(studentDetailsId?.value)
    }

    function viewStudentCourseChangedList() {
        setDataSuccess(false)
        setIsLoader(true);
        setDepartmentId('')
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        getStudentCourseChanged()
        resetState();
    }

    function viewParticularStudent() {
        resetState();
        setDataSuccess(false)
        setIsLoader(true);
        getStudentProfile(studentDetailsId?.value);
    }

    function selectStudentCard() {
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setGraduationTypeId('')
        setStudentDetailsId('')
        resetState();
    }

    const selectChangedDepartment = (e: any) => {
        setChangedCourseId('')
        setChangedCourseData([])
        let changedDepartmentId = e
        setChangedDepartmentId(changedDepartmentId)
        if (changedDepartmentId?.value != undefined) {
            getChangedCourse(changedDepartmentId?.value)
        }
    }

    const selectChangedCourse = (e: any) => {
        let changedCourseId = e
        setChangedCourseId(changedCourseId)
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfile(studentDetailsId: number) {
        setIsLoader(true);
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile[0];
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentProfileData(data);
                        setPreviousCourseId(data.courseId)
                        setBatchMasterId({ value: data.batchMasterId, label: data.batchName })
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        setIsReportOrAdd('Add');
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(false);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataSuccess(false);
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                setIsLoader(false);
                setDataSuccess(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getChangedCourse(departmentId: number) {
        getCourse(departmentId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setChangedCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setChangedCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStudentCourse() {
        return ({
            studentDetailsId: studentDetailsId?.value,
            departmentId: changedDepartmentId?.value,
            previousCourseId: previousCourseId,
            changedCourseId: changedCourseId?.value,
            previousAdmissionNo: studentProfileData.admissionNo,
            changedAdmissionNo: courseChangeForm.values.changedAdmissionNo,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function updateStudentCourseSubmit() {
        if (courseChangeForm.isValid) {
            let courseChangePostData = setStudentCourse();
            updateStudentCourse(courseChangePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentCourseChanged()
                        resetState()

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (courseChangeForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getFeeMasterList(courseId: number, batchMasterId: number) {
        showLoader(true);
        getCourseFees(courseId, batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseFees;
                    if (data != undefined) {
                        updateStudentCourseSubmit();
                    } else {
                        showLoader(false);
                        setAlertStatus({ message: "Undefined Data", type: "error" });
                        setIsOpen(true);
                    }
                }
                else if (response.data.success === false) {
                    showLoader(false);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);

                } else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }, error => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getStudentCourseChanged() {
        setIsLoader(true);
        getStudentCourseChangedList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentCourseChangedList;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentCourseChangeData(data);
                        getColumnsForTable('getStudentCourseChangedList', 'get');
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        setIsReportOrAdd('Report');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false);
                    }
                }
                else if (response.data.success === false) {
                    getColumnsForTable('getStudentCourseChangedList', 'get');
                    setStudentCourseChangeData([]);
                    setNoDataMsg(response.data.message)
                    setIsLoader(false);
                    // toasts(response.data.message, "Error")
                    setDataSuccess(false);
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false);
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentCourseChangedList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function resetState() {
        courseChangeForm.resetForm();
        setChangedDepartmentId('');
        setChangedCourseId('');
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title='Course Change'>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card>
                            <CardHeader borderSize={1} className='d-flex '>
                                <div >
                                    <Button
                                        icon='PersonSearch'
                                        color='info'
                                        isLight={isStudentOrClassWise ? false : true}
                                        onClick={selectStudentCard}>
                                        Student
                                    </Button>
                                </div>
                                <div className="px-2">
                                    <Button
                                        icon='PeopleAlt'
                                        color='info'
                                        isLight={isStudentOrClassWise ? true : false}
                                        onClick={selectStudentCard}>
                                        Class Wise
                                    </Button>
                                </div>
                                <div className='ms-auto'>
                                    <Button
                                        icon='Report'
                                        color='danger'
                                        onClick={viewStudentCourseChangedList}>
                                        Report
                                    </Button>
                                </div>
                            </CardHeader>
                            {isStudentOrClassWise ?
                                <CardBody>
                                    <div className='row'>
                                        <div className="col-4 mt-3">
                                            <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                    list={allStudentData.map((data: any) => (
                                                        { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-3 mt-4">
                                            <Button icon='ArrowDownward' color='primary' onClick={viewParticularStudent} isDisable={studentDetailsId?.value == undefined}>View</Button>
                                        </div>
                                    </div>
                                </CardBody> :
                                <CardBody>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <FormGroup id='departmentId' label='Department' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                    list={departmentData.map((data: any) => (
                                                        { value: data.departmentId, label: data.departmentName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>

                                        {departmentId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType} value={graduationTypeId}
                                                    list={graduationTypeData.map((data: any) => (
                                                        { value: data.graduationTypeId, label: data.graduationType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {graduationTypeId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='courseId' label='Course' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Select Course' onChange={selectCourse} value={courseId}
                                                    list={courseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {courseId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                    list={batchMasterData.map((data: any) => (
                                                        { value: data.batchMasterId, label: data.batchName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                            <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                    list={semesterDetailsData.map((data: any) => (
                                                        { value: data.semesterDetailsId, label: data.semesterNumber }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {semesterDetailsId?.value != undefined ? <div className='col-3 mt-3'>
                                            <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                    list={sectionDetailsData.map((data: any) => (
                                                        { value: data.sectionDetailsId, label: data.sectionName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {sectionDetailsId?.value != undefined ? <div className="col-3 mt-3">
                                            <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                    list={studentDetailsData.map((data: any) => (
                                                        { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {studentDetailsId?.value != undefined ? <div className="col-3 mt-4">
                                            <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>
                                            &nbsp;
                                        </div>
                                            : null}
                                    </div>
                                </CardBody>}
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ? <> {isReportOrAdd == 'Add' ?
                        <div className="row">
                            <div className='col-xl-6 col-lg-8 col-md-6'>
                                <Card stretch>
                                    <CardHeader>
                                        <CardLabel icon='PersonPin'>
                                            <CardTitle tag='div' className='h5'>
                                                Current Profile
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='row mt-4'>
                                                <div className="col-lg-5">
                                                    <div className="row g-4">
                                                        <div className="col-lg-3">
                                                            <img className='rounded' src={studentProfileData.profilePath != null ? studentProfileData.profilePath : profilePic(studentProfileData.genderId)}
                                                                width={180} height={180} onError={(e : any) => pictNotLoading(e, studentProfileData.genderId)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='AccountBox'
                                                                        size='3x'
                                                                        color='primary'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>
                                                                        {studentProfileData.studentName}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        {studentProfileData.admissionNo}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12  mt-2'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Subject' size='3x' color='primary' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>
                                                                        {studentProfileData.departmentName}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        {studentProfileData.courseName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12  mt-2'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Subject' size='3x' color='primary' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>
                                                                        {studentProfileData.batchName}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        {studentProfileData.semesterNumber}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='col-12  mt-2'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Mail' size='3x' color='primary' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>
                                                                        {studentProfileData.email}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        {studentProfileData.smsToBeSentNumber}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className='col-xl-6 col-lg-6 col-md-6'>
                                <Card stretch tag='form' noValidate onSubmit={courseChangeForm.handleSubmit}>
                                    <CardHeader>
                                        <CardLabel icon='ChangeCircle' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Change To
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody className='pb-0'>
                                        <div className='row g-4 align-items-center'>
                                            <div className='col-lg'>
                                                <div className='col-12'>
                                                    <FormGroup id='changedDepartmentId' label='Department' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Department' onChange={selectChangedDepartment} value={changedDepartmentId} onBlur={courseChangeForm.handleBlur} isValid={courseChangeForm.isValid} isTouched={courseChangeForm.touched.changedDepartmentId} invalidFeedback={courseChangeForm.errors.changedDepartmentId}
                                                            list={departmentData.map((data: any) => (
                                                                { value: data.departmentId, label: data.departmentName }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-12 mt-4'>
                                                    <FormGroup id='changedCourseId' label='Course' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Course' onChange={selectChangedCourse} value={changedCourseId} onBlur={courseChangeForm.handleBlur} isValid={courseChangeForm.isValid} isTouched={courseChangeForm.touched.changedCourseId} invalidFeedback={courseChangeForm.errors.changedCourseId}
                                                            list={changedCourseData.map((data: any) => (
                                                                { value: data.courseId, label: data.courseName }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-12 mt-4'>
                                                    <FormGroup id='changedAdmissionNo' label='Admission No' isFloating>
                                                        <Input placeholder='Admission No' type='text' onChange={courseChangeForm.handleChange} onBlur={courseChangeForm.handleBlur} value={courseChangeForm.values.changedAdmissionNo} isValid={courseChangeForm.isValid} isTouched={courseChangeForm.touched.changedAdmissionNo} invalidFeedback={courseChangeForm.errors.changedAdmissionNo} required />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>

                                    <CardFooter>
                                        <CardFooterRight>
                                            <Button type='submit' icon='Save' color='info' isDisable={!courseChangeForm.isValid && !!courseChangeForm.submitCount}>Update</Button>
                                        </CardFooterRight>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>

                        : <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Course Changed Student List
                                        </CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-6 justify-content-end d-print-none'>
                                    <div className='d-flex col-12 justify-content-end gap-2'>
                                        <div className='d-flex col-6 justify-content-end' data-tour='search'>
                                            <label className='border-0 bg-transparent cursor-pointer mt-2'
                                                htmlFor='searchInput'>
                                                <Icon icon='Search' size='2x' color='primary' />
                                            </label>
                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </div>

                                        <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                            <DropdownToggle>
                                                <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                                <DropdownItem>
                                                    <div className='container py-2'>
                                                        <form className='row g-3'>
                                                            <div className='col-12'>
                                                                <FormGroup>
                                                                    <h6>Select All</h6>
                                                                    <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentCourseChangedList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                                </FormGroup>
                                                            </div>
                                                            <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                <FormGroup>
                                                                    <h6>Columns</h6>
                                                                    <ChecksGroup>
                                                                        {allColumnsData.map((i: any) => (
                                                                            <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                        ))}
                                                                    </ChecksGroup>
                                                                </FormGroup>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                        <CardActions className='d-print-none'>
                                            <Dropdown isButtonGroup>
                                                <DropdownToggle>
                                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StudentCourseChangeList", studentCourseChangeData, columnVisibilityData)}> Excel </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentCourseChangeData, columnVisibilityData, "StudentCourseChangeList")}> PDF
                                                        </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StudentCourseChangeList", studentCourseChangeData, columnVisibilityData)}> CSV </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </CardActions>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.studentCourseChangeId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.studentCourseChangeId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                            : <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                        </Card>
                    }</> : null}
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default ChangeCourse